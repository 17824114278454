import { SingleFactorRegressionResult } from "../interfaces"

/**
 * Expose single factor linear regression analysis
 *
 */
export class RegressionAnalysis {
    /**
     * Get single factor linear regression analysis result
     *
     * @returns
     */
    private static getSingleFactorRegressionResult(x: number[], y: number[]): SingleFactorRegressionResult | null {
        // S(x)
        const sumX = this.sum(x)
        // S(y)
        const sumY = this.sum(y)
        // S(x, y: i, j => i * j)
        const sumXY = this.sumOfMultiplied(x, y)
        // |x|
        const n = x.length

        // S(x: i => i^2)
        const sumXX = this.sum(x.map((v) => Math.pow(v, 2)))

        // S(x: i => i)^2
        const sumXSquared = Math.pow(sumX, 2)
        // |x| * S(x: i => i^2) - S(x: i => i)^2
        const divisor = (n * sumXX - sumXSquared)

        if (!divisor) {
            return null
        }

        // (S(y) * S(x: i => i^2) - S(x) * S(x, y: i, j => i * j)) / d
        const a = (sumY * sumXX - sumX * sumXY) / divisor
        // (|x| * S(x, y: i, j => i * j) - S(x) * S(y)) / d
        const b = (n * sumXY - sumX * sumY) / divisor

        return { a, b }
    }

    /**
     * Sum number array values
     *
     * @param v
     * @returns
     */
    private static sum(v: number[]) {
        return v.reduce((p, c) => p + c, 0)
    }

    /**
     * Sum multipied values of two number arrays
     *
     * @param a
     * @param b
     * @returns
     */
    private static sumOfMultiplied(a: number[], b: number[]) {
        let t = 0
        for (let i = 0; i < a.length; i++) {
            t += a[i] * b[i]
        }
        return t
    }

    /**
     *
     */
    private x: number[]
    /**
     *
     */
    private y: number[]

    /**
     *
     * @param x
     * @param y
     */
    constructor(x: number[], y: number[]) {
        if ((x.length !== y.length)) {
            throw new Error(
                `Arrays have to be with same length:  x:${x.length}, y:${y.length}`
            )
        }

        this.x = x
        this.y = y
    }

    /**
     * Get single factor linear regression analysis result
     *
     * @returns
     */
    public getSingleFactorRegressionResult(): SingleFactorRegressionResult | null {
        return RegressionAnalysis.getSingleFactorRegressionResult(this.x,
            this.y)
    }
}
