import { Notification } from "@/core/interfaces"
import { reactive } from "vue"

import { NotificationType } from "../enums"


class NotificationsService {
    private state: { notification: Notification | null } = reactive({
        notification: null
    });

    get notification(): Notification | null {
        return this.state.notification
    }

    notify(notification: Notification) {
        this.state.notification = notification
        setTimeout(() => {
            this.removeNotification()
        }, notification.duration || 3000)
    }

    notifySuccess(message: string) {
        this.notify({
            title: "Success",
            type: NotificationType.Success,
            message
        })
    }

    notifyError(message = "Something went wrong") {
        this.notify({
            title: "Error",
            type: NotificationType.Error,
            message
        })
    }

    removeNotification() {
        this.state.notification = null
    }
}

export const notificationsService = new NotificationsService()