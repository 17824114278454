
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { PerformanceParameterBase } from "./PerformanceParameterBase"

export class GridParameter extends PerformanceParameterBase<Models.GridModel> {

    async fetch() {

        const loadLosses: { load: number, loss: number }[] = []
        for await (const loadLoss of this.performance.getLoadLosses()) {
            loadLosses.push({
                load: Number(loadLoss.getLoad()),
                loss: Number(loadLoss.getLosses())
            })
        }

        return {
            capacity: this.performance.getCapacity()!,
            type: "grid",
            loadLosses
        } as Interface.GridPerformanceData
    }

}