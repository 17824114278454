import { NodesetHelper } from "@/core/helpers"
import { AggregatorSimulateClient, MeteredData, UnbatchEvents } from "@/core/interfaces"
import { Models } from "@ekko/predict-client-api"
import { DateGroupSimulationResult, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"
import { EnergyBaseClient } from "./EnergyBaseClient"
import { AllocationOuputNode } from "./AllocationOuputNode"
import { ReadingType } from "@/History/enums"

/**
 *
 */
export class EnergyClient extends EnergyBaseClient implements AggregatorSimulateClient {
    /**
     *
     * @param site
     * @param readingType
     * @returns
     */
    getHistoricalReadings(site: Models.SiteModel, readingType?: ReadingType.POWER_LOAD | ReadingType.POWER_SUPPLY) {
        return this.getHistoricalEnergyReadings(site, readingType)
    }

    async simulate(
        nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo,
        deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null,
    ): Promise<NodeDynamicCostsByType> {
        return this.simulateEnergy(
            nodesets,
            siteInfo,
            deviceSelected,
            unbatchCallbacks,
        )
    }

    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        return this.simulateEnergyAllocated(nodesets, siteInfo, deviceSelected,
            unbatchCallbacks)
    }

    async simulateAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        return this.simulateEnergyAttributed(nodesets, siteInfo,
            deviceSelected, unbatchCallbacks)
    }

    async simulateHistorical(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        return this.simulateEnergyHistorical(nodesets, siteInfo,
            deviceSelected, meteredData, unbatchCallbacks)
    }

    async simulateHistoricalAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        return this.simulateEnergyHistoricalAllocated(nodesets, siteInfo,
            deviceSelected, meteredData, unbatchCallbacks)
    }

    async simulateHistoricalAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        return this.simulateEnergyHistoricalAttributed(nodesets, siteInfo,
            deviceSelected, meteredData, unbatchCallbacks)
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param unbatchCallbacks
     */
    async simulateByTimeNode(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, unbatchCallbacks: UnbatchEvents
    ): Promise<DateGroupSimulationResult[]> {
        const nodes = NodesetHelper.nodes(nodesets)
        let result: Map<string, Map<string, { fixed: number, it: number, variable: number }>>
        if (this.isFilteringITNodes(nodes)) {
            const initialResult = await this.simulateEnergyResult(true,
                nodesets, siteInfo, unbatchCallbacks)
            result = this.addAllocations(initialResult, nodes, null, AllocationOuputNode.Item)
        } else {
            const initialResult = await this.simulateEnergyResult(false,
                nodesets, siteInfo, unbatchCallbacks)
            result = this.buildResultTree(initialResult, nodes, null)
        }

        const output: DateGroupSimulationResult[] = []

        for (const [dateGroup, nodeCosts] of result.entries()) {
            output.push({ dateGroup, nodeCosts })
        }

        return output
    }
}