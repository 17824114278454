<template>
  <div class="home">
    <!-- will redirect to app -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component"

@Options({})
export default class Home extends Vue {
  // Methods
  created() {
    this.$router.push({name: "AppSiteAccess"})
  }
}
</script>

<style scoped>
.home {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 3rem;
  overflow: scroll;
  height: 90vh;
}
</style>
