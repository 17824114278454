/**
 *
 */
export enum ModalViewReferenceClimate {
    ClimateSelection = "climateSelection",
}

/**
 *
 */
export enum ModalViewReferenceDevice {
    DeviceInformation = "deviceInformation",
    ProvisioningEvent = "provisioningEvent",
    Resilience = "resilience",
}

/**
 *
 */
export enum ModalViewReferenceDeviceInformation {
    DeviceModeTable = "deviceModeTable",
    DeviceModeDetail = "deviceModeDetail",
}

/**
 *
 */
export enum ModalViewReferenceFooter {
    ClimateLibrary = "climateLibrary",
    DeviceLibrary = "deviceLibrary",
    ITWorkloadProfileLibrary = "itWorkloadProfileLibrary",
    LayoutError = "layoutError",
    ManufacturerLibrary = "manfacturerLibrary",
}

/**
 *
 */
export enum ModalViewReferenceIT {
    ITProvisioningEvent = "itProvisioningEvent",
}

/**
 *
 */
export enum ModalViewReferenceOtherLoad {
    OtherProvisioningEvent = "otherProvisioningEvent",
}

/**
 *
 */
export enum ModalViewReferenceSiteAccess {
    EULA = "eula",
    ImportantInformation = "importantInformation",
    Permissions = "permissions",
    VersionHistory = "versionHistory",
}

/**
 *
 */
export enum ModalViewReferenceSiteConfiguration {
    PowerCostProfile = "powerCostProfile",
    WeatherStoredSelection = "weatherStoredSelection",
    ClimateSetting = "climateSetting",
}