import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { Base } from "./Base"
import { NRef } from "@/store/SiteViewState"

/**
 *
 */
export class ITNode extends Base<Models.ITNodeModel> {
    /**
     *
     * @returns
     */
    private getService() {
        return ApiService.getNamedService<Services.ITNodeService>("ITNodeService")
    }
    async add(): Promise<NRef<Models.ITNodeModel>> {
        const service = await this.getService()
        const [nodeOut] = await service.addITNode(this.node)
        this.node = nodeOut
        await this.connect()
        return nodeOut
    }
    async connect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.addItNodes([this.node])
    }
    async disconnect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.removeItNodes([this.node])
    }
    async remove(): Promise<any> {
        const service = await this.getService()
        await service.removeITNode(this.node)
        return this.disconnect()
    }
    async update(): Promise<any> {
        const service = await this.getService()
        await service.updateITNode(this.node)
    }
}