import ApiService from "@/core/services/api.service"
import { jsonRpcService } from "@/core/services/json-rpc.service"
import { Models } from "@ekko/predict-client-api"

import { HistoricalCost, MonthHour } from "../interfaces"
import { HistoryClientBase } from "./HistoryClientBase"

/**
 * Class for Cost
 */
export class CostHistoryClient extends HistoryClientBase {
    async fetch(siteId: string): Promise<Map<string, number>> {
        const siteService = await ApiService.getSiteService()
        const site: Models.SiteModel = await siteService.getSite(siteId)
        const powerCostProfile = await site.getPowerCostProfile()
        const powerCosts: MonthHour<number>[] = []

        if(!powerCostProfile) {
            throw new Error("No power cost profile for site")
        }

        for await (const powerCostMonthModel of powerCostProfile.getPowerCostMonths()) {
            for await (const powerCostModel of powerCostMonthModel.getPowerCosts()) {
                const hour = powerCostModel.getHour()
                const cost = powerCostModel.getCost()
                const month = powerCostMonthModel.getMonth()

                if (hour && cost && month) {
                    const powerCost: MonthHour<number> = {
                        hour: Number(hour),
                        month: new Date(month),
                        value: cost
                    }

                    powerCosts.push(powerCost)
                }
            }
        }

        return this.getSiteHistoricalCosts(siteId, powerCosts)
    }
    /**
     * Call json-rpc service to get Site Historical Costs
     * @param siteId
     * @param powerCosts
     */
    async getSiteHistoricalCosts(
        siteId: string, powerCosts: MonthHour<number>[]
    ): Promise<Map<string, number>> {
        const rpcResult: HistoricalCost[] =
            await jsonRpcService.getSiteHistoricalCosts(siteId, this.period, this.granularity, powerCosts)

        const output = new Map<string, number>()
        if (rpcResult) {
            for (const cost of rpcResult) {
                output.set(cost.date.toISOString(), cost.amount)
            }
        }
        return output
    }
}