import { Paginated } from "@/core/helpers/Paginated"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { ITLoadProfileState } from "./types"

const getters = <GetterTree<ITLoadProfileState, any>> {
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getITLoadProfiles(state, getters): PaginatedResult<Models.ITLoadProfileModel> {
        return new Paginated(
            state.itLoadProfiles, state.page, state.pageSize, getters.itLoadProfilesSort,
            getters.itLoadProfilesFilter
        )
    },
    /**
     *
     * @param state
     * @returns
     */
    itLoadProfilesFilter(state): (a: Models.ITLoadProfileModel) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return itLoadProfile => {
            const nameMatches = itLoadProfile?.getName()?.match(regexp)

            const accessFilterMatches = state.accessFilter && state.accessFilter !== 'all'
                ? itLoadProfile?.getAccessLevel() === state.accessFilter
                : true

            return nameMatches && accessFilterMatches || true
        }
    },
    /**
     *
     * @param state
     * @returns
     */
    itLoadProfilesSort(state): (a: Models.ITLoadProfileModel, b: Models.ITLoadProfileModel) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "AccessLevel":
                return Paginated.sortUndefinedFirst((profile) => profile.getAccessLevel(), reverse)
            case "Name":
                return Paginated.sortUndefinedFirst((profile) => profile.getName(), reverse)
            default:
                return (a, b) => 0
        }
    },
    getITLoadProfile: (state) => (id: string): Models.ITLoadProfileModel | undefined => {
        return state.itLoadProfiles.find(m => (m as any).id === id)
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.itLoadProfiles?.length === 0
    }
}

const mutations = <MutationTree<ITLoadProfileState>>{
    setITLoadProfiles(state, itLoadProfiles): void {
        state.itLoadProfiles = itLoadProfiles
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    }
}

const actions = <ActionTree<ITLoadProfileState, any>>{

}

export { getters, mutations, actions }
