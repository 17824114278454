import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { DeviceModelType } from "predict-performance-calculation"
import { UserReportableError } from "../UserReportableError"
import { PerformanceType } from "../enums"
import * as Interface from "../interfaces"
import { NumberFormat } from "./NumberFormat"
import { Interface as PInterface } from "predict-performance-calculation"
import { LegacyPerfSplitTemperatureType } from "../enums/LegacyPerfSplitTemperatureType"

/**
 *
 */
export class AddDevicePerformanceHelper {
    /**
     *
     * @param cells
     * @throws
     */
    static assertAllNonNegative(cells: number[]) {
        if (cells.some(cell => +cell < 0)) {
            throw new UserReportableError(
                `Row contains negative values: ${cells.join(", ")}`)
        }
    }

    /**
     *
     * @param row
     * @param length
     * @param options
     * @throws
     */
    static assertRowLength(row: string[], length: number,
        options?: {message?: string, isMin?: boolean}
    ) {
        const {message, isMin} = options ?? {}
        if (
            (isMin && row.length < length) ||
            (!isMin && row.length != length)
        ) {
            throw new UserReportableError(message ??
                `One or more rows are not of the expected length (${row.length} != ${length})`
            )
        }
    }

    /**
     *
     * @param cells
     * @param options
     * @throws
     * @returns the cells as numbers
     */
    static numericRow(cells: string[],
        options?: {message?: string, allowPercent?: boolean}
    ) {
        const {message, allowPercent} = options || {}
        const normalisedCells = allowPercent ?
            cells.map(cell => cell.replace(/%$/, "")) : cells
        const normalisedCellValues = normalisedCells.map(cell => +cell)
        if (normalisedCellValues.some(cell => Number.isNaN(cell))) {
            throw new UserReportableError(
                message ??
                `Row contains non-numeric cells: ${cells.join(", ")}`)
        }

        return normalisedCellValues
    }

    /**
     * @param grid
     * @param form
     */
    static async toGridModel(grid: Models.GridModel, form: Interface.DeviceModelForm) {
        form.details.capacity = grid.getCapacity()

        form.performance = {
            loadLosses: [],
        } as Interface.GridPerformanceForm

        for await (const loadLoss of grid.getLoadLosses()) {
            form.performance.loadLosses.push({
                load: NumberFormat.to1dp(loadLoss.getLoad()! * 100),
                loss: NumberFormat.to2dp(loadLoss.getLosses()! * 100),
            })
        }

        return form
    }

    /**
     * @param heatExchanger
     * @param form
     */
    static async toHeatExchangerModel(heatExchanger: Models.HeatExchangerModel, form: Interface.DeviceModelForm) {
        form.details.deviceModelType = DeviceModelType.HEAT_EXCHANGER
        form.details.capacity = heatExchanger.getCapacity()

        const temperatureType =
            heatExchanger.getTemperatureType() as LegacyPerfSplitTemperatureType | PInterface.TemperatureType

        form.performance = {
            temperatureType,
            supplyWaterT: heatExchanger.getSupplyWaterT(),
            returnWaterT: heatExchanger.getReturnWaterT(),
            approachTLow: heatExchanger.getApproachTLow(),
            approachTMax: heatExchanger.getApproachTMax(),
            coolerApproachTMin: heatExchanger.getCoolerApproachTMin(),
            coolerApproachTMax: heatExchanger.getCoolerApproachTMax(),
            maxOperatingTemperature: heatExchanger.getMaxOperatingTemperature(),
        } as Interface.HeatExchangerPerformanceForm

        return form
    }

    /**
     * @param polynomial
     * @param form
     */
    static async toPolynomialModel(polynomial: Models.PolynomialModel, form: Interface.DeviceModelForm) {
        form.details.capacity = polynomial.getCapacity()

        const polynomialValues: Models.PolynomialValueModel[] = []
        for await (const polynomialValue of polynomial.getPolynomialValues()) {
            polynomialValues.push(polynomialValue)
        }

        form.performance = polynomialValues.reduce(
            (previous: any, current) => {
                switch (current.getPower()) {
                    case 0:
                        previous.polynomialValues.constant = {
                            power: current.getPower(),
                            coefficient: current.getCoefficient(),
                        }
                        return previous
                    case 1:
                        previous.polynomialValues.linear = {
                            power: current.getPower(),
                            coefficient: current.getCoefficient(),
                        }
                        return previous
                    case 2:
                        previous.polynomialValues.squared = {
                            power: current.getPower(),
                            coefficient: current.getCoefficient(),
                        }
                        return previous
                    case 3:
                        previous.polynomialValues.cubic = {
                            power: current.getPower(),
                            coefficient: current.getCoefficient(),
                        }
                        return previous
                }
            },
            { polynomialValues: {} }
        ) as Interface.PolynomialPerformanceForm

        return form
    }

    /**
     * @param gridList
     * @param form
     */
    static async toGridListModel(gridList: Models.GridListModel, form: Interface.DeviceModelForm) {
        form.details.capacity = gridList.getCapacity()

        form.performance = await AddDevicePerformanceHelper.toGridListPerformanceData(gridList)
        return form
    }

    /**
     * @param gridList
     * @returns
     */
    static async toGridListPerformanceData(gridList: Models.GridListModel) {
        const temperatureType =
            gridList.getTemperatureType() as LegacyPerfSplitTemperatureType | PInterface.TemperatureType
        const performance: Interface.GridListPerformanceForm = {
            loadLossesGrids: [],
            temperatureType,
        } as Interface.GridListPerformanceForm

        for await (const tempLoadLoss of gridList.getTempLoadLosses()) {
            for await (const loadLoss of tempLoadLoss.getLoadLosses()) {
                const load = NumberFormat.to1dp(loadLoss.getLoad()! * 100)
                const lossTempValue: Interface.LossTemp = {
                    temp: tempLoadLoss.getTemperature()!,
                    loss: NumberFormat.to2dp(loadLoss.getLosses()! * 100),
                }
                const existing = performance.loadLossesGrids.find((e) => e.load == load)
                if (existing) {
                    existing.losstemp.push(lossTempValue)
                } else {
                    performance.loadLossesGrids.push({ load, losstemp: [lossTempValue] })
                }
            }
        }

        return performance
    }

    /**
     * @param data
     * @param form
     */
    static add(formData: Interface.DeviceModelForm, newMode: Models.DeviceModeModel) {
        switch (formData.performanceType) {
            case PerformanceType.Grid:
                return this.addGrid(formData, newMode)
            case PerformanceType.GridList:
                return this.addGridList(formData, newMode)
            case PerformanceType.Polynomial:
                return this.addPolynomial(formData, newMode)
            default:
                throw new Error(`Cannot add unknown type ${formData.performanceType}`)
        }
    }

    /**
     * @param form
     * @param newMode
     */
    private static async addGrid(formData: Interface.DeviceModelForm, newMode: Models.DeviceModeModel) {
        const {
            GridModel,
            LoadLossModel
        } = await ApiService.getModels()

        const performance = formData.performance as Interface.GridPerformanceForm

        const gridService = await ApiService.getGridService()
        const grid: Models.GridModel = new GridModel()
        grid.setCapacity(formData.details.capacity as number)
        await grid.addDeviceMode(newMode)
        gridService.addGrid(grid)

        // LOAD LOSSES
        const lossService: Services.LoadLossService =
            await ApiService.getLoadLossService()
        const loadLosses: Models.LoadLossModel[] = []
        for (const ls of performance.loadLosses) {
            const loadLoss: Models.LoadLossModel = new LoadLossModel()
            loadLoss.setLoad(NumberFormat.to3dp(+ls.load / 100))
            loadLoss.setLosses(ls.loss / 100)
            loadLoss.addGrid(grid)
            loadLosses.push(loadLoss)
        }
        await lossService.addLoadLoss(loadLosses)
    }

    /**
     * @param form
     * @param newMode
     */
    static async addHeatExchanger(formData: Interface.DeviceModelForm, newMode: Models.DeviceModeModel) {
        const {
            HeatExchangerModel
        } = await ApiService.getModels()

        const performance = formData.performance as Interface.HeatExchangerPerformanceForm
        const heatExchangerService = await ApiService.getHeatExchangerService()
        const heatExchanger: Models.HeatExchangerModel = new HeatExchangerModel()
        heatExchanger.setTemperatureType(performance.temperatureType)
        heatExchanger.setCapacity(formData.details.capacity as number)
        heatExchanger.setApproachTLow(performance.approachTLow as number)
        heatExchanger.setApproachTMax(performance.approachTMax as number)
        heatExchanger.setSupplyWaterT(performance.supplyWaterT as number)
        heatExchanger.setReturnWaterT(performance.returnWaterT as number)
        heatExchanger.setCoolerApproachTMin(
            performance.coolerApproachTMin as number
        )
        heatExchanger.setCoolerApproachTMax(
            performance.coolerApproachTMax as number
        )
        heatExchanger.setMaxOperatingTemperature(
            performance.maxOperatingTemperature as number
        )

        formData.electrical.peakDraw = null
        formData.electrical.nameplateDraw = null
        formData.electrical.idleDraw = null
        await heatExchanger.addDeviceMode(newMode)
        heatExchangerService.addHeatExchanger(heatExchanger)
    }

    /**
     * @param form
     * @param newMode
     */
    private static async addPolynomial(formData: Interface.DeviceModelForm, newMode: Models.DeviceModeModel) {
        const {
            PolynomialModel,
            PolynomialValueModel
        } = await ApiService.getModels()

        const performance = formData.performance as Interface.PolynomialPerformanceForm
        const polynomialService = await ApiService.getPolynomialService()
        const polynomial: Models.PolynomialModel = new PolynomialModel()
        polynomial.setCapacity(formData.details.capacity as number)
        await polynomial.addDeviceMode(newMode)
        polynomialService.addPolynomial(polynomial)

        const polynomialValueService = await ApiService.getPolynomialValueService()
        const polynomialValues: Models.PolynomialValueModel[] = []
        for (const pv of Object.values(performance.polynomialValues)) {
            const polynomialValue: Models.PolynomialValueModel =
                new PolynomialValueModel()
            polynomialValue.setPower(pv.power as number)
            polynomialValue.setCoefficient(pv.coefficient as number)
            polynomialValue.addPolynomial(polynomial)
            polynomialValues.push(polynomialValue)
        }
        await polynomialValueService.addPolynomialValue(polynomialValues)
    }

    /**
     * @param form
     * @param newMode
     */
    private static async addGridList(formData: Interface.DeviceModelForm, newMode: Models.DeviceModeModel) {
        const {
            GridListModel,
            TempLoadLossModel,
            LoadLossModel
        } = await ApiService.getModels()

        const performance = formData.performance as Interface.GridListPerformanceForm
        const gridListService = await ApiService.getGridListService()
        const tempLoadLossService = await ApiService.getNamedService<Services.TempLoadLossService>("TempLoadLossService")
        const loadLossService = await ApiService.getLoadLossService()
        const gridList: Models.GridListModel = new GridListModel()

        gridList.setTemperatureType(performance.temperatureType!)
        gridList.setCapacity(formData.details.capacity as number)
        await gridList.addDeviceMode(newMode)
        const [newGridList]: Models.GridListModel[] =
            await gridListService.addGridList(gridList)

        const tempLoadLosses = new Map<number, Interface.TempLoadLoss>()
        for (const loadLossGridList of performance.loadLossesGrids) {
            for (const ls of loadLossGridList.losstemp) {
                const temperature = +ls.temp
                let tempLoadLoss: Interface.TempLoadLoss
                const existing = tempLoadLosses.get(temperature)
                if (existing) {
                    tempLoadLoss = existing
                } else {
                    tempLoadLoss = { loadLosses: [], temperature }
                    tempLoadLosses.set(temperature, tempLoadLoss)
                }
                tempLoadLoss.loadLosses.push({
                    load: NumberFormat.to3dp(loadLossGridList.load / 100),
                    loss: ls.loss / 100,
                })
            }
        }

        for (const tempLoadLoss of tempLoadLosses.values()) {
            const tempLoadLossModel: Models.TempLoadLossModel =
                new TempLoadLossModel()
            tempLoadLossModel.setTemperature(tempLoadLoss.temperature)
            tempLoadLossModel.addGridList(newGridList)
            const [newTempLoadLoss]: Models.TempLoadLossModel[] =
                await tempLoadLossService.addTempLoadLoss(tempLoadLossModel)

            if (tempLoadLoss.loadLosses) {
                const loadLosses: Models.LoadLossModel[] = []
                for (const ls of tempLoadLoss.loadLosses) {
                    const loadLoss: Models.LoadLossModel = new LoadLossModel()
                    loadLoss.setLoad(ls.load)
                    loadLoss.setLosses(ls.loss)
                    loadLoss.addTempLoadLoss(newTempLoadLoss)
                    loadLosses.push(loadLoss)
                }
                await loadLossService.addLoadLoss(loadLosses)
            }
        }
    }
}