import { AnyNodeModel } from "@/store/SiteViewState"

/**
 *
 */
export class MeterMappingsHelper {
    /**
     *
     * @param node 
     * @returns
     */
    static async getMeterMappingsSensorTypes(node: AnyNodeModel) {
        const sensorTypes = new Set<string>()

        for await (const meterMappings of node.getMeterMappings()) {
            const sensorType = meterMappings.getSensorType()

            if (sensorType) {
                sensorTypes.add(sensorType)
            }
        }
        return [...sensorTypes]
    }
}