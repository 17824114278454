import { AxisConfiguration } from "../interfaces"
import { NumberFormat } from "./NumberFormat"
import { NumberFormatMode } from "./NumberFormatMode"
import { StringFormatMode } from "./StringFormatMode"

/**
 *
 */
export class GraphFormat {
  /**
   * @param value
   * @param axis
   * @param options
   */
  static formatAxisValue(value: string | number, axis: AxisConfiguration, options?: { units?: string }) {
    let formattedValue = value

    if (!axis.format) {
      formattedValue = typeof value === "number" ? value.toFixed(2) : value
    }

    return this.formatValue(formattedValue, axis.format, { units: axis?.unit, digits: axis?.digits, ...(options || {}) })
  }

  /**
   * @param value
   * @param format
   * @param options
   */
  static formatValue(value: string | number, format: NumberFormatMode | StringFormatMode, options?: { units?: string, digits?: number }) {
    if (Object.values(NumberFormatMode).includes(format as NumberFormatMode)) {
      return NumberFormat.formatValue(+value, format as NumberFormatMode, options)
    } else {
      return value.toString()
    }
  }
}