
/**
 *
 */
export class CSVHelper {
    /**
     *
     * @param cells
     * @returns
     */
    private static csvLine(cells: (string | number)[]) {
        return cells.map((cell) => {
            if (typeof cell === 'string') {
                return cell.match(/[",\n]/) ? `"${cell.replace(/"/g, '""')}"` : cell
            } else {
                return cell
            }

        }).join(",") + "\r\n"
    }

    /**
     * Converts a structured object to a CSV file
     *
     * @param items
     * @returns
     */
    static dataToCSV(items: { [key: string]: number | string }[]) {
        const headers = Object.keys(items[0])
        const orderedHeaders = [...headers.filter(n => n !== "Actual"), ...headers.filter(n => n === "Actual")]
        return this.csvLine(orderedHeaders) + items.map(v => this.csvLine(orderedHeaders.map(h => v[h]))).join("")
    }

    /**
     * Triggers a download of a CSV version of `items`.
     *
     * @param items
     * @param filename
     */
    static downloadData(items: { [key: string]: number | string }[], fileName = "graph") {
        const link = document.createElement("a")
        const data = this.dataToCSV(items)
        link.href = `data:text/csv,${encodeURI(data)}`
        link.download = `${fileName}.csv`
        link.click()
    }

    /**
     * @param content
     * @returns
     */
    static tsvToRowData(content: string) {
        const lines = content.split(/\r?\n/)
        if (lines[lines.length - 1] == "") {
            lines.pop() // This is expected
        } else {
            console.warn("Last line does not end with newline, probable bad paste")
        }
        return lines.map(line => line.split(/\t/))
    }
}