import { AnyNode } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"
import { NodeType } from "predict-performance-calculation"

/**
 *
 */
export class ConnectionRuleHelper {

    /**
     * Adds an overhead connection between allowed nodes
     * @param fromNode
     * @param toNode
     */
    static addOverhead(fromNode: AnyNode, toNode: AnyNode) {

        if (fromNode instanceof Models.OtherNodeModel) {
            if (toNode.type === NodeType.Item) {
                fromNode.addOtherSupplyForItem(toNode as Models.ItemModel)
            }
            else if (toNode.type == NodeType.ITNode) {
                fromNode.addOtherSupplyForItNode(toNode as Models.ITNodeModel)
            }
            else if (toNode.type == NodeType.OtherNode) {
                fromNode.addOtherSupplyBy(toNode as Models.OtherNodeModel)
            }

        } else if (fromNode instanceof Models.ItemModel) {
            if (toNode.type == NodeType.OtherNode) {
                fromNode.addOtherSupplyBy(toNode as Models.OtherNodeModel)
            }
            else {
                throw new Error(`Overhead connections cannot be made from ${fromNode.type} to ${toNode.type}`)
            }
        } else {
            throw new Error(`Overhead connections cannot be made from ${fromNode.type} to ${toNode.type}`)
        }
    }

    /**
     * Removes an existing overhead connection
     * @param fromNode
     * @param toNode
     */
    static removeOverhead(fromNode: AnyNode, toNode: AnyNode) {

        if (fromNode instanceof Models.OtherNodeModel) {
            if (toNode.type === NodeType.Item) {
                fromNode.removeOtherSupplyForItem(toNode as Models.ItemModel)
            }
            else if (toNode.type == NodeType.ITNode) {
                fromNode.removeOtherSupplyForItNode(toNode as Models.ITNodeModel)
            }
            else if (toNode.type == NodeType.OtherNode) {
                fromNode.removeOtherSupplyBy(toNode as Models.OtherNodeModel)
            }
        } else if (fromNode instanceof Models.ItemModel) {
            if (toNode.type == NodeType.OtherNode) {
                fromNode.removeOtherSupplyBy(toNode as Models.OtherNodeModel)
            }
        }
    }
}