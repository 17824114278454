import { SimulationNode } from "@/Simulation/interfaces"
import { ITComparisonHistoryClient } from "./ITComparisonHistoryClient"

/**
 * Class for DCiE
 */
export class DCiEHistoryClient extends ITComparisonHistoryClient {
    async fetchComparison(
        siteId: string,
        nodes: SimulationNode[],
        actualItLoad: Map<string, number>
    ): Promise<Map<string, number>> {
        const datacentreEnergy = await this.fetchEnergyHistory(siteId, nodes)
        const dcieValues = new Map<string, number>()
        for (const energyReading of datacentreEnergy) {
            const readingKey = new Date(energyReading.timestamp).toISOString()
            const itLoadReading = actualItLoad.get(readingKey)

            if (itLoadReading) {
                dcieValues.set(readingKey, energyReading.value / itLoadReading)
            }
        }

        return dcieValues
    }
}