import { Reading } from "@/core/interfaces"
import { SimulationNode } from "@/Simulation/interfaces"
import { EnergyPerformanceHistoryClient } from "./EnergyPerformanceHistoryClient"

/**
 *
 */
export abstract class ITComparisonHistoryClient
    extends EnergyPerformanceHistoryClient {

    /**
     * Fetches and calculates the values for a given site's comparison.
     *
     * @param siteId
     * @param nodes
     * @param actualItLoad
     * @returns A Promise that resolves to a Map with timestamps as keys and
     * values as values.
     */
    protected abstract fetchComparison(siteId: string, nodes: SimulationNode[], actualItLoad: Map<string, number>| undefined,
    ): Promise<Map<string, number>>

    fetch(siteId: string, nodes?: undefined): Promise<Reading<Date>[]>
    fetch(siteId: string, nodes: SimulationNode[]): Promise<Reading<Date>[]>
    async fetch(siteId: string, nodes?: SimulationNode[]
    ):
        Promise<Map<string, number> | Reading<Date>[]> {
        if (nodes === undefined) {
            throw new Error("Nodes are required here")
        }
        return this.fetchEnergyHistory(siteId, nodes)
    }
}