import { ReadingType } from "@/History/enums"
import { PeriodHelper } from "@/Simulation/helpers/PeriodHelper"
import { TemperatureUnit } from "@/core/enums"
import { Formatter } from "@/core/helpers/Formatter"
import * as Formatters from "@/core/helpers/Formatters"
import { NodeHelper } from "@/core/helpers/NodeHelper"
import { NodeReferenceHelper } from "@/core/helpers/NodeReferenceHelper"
import { NumberFormat } from "@/core/helpers/NumberFormat"
import { WeatherReading } from "@/core/interfaces"
import { AnyNode } from "@/store/SiteViewState"
import { NodeReadings, TimeBasedData } from "@/store/simulation-view/types"
import { Models } from "@ekko/predict-client-api"
import { Interface, ItLoadProvisioning, NodeType } from "predict-performance-calculation"
import { ClimateHelper, HourlyWeather } from "../helpers/ClimateHelper"
import { SimulationSiteConfiguration } from "../interfaces"
import { ClimateWidgetData } from "../interfaces/ClimateWidgetData"
import { NamedSimulationNode } from "../interfaces/SimulationNode"
import { SimulationWidgetData } from "../interfaces/SimulationWidgetData"
import { NodeParameter } from "../siteParameter/factory/NodeParameter"
import { SplitterParameter } from "../siteParameter/nodeParameter"

/**
 *
 */
export class TimeBasedParameter {
    /**
     *
     */
    private static warnedNoNodeset = false

    /**
     *
     * @param date
     */
    private static warnNoNodeset(date: Date) {
        if(!this.warnedNoNodeset) {
            this.warnedNoNodeset = true
            console.warn(`No nodeset found for date ${date.toISOString()}, will use closest`)
        }
    }

    /**
     * Returns simulation payload which is node list with their
     * respective supply dependency, resilience and performanceData
     *
     * @param nodes
     * @returns
     */
    static async buildNodesData(nodes: AnyNode[]) {
        const outNodes: NamedSimulationNode[] = []
        for (const node of nodes) {
            let simulationNode: NamedSimulationNode
            const persistentReference = NodeHelper.getPersistentReference(node)
            if(node.modelType == NodeType.Splitter) {
                simulationNode = {
                    ...await new SplitterParameter().fetch(node as Models.SplitterModel),
                    name: node.getName()!,
                    persistentReference,
                }
            } else {
                simulationNode = {
                    ...await NodeParameter.factory(node.modelType).fetch(node),
                    name: node.getName()!,
                    persistentReference,
                }
            }
            outNodes.push(simulationNode)
        }
        return outNodes
    }

    /**
     *
     * @param itNode
     * @returns
     */
    private getITLoadValueFor(itNode: Interface.ITNode<Date>) {
        if(this.readings) {
            const nodeReference = NodeReferenceHelper.getNodeReference(itNode)
            const itReadings =
                this.readings[nodeReference]?.filter(
                    (reading) => reading.type == ReadingType.POWER_SUPPLY
                )

            if (itReadings?.length) {
                const powerSupplyReading = PeriodHelper.closestReading(
                    itReadings,
                    this.date,
                )
                return powerSupplyReading!.value
            }
        }

        // Place for a breakpoint
        const load = ItLoadProvisioning.ITLoadHandler.loadForHour(itNode.itProvisioning!, this.date)
        return load
    }

    /**
     *
     * @param otherNode
     * @returns
     */
    private getOtherLoadValueFor(otherNode: Interface.OtherNode<Date>) {
        if(this.readings) {
            const nodeReference = NodeReferenceHelper.getNodeReference(otherNode)
            const itReadings =
                this.readings[nodeReference]?.filter(
                    (reading) => reading.type == ReadingType.POWER_SUPPLY
                )

            if (itReadings?.length) {
                const powerSupplyReading = PeriodHelper.closestReading(
                    itReadings,
                    this.date,
                )
                return powerSupplyReading!.value
            }
        }

        return ItLoadProvisioning.OtherLoadHandler.loadForHour(otherNode.otherProvisioning!, this.date)
    }

    /**
     *
     */
    date: Date

    /**
     *
     */
    climateProfile: Promise<Models.ClimateProfileReferenceModel | null> | null = null

    /**
     *
     */
    get currentNodeset() {
        if(!this.nodesets.length) {
            throw new Error("No nodesets loaded")
        }
        const nodeset = this.nodesets.find(
            nodeset => nodeset.start <= this.date && (!nodeset.finish || nodeset.finish >= this.date)
        )
        if(!nodeset) {
            TimeBasedParameter.warnNoNodeset(this.date)
            // If one finishes after this point, use that
            const nodesetAfter = this.nodesets.find(
                nodeset => !nodeset.finish || nodeset.finish >= this.date
            )
            if(nodesetAfter) {
                return nodesetAfter
            } else {
                // Otherwise, use the last one.
                return this.nodesets[this.nodesets.length - 1]
            }
        }
        return nodeset
    }

    /**
     *
     */
    get nodes() {
        return this.currentNodeset?.nodes ?? []
    }

    /**
     * Creates an instance of time based parameter.
     *
     * @param siteModel
     * @param nodesets
     * @param readings
     * @param weatherReadings
     * @param oneDayPerMonth
     * @param currentDate
     */
    constructor(
        private siteModel: Models.SiteModel,
        private nodesets: SimulationSiteConfiguration[],
        private readings: NodeReadings | null,
        private weatherReadings: WeatherReading[] | null,
        private oneDayPerMonth: boolean,
        currentDate: Date = new Date(),
    ) {
        this.date = currentDate
    }

    /**
     *
     */
    async getTimeBasedData(): Promise<TimeBasedData> {

        const itLoad = this.getCurrentStaticLoad()

        const itProvisioning = this.getCurrentStaticProvisioning()

        const provisioning = this.getCurrentProvisioning()

        const weather = await this.getBestIndicationWeather()

        const workLoadCapacity = this.getCurrentWorkloadCapacity()

        return { itLoad, itProvisioning, provisioning, weather, workLoadCapacity }

    }

    /**
     * Gets the weather readings for the point or, if they are partial, the
     * weather readings as filled via the relative humidity of the climate at
     * that point, or if there are no weather readings at all, the climate for
     * that point.
     *
     * @returns The best available combination of weather readings and climate
     * profile for the time.
     */
    private async getBestIndicationWeather(): Promise<Pick<WeatherReading, "dryBulb" | "wetBulb"> | null> {
        const actualWeatherData = this.getActualWeatherData()
        if (actualWeatherData && actualWeatherData.dryBulb !== undefined &&
            actualWeatherData?.wetBulb !== undefined
        ) {
            return { dryBulb: actualWeatherData.dryBulb,
                wetBulb: actualWeatherData.wetBulb }
        }

        const climateProfile = await this.getClimateProfile()
        if (!climateProfile) return null

        const climateProfileId = climateProfile.id
        const profileData = await ClimateHelper.getClosest(climateProfileId, this.date, this.oneDayPerMonth) as HourlyWeather

        const { dryBulb, wetBulb } =
            ClimateHelper.fillWeatherReadingTemperature(actualWeatherData,
                profileData)
        return { dryBulb, wetBulb }
    }

    /**
     * Retrieve current climate widget data
     */
    async getCurrentClimateWidgetData(): Promise<ClimateWidgetData | null> {
        const climateProfile = await this.getClimateProfile()
        if (!climateProfile) return null

        const climateProfileId = climateProfile.id
        const profileData = await ClimateHelper.getClosest(climateProfileId, this.date, this.oneDayPerMonth) as HourlyWeather
        const actualWeatherData = this.getActualWeatherData()
        const { dryBulb, wetBulb, relativeHumidity } =
            ClimateHelper.fillWeatherReadingTemperature(actualWeatherData,
                profileData)

        const presentationPreference = await this.siteModel.getPresentationPreference()
        const location = await climateProfile.getClimateLocation()

        const unit =
            presentationPreference.getTemperatureUnit() as TemperatureUnit

        let formatter: Formatter
        switch(unit) {
            case TemperatureUnit.Celsius:
                formatter = new Formatters.Celsius
                break
            case TemperatureUnit.Fahrenheit:
                formatter = new Formatters.Fahrenheit
                break
            case TemperatureUnit.Kelvin:
                formatter = new Formatters.Kelvin
                break
            default:
                console.warn(`Unknown temperature unit ${unit}, using celsius`)
                formatter = new Formatters.Celsius
        }

        return {
            humidity: {
                value: relativeHumidity,
                suffix: 'percent'
            },
            location: `${climateProfile.getName()} (${location!.getCountry()})`,
            temperature: {
                value: dryBulb,
                suffix: unit,
                formatter,
            },
            wetBulb: {
                value: NumberFormat.to1dp(wetBulb),
                suffix: unit,
                formatter,
            },
        }

    }

    /**
    * Retrieves the climate profile for the instance.
    *
    * If the climate profile is already available, it's returned immediately.
    * If not, it fetches the climate profile using the site model and caches it for future use.
    *
    */
    async getClimateProfile(): Promise<Models.ClimateProfileReferenceModel | null> {
        if (!this.climateProfile) {
            this.climateProfile = this.siteModel.getClimateProfileReference()
        }
        return this.climateProfile
    }

    /**
     * Retrieves Simulation widget data
     */
    getCurrentSimulationWidgetData(): SimulationWidgetData | null {
        const itLoad = this.getITLoadForWidgetData()
        return {
            itLoadCapacity: itLoad
        }

    }


    /**
     * Retrieves all IT nodes load and capacity for the current time
     */
    private getITLoadForWidgetData() {
        const itNodes = this.nodes.filter(n => n.type === NodeType.ITNode) as Interface.ITNode[]
        const itLoad: { [ref: string]: { value: number, capacity: number } } = {}

        for (const itNode of itNodes) {
            const itProvisioningEvents = itNode.itProvisioning!.itProvisioningEvents
            if (itProvisioningEvents.length) {
                let lastEvent = itProvisioningEvents[itProvisioningEvents.length - 1]
                for (const event of itProvisioningEvents) {
                    if (event.month < this.date) {
                        lastEvent = event
                    }
                }

                itLoad[NodeReferenceHelper.getNodeReference(itNode)] = {
                    value: this.getITLoadValueFor(itNode),
                    capacity: lastEvent.powerCapacity
                }
            } else {
                console.warn(
                    `Node ${itNode.type}/${itNode.id} has no provisioning`)
            }
        }

        return itLoad
    }

    /**
     * Gets the IT load at 100% for the current time
     *
     * @returns
     */
    getCurrentFullStaticLoad() {
        const staticLoad: { [ref: string]: number } = {}

        const itNodes = this.nodes.filter(node => node.type === NodeType.ITNode) as Interface.ITNode[]

        for (const itNode of itNodes) {

            const load = ItLoadProvisioning.ITLoadHandler.fullLoadForHour(itNode.itProvisioning!, this.date)
            staticLoad[NodeReferenceHelper.getNodeReference(itNode)] = load
        }

        const otherNodes = this.nodes.filter(node => node.type === NodeType.OtherNode) as Interface.OtherNode[]

        for (const otherNode of otherNodes) {

            const load = ItLoadProvisioning.OtherLoadHandler.fullLoadForHour(otherNode.otherProvisioning!, this.date)
            staticLoad[NodeReferenceHelper.getNodeReference(otherNode)] = load
        }
        return staticLoad
    }

    /**
     * Gets the IT load for the current time
     *
     * @returns
     */
    getCurrentStaticLoad() {
        const staticLoads: Record<string, number> = {}

        const itNodes = this.nodes.filter(node => node.type === NodeType.ITNode) as Interface.ITNode[]
        for (const itNode of itNodes) {
            staticLoads[NodeReferenceHelper.getNodeReference(itNode)] = this.getITLoadValueFor(itNode)
        }

        const otherNodes = this.nodes.filter(node => node.type === NodeType.OtherNode) as Interface.OtherNode[]
        for (const otherNode of otherNodes) {
            staticLoads[NodeReferenceHelper.getNodeReference(otherNode)] = this.getOtherLoadValueFor(otherNode)
        }

        return staticLoads
    }

    /**
     *
     */
    private getCurrentStaticProvisioning() {

        const staticProvisioning: { [ref: string]: number } = {}

        const itNodes = this.nodes.filter(n => n.type === NodeType.ITNode) as Interface.ITNode[]
        const otherNodes = this.nodes.filter(n => n.type === NodeType.OtherNode) as Interface.OtherNode[]
        for (const itNode of itNodes) {
            const itProvisioningEvents = itNode.itProvisioning!.itProvisioningEvents
            if (itProvisioningEvents.length) {
                let lastEvent = itProvisioningEvents[itProvisioningEvents.length - 1]
                for (const event of itProvisioningEvents) {
                    if (event.month < this.date) {
                        lastEvent = event
                    }
                }

                staticProvisioning[NodeReferenceHelper.getNodeReference(itNode)] = lastEvent.power
            }
        }

        for (const otherNode of otherNodes) {
            const provisioningEvents = otherNode.otherProvisioning!.otherProvisioningEvents
            if (provisioningEvents.length) {
                let lastEvent = provisioningEvents[provisioningEvents.length - 1]
                for (const event of provisioningEvents) {
                    if (event.month < this.date) {
                        lastEvent = event
                    }
                }

                staticProvisioning[NodeReferenceHelper.getNodeReference(otherNode)] = lastEvent.power
            }
        }

        return staticProvisioning
    }

    /**
     *
     */
    private getCurrentProvisioning() {

        const items = this.nodes.filter(n => n.type === NodeType.Item) as Interface.Item[]
        const provisioning: { [ref: string]: { count: number, designCapacity?: number | null } } = {}

        for (const item of items) {
            const provisioningEvents = item.provisioningEvents
            if (provisioningEvents) {
                let lastEvent = provisioningEvents[provisioningEvents.length - 1]
                for (const event of provisioningEvents) {
                    if (new Date(event.date as string) < this.date) {
                        lastEvent = event
                    }
                }
                provisioning[NodeReferenceHelper.getNodeReference(item)] = { count: lastEvent.count as number, designCapacity: lastEvent.designCapacity }
            }
        }
        return provisioning
    }

    /**
     *
     * @param nodeType
     * @returns
     */
    private getCurrentWorkloadCapacity() {

        const loadNodes = this.nodes.filter(node => [NodeType.ITNode, NodeType.OtherNode].includes(node.type))
        const provisioningData: Record<string, number> = {}

        for (const loadNode of loadNodes) {
            const provisioningEvents: Interface.ITProvisioningEvent<Date>[] = []
            if (loadNode.type == NodeType.ITNode && loadNode.itProvisioning?.itProvisioningEvents) {
                provisioningEvents.push(...loadNode.itProvisioning.itProvisioningEvents)
            } else if (loadNode.type == NodeType.OtherNode && loadNode.otherProvisioning?.otherProvisioningEvents) {
                provisioningEvents.push(...loadNode.otherProvisioning.otherProvisioningEvents)
            }
            if (provisioningEvents.length) {
                let lastEvent = provisioningEvents[provisioningEvents.length - 1]
                for (const event of provisioningEvents) {
                    if (event.month < this.date) {
                        lastEvent = event
                    }
                }

                provisioningData[NodeReferenceHelper.getNodeReference(loadNode)] = lastEvent.powerCapacity
            }
        }

        return provisioningData
    }

    /**
     * Get the actual weather data.
     *
     * @returns Actual weather data or null.
    */
    private getActualWeatherData(): Pick<WeatherReading, "dryBulb" | "wetBulb" | "relativeHumidity"> | null {
        if (this.weatherReadings?.length) {
            const latestReadingTimestamp = new Date(this.weatherReadings[this.weatherReadings.length - 1].timestamp)

            if (latestReadingTimestamp >= this.date) {
                let currentWeather = this.weatherReadings[0]

                for (const weatherReading of this.weatherReadings) {
                    if (new Date(weatherReading.timestamp) > this.date) {
                        break
                    }
                    currentWeather = weatherReading
                }
                return currentWeather
            }
        }
        return null
    }

    /**
     * Returns true if constructing a new object with these args would be
     * equivalent to reusing this object.
     *
     * @param siteModel
     * @param nodesets
     * @param readings
     * @param weatherReadings
     * @param oneDayPerMonth
     * @param currentDate
     */
    matches(
        siteModel: Models.SiteModel,
        nodesets: SimulationSiteConfiguration[],
        readings: NodeReadings | null,
        weatherReadings: WeatherReading[] | null,
        oneDayPerMonth: boolean,
        currentDate: Date = new Date(),
    ) {
        return (
            this.siteModel.id == siteModel.id &&
            this.nodesets.length == nodesets.length &&
            this.nodesets.every((ns, i) => {
                const newNodeset = nodesets[i]
                return (
                    newNodeset.finish == ns.finish &&
                    newNodeset.start == ns.start &&
                    newNodeset.nodes.length == ns.nodes.length &&
                    ns.nodes.every((n, i) => n.id == newNodeset.nodes[i].id)
                )
            }) &&
            (!!readings) == (!!this.readings) &&
            (!!weatherReadings) == (!!this.weatherReadings) &&
            this.oneDayPerMonth == oneDayPerMonth
        )
    }
}