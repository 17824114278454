import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { Kilowatts } from "./Kilowatts"

/**
 *
 */
export class KilowattsCooling extends Kilowatts {
    protected suffixSymbol = "kWc"

    suffix = NumberFormatSuffix.kilowattsCooling
}