import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex"
import LookupCacheModule from "./LookupCache"
import ClimateProfileModule from "./climate-profiles"
import ClimateProfileArchiveModule from "./climate-profiles-archive"
import CommentModule from "./comments"
import DeviceModeModule from "./device-modes"
import DeviceModelModule from "./devices"
import DisplayPreferencesModule from "./display-preferences"
import EditViewModule from "./edit-view"
import ITLoadProfileModule from "./it-load-profiles"
import ITLoadProfileArchiveModule from "./it-load-profiles-archive"
import LogsModule from "./logs"
import ManufacturerModule from "./manufacturers"
import MeterMappingModule from "./meter-mappings"
import MeterPointModule from "./meter-points"
import PowerCostMonthModule from "./power-cost-months"
import PowerCostProfileModule from "./power-cost-profiles"
import PowerCostModule from "./power-costs"
import ScreenshotModule from "./screenshot"
import SensorModule from "./sensors"
import SimulationViewModule from "./simulation-view"
import SiteModule from "./sites"
import WeatherMeterConfigurationModule from "./weather-meter-configuration"
import WeatherStoredReferenceModule from "./weather-stored-references"

const getters = <GetterTree<State, any>>{}
const mutations = <MutationTree<State>>{}
const actions = <ActionTree<State, any>>{}

class State { }

export default new Vuex.Store({
    state: new State(),
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        climateProfile: ClimateProfileModule,
        climateProfileArchive: ClimateProfileArchiveModule,
        comment: CommentModule,
        deviceMode: DeviceModeModule,
        deviceModel: DeviceModelModule,
        displayPreferences: DisplayPreferencesModule,
        editView: EditViewModule,
        itLoadProfile: ITLoadProfileModule,
        itLoadProfileArchive: ITLoadProfileArchiveModule,
        log: LogsModule,
        lookupCache: LookupCacheModule,
        manufacturer: ManufacturerModule,
        meterMapping: MeterMappingModule,
        meterPoints: MeterPointModule,
        powerCost: PowerCostModule,
        powerCostMonth: PowerCostMonthModule,
        powerCostProfile: PowerCostProfileModule,
        screenshot: ScreenshotModule,
        sensors: SensorModule,
        simulationView: SimulationViewModule,
        site: SiteModule,
        weatherMeterConfiguration: WeatherMeterConfigurationModule,
        weatherStoredReference: WeatherStoredReferenceModule,
    }
})
