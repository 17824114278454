import { PaginatedResult } from "../interfaces";

/**
 *
 */
export class Paginated<T> implements PaginatedResult<T> {
    /**
     * Returns a function to do the sort equivalent of (a, b) => a - b,
     * but handling null and undefined
     *
     * @param f
     * @param reverse
     * @param otherFields
     * @returns
     */
    static sortUndefinedFirst<A, B, C>(f: (a: A) => B, reverse = false, ...otherFields: ((a: A) => C)[]): (a: A, b: A) => number {
        const handlers = [f, ...otherFields]
        return (a, b) => {
            let result = 0
            for(const f of handlers) {
                const [av, bv] = [f(a), f(b)]
                if(av === undefined || bv === undefined) {
                    result = +(av !== undefined) - +(bv !== undefined)
                } else if(av === null || bv === null) {
                    result = +(av !== null) - +(bv !== null)
                } else {
                    result = av < bv ? -1 : (av > bv ? 1 : 0)
                }
                if(result != 0) {
                    break
                }
            }
            return reverse ? -result : result
        }
    }

    /**
     *
     * @param values
     * @param page
     * @param pageSize
     * @param sort
     * @param filter
     */
    constructor(private values: T[], public page = 1, private pageSize = 10,
        private sort?: (a: T, b: T) => number, public filter?: (a: T) => boolean) {

        if(this.sort) {
            this.values.sort(this.sort)
        }
    }

    /**
     * All the values which match the search/filter.
     */
    get filteredValues() {
        return this.filter ? this.values.filter(this.filter) : this.values
    }

    /**
     * This is the current page of results only (filtered)
     */
    get results() {
        return this.filteredValues.slice(
            (this.page - 1) * this.pageSize,
            this.page * this.pageSize
        )
    }

    get sortRule() {
        return this.sort
    }
    set sortRule(v) {
        this.sort = v
        this.values.sort(this.sort)
    }

    /**
     *
     */
    get totalCount() {
        return this.filteredValues.length
    }

    /**
     *
     */
    get unfilteredTotalCount() {
        return this.values.length
    }
}