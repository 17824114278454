import { AggregatorSimulateClient, MeteredData, UnbatchEvents } from "@/core/interfaces"
import { SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"
import { EnergyBaseClient } from "./EnergyBaseClient"
import { DateHelper } from "@/core/helpers"

/**
 *
 */
export class PowerClient extends EnergyBaseClient implements AggregatorSimulateClient {
    /**
     *
     * @param parentOutput
     * @returns
     */
    private rescale(parentOutput: NodeDynamicCostsByType) {
        const output: Map<string, { fixed: number, variable: number, it: number }> = new Map()
        for (const [point, value] of parentOutput.entries()) {
            const sliceHours = DateHelper.getSliceHours(this.granularity, point)

            output.set(point, {
                fixed: value.fixed / sliceHours,
                variable: value.variable / sliceHours,
                it: value.it / sliceHours,
            })

        }

        return output
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergy(nodesets, siteInfo,
            deviceSelected, unbatchCallbacks)

        return this.rescale(parentOutput)
    }

    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyAllocated(nodesets,
            siteInfo, deviceSelected, unbatchCallbacks)

        return this.rescale(parentOutput)
    }

    async simulateAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyAttributed(nodesets,
            siteInfo, deviceSelected, unbatchCallbacks)

        return this.rescale(parentOutput)
    }

    async simulateHistorical(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyHistorical(nodesets,
            siteInfo, deviceSelected, meteredData, unbatchCallbacks)

        return this.rescale(parentOutput)
    }

    async simulateHistoricalAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyHistoricalAllocated(
            nodesets, siteInfo, deviceSelected, meteredData, unbatchCallbacks)

        return this.rescale(parentOutput)
    }

    async simulateHistoricalAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyHistoricalAttributed(
            nodesets, siteInfo, deviceSelected, meteredData, unbatchCallbacks)

        return this.rescale(parentOutput)
    }
}