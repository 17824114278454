import { Granularity } from "@/Simulation/enums"
import { StaticCost } from "@/Simulation/interfaces"
import { StaticCostResponse } from "@/Simulation/interfaces/StaticCostResponse"
import { BatchResults } from "./BatchResults"

/**
 *
 */
export class StaticCostBatchResults extends BatchResults<StaticCostResponse> {
    /**
     *
     * @param items
     * @param getResults
     * @param filterDate
     * @param granularity
     * @returns
     */
    static async iterate<I>(items: I[],
        getResults: (item: I) => Promise<StaticCostResponse> | StaticCostResponse,
        filterDate: (point: Date, item: I) => boolean, granularity: Granularity
    ) {
        const batchResults = new StaticCostBatchResults(granularity)
        for(const item of items) {
            const itemResult = await getResults(item)
            const itemResultFiltered: StaticCostResponse = Object.fromEntries(
                Object.entries(itemResult).filter(([point]) => filterDate(new Date(point), item))
            )
            batchResults.add(itemResultFiltered)
        }
        return batchResults.finalise()
    }

    /**
     *
     */
    private importCount = 0

    /**
     *
     */
    private resultCounts: Record<string, number> = {}

    /**
     *
     */
    private storedResults: StaticCostResponse = {}


    /**
     *
     * @param results
     * @param batchCount
     * @returns
     */
    private finaliseStaticCostData(results: StaticCost, batchCount: number) {
        let allocation: {[a: string]: {[b: string]: number}} | null = null
        if(results.allocation) {
            allocation = {}
            for(const [supplierReference, demanderAllocation] of Object.entries(results.allocation)) {
                allocation[supplierReference] = {}
                for(const [demanderReference, allocationIn] of Object.entries(demanderAllocation)) {
                    allocation[supplierReference][demanderReference] = allocationIn / batchCount
                }
            }
        }
        return {...results, allocation}
    }

    /**
     *
     * @param stored
     * @param result
     */
    private mergeStaticCostData(stored: StaticCost, result: StaticCost) {
        if(result.allocation) {
            if(stored.allocation) {
                for(const [supplierRef, demandProportion] of Object.entries(result.allocation)) {
                    const storedDemandProportion = stored.allocation[supplierRef]
                    if(storedDemandProportion) {
                        for(const [demanderRef, proportion] of Object.entries(demandProportion)) {
                            if(storedDemandProportion[demanderRef]) {
                                storedDemandProportion[demanderRef] += proportion
                            } else {
                                storedDemandProportion[demanderRef] = proportion
                            }
                        }
                    } else {
                        stored.allocation[supplierRef] = demandProportion
                    }
                }
            } else {
                stored.costs = result.costs
            }
        }
        if(result.costs) {
            if(stored.costs) {
                for(const [sourceRef, expenseCost] of Object.entries(result.costs)) {
                    const storedExpenseCost = stored.costs[sourceRef]
                    if(storedExpenseCost) {
                        for(const [expense, cost] of Object.entries(expenseCost)) {
                            if(storedExpenseCost[expense]) {
                                storedExpenseCost[expense] += cost
                            } else {
                                storedExpenseCost[expense] = cost
                            }
                        }
                    } else {
                        stored.costs[sourceRef] = expenseCost
                    }
                }
            } else {
                stored.costs = result.costs
            }
        }
    }

    /**
     *
     */
    get count() {
        return this.importCount
    }

    /**
     *
     * @param resultsIn
     */
    add(resultsIn: StaticCostResponse) {
        const results: StaticCostResponse = JSON.parse(JSON.stringify(resultsIn))
        for(const [period, result] of Object.entries(results)) {
            const granularPeriod = this.granularPoint(period)
            const stored = this.storedResults[granularPeriod]
            if(stored) {
                this.mergeStaticCostData(stored, result)
                this.resultCounts[granularPeriod]++
            } else {
                this.storedResults[granularPeriod] = result
                this.resultCounts[granularPeriod] = 1
            }
        }
        this.importCount++
    }

    /**
     *
     * @returns
     */
    finalise() {
        const out: StaticCostResponse = {}
        for(const [period, result] of Object.entries(this.storedResults)) {
            out[period] = this.finaliseStaticCostData(result, this.resultCounts[period])
        }
        return out
    }
}