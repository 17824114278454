import { GeneralNodeReference, NodeReference } from '@/Simulation/interfaces/NodeReference'
import { SimulationNode } from "@/Simulation/interfaces/SimulationNode"
import { Models } from "@ekko/predict-client-api"
import { ConnectionType, NodeType } from "predict-performance-calculation"

/**
 *
 */
export class NodeReferenceHelper {

    /**
     * Gets node reference
     * @param node
     */
    static getNodeReference(node: NodeReference): string {
        return `${node.type}/${node.id}`
    }

    /**
     * Gets Device from node reference
     * @param nodes
     * @param nodeReference
     */
    static findDevice(nodes: SimulationNode[], nodeReference: string) {

        const node = nodes.find(n => NodeReferenceHelper.getNodeReference(n) === nodeReference)

        return node && node.type == NodeType.Item ? node.deviceModelType : null

    }

    /**
     * Retrieve the related node type and id as object
     * @param node
     */
    static async getSupplierReference(
        node: { getCooledBy(): Promise<GeneralNodeReference | null>, getPoweredBy(): Promise<GeneralNodeReference | null> }
    ): Promise<{ cooledBy: NodeReference | null, poweredBy: NodeReference | null }> {
        let cooledBy: NodeReference | null = null
        let poweredBy: NodeReference | null = null
        const cooling = await node.getCooledBy()
        if (cooling) {
            cooledBy = { id: cooling.id, type: cooling.type as NodeType }
        }
        const power = await node.getPoweredBy()
        if (power) {
            poweredBy = { id: power.id, type: power.type as NodeType }
        }

        return { cooledBy, poweredBy }
    }


    /**
     * Retrieve the related node type and id as object
     * @param item
     */
    static async getSplitterSupplierReference(item: Models.SplitterModel):
        Promise<{ cooledBy: NodeReference[], poweredBy: NodeReference[] }> {
        const cooledBy: NodeReference[] = []
        const poweredBy: NodeReference[] = []
        for await (const c of item.getCooledBy()) {
            if (c) {
                cooledBy.push({ id: c.id, type: c.type as NodeType })
            }
        }
        for await (const p of item.getPoweredBy()) {
            if (p) {
                poweredBy.push({ id: p.id, type: p.type as NodeType })
            }
        }
        return { cooledBy, poweredBy }
    }

    /**
     *
     * @param node
     * @param nodes
     * @param supplyType
     * @returns
     */
    static suppliedNodes(node: NodeReference, nodes: SimulationNode[],
        supplyType: ConnectionType.POWER | ConnectionType.COOLING
    ) {

        const relation = supplyType == ConnectionType.POWER ? 'poweredBy' : 'cooledBy'
        const nodeRef = this.getNodeReference(node)

        const suppliedNodes = nodes.filter(n => {
            if (n[relation]) {
                if (n.type === 'splitter') {
                    const supplier = n[relation]
                    return (supplier).find(s => `${s.type}/${s.id}` == nodeRef)
                }
                else {
                    const supplier = n[relation]
                    return supplier && `${supplier.type}/${supplier.id}` == nodeRef
                }
            }
            return false
        })

        return suppliedNodes.map(s => this.getNodeReference(s))
    }

}