/**
 *
 */
export enum NumberFormatSuffix {
    celsius = "celsius",
    fahrenheit = "fahrenheit",
    kelvin = "kelvin",
    kilowattHours = "kilowatt-hours",
    kilowatts = "kilowatts",
    kilowattsCooling = "kilowatts-cooling",
    percent = "percent",
}
