import { Granularity, SimPeriod } from "@/Simulation"
import { UnbatchEvents } from "../interfaces"
import { BatchResults } from "./BatchResults"
import { UrlConfig } from "./UrlConfig"


/**
 *
 */
const debug = false

/**
 * This is a decorator - use it before your method like:
 *
 * ```
 * @Unbatch({period: 4, granularity: 5, unbatchCallbacks: 6})
 * myMethod(...a: A[]) {
 *  // ...
 * }
 * ```
 *
 * This will split the request by month.
 *
 * @param argPositions
 * @param newBatchResults
 * @returns
 */
export function Unbatch(
    argPositions: {period: number, unbatchCallbacks: number, granularity: number},
    newBatchResults: (granularity: Granularity) => BatchResults<any>
) {
    /**
     * This actually wraps the method.
     *
     * @param target
     * @param property
     * @param descriptor
     */
    return (target: any, property: string, descriptor: PropertyDescriptor) => {
        const original: (...args: any[]) => any = descriptor.value
        descriptor.value = async function (...args) {
            const unbatchForAWS = await UrlConfig.shouldUnbatchForAWS()
            if(!unbatchForAWS) {
                return original.apply(this, args)
            }
            const [period, unbatchCallbacks, granularity] = [
                args[argPositions.period] as SimPeriod,
                args[argPositions.unbatchCallbacks] as UnbatchEvents,
                args[argPositions.granularity] as Granularity
            ]
            if(period.months <= 1) {
                return original.apply(this, args)
            }

            unbatchCallbacks?.start?.(period.months)
            const batchResults = newBatchResults(granularity)
            if(debug) {
                console.debug(`Will run up to ${period.months} from ${period.start}`)
            }
            for(let offset = 0; offset < period.months; offset++) {
                const point = new Date(period.start.replace(
                    /^(\d{4}-\d{2}-\d{2})$/, "$1T00:00:00Z"))
                point.setUTCMonth(point.getUTCMonth() + offset)
                const pointPeriod: SimPeriod = {
                    start: point.toISOString(),
                    months: 1,
                    oneDayPerMonth: period.oneDayPerMonth,
                }
                if(debug) {
                    console.debug(`Using ${pointPeriod.start}`)
                }
                const argsModified = args.slice()
                argsModified.splice(argPositions.period, 1, pointPeriod)
                argsModified.splice(argPositions.unbatchCallbacks, 1, undefined)
                const newResults = await original.apply(this, argsModified)
                batchResults.add(newResults)
                if(debug) {
                    console.debug(`BS is ${batchResults.count}`)
                }
                unbatchCallbacks?.progress?.(batchResults.count)
            }
            unbatchCallbacks?.progress?.(period.months)
            const result = batchResults.finalise()
            if(debug) {
                console.debug(`Done (${period.months})`)
            }
            return result
        }
    }
}