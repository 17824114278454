import { NumberFormat } from "@/core/helpers/NumberFormat"
import { UnbatchEvents } from "@/core/interfaces"
import { Models } from "@ekko/predict-client-api"
import { NamedSimulationNode, Provisioning } from "../interfaces"
import { ProfileBase } from "./ProfileBase"

/**
 * This is the PUE profile of a site configuration
 */
export class PUEProfile extends ProfileBase {
    /**
     *
     * @param site
     * @param nodes
     * @param provisioning
     */
    constructor(site: Models.SiteModel, nodes: NamedSimulationNode[], provisioning: Provisioning) {
        super(site, nodes, provisioning)
    }

    async simulate(unbatchCallbacks: UnbatchEvents | null = null) {
        return this.simulateInner(
            (powerLoss: number, itLoad: number) => itLoad ? NumberFormat.to2dp(powerLoss / itLoad) : 0,
            unbatchCallbacks)
    }

}