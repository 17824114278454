import { Granularity } from "@/Simulation/enums"

/**
 * Date helper class
 */
export class DateHelper {
    /**
     *
     * @param granularity
     * @param timestamp
     * @returns
     */
    static getSliceHours(granularity: Granularity, timestamp: string | number | Date) {
        let sliceHours: number
        switch (granularity) {
            case Granularity.YEAR:
                sliceHours = 365 * 24
                break
            case Granularity.MONTH:
                {
                    //
                    const point = new Date(timestamp)
                    point.setMonth(point.getMonth() + 1, 0)
                    const daysInMonth = point.getDate()
                    sliceHours = daysInMonth * 24
                }
                break
            case Granularity.DAY:
                sliceHours = 24
                break
            case Granularity.HOUR:
                sliceHours = 1
                break
        }
        return sliceHours
    }
    /**
     *
     */
    static readonly dayHours = 24
    /**
     *
     */
    static readonly oneHourInMs = 1_000 * 60 * 60
    /**
     *
     */
    static readonly oneDayInMs = DateHelper.oneHourInMs * DateHelper.dayHours

    /**
    * Produces the number of days between two dates.
    * @param from
    * @param to
    */
    static daysBetween(from: Date, to: Date): number {
        return Math.round((to.getTime() - from.getTime()) / this.oneDayInMs)
    }

    /**
     * Determines the time slices (hourly) based on the time range
     * - Expects dates with times:
     *      @param from = "2022-01-01T00:00:00" @param to = "2022-01-07T00:00:00" (exclude 2022-01-07)
     * - Expects dates (time ignored):
     *      @param from = "2022-01-01T00:00:00" @param to = "2022-01-07T23:59:59" (include 2022-01-07)
     * @param from
     * @param to
     */
    static getTimeSlices(from: Date, to: Date): Date[] {
        const timeSlices: Date[] = []
        const diffInDays = DateHelper.daysBetween(from, to)
        const startDateTime = from.setHours(from.getHours())

        for (let daysAfterStart = 0; daysAfterStart < diffInDays; daysAfterStart++) {
            const currentDayStartTime = startDateTime + (daysAfterStart * DateHelper.oneDayInMs)

            for (let hourOffset = 0; hourOffset < DateHelper.dayHours; hourOffset++) {
                const date = new Date(currentDayStartTime)
                date.setHours(hourOffset)
                timeSlices.push(date)
            }
        }

        return timeSlices
    }

    /**
     * Retrieves date format requested for the site header and Data import
     *
     * @param dateStr
     * @returns
     */
    static headerDateFormat(dateStr: string) {
        return new Date(dateStr).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
    }

    /**
     *
     * @param dateStr
     * @returns
     */
    static auditLogDateFormat(dateStr: string) {
        return new Date(dateStr).toLocaleString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })
    }

    /**
     *
     * @param date
     * @returns
     */
    static longFormat(date: Date) {
        return date.toLocaleString('en-GB', { year: 'numeric', month: 'long', day: '2-digit' })
    }

    /**
     * Round a Date to the Nearest Hour
     * @param date
     * @returns
     */
    static roundToNearestHour(date: Date) {
        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60))
        date.setMinutes(0, 0, 0)

        return date
    }

    /**
     * Get days in month
    *  Expects date string format to be:
     *      @param dateStr = "2022-01"
     * @param dateStr
     * @returns
     */
    static getDaysInMonth(dateStr: string) {
        const date = new Date(dateStr)
        const year = date.getFullYear()
        const month = date.getMonth() + 1

        return new Date(year, month, 0).getDate()
    }

    /**
     * Adds the given number of days.
     *
     * @param days
     * @param from date only, eg YYYY-MM-DD
     */
    static addDateTo(days: number, from: string) {
        const date = new Date(from)
        date.setDate(date.getDate() + days)
        return this.dateOnly(date)
    }

    /**
     * Returns the date component of the `Date` object as a string. When used as
     * a full date, this is equivalent to a "rounded down" datetime.
     *
     * This applies in the current timezone, so for a given date `d` this will
     * produce an output that recognisably is on the same day as `d`.
     *
     * @param dateTime
     * @returns The date in ISO8601 format with no time or timezone
     */
    static dateOnly(dateTime: Date) {
        return [
            dateTime.getFullYear(), dateTime.getMonth() + 1, dateTime.getDate()
        ].map(c => ("" + c).padStart(2, "0")).join("-")
    }

    /**
     *
     * @param dateTime
     * @returns Only the full year and month components of a date, separated by a dash
     */
    static fullYearMonthOnly(dateTime: Date) {
        return dateTime.toISOString().replace(/-..T.*/, "")
    }
}