import { CostFrequency } from "@/Simulation/enums"
import { NodeCapitalCost, NodeMaintenanceCost, ITCostProfile as OtherCostProfile } from "@/Simulation/interfaces"
import { NodeReferenceHelper } from "@/core/helpers"
import { Models } from "@ekko/predict-client-api"
import { DeviceModelType, Interface } from "predict-performance-calculation"
import { WorkloadNodeParameter } from "./WorkloadNodeParameter"

/**
 *
 */
export class OtherNodeParameter extends WorkloadNodeParameter<Models.OtherNodeModel, Interface.OtherNode> {
    protected async getProvisioningEvents(node: Models.OtherNodeModel) {
        return (await this.getOtherProvisioning(node)).otherProvisioningEvents
    }

    async fetch(node: Models.OtherNodeModel) {
        return {
            ...await this.fetchBasic(node),
            ...await NodeReferenceHelper.getSupplierReference(node),
            otherProvisioning: await this.getOtherProvisioning(node),
            otherCostProfile: await this.getOtherCostProfile(node),
            deviceModelType: 'other'
        } as Interface.OtherNode & {otherCostProfile: OtherCostProfile | null, deviceModelType: DeviceModelType | "other", name: string, id: string}
    }

    /**
     * Retrieves the item/It node cost profile
     * @param node
     */
    async getOtherCostProfile(node: Models.OtherNodeModel): Promise<OtherCostProfile | null> {

        let costProfile: OtherCostProfile | null = null

        const nodeCostProfile = await node.getOtherCostProfile()

        if (nodeCostProfile) {

            costProfile = {} as OtherCostProfile

            costProfile.nodeCapitalCosts = []
            costProfile.nodeMaintenanceCosts = []

            const nodeCapitalCosts = nodeCostProfile.getNodeCapitalCosts()
            for await (const nodeCapitalCost of nodeCapitalCosts) {

                const cost = {
                    type: "nodeCapitalCost",
                    name: nodeCapitalCost.getName(),
                    amount: nodeCapitalCost.getAmount(),
                    lifetimeYears: nodeCapitalCost.getLifetimeYears(),
                    startDate: new Date(nodeCapitalCost.getStartDate() as string)

                } as NodeCapitalCost

                costProfile.nodeCapitalCosts.push(cost)
            }

            const nodeMaintenanceCosts = nodeCostProfile.getNodeMaintenanceCosts()
            for await (const nodeMaintenanceCost of nodeMaintenanceCosts) {

                const cost = {
                    type: "nodeMaintenanceCost",
                    name: nodeMaintenanceCost.getName(),
                    amount: nodeMaintenanceCost.getAmount(),
                    startDate: new Date(nodeMaintenanceCost.getStartDate() as string),
                    endDate: new Date(nodeMaintenanceCost.getEndDate() as string),
                    frequency: nodeMaintenanceCost.getFrequency() as CostFrequency


                } as NodeMaintenanceCost

                costProfile.nodeMaintenanceCosts.push(cost)
            }
        }

        return costProfile

    }

    /**
     * Retrieves item's (other node) IT Provisioning, in order
     *
     * @param nodeModel
     * @returns
     */
    async getOtherProvisioning(nodeModel: Models.OtherNodeModel): Promise<Interface.OtherProvisioning<Date>> {
        const provisioning = await nodeModel.getOtherProvisioning()

        const provisioningEventsUnsorted: Interface.LoadProvisioningEvent<Date>[] = []
        if (provisioning) {
            const nodeEvents = provisioning.getOtherProvisioningEvents()

            for await (const event of nodeEvents) {
                const loadProfile = await event.getItLoadProfile()

                if (loadProfile) {
                    const loadHours: Interface.ITLoadHour[] = []
                    const eventLoadHours = loadProfile.getItLoadHours()

                    for await (const loadHour of eventLoadHours) {
                        loadHours.push({
                            hour: loadHour.getHour()!,
                            load: loadHour.getLoad()!,
                        })
                    }

                    const newEvent = {
                        power: event.getPower() as number,
                        month: new Date(event.getMonth()!),
                        powerCapacity: event.getPowerCapacity()!,
                        itLoadProfile: {
                            defunct: loadProfile.getDefunct()!,
                            name: loadProfile.getName()!,
                            itLoadHours: loadHours
                        }
                    } as Interface.LoadProvisioningEvent<Date>

                    provisioningEventsUnsorted.push(newEvent)
                }
            }
        }

        return {
            otherProvisioningEvents: provisioningEventsUnsorted.sort(
                (a, b) => a.month < b.month ? -1 :
                    (a.month === b.month ? 0 : 1)
            ),
        }
    }
}