import { PerformanceType } from "../enums"
import * as Interface from "../interfaces"
import { jsonRpcService } from "../services"

export class AddDeviceHelper {
    /**
     * @param formData
     */
    static getInferredPerformanceData(formData: Interface.DeviceModelForm) {
        switch (formData?.performanceType) {
            case PerformanceType.PumpCondensed:
                return this.getPumpCondensed(
                    formData.performance as Interface.PumpCondensedPerformanceForm
                )
            case PerformanceType.ReferencePolynomial:
                return this.getReferencePolynomial(
                    formData.performance as Interface.PolynomialPerformanceForm
                )
            case PerformanceType.Transformer:
                return this.getTransformer(
                    formData.performance as Interface.ManufacturerDataTransformerForm
                )
            case PerformanceType.UPS:
                return this.getUPS(
                    formData.performance as Interface.UpsPerformanceForm
                )
            case PerformanceType.PrimaryPump:
                return this.getPrimaryPump(
                    formData.performance as Interface.PrimaryPumpPerformanceForm
                )
            case PerformanceType.Switchgear:
                return this.getSwitchgear(
                    formData.performance as Interface.SwitchgearPerformanceForm
                )
            case PerformanceType.CRAC:
                return this.getCRAC(
                    formData.performance as Interface.CracPerformanceForm
                )
            case PerformanceType.CoolingTower:
                return this.getCoolingTower(
                    formData.performance as Interface.CoolingTowerPerformanceForm
                )
            case PerformanceType.Chiller:
                return this.getChiller(
                    formData.performance as Interface.ChillerPerformanceForm
                )
            case PerformanceType.PDU:
                return this.getPDU(
                    formData.performance as Interface.PduPerformanceForm
                )
            default: return null
        }
    }

    /**
     * @param performanceData
     */
    private static getPumpCondensed(performanceData: Interface.PumpCondensedPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("PumpCondensed", 0, {
            motorPowerHP: Number(performanceData.motorPowerHP),
            waterSupplyTemperature: Number(
                performanceData.waterSupplyTemperature
            ),
            waterReturnTemperature: Number(
                performanceData.waterReturnTemperature
            ),
            variableSpeedDrive: Boolean(performanceData.variableSpeedDrive),
            offAtZeroLoad: Boolean(performanceData.offAtZeroLoad),
            maxFractionGlycol: Number(performanceData.maxFractionGlycol) / 100,
            maxMassFlowRateLS: Number(performanceData.maxMassFlowRateLS),
            fixedLossFactor: Number(performanceData.fixedLossFactor) / 100,
            proportionalLossFactor:
                Number(performanceData.proportionalLossFactor) / 100,
            squareLawLossFactor:
                Number(performanceData.squareLawLossFactor) / 100,
            cubeLawLossFactor: Number(performanceData.cubeLawLossFactor) / 100,
            glycolType: performanceData.glycolType,
        })
    }

    /**
     * @param performanceForm
     */
    private static getReferencePolynomial(performanceForm: Interface.PolynomialPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData(
            "ReferencePolynomial",
            0,
            {
                polynomialValues: Object.values(
                    performanceForm.polynomialValues
                ),
            }
        )
    }

    /**
     * @param performanceData
     */
    private static getTransformer(performanceData: Interface.ManufacturerDataTransformerForm) {
        return jsonRpcService.getInferredPerformanceData("Transformer", 0, {
            capacityKVA: Number(performanceData.capacityKVA),
            powerFactor: Number(performanceData.powerFactor),
            noLoadLosses: Number(performanceData.noLoadLosses),
            loadLosses: Number(performanceData.loadLosses),
        })
    }

    /**
     * @param performanceData
     */
    private static getUPS(performanceData: Interface.UpsPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("UPS", 0, {
            capacityKVA: Number(performanceData.capacityKVA),
            powerFactor: Number(performanceData.powerFactor),
            fixedLosses: Number(performanceData.fixedLosses as number),
            proportionalLosses: Number(
                performanceData.proportionalLosses as number
            ),
            squareLawLosses: Number(performanceData.squareLawLosses as number),
            cubeLawLosses: Number(performanceData.cubeLawLosses as number),
        })
    }

    /**
     * @param performanceData
     */
    private static getPrimaryPump(performanceData: Interface.PrimaryPumpPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("PrimaryPump", 0, {
            fixedLosses: Number((performanceData.fixedLosses as number) / 100),
            squareLaw: Number((performanceData.squareLaw as number) / 100),
            motorHorsePower: Number(performanceData.motorHorsePower),
            waterSupplyTemp: Number(performanceData.waterSupplyTemp),
            waterReturnTemp: Number(performanceData.waterReturnTemp),
            variableSpeedOrVFD: performanceData.variableSpeedOrVFD,
            minFlowRate: Number((performanceData.minFlowRate as number) / 100),
            staticHead: Number(performanceData.staticHead),
            dynamicHead: Number(performanceData.dynamicHead),
            glycolType: performanceData.glycolType,
            massFractionGlycol: Number(
                (performanceData.massFractionGlycol as number) / 100
            ),
            maxMassFlowRate: Number(performanceData.maxMassFlowRate),
            vendorPumpCurve: performanceData.vendorPumpCurve,
        })
    }

    /**
     * @param performanceData
     */
    private static getSwitchgear(performanceData: Interface.SwitchgearPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("Switchgear", 0, {
            ratedCurrent: Number(performanceData.ratedCurrent),
            voltage: Number(performanceData.voltage),
            threePhase: performanceData.threePhase,
            powerFactor: Number(performanceData.powerFactor),
            noLoadLosses: Number(performanceData.noLoadLosses),
            fullLoadLosses: Number(performanceData.fullLoadLosses),
            fixedSpeedFans: Number(performanceData.fixedSpeedFans),
            variableSpeedFans: Number(performanceData.variableSpeedFans),
        })
    }

    /**
     * @param performanceData
     */
    private static getCRAC(performanceData: Interface.CracPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("CRAC", 0, {
            ratedSensibleCapacity: Number(performanceData.ratedSensibleCapacity),
            nominalLatentCooling: Number(performanceData.nominalLatentCooling), // latendCoolingLoad
            ratedAirReturnTemp: Number(performanceData.ratedAirReturnTemp),
            ratedAirSupplyTemp: Number(performanceData.ratedAirSupplyTemp),
            ratedTempCHWS: Number(performanceData.ratedTempCHWS),
            // Operational Ratings
            varyInstallationCapacity:
                performanceData.varyInstallationCapacity as boolean,
            installedAirReturnTemp: Number(
                performanceData.installedAirReturnTemp
            ),
            installedTempCHWS: Number(performanceData.installedTempCHWS),
            operatingFanSpeed: Number(
                (performanceData.operatingFanSpeed as number) / 100
            ), // installed Air Flow Volume
            installedAirSupplyTemp: Number(
                performanceData.installedAirSupplyTemp
            ),
            // Motor Specifications
            motorShaftPower: Number(performanceData.motorShaftPower),
            numberOfMotors: Number(performanceData.numberOfMotors),
            motorEfficiency: Number(
                (performanceData.motorEfficiency as number) / 100
            ),
            variableSpeedDrive: performanceData.variableSpeedDrive as boolean,
            // Fan Specifications
            fanType: performanceData.fanType,
            fanControlMethod: performanceData.fanControlMethod,
            maxFanSpeed: Number((performanceData.maxFanSpeed as number) / 100),
            minFanSpeed: Number((performanceData.minFanSpeed as number) / 100),
            bypassAirflow: Number(
                (performanceData.bypassAirflow as number) / 100
            ),
        })
    }

    /**
     * @param performanceData
     */
    private static getCoolingTower(performanceData: Interface.CoolingTowerPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("CoolingTower", 0, {
            designCapacity: Number(performanceData.designCapacity),
            supplyWaterTemperature: Number(
                performanceData.supplyWaterTemperature
            ),
            returnWaterTemperature: Number(
                performanceData.returnWaterTemperature
            ),
            designAirTemperatureWetBulb: Number(
                performanceData.designAirTemperatureWetBulb
            ),
            exitAirDepressionEfficiency: Number(
                performanceData.exitAirDepressionEfficiency ?? 0 / 100
            ),
            convectionFlowPerDegree: Number(
                performanceData.convectionFlowPerDegree ?? 0 / 100
            ),
            maxFanPower: Number(performanceData.maxFanPower),
            minApproachTemperature: Number(
                performanceData.minApproachTemperature
            ),
            fullLoadApproachTemperature: Number(
                performanceData.fullLoadApproachTemperature
            ),
            fanControlMode: performanceData.fanControlMode,
            minimumFanSpeed: Number(performanceData.minimumFanSpeed ?? 0 / 100),
            fixedLosses: Number(performanceData.fixedLosses ?? 0 / 100),
            numberOfFans: Number(performanceData.numberOfFans),
            fanOffTemperature: Number(performanceData.fanOffTemperature),
            variableWaterFlowActive: performanceData.variableWaterFlowActive,
            minimumWaterFlow: Number(performanceData.minimumWaterFlow ?? 0 / 100),
        })
    }

    /**
     * @param performanceData
     */
    static getDryCooler(performanceData: Interface.DryCoolerPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData(
            "DryCooler",
            0,
            {
                designEnteringWater: Number(performanceData.designEnteringWater),
                designLeavingWater: Number(performanceData.designLeavingWater),
                designWaterFlow: Number(performanceData.designWaterFlow),
                designEnteringAir: Number(performanceData.designEnteringAir),
                designAirflow: Number(performanceData.designAirflow),
                variableWaterFlowActive: performanceData.variableWaterFlowActive,
                minimumWaterFlow: Number(performanceData.minimumWaterFlow ?? 0) / 100,
                waterMixture: performanceData.waterMixture,
                massFractionGlycol: Number(performanceData.massFractionGlycol ?? 0) / 100,
                fanMotorPower: Number(performanceData.fanMotorPower),
                fanControlMode: performanceData.fanControlMode,
                minimumFanSpeed: Number(performanceData.minimumFanSpeed ?? 0) / 100,
                fixedLosses: Number(performanceData.fixedLosses ?? 0) / 100,
                numberOfFans: Number(performanceData.numberOfFans),
                fanShutoffSpeed: Number(performanceData.fanShutoffSpeed ?? 0) / 100,
                convectionFlowPerDegree: Number(performanceData.convectionFlowPerDegree ?? 0) / 100,
            }
        )
    }

    /**
     * @param performanceData
     */
    private static getChiller(performanceData: Interface.ChillerPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("Chiller", 0, {
            nominalCapacity: Number(performanceData.nominalCapacity),
            copValues: performanceData.copValues,
            nominalCopValue: Number(performanceData.nominalCopValue),
            nominalInputPower: Number(performanceData.nominalInputPower),
            idleInputPowerAtZeroLoad: Number(
                performanceData.idleInputPowerAtZeroLoad
            ),
            condenserEnteringTemperature: Number(
                performanceData.condenserEnteringTemperature
            ),
            condenserApproachMinLoadTemperature: Number(
                performanceData.condenserApproachMinLoadTemperature
            ),
            condenserApproachFullLoadTemperature: Number(
                performanceData.condenserApproachFullLoadTemperature
            ),
            economiserActive: performanceData.economiserActive,
            economiserApproachMinLoadTemperature: Number(
                performanceData.economiserApproachMinLoadTemperature
            ),
            economiserApproachFullLoadTemperature: Number(
                performanceData.economiserApproachFullLoadTemperature
            ),
            economiserActiveTemperature: Number(
                performanceData.economiserActiveTemperature
            ),
            economiserTargetTemperature: Number(
                performanceData.economiserTargetTemperature
            ),
        })
    }

    /**
     * @param performanceData
     */
    static getCabling(performanceData: Interface.CablingPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("Cabling", 0, {
            crossSection: Number(performanceData.crossSection),
            awgGauge: Number(performanceData.awgGauge),
            length: Number(performanceData.length),
            threePhase: performanceData.threePhase,
            powerFactor: Number(performanceData.powerFactor),
            material: performanceData.material,
            numberOfCables: Number(performanceData.numberOfCables),
            cableGrouping: performanceData.cableGrouping,
            temperature: Number(performanceData.temperature),
            voltage: Number(performanceData.voltage),
            currentPerCable: Number(performanceData.currentPerCable),
            impedance: Number(performanceData.impedance),
        })
    }

    /**
     * @param performanceData
     */
    private static getPDU(performanceData: Interface.PduPerformanceForm) {
        return jsonRpcService.getInferredPerformanceData("PDU", 0, {
            ratedCurrent: Number(performanceData.ratedCurrent),
            voltage: Number(performanceData.voltage),
            threePhase: performanceData.threePhase,
            powerFactor: Number(performanceData.powerFactor),
            noLoadLosses: Number(performanceData.noLoadLosses),
            fullLoadLosses: Number(performanceData.fullLoadLosses),
            fixedSpeedFans: Number(performanceData.fixedSpeedFans),
            variableSpeedFans: Number(performanceData.variableSpeedFans),
        })
    }
}