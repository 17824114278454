import { NodeType } from "predict-performance-calculation"

/**
 *
 */
export enum ModelType {
    CHEAP_ONE_COST_STRATEGY = "cheapOneCostStrategy",
    ENVIRONMENT_COST_STRATEGY = "environmentCostStrategy",
    PRIORITY_COST_STRATEGY = "priorityCostStrategy",
    SHARE_COST_STRATEGY = "shareCostStrategy",
    SITE="site",
    ITEM=NodeType.Item,
    ITNODE=NodeType.ITNode,
    OTHER_NODE=NodeType.OtherNode,
    SPLITTER=NodeType.Splitter,
    COMBINER=NodeType.Combiner,
    COOLING_METER_POINT = NodeType.CoolingMeterPoint,
    POWER_METER_POINT = NodeType.PowerMeterPoint,
}