import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { PerformanceParameterBase } from "./PerformanceParameterBase"
import { LegacyFixes } from "@/Simulation/helpers/LegacyFixes"
import { LegacyPerfSplitTemperatureType } from "@/core/enums/LegacyPerfSplitTemperatureType"

export class HeatExchangerParameter extends PerformanceParameterBase<Models.HeatExchangerModel> {
    async fetch() {
        const storedTemperatureType =
            this.performance.getTemperatureType() as Interface.TemperatureType | LegacyPerfSplitTemperatureType
        return {
            capacity: this.performance.getCapacity() ?? 0,
            type: "heatExchanger",
            temperatureType:
                LegacyFixes.temperatureTypeFromLegacy(storedTemperatureType),
            supplyWaterT: this.performance.getSupplyWaterT() ?? 0,
            returnWaterT: this.performance.getReturnWaterT() ?? 0,
            approachTLow: this.performance.getApproachTLow() ?? 0,
            approachTMax: this.performance.getApproachTMax() ?? 0,
            coolerApproachTLow: this.performance.getCoolerApproachTMin() ?? 0,
            coolerApproachTMax: this.performance.getCoolerApproachTMax() ?? 0,
            maxOperatingTemperature: this.performance.getMaxOperatingTemperature() ?? 0,
        } as Interface.HeatExchangerPerformanceData
    }
}