import ApiService from "@/core/services/api.service"
import { WeatherMeterConfigurationsState } from "./types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import { Paginated } from "@/core/helpers/Paginated"

class State implements Partial<WeatherMeterConfigurationsState> {
    weatherMeterConfigurations: Models.WeatherMeterConfigurationModel[] = [];
    loaded = false
}

const getters: GetterTree<WeatherMeterConfigurationsState, any> = {
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getWeatherMeterConfigurations(state, getters): PaginatedResult<Models.WeatherMeterConfigurationModel> {
        return new Paginated(
            state.weatherMeterConfigurations, state.page, state.pageSize,
            getters.weatherMeterConfigurationSort, getters.weatherMeterConfigurationFilter
        )
    },
    getWeatherMeterConfiguration: (state) => (id: string): Models.WeatherMeterConfigurationModel | undefined => {
        return state.weatherMeterConfigurations.find(m => (m as any).id === id)
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.weatherMeterConfigurations?.length === 0
    },
    /**
     *
     * @param state
     * @returns
     */
    weatherMeterConfigurationFilter(state): (a: Models.WeatherMeterConfigurationModel) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return weatherMeterConfiguration => !!weatherMeterConfiguration.id?.match(regexp)
    },
    /**
     *
     * @param state
     * @returns
     */
     weatherMeterConfigurationSort(state): (a: Models.WeatherMeterConfigurationModel, b: Models.WeatherMeterConfigurationModel) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "id":
                return Paginated.sortUndefinedFirst((config) => config.id, reverse)
            default:
                return (a, b) => 0
        }
    },
}

const mutations: MutationTree<State> = {
    setWeatherMeterConfigurations(state, weatherMeterConfigurations): void {
        state.weatherMeterConfigurations = weatherMeterConfigurations
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    mutDeleteWeatherMeterConfiguration(state, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel): void {
        state.weatherMeterConfigurations = state.weatherMeterConfigurations.filter(m => (m as any).id !== (weatherMeterConfiguration as any).id)
    },
    mutCreateWeatherMeterConfiguration(state, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel): void {
        state.weatherMeterConfigurations.push(weatherMeterConfiguration)
    },
    mutUpdateWeatherMeterConfiguration(state, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel): void {
        state.weatherMeterConfigurations = state.weatherMeterConfigurations.map(m => {
            if ((m as any).id === (weatherMeterConfiguration as any).id) {
                return weatherMeterConfiguration
            }
            return m
        })
    },
}

const actions = <ActionTree<State, any>>{
    async fetchWeatherMeterConfigurations({ commit }) {
        const service = await ApiService.getWeatherMeterConfigurationService()
        const records = service.getAllWeatherMeterConfigurations()
        const recordsFound: Models.WeatherMeterConfigurationModel[] = []
        for await (const item of records) {
            recordsFound.push(item)
        }
        commit("setLoaded", true)
        commit("setWeatherMeterConfigurations", recordsFound)
    },
    deleteWeatherMeterConfiguration({ commit }, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel) {
        commit("mutDeleteWeatherMeterConfiguration", weatherMeterConfiguration)
    },
    createWeatherMeterConfiguration({ commit }, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel) {
        commit("mutCreateWeatherMeterConfiguration", weatherMeterConfiguration)
    },
    updateWeatherMeterConfiguration({ commit }, weatherMeterConfiguration: Models.WeatherMeterConfigurationModel) {
        commit("mutUpdateWeatherMeterConfiguration", weatherMeterConfiguration)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const WeatherMeterConfigurationModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default WeatherMeterConfigurationModule
