/**
 *
 */
export enum DeviceModelLabel {
    CRAC = "CRAC Unit",
    CABLES = "Cables",
    CHILLER = "Chiller",
    CONDENSER = "Condenser",
    COOLING_TOWER = "Cooling Tower",
    HEATER = "Heater",
    PDU = "PDU",
    PUMP = "Pump",
    SWITCH = "Switchgear",
    TRANSFORMER = "Transformer",
    UPS = "UPS",
    HEAT_EXCHANGER = "Heat Exchanger",
    PUMP_CONDENSED = "Pump (simple model)",
    DRY_COOLER = "Dry Cooler"
}