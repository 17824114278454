import { ClimateProfileData } from "@/Simulation"
import { ClimateGraphType, TemperatureUnit } from "@/core/enums"
import { DateHelper } from "@/core/helpers"
import { ConvertibleUnits } from "@/core/localisation"

/**
 *
 */
export class GraphHelper {
    /**
     *
     * @param result
     * @param date
     * @returns
     */
    static getActualValue(result: Map<string, number>, date: string) {
        const from = new Date(date)
        const to = new Date(from)
        to.setDate(to.getDate() + 1)

        for (const [date, resultItem] of result.entries()) {
            const dt = new Date(date)

            if (dt >= from && dt <= to) {
                return resultItem
            }
        }
        return null
    }

    /**
     *
     * @param temperatureUnit
     * @param convertibleUnits
     * @param value
     * @param type
     * @returns
     */
    private static getConvertedValue(
        temperatureUnit: TemperatureUnit | null,
        convertibleUnits: ConvertibleUnits | null,
        value: number,
        type: ClimateGraphType
    ): number {
        const isFahrenheit = temperatureUnit === TemperatureUnit.Fahrenheit
        if (type === ClimateGraphType.T2M) {
            const convertedTemperature = convertibleUnits?.convertTemperature(value) as number
            if (isFahrenheit) {
                return Math.round((convertedTemperature) / 2) * 2
            }
            return Math.round(convertedTemperature)
        }
        return Math.round(value)
    }

    /**
     *
     * @param temperatureUnit
     * @param convertibleUnits
     * @param climateProfileData
     * @param type
     * @returns
     */
    static getFrequencyMapData(
        temperatureUnit: TemperatureUnit | null,
        convertibleUnits: ConvertibleUnits | null,
        climateProfileData: ClimateProfileData,
        type: ClimateGraphType
    ) {
        const frequencyMap = new Map<number, number>()

        if ("TMYMonth" in climateProfileData) {
            const tmyMonthData = climateProfileData.TMYMonth

            for (const t of tmyMonthData) {
                for (const curr of t.TMYData) {
                    const convertedValue = GraphHelper.getConvertedValue(temperatureUnit, convertibleUnits, curr[type], type)
                    const v = frequencyMap.get(convertedValue) ?? 0
                    frequencyMap.set(convertedValue, v + 1)
                }
            }
        } else if ("StatMonth" in climateProfileData) {
            const statMonthData = climateProfileData.StatMonth
            const knownLeapYear = 2024
            for (const t of statMonthData) {
                const daysInMonth = DateHelper.getDaysInMonth(`${knownLeapYear}-${t.month}`)
                for (const curr of t.statDatum) {
                    const convertedValue = GraphHelper.getConvertedValue(temperatureUnit, convertibleUnits, curr[type], type)
                    const v = frequencyMap.get(convertedValue) ?? 0
                    frequencyMap.set(convertedValue, v + daysInMonth)
                }
            }
        } else {
            return []
        }

        return new Map([...frequencyMap.entries()].sort((a, b) => a[0] - b[0]))
    }
}