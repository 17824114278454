import { FormatterFormatOptions } from "../interfaces"
import { NumberFormatSuffix } from "./NumberFormatSuffix"

/**
 *
 */
export abstract class Formatter {
    /**
     * @param value
     * @param units
     * @param withSpace
     */
    static appendUnits(value: string, units: string, withSpace = true) {
        if (units) {
            return withSpace ? `${value} ${units}` : (value + units)
        } else {
            return value
        }
    }

    /**
     *
     */
    protected defaultMaxDigits: number | null = null

    /**
     *
     */
    abstract readonly suffix: NumberFormatSuffix

    /**
     * Produces the value with (or without) units
     *
     * @param value
     * @param options
     * @returns
     */
    abstract format(value: number, options?: FormatterFormatOptions): string

    /**
     * Produces the number in the preferred form (not formatted)
     *
     * @param value
     * @param options
     * @returns
     */
    nativeToPreferred(value: number, options?: { digits?: number }): number {
        const digits = options?.digits ?? this.defaultMaxDigits
        if(digits !== null) {
            const factor = Math.pow(10, digits)
            return Math.round(+value * factor) / factor
        } else {
            return +value
        }
    }

    /**
     *
     * @param value
     * @returns
     */
    preferredToNative(value: number) {
        return value
    }
}