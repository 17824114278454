import { ProgressClientBase } from "./ProgressClientBase"

/**
 *
 */
export abstract class AllocatableClient extends ProgressClientBase {
    /**
     *
     */
    amortise = false
}