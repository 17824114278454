import { SensorSource, SensorTabType } from "@/core/enums"
import { Paginated } from "@/core/helpers/Paginated"
import { MeterMappingSensor, Sensor } from "@/core/interfaces"
import { CbreSoftSensorService, EkkoSoftSensorService } from "@/core/services"
import ApiService from "@/core/services/api.service"
import { Models } from "@ekko/predict-client-api"
import { NodeType } from "predict-performance-calculation"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { AnyNode } from "../SiteViewState"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import { AnySensorInformation, MeterMappingsState } from "./types"

class State implements Partial<MeterMappingsState> {
    sensors: Sensor[] = []
    sensorTab: SensorTabType | null = null
    loaded = false
    sensorSource: SensorSource | null = null
    /**
     *
     */
    sensorSourceInformation = new Map<SensorSource, AnySensorInformation>()
    /**
     * FIXME
     */
    site: Models.SiteModel | null = null
    isMultipleSensorSource = false
}

const getters: GetterTree<MeterMappingsState, any> = {
    /**
     *
     * @param state
     * @returns
     */
    currentSensorSourceInformation(state) {
        if(!state.sensorSource) return null
        return state.sensorSourceInformation.get(state.sensorSource)
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getSensors(state, getters): PaginatedResult<Sensor> {

        return new Paginated(
            state.sensors, state.page, state.pageSize, undefined,
            getters.sensorsFilter
        )
    },
    getSensor: (state) => (sourceId: string): Sensor | undefined => {
        return state.sensors.find(sensor => sensor.sourceId === sourceId)
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.sensors?.length === 0
    },
    getSensorTab(state): SensorTabType | null {
        return state.sensorTab
    },
    getSensorSource(state): SensorSource | null {
        return state.sensorSource
    },
    isMultipleSensorSource(state): boolean {
        return state.isMultipleSensorSource
    },
    /**
     *
     * @param state
     * @returns
     */
    sensorsFilter(state): (a: Sensor) => boolean {
        const escapedSearchText = state.searchText.replace(/([+*.[{()^?$|\\-])/g, "\\$1")
        const regexp = new RegExp(escapedSearchText, "i")

        return sensor => {

            const textMatches = sensor.meterMapping.item?.match(regexp)
                || sensor.meterMapping.mapping?.match(regexp)

            const tabMatches = state.sensorTab ? sensor.meterMapping.mapping?.match(state.sensorTab) : null
            return !!textMatches && !!tabMatches
        }
    },
}

const mutations: MutationTree<State> = {
    /**
     *
     * @param state
     * @param sensorSourceData
     */
    addSensorSourceInformation(state,
        [sensorSource, data]: [SensorSource, AnySensorInformation]
    ) {
        state.sensorSourceInformation.set(sensorSource, data)
    },
    setSensors(state, sensors: Sensor[]): void {
        state.sensors = sensors
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    setSensorSource(state, sensorSource: SensorSource | null): void {
        state.sensorSource = sensorSource
    },
    setMultipleSensorSource(state, multipleSource: boolean): void {
        state.isMultipleSensorSource = multipleSource
    },
    mutDeleteSensor(state, sensor: Sensor): void {
        state.sensors = state.sensors.filter(s => s.sourceId !== sensor.sourceId)
    },
    mutCreateSensor(state, sensor: Sensor): void {
        state.sensors.push(sensor)
    },
    mutUpdateSensor(state, sensor: Sensor): void {
        state.sensors = state.sensors.map(s => {
            if (s.sourceId === sensor.sourceId) {
                return sensor
            }
            return s
        })
    },
    mutUpdateSensorTab(state, sensorTab: SensorTabType | null): void {
        state.sensorTab = sensorTab
    },
}

const actions = <ActionTree<State, any>>{
    /**
     *
     * @param actionState
     * @param site
     */
    async selectSite({ commit, state }, site: Models.SiteModel) {
        if(site.id !== state.site?.id) {
            const siteEkkoSoftMeterMapping = await site.getSiteEkkoSoftMeterMapping()
            const siteCbreMeterMapping = await site.getSiteCbreMeterMapping()
            if (siteEkkoSoftMeterMapping) {
                commit("setSensorSource", SensorSource.EKKO_SOFT)

                const sensorService = new EkkoSoftSensorService(site.id)
                commit("addSensorSourceInformation", [SensorSource.EKKO_SOFT,
                    await sensorService.getSiteSensorInformation()])
            }
            if (siteCbreMeterMapping) {
                commit("setSensorSource", SensorSource.CBRE)

                const sensorService = new CbreSoftSensorService(site.id)
                commit("addSensorSourceInformation", [SensorSource.CBRE,
                    await sensorService.getSiteSensorInformation()])
            }

            if (siteEkkoSoftMeterMapping && siteCbreMeterMapping) {
                commit("setMultipleSensorSource", true)
                commit("setSensorSource", SensorSource.EKKO_SOFT)
            } else {
                commit("setMultipleSensorSource", false)
            }
        }
    },
    async fetchSensors({ commit, dispatch, state }, record: { type: string, id: string }) {
        const mappedMeterMappings = new Map<
            string,
            AsyncGenerator<Models.EkkoSoftMeterMappingModel, void, any> |
            AsyncGenerator<Models.CbreMeterMappingModel, void, any>
        >()
        let node: AnyNode

        switch (record.type) {
            case NodeType.Combiner: {
                const service = await ApiService.getCombinerService()
                node = await service.getCombiner(record.id)
                break
            }
            case NodeType.CoolingMeterPoint: {
                const service = await ApiService.getCoolingMeterPointService()
                node = await service.getCoolingMeterPoint(record.id)
                break
            }
            case NodeType.Item: {
                const service = await ApiService.getItemService()
                node = await service.getItem(record.id)
                break
            }
            case NodeType.ITNode: {
                const service = await ApiService.getITNodeService()
                node = await service.getITNode(record.id)
                break
            }
            case NodeType.OtherNode: {
                const service = await ApiService.getOtherNodeService()
                node = await service.getOtherNode(record.id)
                break
            }
            case NodeType.PowerMeterPoint: {
                const service = await ApiService.getPowerMeterPointService()
                node = await service.getPowerMeterPoint(record.id)
                break
            }
            case NodeType.Splitter: {
                const service = await ApiService.getSplitterService()
                node = await service.getSplitter(record.id)
                break
            }
            default: {
                throw new Error(`Unrecognised node type: ${record.type}`)
            }
        }

        if (node) {
            const siteConfig = await node.getSiteConfiguration()

            if (siteConfig) {
                const site = await siteConfig.getSite()
                if (site) {
                    const filter = { relation: "node", record: record }

                    const siteEkkoSoftMeterMapping = await site.getSiteEkkoSoftMeterMapping()
                    const siteCbreMeterMapping = await site.getSiteCbreMeterMapping()
                    if (siteEkkoSoftMeterMapping) {
                        const ekkoSoftMeterMappingService = await ApiService.getEkkoSoftMeterMappingService()
                        const ekkoSoftMeterMappingsGenerator = ekkoSoftMeterMappingService.getAllEkkoSoftMeterMappings(filter)
                        mappedMeterMappings.set(SensorSource.EKKO_SOFT, ekkoSoftMeterMappingsGenerator)
                    }
                    if (siteCbreMeterMapping) {
                        const cbreMeterMappingService = await ApiService.getCbreMeterMappingService()
                        const cbreMeterMappingsGenerator = cbreMeterMappingService.getAllCbreMeterMappings(filter)
                        mappedMeterMappings.set(SensorSource.CBRE, cbreMeterMappingsGenerator)
                    }

                    await dispatch("selectSite", site)
                }
            }
        }

        const sensors: Sensor[] = []

        for (const [sensorSource, meterMappingsGenerator] of mappedMeterMappings) {
            for await (const meterMappingModel of meterMappingsGenerator) {
                const meterMappingSensor: MeterMappingSensor = {
                    item: meterMappingModel.getReference(),
                    mapping: meterMappingModel.getSensorType(),
                    scale: meterMappingModel.getMultiplyBy(),
                    mappingId: meterMappingModel.id,
                }

                for await (const meterMappingSourceModel of meterMappingModel.getMappingSources()) {
                    const sensor: Sensor = {
                        sensorSource: sensorSource as SensorSource,
                        function: meterMappingSourceModel.getCollate() as string,
                        meterId: meterMappingSourceModel.getSourceReference(),
                        sourceId: meterMappingSourceModel.id,
                        meterMapping: meterMappingSensor,
                    }

                    sensors.push(sensor)
                }
            }
        }

        commit("setLoaded", true)
        commit("setSensors", sensors)
    },
    deleteSensor({ commit }, sensor: Sensor) {
        commit("mutDeleteSensor", sensor)
    },
    createSensor({ commit }, sensor: Sensor) {
        commit("mutCreateSensor", sensor)
    },
    updateSensor({ commit }, sensor: Sensor) {
        commit("mutUpdateSensor", sensor)
    },
    reset({ commit }) {
        commit("setSensors", [])
        commit("setLoaded", false)
    },
    updateSensorTab({ commit }, sensorTab: SensorTabType | null) {
        commit("mutUpdateSensorTab", sensorTab)
    },
    setSensorSource({ commit }, sensorSource: SensorSource | null) {
        commit("setSensorSource", sensorSource)
    },
    setIsMultipleSensorSource({ commit }, multipleSource: boolean) {
        commit("setMultipleSensorSource", multipleSource)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const MeterMappingModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default MeterMappingModule
