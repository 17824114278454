import { Granularity } from "@/Simulation"
import { Reading } from "../interfaces"
import { BatchResults } from "./BatchResults"


/**
 *
 */
export class ReadingBatchResults extends BatchResults<Record<string, Reading<string>[]>> {
    /**
     *
     * @param items
     * @param getResults
     * @param granularity
     * @returns
     */
       static async iterate<I>(items: I[],
        getResults: (item: I) => Promise<Record<string, Reading<string>[]>>,
        granularity: Granularity
    ) {
        const batchResults = new ReadingBatchResults(granularity)
        
        for(const item of items) {
            const itemResult = await getResults(item)
            batchResults.add(itemResult)
        }
        return batchResults.finalise()
    }

    /**
     *
     */
    private importCount = 0

    /**
     *
     */
    private storedResults: Record<string, Reading<string>[]> = {}

    /**
     *
     */
    get count() {
        return this.importCount
    }

    /**
     *
     * @param resultsIn
     */
    add(resultsIn: Record<string, Reading<string>[]>) {
        const results: Record<string, Reading<string>[]> = JSON.parse(JSON.stringify(resultsIn))
        for (const [nodeName, result] of Object.entries(results)) {
            this.storedResults[nodeName] = this.storedResults[nodeName]?.concat(result) ?? result
        }
        
        this.importCount++
    }

    /**
     *
     * @returns
     */
    finalise() {        
        return this.storedResults
    }
}