/**
 *
 */
export enum FilterType {
    COMBINED_CAPACITY = "combinedCapacity",
    SIMULATION_OVER_TIME = "simulationOverTime",
    PROVISIONAL_CAPACITY = "provisionalCapacity",
    UTILIZED_CAPACITY = "utilizedCapacity",
    EFFICIENCY = "efficiency",
    ACTUAL_EXPECTED = "actualExpected",
    CALIBRATION_STATUS = "calibrationStatus",
    METERING_STATUS = "meteredStatus",
    SIMULATION_OUTPUT = "simulationOutput",
    ENGINEER_VIEW = "engineerView",
}