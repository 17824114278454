import { ExternalVariableHumidity } from "@/core/enums"
import { NodeReferenceHelper } from "@/core/helpers"
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { NodeParameterBase } from "./NodeParameterBase"
import { LegacyFixes } from "@/Simulation/helpers/LegacyFixes"
import { LegacyPerfSplitTemperatureType } from "@/core/enums/LegacyPerfSplitTemperatureType"

/**
 *
 */
export class SplitterParameter extends NodeParameterBase<Models.SplitterModel, Interface.Splitter> {
    async fetch(splitter: Models.SplitterModel) {
        const dynamicCostStrategy = await splitter.getSplitterDynamicCostStrategy()

        let splitterDynamicCostStrategy
        if (dynamicCostStrategy instanceof Models.CheapOneCostStrategyModel) {
            const strategy: Interface.CheapOneCostStrategy = {
                handler: "CheapOne",
                lowNode: dynamicCostStrategy.getLowNode()
            }
            splitterDynamicCostStrategy = strategy
        } else if (dynamicCostStrategy instanceof Models.EnvironmentCostStrategyModel) {
            const type =
                dynamicCostStrategy.getExternalVariable() as Interface.TemperatureType | LegacyPerfSplitTemperatureType | ExternalVariableHumidity
            const strategy: Interface.EnvironmentCostStrategy = {
                handler: "Environment",
                lowNodes: dynamicCostStrategy.getLowNodes()!,
                type:
                    LegacyFixes.environmentConditionFromLegacy(type) as Interface.TemperatureType,
                lowPoint: dynamicCostStrategy.getLowPoint()!,
                highPoint: dynamicCostStrategy.getHighPoint()!,
                cutoff: dynamicCostStrategy.getCutoff()!,
            }
            splitterDynamicCostStrategy = strategy
        } else if (dynamicCostStrategy instanceof Models.PriorityCostStrategyModel) {
            const strategy: Interface.PriorityCostStrategy = {
                handler: "Priority",
                nodeOrder: dynamicCostStrategy.getNodeOrder()!,
                capacityThreshold: dynamicCostStrategy.getCapacityThreshold()!,
                excessNode: dynamicCostStrategy.getExcessNode(),
                evenSplit: !!dynamicCostStrategy.getEvenSplit(),
            }
            splitterDynamicCostStrategy = strategy
        } else if (dynamicCostStrategy instanceof Models.ShareCostStrategyModel) {
            const strategy: Interface.ShareCostStrategy = {
                handler: "Share",
                excessNode: dynamicCostStrategy.getExcessNode(),
                nodeShares: dynamicCostStrategy.getNodeShares()!,
            }
            splitterDynamicCostStrategy = strategy
        }
        return {
            ...await this.fetchBasic(splitter),
            ...await NodeReferenceHelper.getSplitterSupplierReference(splitter),
            staticCostHandler: splitter.getStaticCostHandler() || "",
            splitterDynamicCostStrategy
        }
    }
}