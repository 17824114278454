import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { Kilowatts } from "./Kilowatts"

/**
 *
 */
export class KilowattHours extends Kilowatts {
    protected suffixSymbol = "kWh"

    suffix = NumberFormatSuffix.kilowattHours
}