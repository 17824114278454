/**
 *
 */
export enum ReportFields {
    LABEL = "Node Label",
    TYPE = "Node Type",
    MODEL = "Model",
    MANUFACTURER = "Manufacturer",
    MODE = "Mode/Specification",
    CAPACITY = "Capacity (kW)",
    CALIBRATED = "Calibrated",
    RESILIENCE = "Resilience",
    QUANTITY = "Quantity",
    CALCULATED_CAPACITY = "Calculated Capacity"
}

