import { ClientCollection } from "@/History/factory/ClientCollection"
import { NamedSimulationNode, SimulationSiteConfiguration } from "@/Simulation/interfaces"
import { NodeHelper } from "@/core/helpers"
import { GraphData } from "@/core/interfaces/GraphData"
import { SimulationOverTime } from "@/store/simulation-view/types"
import Highcharts from "highcharts"
import { Interface } from "predict-performance-calculation"
import { Granularity } from "../enums"


type AreaGraphData = { [row: string]: { [column: string]: any } }
export type LinearGraphData = AreaGraphData[]

/**
 *
 */
export enum LineGraphType {
    Spline = "spline",
    Line = "line"
}

export class GraphDataHelper {
    /**
     *
     * @param series
     * @returns
     */
    private static getNumberKeyedSeriesData(series: AreaGraphData): Array<[number, number]> {
        return Object.entries(series)
            .sort((a, b) => +a[0] - +b[0])
            .map(([key, value]) => [+key, +Object.values(value)[0]])
    }

    /**
     *
     * @param nodesData
     * @param nodesets
     * @returns
     */
    private static getPowerSupplyNodeReferences(nodesData: NamedSimulationNode[], nodesets: SimulationSiteConfiguration[] | null) {
        const powerSupplyNode = this.getPowerSupplyNode(nodesData)
        if(!powerSupplyNode) {
            return []
        }
        const nodeData = nodesData.find(nd => nd.type == powerSupplyNode.type && nd.id == powerSupplyNode.id)
        return NodeHelper.getNodeReferences(powerSupplyNode, nodeData, nodesets)
    }

    /**
     *
     * @param series
     * @returns
     */
    private static getStringKeyedSeriesData(series: AreaGraphData): Array<[string, number]> {
        return Object.entries(series)
            .map(([key, value]) => [key, +Object.values(value)[0]])
    }

    /**
     *
     * @param graphData
     * @param labels
     * @returns
     */
    private static getSeriesLabels(graphData: LinearGraphData, labels: string[] | null = null) {
        return labels ?? graphData.map((_, index) => `Series ${index + 1}`)
    }

/**
 * @param simulationOverTime
 * @param nodesData
 * @param nodesets
 * @param getValueFn
 * @returns
 */
static getChartData(
    simulationOverTime: SimulationOverTime,
    nodesData: NamedSimulationNode[],
    nodesets: SimulationSiteConfiguration[] | null,
    getValueFn: (itLoad: number, incomerPowerDemand: number) => number
  ) {
    const powerSupplyNodeReferences = this.getPowerSupplyNodeReferences(
      nodesData,
      nodesets
    )
    if (!powerSupplyNodeReferences.length) {
      return []
    }

    const graphData: GraphData[] = []

    const timestamps = Object.keys(simulationOverTime)
    const timestampsOrdered = [...timestamps].sort()

    for (const timestamp of timestampsOrdered) {
      const simulationData = simulationOverTime[timestamp].simulationData
      if (!simulationData) continue

      const itLoad = Object.entries(simulationData)
        .filter(([reference]) => reference.match(/^itNode\//))
        .reduce((c, [r, i]) => c + i[0], 0)

      const incomerPowerDemand = powerSupplyNodeReferences.reduce(
        (c, ref) => c + (simulationData[ref]?.[0] ?? 0),
        0
      )

      graphData.push({ timestamp, value: getValueFn(itLoad, incomerPowerDemand) })
    }

    return graphData
  }

  /**
   * @param simulationOverTime
   * @param nodesData
   * @param nodesets
   * @returns
   */
  static getDCiEData(
    simulationOverTime: SimulationOverTime,
    nodesData: NamedSimulationNode[],
    nodesets: SimulationSiteConfiguration[] | null
  ) {
    return this.getChartData(simulationOverTime, nodesData, nodesets, (itLoad, incomerPowerDemand) => {
      return 100 * itLoad / incomerPowerDemand
    })
  }

  /**
   * @param simulationOverTime
   * @param nodesData
   * @param nodesets
   * @returns
   */
  static getCUEData(
    simulationOverTime: SimulationOverTime,
    nodesData: NamedSimulationNode[],
    nodesets: SimulationSiteConfiguration[] | null,
    carbonFactor: number
  ) {
    return this.getChartData(simulationOverTime, nodesData, nodesets, (itLoad, incomerPowerDemand) => {
      return carbonFactor * incomerPowerDemand / itLoad
    })
  }

    /**
     *
     * @param clientName
     * @param siteId
     * @param nodesData
     * @param granularity
     * @returns
     */
    static async getHistoricalData(
        clientName: "PUEHistoryClient" | "DCiEHistoryClient" | "CUEHistoryClient",
        siteId: string,
        nodesData: NamedSimulationNode[],
        granularity: Granularity,
        actualItLoad?: Map<string, number> | undefined
    ) {
        const graphData: GraphData[] = []
        if (siteId && nodesData) {
            const client = ClientCollection.factory(clientName) as any
            client.granularity = granularity
            const data = (await client.fetchComparison(
                siteId,
                nodesData,
                actualItLoad
            )) as Map<string, number>

            for (const [timestamp, value] of data) {
                graphData.push({ timestamp, value })
            }
        }

        return graphData
    }

    /**
     *
     * @param simulationOverTime
     * @param nodesData
     * @param nodesets
     * @returns
     */
    static getTotalIncomerData(simulationOverTime: SimulationOverTime,
        nodesData: NamedSimulationNode[],
        nodesets: SimulationSiteConfiguration[] | null
    ) {
        const graphData: GraphData[] = []

        if (simulationOverTime) {
            const timestamps = Object.keys(simulationOverTime)
            const timestampsOrdered = [...timestamps.values()].sort((a, b) => a.localeCompare(b))

            const powerSupplyNodeReferences = this.getPowerSupplyNodeReferences(nodesData, nodesets)
            if(powerSupplyNodeReferences.length) {
                for (const timestamp of timestampsOrdered) {
                    const simulationData = simulationOverTime[timestamp].simulationData

                    if(!simulationData) continue

                    const incomerPowerDemand = powerSupplyNodeReferences.reduce((c, ref) => c + (simulationData[ref]?.[0] ?? 0), 0)

                    graphData.push({ timestamp, value: incomerPowerDemand })
                }
            }
        }
        return graphData
    }

    /**
     *
     * @param nodesData
     * @returns
     */
    static getPowerSupplyNode(nodesData: NamedSimulationNode[]) {
        return (
            (nodesData?.find(
                (n) => !n.cooledBy && !n.poweredBy
            ) as unknown as Interface.Item) || null
        )
    }

    /**
     *
     * @param graphData
     * @param type
     * @param labels
     * @returns
     */
    static populateNumberKeyedLineSeries(graphData: LinearGraphData,
        type: LineGraphType, labels: string[] | null = null
    ): Array<Highcharts.SeriesOptionsType & {data: Array<[number, number]>}> {
        const allSeriesLabels = this.getSeriesLabels(graphData, labels)
        const allSeriesData = Object.values(graphData).map(series => this.getNumberKeyedSeriesData(series))
        return allSeriesData.map((seriesData, i) => ({type, name: allSeriesLabels[i], data: seriesData}))
    }

    /**
     *
     * @param graphData
     * @param type
     * @param labels
     * @returns
     */
    static populateStringKeyedLineSeries(graphData: LinearGraphData, type: LineGraphType, labels: string[] | null = null) {
        const allSeriesLabels = this.getSeriesLabels(graphData, labels)
        const allSeriesData = Object.values(graphData).map(series => this.getStringKeyedSeriesData(series))
        return allSeriesData.map((seriesData, i) => ({type, name: allSeriesLabels[i], data: seriesData}))
    }
}