<template>
  <div
    class="toast-container toast-pos-top-mid"
  >
    <div
      v-if="notification"
      id="toast-2"
      :class="toastClassesByType[notification.type]"
    >
      <a
        href="#"
        class="close-toast"
        @click="onClose"
      >&#10006;</a>
      <b>{{ notification.title }} /</b> {{ notification.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/core/interfaces"
import { Options, Vue } from "vue-class-component"
import { notificationsService } from "@/core/services/notifications.service"
import { NotificationType } from "@/core/enums"

@Options({
  emits: ["close"],
})
export default class Notifications extends Vue {
  get notification(): Notification | null {
    return notificationsService.notification
  }

  get toastClassesByType() {
    return {
      [NotificationType.Success]: "toast saved",
      [NotificationType.Error]: "toast warning"
    }
  }

  onClose(): void {
    this.$emit("close")
    notificationsService.removeNotification()
  }
}
</script>
