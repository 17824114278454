import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { Base } from "./Base"
import { NRef } from "@/store/SiteViewState"
import { AnySplitterStrategy } from "./AnySplitterStrategy"
import { ModelType } from "@/core/enums"

/**
 *
 */
export class Splitter extends Base<Models.SplitterModel> {
    /**
     *
     * @returns
     */
    private getService() {
        return ApiService.getNamedService<Services.SplitterService>("SplitterService")
    }
    /**
     *
     * @param strategy
     * @returns
     */
    private isCheapOne(strategy: AnySplitterStrategy): strategy is Models.CheapOneCostStrategyModel {
        return strategy.type == ModelType.CHEAP_ONE_COST_STRATEGY
    }
    /**
     *
     * @param strategy
     * @returns
     */
    private isEnvCost(strategy: AnySplitterStrategy): strategy is Models.EnvironmentCostStrategyModel {
        return strategy.type == ModelType.ENVIRONMENT_COST_STRATEGY
    }
    /**
     *
     * @param strategy
     * @returns
     */
    private isPriority(strategy: AnySplitterStrategy): strategy is Models.PriorityCostStrategyModel {
        return strategy.type == ModelType.PRIORITY_COST_STRATEGY
    }
    /**
     *
     * @param strategy
     * @returns
     */
    private isShareCost(strategy: AnySplitterStrategy): strategy is Models.ShareCostStrategyModel {
        return strategy.type == ModelType.SHARE_COST_STRATEGY
    }
    /**
     *
     * @param splitter
     * @param strategy
     */
    private async storeSplitterStrategy(splitter: Models.SplitterModel, strategy: AnySplitterStrategy) {
        strategy.addSplitter(splitter)
        if(this.isCheapOne(strategy)) {
            const strategyService = await ApiService.getNamedService<Services.CheapOneCostStrategyService>("CheapOneCostStrategyService")
            await strategyService.addCheapOneCostStrategy(strategy)
        } else if(this.isEnvCost(strategy)) {
            const strategyService = await ApiService.getNamedService<Services.EnvironmentCostStrategyService>("EnvironmentCostStrategyService")
            await strategyService.addEnvironmentCostStrategy(strategy)
        } else if(this.isPriority(strategy)) {
            const strategyService = await ApiService.getNamedService<Services.PriorityCostStrategyService>("PriorityCostStrategyService")
            await strategyService.addPriorityCostStrategy(strategy)
        } else if(this.isShareCost(strategy)) {
            const strategyService = await ApiService.getNamedService<Services.ShareCostStrategyService>("ShareCostStrategyService")
            await strategyService.addShareCostStrategy(strategy)
        } else {
            throw new Error(`Splitter strategy ${(strategy as any).modelType} is unknown`)
        }
    }
    async add(): Promise<NRef<Models.SplitterModel>> {
        const service = await this.getService()
        const strategy = await this.node.getSplitterDynamicCostStrategy()
        const [nodeOut] = await service.addSplitter(this.node)
        try {
            await this.storeSplitterStrategy(nodeOut, strategy)
        } catch (e) {
            await service.removeSplitter(nodeOut)
            throw e
        }
        this.node = nodeOut
        await this.connect()
        return nodeOut
    }
    async connect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.addSplitters([this.node])
    }
    async disconnect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.removeSplitters([this.node])
    }
    async remove(): Promise<any> {
        const service = await this.getService()
        await service.removeSplitter(this.node)
        return this.disconnect()
    }
    async update(): Promise<any> {
        const service = await this.getService()
        await service.updateSplitter(this.node)
    }
}