import { UnbatchEvents } from "@/core/interfaces"
import { DateGroupSimulationResult, GranularCapacityUsage, HistogramFormat, NodeDynamicCosts, NodeDynamicCostsByType, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { ClientBase } from "./ClientBase"

/**
 *
 */
export abstract class ProgressClientBase extends ClientBase {
    /**
     *
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     */
    abstract simulate(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        unbatchCallbacks?: UnbatchEvents
    ): Promise<NodeDynamicCosts | NodeDynamicCostsByType |
        GranularCapacityUsage | HistogramFormat[] |
        DateGroupSimulationResult[]>
}