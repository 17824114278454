import { BaseError } from "./BaseError"

/**
 *
 */
type JsonRpcRemoteError = {code: number, message: string}

/**
 *
 */
export class JsonRpcError extends BaseError {
    /**
     *
     */
    public readonly error: JsonRpcRemoteError

    /**
     *
     * @param responseBody
     * @param method
     */
    constructor(responseBody: {error: JsonRpcRemoteError},
        public method: string
    ) {
        super(
            `JSON-RPC error calling "${method}": [${responseBody.error.code}] ${responseBody.error.message}`
        )
        this.error = responseBody.error
    }
}