/**
 * This works around Typescript being unable to properly subclass Error as of version 2.1
 *
 * @see https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
 *
 * Suitable for errors where "instanceof" should be used to test them.
 */
export abstract class BaseError {
    /**
     *
     * @param message
     */
    constructor(public message: string = "") {

    }
}