import { BaseError } from "./BaseError"

/**
 *
 */
export class HttpError extends BaseError {
    /**
     *
     * @param code
     * @param message
     */
    constructor(public readonly code: number, message?: string) {
        super(message ?? `Request failed with HTTP status ${code}`)
    }
}