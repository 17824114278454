import { NamedSimulationNode, NodeReference, NodeTooltipData, QuickModeProps } from "@/Simulation/interfaces"
import { SimulationData, TimeBasedData } from "@/store/simulation-view/types"
import { ConnectionType, NodeType, ResilienceHandler } from "predict-performance-calculation"
import { NodeReferenceHelper } from "./NodeReferenceHelper"
import { NumberFormat } from "./NumberFormat"
import { NumberFormatMode } from "./NumberFormatMode"

/**
 * This handles the production of information for hover info in the simulation view
 */
export class NodeTooltipHelper {
    /**
     *
     * @param nodesData
     * @param node
     * @param coolingLoad
     * @param powerLoad
     * @returns
     */
    private static getMinimumProvisioning(nodesData: NamedSimulationNode[],
        node: NodeReference, coolingLoad: number, powerLoad: number) {
        const simNode = nodesData.find(
            nd => nd.type == node.type && nd.id == node.id)

        if (simNode && "resilience" in simNode) {
            const resilienceHandler = new ResilienceHandler()
            return resilienceHandler.getInstalledDevicesFor(
                    simNode.resilience ?? null, simNode.performanceData ??
                simNode.deviceMode.performanceData,
                    { cooling: coolingLoad, power: powerLoad, it: 0 })
        }
        return undefined
    }

    /**
     * This is for simulation hover info
     *
     * @param node
     * @param simulationData
     * @param timeBasedData
     * @param nodesData
     * @param quickModeProps
     * @returns
     */
    static getTooltipContent(node: NodeReference,
        simulationData: SimulationData | null,
        timeBasedData: Pick<TimeBasedData, "provisioning"> | null,
        nodesData: NamedSimulationNode[] | null,
        quickModeProps: QuickModeProps | null,
    ): NodeTooltipData {
        const nodeRef = NodeReferenceHelper.getNodeReference(node)

        let heatIn: number | null = null
        let heatOut: number | null = null
        let powerIn: number | null = null
        let powerOut: number | null = null
        let quantity: number | null = null

        let minimumProvisioningRequired: number | undefined
        if (simulationData?.[nodeRef] && nodesData) {
            const [powerDemand, powerLoad, coolingLoad] = simulationData[nodeRef]

            const poweredNodes = NodeReferenceHelper.suppliedNodes(
                node,
                nodesData,
                ConnectionType.POWER
            )
            const cooledNodes = NodeReferenceHelper.suppliedNodes(
                node,
                nodesData,
                ConnectionType.COOLING
            )

            powerIn = powerDemand
            heatOut = coolingLoad + powerDemand - powerLoad
            if (poweredNodes.length > 0) {
                powerOut = powerLoad || 0
            }
            if (cooledNodes.length > 0) {
                heatIn = coolingLoad || 0
            }
            if (node.type == NodeType.Item) {
                quantity = timeBasedData?.provisioning[nodeRef]?.count ?? 1
            }

            minimumProvisioningRequired =
                NodeTooltipHelper.getMinimumProvisioning(nodesData, node,
                    coolingLoad, powerLoad)
        }

        return {
            coolingIn:
                NumberFormat.withUnits(heatIn, NumberFormatMode.KWc) || "-",
            coolingOut:
                NumberFormat.withUnits(heatOut, NumberFormatMode.KWc) || "-",
            minimumProvisioningRequired,
            powerIn:
                NumberFormat.withUnits(powerIn, NumberFormatMode.KW) || "-",
            powerOut:
                NumberFormat.withUnits(powerOut, NumberFormatMode.KW) || "-",
            quantity: quickModeProps?.provisioning?.[nodeRef]?.count ?? quantity,

        }
    }
}