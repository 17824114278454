import { Models } from "@ekko/predict-client-api"

import { PerformanceType } from "../enums"
import * as Interface from "../interfaces"

export class AddManufacturerPerformanceHelper {
    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    static setPerformanceData(
        manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null,
        form: Interface.DeviceModelForm) {
        switch (form.performanceType) {
            case PerformanceType.UPS:
                return this.setUPS(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.PrimaryPump:
                return this.setPrimaryPump(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.CRAC:
                return this.setCRAC(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.ReferencePolynomial:
                return this.setReferencePolynomial(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.CoolingTower:
                return this.setCoolingTower(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.DryCooler:
                return this.setDryCooler(
                    manufacturerPerformanceData,
                    form
                )
            case PerformanceType.PumpCondensed:
                return this.setPumpCondensed(
                    manufacturerPerformanceData,
                    form
                )
            default: return null
        }
    }

    /**
     * @param formData
     */
    static ToPerformanceData(formData: Interface.DeviceModelForm) {
        switch (formData.performanceType) {
            case PerformanceType.ReferencePolynomial:
                return this.toReferencePolynomial((formData.performance as Interface.PolynomialPerformanceForm).polynomialValues)
            case PerformanceType.CRAC:
                return this.toCRAC(formData.performance as Interface.CracPerformanceForm)
            case PerformanceType.Cabling:
                return this.toCabling(formData.performance as Interface.CablingPerformanceForm)
            case PerformanceType.Chiller:
                return this.toChiller(formData.performance as Interface.ChillerPerformanceForm)
            case PerformanceType.CoolingTower:
                return this.toCoolingTower(formData.performance as Interface.CoolingTowerPerformanceForm)
            case PerformanceType.PDU:
                return this.toPDU(formData.performance as Interface.PduPerformanceForm)
            case PerformanceType.PrimaryPump:
                return this.toPrimaryPump(formData.performance as Interface.PrimaryPumpPerformanceForm)
            case PerformanceType.Switchgear:
                return this.toSwitchgear(formData.performance as Interface.SwitchgearPerformanceForm)
            case PerformanceType.Transformer:
                return this.toTransformer(formData.performance as Interface.ManufacturerDataTransformerForm)
            case PerformanceType.UPS:
                return this.toUPS(formData.performance as Interface.UpsPerformanceForm)
            case PerformanceType.PumpCondensed:
                return this.toPumpCondensed(formData.performance as Interface.PumpCondensedPerformanceForm)
            case PerformanceType.DryCooler:
                return this.toDryCooler(formData.performance as Interface.DryCoolerPerformanceForm)
            default:
                throw new Error(
                    `Cannot convert performance data of type ${formData.performanceType}`
                )
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toDryCooler(formDataPerformance: Interface.DryCoolerPerformanceForm) {
        return {
            designEnteringWater: Number(formDataPerformance.designEnteringWater),
            designLeavingWater: Number(formDataPerformance.designLeavingWater),
            designWaterFlow: Number(formDataPerformance.designWaterFlow),
            designEnteringAir: Number(formDataPerformance.designEnteringAir),
            designAirflow: Number(formDataPerformance.designAirflow),
            variableWaterFlowActive: formDataPerformance.variableWaterFlowActive,
            minimumWaterFlow: Number(formDataPerformance.minimumWaterFlow ?? 0) / 100,
            waterMixture: formDataPerformance.waterMixture,
            massFractionGlycol:
                Number(formDataPerformance.massFractionGlycol ?? 0) / 100,
            fanMotorPower: Number(formDataPerformance.fanMotorPower),
            fanControlMode: formDataPerformance.fanControlMode,
            minimumFanSpeed: Number(formDataPerformance.minimumFanSpeed ?? 0) / 100,
            fixedLosses: Number(formDataPerformance.fixedLosses ?? 0) / 100,
            numberOfFans: Number(formDataPerformance.numberOfFans),
            fanShutoffSpeed: Number(formDataPerformance.fanShutoffSpeed ?? 0) / 100,
            convectionFlowPerDegree:
                Number(formDataPerformance.convectionFlowPerDegree ?? 0) / 100,
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toPumpCondensed(formDataPerformance: Interface.PumpCondensedPerformanceForm) {
        return {
            motorPowerHP: Number(formDataPerformance.motorPowerHP),
            waterSupplyTemperature: Number(
                formDataPerformance.waterSupplyTemperature
            ),
            waterReturnTemperature: Number(
                formDataPerformance.waterReturnTemperature
            ),
            variableSpeedDrive: Boolean(formDataPerformance.variableSpeedDrive),
            offAtZeroLoad: Boolean(formDataPerformance.offAtZeroLoad),
            maxFractionGlycol: Number(formDataPerformance.maxFractionGlycol) / 100,
            maxMassFlowRateLS: Number(formDataPerformance.maxMassFlowRateLS),
            fixedLossFactor: Number(formDataPerformance.fixedLossFactor) / 100,
            proportionalLossFactor:
                Number(formDataPerformance.proportionalLossFactor) / 100,
            squareLawLossFactor:
                Number(formDataPerformance.squareLawLossFactor) / 100,
            cubeLawLossFactor: Number(formDataPerformance.cubeLawLossFactor) / 100,
            glycolType: formDataPerformance.glycolType,
        }
    }

    /**
     * @param performanceForm
     */
    private static toReferencePolynomial(polynomialValues: any) {
        return {
            polynomialValues: Object.values(polynomialValues).map(
                (pv: any) => ({ power: pv.power, coefficient: pv.coefficient })
            ),
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toTransformer(formDataPerformance: Interface.ManufacturerDataTransformerForm) {
        return {
            capacityKVA: Number(formDataPerformance.capacityKVA),
            powerFactor: Number(formDataPerformance.powerFactor),
            noLoadLosses: Number(formDataPerformance.noLoadLosses),
            loadLosses: Number(formDataPerformance.loadLosses),
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toUPS(formDataPerformance: Interface.UpsPerformanceForm) {
        return {
            capacityKVA: Number(formDataPerformance.capacityKVA),
            powerFactor: Number(formDataPerformance.powerFactor),
            fixedLosses: Number((formDataPerformance.fixedLosses as number) / 100),
            proportionalLosses: Number(
                (formDataPerformance.proportionalLosses as number) / 100
            ),
            squareLawLosses: Number(
                (formDataPerformance.squareLawLosses as number) / 100
            ),
            cubeLawLosses: Number(
                (formDataPerformance.cubeLawLosses as number) / 100
            ),
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toPrimaryPump(formDataPerformance: Interface.PrimaryPumpPerformanceForm) {
        return {
            fixedLosses: Number((formDataPerformance.fixedLosses as number) / 100),
            squareLaw: Number((formDataPerformance.squareLaw as number) / 100),
            motorHorsePower: Number(formDataPerformance.motorHorsePower),
            waterSupplyTemp: Number(formDataPerformance.waterSupplyTemp),
            waterReturnTemp: Number(formDataPerformance.waterReturnTemp),
            variableSpeedOrVFD: formDataPerformance.variableSpeedOrVFD,
            minFlowRate: Number((formDataPerformance.minFlowRate as number) / 100),
            staticHead: Number(formDataPerformance.staticHead),
            dynamicHead: Number(formDataPerformance.dynamicHead),
            glycolType: formDataPerformance.glycolType,
            massFractionGlycol: Number(
                (formDataPerformance.massFractionGlycol as number) / 100
            ),
            maxMassFlowRate: Number(formDataPerformance.maxMassFlowRate),
            vendorPumpCurve: formDataPerformance.vendorPumpCurve,
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toSwitchgear(formDataPerformance: Interface.SwitchgearPerformanceForm) {
        return {
            ratedCurrent: Number(formDataPerformance.ratedCurrent),
            voltage: Number(formDataPerformance.voltage),
            threePhase: formDataPerformance.threePhase,
            powerFactor: Number(formDataPerformance.powerFactor),
            noLoadLosses: Number(formDataPerformance.noLoadLosses),
            fullLoadLosses: Number(formDataPerformance.fullLoadLosses),
            fixedSpeedFans: Number(formDataPerformance.fixedSpeedFans),
            variableSpeedFans: Number(formDataPerformance.variableSpeedFans),
        }
    }

    /**
    * @param formDataPerformance
    */
    private static toCRAC(formDataPerformance: Interface.CracPerformanceForm) {
        return {
            ratedSensibleCapacity: Number(
                formDataPerformance.ratedSensibleCapacity
            ),
            nominalLatentCooling: Number(formDataPerformance.nominalLatentCooling), // latendCoolingLoad
            ratedAirReturnTemp: Number(formDataPerformance.ratedAirReturnTemp),
            ratedAirSupplyTemp: Number(formDataPerformance.ratedAirSupplyTemp),
            ratedTempCHWS: Number(formDataPerformance.ratedTempCHWS),
            varyInstallationCapacity:
                formDataPerformance.varyInstallationCapacity as boolean,
            installedAirReturnTemp: Number(
                formDataPerformance.installedAirReturnTemp
            ),
            installedTempCHWS: Number(formDataPerformance.installedTempCHWS),
            operatingFanSpeed: Number(
                (formDataPerformance.operatingFanSpeed as number) / 100
            ), // installed Air Flow Volume
            installedAirSupplyTemp: Number(
                formDataPerformance.installedAirSupplyTemp
            ),
            motorShaftPower: Number(formDataPerformance.motorShaftPower),
            numberOfMotors: Number(formDataPerformance.numberOfMotors),
            motorEfficiency: Number(
                (formDataPerformance.motorEfficiency as number) / 100
            ),
            variableSpeedDrive: formDataPerformance.variableSpeedDrive as boolean,
            fanType: formDataPerformance.fanType,
            fanControlMethod: formDataPerformance.fanControlMethod,
            maxFanSpeed: Number((formDataPerformance.maxFanSpeed as number) / 100),
            minFanSpeed: Number((formDataPerformance.minFanSpeed as number) / 100),
            bypassAirflow: Number(
                (formDataPerformance.bypassAirflow as number) / 100
            ),
        }
    }

    /**
     * @param performanceData
     */
    private static toCoolingTower(performanceData: Interface.CoolingTowerPerformanceForm) {
        return {
            designCapacity: Number(performanceData.designCapacity),
            supplyWaterTemperature: Number(performanceData.supplyWaterTemperature),
            returnWaterTemperature: Number(performanceData.returnWaterTemperature),
            designAirTemperatureWetBulb: Number(
                performanceData.designAirTemperatureWetBulb
            ),
            exitAirDepressionEfficiency:
                Number(performanceData.exitAirDepressionEfficiency ?? 0) / 100,
            convectionFlowPerDegree:
                Number(performanceData.convectionFlowPerDegree ?? 0) / 100,
            maxFanPower: Number(performanceData.maxFanPower),
            minApproachTemperature: Number(performanceData.minApproachTemperature),
            fullLoadApproachTemperature: Number(
                performanceData.fullLoadApproachTemperature
            ),
            fanControlMode: performanceData.fanControlMode,
            minimumFanSpeed: Number(performanceData.minimumFanSpeed ?? 0) / 100,
            fixedLosses: Number(performanceData.fixedLosses ?? 0) / 100,
            numberOfFans: Number(performanceData.numberOfFans),
            fanOffTemperature: Number(performanceData.fanOffTemperature),
            variableWaterFlowActive: performanceData.variableWaterFlowActive,
            minimumWaterFlow: Number(performanceData.minimumWaterFlow ?? 0) / 100,
        }
    }

    /**
     * @param performanceData
     */
    private static toChiller(performanceData: Interface.ChillerPerformanceForm) {
        return {
            nominalCapacity: Number(performanceData.nominalCapacity),
            copValues: performanceData.copValues,
            nominalCopValue: Number(performanceData.nominalCopValue),
            idleInputPowerAtZeroLoad: Number(
                performanceData.idleInputPowerAtZeroLoad
            ),
            nominalInputPower: Number(performanceData.nominalInputPower),
            condenserEnteringTemperature: Number(
                performanceData.condenserEnteringTemperature
            ),
            condenserApproachMinLoadTemperature: Number(
                performanceData.condenserApproachMinLoadTemperature
            ),
            condenserApproachFullLoadTemperature: Number(
                performanceData.condenserApproachFullLoadTemperature
            ),
            economiserActive: performanceData.economiserActive,
            economiserApproachMinLoadTemperature: Number(
                performanceData.economiserApproachMinLoadTemperature
            ),
            economiserApproachFullLoadTemperature: Number(
                performanceData.economiserApproachFullLoadTemperature
            ),
            economiserActiveTemperature: Number(
                performanceData.economiserActiveTemperature
            ),
            economiserTargetTemperature: Number(
                performanceData.economiserTargetTemperature
            ),
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toCabling(formDataPerformance: Interface.CablingPerformanceForm) {
        return {
            crossSection: Number(formDataPerformance.crossSection),
            awgGauge: Number(formDataPerformance.awgGauge),
            length: Number(formDataPerformance.length),
            threePhase: formDataPerformance.threePhase,
            powerFactor: Number(formDataPerformance.powerFactor),
            material: formDataPerformance.material,
            numberOfCables: Number(formDataPerformance.numberOfCables),
            cableGrouping: formDataPerformance.cableGrouping,
            temperature: Number(formDataPerformance.temperature),
            voltage: Number(formDataPerformance.voltage),
            currentPerCable: Number(formDataPerformance.currentPerCable),
            impedance: Number(formDataPerformance.impedance),
        }
    }

    /**
     * @param formDataPerformance
     */
    private static toPDU(formDataPerformance: Interface.PduPerformanceForm) {
        return {
            ratedCurrent: Number(formDataPerformance.ratedCurrent),
            voltage: Number(formDataPerformance.voltage),
            threePhase: formDataPerformance.threePhase,
            powerFactor: Number(formDataPerformance.powerFactor),
            noLoadLosses: Number(formDataPerformance.noLoadLosses),
            fullLoadLosses: Number(formDataPerformance.fullLoadLosses),
            fixedSpeedFans: Number(formDataPerformance.fixedSpeedFans),
            variableSpeedFans: Number(formDataPerformance.variableSpeedFans),
        }
    }

    /**
  * @param manufacturerPerformanceData
  * @param form
  */
    private static setReferencePolynomial(
        manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null,
        form: Interface.DeviceModelForm
    ) {
        form.performance = manufacturerPerformanceData?.getContent()?.polynomialValues.reduce(
            (previous: any, current) => {
                switch (current.power) {
                    case 0:
                        previous.polynomialValues.constant = {
                            power: current.power,
                            coefficient: current.coefficient,
                        }
                        return previous
                    case 1:
                        previous.polynomialValues.linear = {
                            power: current.power,
                            coefficient: current.coefficient,
                        }
                        return previous
                    case 2:
                        previous.polynomialValues.squared = {
                            power: current.power,
                            coefficient: current.coefficient,
                        }
                        return previous
                    case 3:
                        previous.polynomialValues.cubic = {
                            power: current.power,
                            coefficient: current.coefficient,
                        }
                        return previous
                }
            },
            { polynomialValues: {} }
        ) as Interface.PolynomialPerformanceForm
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setUPS(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content = manufacturerPerformanceData?.getContent() as Interface.UpsPerformanceForm
        form.performance = {
            capacityKVA: Number(content.capacityKVA),
            powerFactor: Number(content.powerFactor),
            fixedLosses: Number((content.fixedLosses as number) * 100),
            proportionalLosses: Number((content.proportionalLosses as number) * 100),
            squareLawLosses: Number((content.squareLawLosses as number) * 100),
            cubeLawLosses: Number((content.cubeLawLosses as number) * 100),
        }
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setPrimaryPump(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content =
            manufacturerPerformanceData?.getContent() as Interface.PrimaryPumpPerformanceForm

        form.performance = {
            fixedLosses: Number((content.fixedLosses as number) * 100),
            squareLaw: Number((content.squareLaw as number) * 100),
            motorHorsePower: Number(content.motorHorsePower),
            waterSupplyTemp: Number(content.waterSupplyTemp),
            waterReturnTemp: Number(content.waterReturnTemp),
            variableSpeedOrVFD: content.variableSpeedOrVFD,
            minFlowRate: Number((content.minFlowRate as number) * 100),
            staticHead: Number(content.staticHead),
            dynamicHead: Number(content.dynamicHead),
            glycolType: content.glycolType,
            massFractionGlycol: Number((content.massFractionGlycol as number) * 100),
            maxMassFlowRate: Number(content.maxMassFlowRate),
            vendorPumpCurve: content.vendorPumpCurve,
        }
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setCRAC(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content = manufacturerPerformanceData?.getContent() as Interface.CracPerformanceForm
        form.performance = {
            ratedSensibleCapacity: Number(content.ratedSensibleCapacity),
            nominalLatentCooling: Number(content.nominalLatentCooling), // latendCoolingLoad
            ratedAirReturnTemp: Number(content.ratedAirReturnTemp),
            ratedAirSupplyTemp: Number(content.ratedAirSupplyTemp),
            ratedTempCHWS: Number(content.ratedTempCHWS),
            varyInstallationCapacity: content.varyInstallationCapacity as boolean,
            installedAirReturnTemp: Number(content.installedAirReturnTemp),
            installedTempCHWS: Number(content.installedTempCHWS),
            operatingFanSpeed: Number((content.operatingFanSpeed as number) * 100), // installed Air Flow Volume
            installedAirSupplyTemp: Number(content.installedAirSupplyTemp),
            motorShaftPower: Number(content.motorShaftPower),
            numberOfMotors: Number(content.numberOfMotors),
            motorEfficiency: Number((content.motorEfficiency as number) * 100),
            variableSpeedDrive: content.variableSpeedDrive as boolean,
            fanType: content.fanType,
            fanControlMethod: content.fanControlMethod,
            maxFanSpeed: Number((content.maxFanSpeed as number) * 100),
            minFanSpeed: Number((content.minFanSpeed as number) * 100),
            bypassAirflow: Number((content.bypassAirflow as number) * 100),
        }
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setDryCooler(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content = manufacturerPerformanceData?.getContent() as Interface.DryCoolerPerformanceForm
        form.performance = {
            designEnteringWater: Number(content.designEnteringWater),
            designLeavingWater: Number(content.designLeavingWater),
            designWaterFlow: Number(content.designWaterFlow),
            designEnteringAir: Number(content.designEnteringAir),
            designAirflow: Number(content.designAirflow),
            variableWaterFlowActive: content.variableWaterFlowActive,
            minimumWaterFlow: Number((content.minimumWaterFlow as number) * 100),
            waterMixture: content.waterMixture,
            massFractionGlycol: Number((content.massFractionGlycol as number) * 100),
            fanMotorPower: Number(content.fanMotorPower),
            fanControlMode: content.fanControlMode,
            minimumFanSpeed: Number((content.minimumFanSpeed as number) * 100),
            fixedLosses: Number((content.fixedLosses as number) * 100),
            numberOfFans: Number(content.numberOfFans),
            fanShutoffSpeed: Number((content.fanShutoffSpeed as number) * 100),
            convectionFlowPerDegree: Number((content.convectionFlowPerDegree as number) * 100),
        } as Interface.DryCoolerPerformanceForm
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setPumpCondensed(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content = manufacturerPerformanceData?.getContent() as Interface.PumpCondensedPerformanceForm
        form.performance = {
            motorPowerHP: Number(content.motorPowerHP),
            waterSupplyTemperature: Number(content.waterSupplyTemperature),
            waterReturnTemperature: Number(content.waterReturnTemperature),
            variableSpeedDrive: Boolean(content.variableSpeedDrive),
            offAtZeroLoad: Boolean(content.offAtZeroLoad),
            maxFractionGlycol: Number((content.maxFractionGlycol as number) * 100),
            maxMassFlowRateLS: Number(content.maxMassFlowRateLS),
            fixedLossFactor: Number((content.fixedLossFactor as number) * 100),
            proportionalLossFactor: Number((content.proportionalLossFactor as number) * 100),
            squareLawLossFactor: Number((content.squareLawLossFactor as number) * 100),
            cubeLawLossFactor: Number((content.cubeLawLossFactor as number) * 100),
            glycolType: content.glycolType,
        } as Interface.PumpCondensedPerformanceForm
        return form
    }

    /**
     * @param manufacturerPerformanceData
     * @param form
     */
    private static setCoolingTower(manufacturerPerformanceData: Models.ManufacturerPerformanceDataModel | null, form: Interface.DeviceModelForm) {
        const content = manufacturerPerformanceData?.getContent() as Interface.CoolingTowerPerformanceForm
        form.performance = {
            designCapacity: content.designCapacity,
            supplyWaterTemperature: content.supplyWaterTemperature,
            returnWaterTemperature: content.returnWaterTemperature,
            designAirTemperatureWetBulb: content.designAirTemperatureWetBulb,
            exitAirDepressionEfficiency: Number((content.exitAirDepressionEfficiency as number) * 100),
            convectionFlowPerDegree: Number((content.convectionFlowPerDegree as number) * 100),
            maxFanPower: content.maxFanPower,
            minApproachTemperature: content.minApproachTemperature,
            fullLoadApproachTemperature: content.fullLoadApproachTemperature,
            fanControlMode: content.fanControlMode,
            minimumFanSpeed: Number((content.minimumFanSpeed as number) * 100),
            fixedLosses: Number((content.fixedLosses as number) * 100),
            numberOfFans: content.numberOfFans,
            fanOffTemperature: content.fanOffTemperature,
            variableWaterFlowActive: content.variableWaterFlowActive,
            minimumWaterFlow: Number((content.minimumWaterFlow as number) * 100),
        } as Interface.CoolingTowerPerformanceForm
        return form
    }
}