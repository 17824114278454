import { Granularity } from "@/Simulation"
import { AggregatorResponse } from "@/Simulation/interfaces/AggregatorResponse"
import { DynamicCostData } from "@/Simulation/interfaces/DynamicCostData"
import { UnbatchEvents } from "../interfaces"
import { BatchResults } from "./BatchResults"
import { UnbatchEventRecursive } from "./UnbatchEventRecursive"

/**
 *
 */
export class SimulationBatchResults extends BatchResults<AggregatorResponse> {
    /**
     *
     * @param items
     * @param getResults
     * @param filterDate
     * @param granularity
     * @param unbatchCallbacks
     * @returns
     */
    static async iterate<I>(items: I[],
        getResults: (item: I, unbatchCallbacks?: UnbatchEvents) => Promise<AggregatorResponse> | AggregatorResponse,
        filterDate: (point: Date, item: I) => boolean, granularity: Granularity,
        unbatchCallbacks: UnbatchEvents | null = null
    ) {
        unbatchCallbacks?.start?.(items.length)
        const batchResults = new SimulationBatchResults(granularity)
        for(const [i, item] of Object.entries(items)) {
            const itemResult = await getResults(item,
                unbatchCallbacks ? new UnbatchEventRecursive(unbatchCallbacks, +i) : undefined)
            const itemResultFiltered: AggregatorResponse = Object.fromEntries(
                Object.entries(itemResult).filter(([point]) => filterDate(new Date(point), item))
            )
            batchResults.add(itemResultFiltered)
        }
        unbatchCallbacks?.progress?.(items.length)
        return batchResults.finalise()
    }

    /**
     *
     */
    private importCount = 0

    /**
     *
     */
    private resultCounts: Record<string, number> = {}

    /**
     *
     */
    private storedResults: AggregatorResponse = {}

    /**
     *
     * @param results
     * @param batchCount
     * @returns
     */
    private finaliseDynamicCostData(results: DynamicCostData, batchCount: number) {
        let allocation: {[a: string]: {[b: string]: number}} | null = null
        if(results.allocation) {
            allocation = {}
            for(const [supplierReference, demanderAllocation] of Object.entries(results.allocation)) {
                allocation[supplierReference] = {}
                for(const [demanderReference, allocationIn] of Object.entries(demanderAllocation)) {
                    allocation[supplierReference][demanderReference] = allocationIn / batchCount
                }
            }
        }
        return {...results, allocation}
    }

    /**
     *
     * @param results
     * @param newResults
     * @returns
     */
    private mergeDynamicCostData(results: DynamicCostData, newResults: DynamicCostData) {
        if(newResults.allocation) {
            const outAllocation = results.allocation
            if(outAllocation) {
                for(const [supplierReference, demanderAllocation] of Object.entries(newResults.allocation)) {
                    const outDemanderAllocation = outAllocation[supplierReference]
                    if(outDemanderAllocation) {
                        for(const [demanderReference, allocation] of Object.entries(demanderAllocation)) {
                            outDemanderAllocation[demanderReference] =
                                (outDemanderAllocation[demanderReference] ?? 0) + allocation
                        }
                    } else {
                        outAllocation[supplierReference] = demanderAllocation
                    }
                }
            } else {
                results.allocation = newResults.allocation
            }
        }
        for(const [nodeReference, cost] of Object.entries(newResults.costs)) {
            results.costs[nodeReference] = (results.costs[nodeReference] ?? 0) + cost
        }
    }

    /**
     *
     */
    get count() {
        return this.importCount
    }

    /**
     *
     * @param resultsIn
     */
    add(resultsIn: AggregatorResponse) {
        const results: AggregatorResponse = JSON.parse(JSON.stringify(resultsIn))
        for(const [period, result] of Object.entries(results)) {
            const granularPeriod = this.granularPoint(period)
            const storedResult = this.storedResults[granularPeriod]
            if(!storedResult) {
                this.resultCounts[granularPeriod] = 1
                this.storedResults[granularPeriod] = result
            } else {
                this.resultCounts[granularPeriod]++
                this.mergeDynamicCostData(storedResult.fixed, result.fixed)
                this.mergeDynamicCostData(storedResult.variable, result.variable)
            }
        }
        this.importCount++
    }

    /**
     *
     * @returns
     */
    finalise() {
        const out: AggregatorResponse = {}
        for(const [period, result] of Object.entries(this.storedResults)) {
            out[period] = {
                fixed: this.finaliseDynamicCostData(result.fixed, this.resultCounts[period]),
                variable: this.finaliseDynamicCostData(result.variable, this.resultCounts[period]),
            }
        }
        return out
    }
}