import { NumberFormatMode } from "./NumberFormatMode"
import * as Formatters from "./Formatters"
import { Formatter } from "./Formatter"

/**
 *
 */
export class NumberFormat {
    /**
     *
     */
    private static readonly unitFormattersByMode: Partial<Record<NumberFormatMode, Formatter>> = {
        [NumberFormatMode.KW]: new Formatters.Kilowatts,
        [NumberFormatMode.KWh]: new Formatters.KilowattHours,
        [NumberFormatMode.KWc]: new Formatters.KilowattsCooling,
        [NumberFormatMode.Percent]: new Formatters.Percent,
        [NumberFormatMode.PercentPremultiplied]:
            new Formatters.PercentPremultiplied,
    }

    /**
     * @param value
     * @param format
     * @param options
     */
    static formatValue(value: number, format: NumberFormatMode,
        options?: { units?: string, digits?: number }
    ): string {
        // These are formatters which have a natural sense of units (not from
        // data)
        const unitFormatter = this.unitFormattersByMode[format]
        if(unitFormatter) {
            if (typeof options?.units == "string") {
                return Formatter.appendUnits(unitFormatter.format(
                    +value, {...options, withoutUnits: true}), options.units,
                    false)
            } else {
                return unitFormatter.format(value, options)
            }
        }

        // These are formatters which need the units to be supplied
        switch (format) {
            case NumberFormatMode.Currency:
                if (options?.units === "") {
                    const formatter = new Intl.NumberFormat(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })
                    return formatter.format(+value)
                } else {
                    const formatter = new Intl.NumberFormat(undefined, {
                        currency: options?.units ?? "GBP",
                        style: "currency"
                    })
                    return formatter.format(+value)
                }
            case NumberFormatMode.GenericUnit:
                {
                    const formatter = new Intl.NumberFormat(undefined,
                        options?.digits !== undefined ? {maximumFractionDigits: options.digits} : {}
                    )
                    if(options?.units) {
                        return Formatter.appendUnits(formatter.format(+value), options.units)
                    } else {
                        return formatter.format(+value)
                    }
                }
            case NumberFormatMode.MonthTimestamp:
                return new Date(0, value - 1).toLocaleString(undefined, { month: "long" })
            case NumberFormatMode.Temperature:
                {
                    const formatter = new Intl.NumberFormat(undefined, {
                        maximumFractionDigits: options?.digits ?? 1,
                        minimumFractionDigits: options?.digits ?? 1,
                    })
                    return Formatter.appendUnits(formatter.format(+value), options?.units ?? '\u00b0C')
                }
            default:
                {
                    const formatter = new Intl.NumberFormat()
                    return formatter.format(+value)
                }
        }
    }
    /**
     * Round value to n decimal places
     *
     * @param value
     * @param places
     * @returns
     */
    static toDecimalPlaces(value: number, places: number) {
        const divisor = Math.pow(10, places)
        return Math.round(value * divisor) / divisor
    }

    /**
     * Round value to 4 decimal point
     * @param value
     */
    static to4dp(value: number) {
        return this.toDecimalPlaces(value, 4)
    }

    /**
     * Round value to 3 decimal point
     * @param value
     */
    static to3dp(value: number) {
        return this.toDecimalPlaces(value, 3)
    }

    /**
     * Round value to 2 decimal point
     * @param value
     * @returns
     */
    static to2dp(value: number) {
        return this.toDecimalPlaces(value, 2)
    }

    /**
     * Round value to 1 decimal point
     * @param value
     * @returns
     */
    static to1dp(value: number) {
        return this.toDecimalPlaces(value, 1)
    }

    /**
     *
     * @param v
     * @param format
     * @returns
     */
    static withUnits(v: number | null, format: NumberFormatMode) {
        if (v === null) {
            return v
        } else {
            switch (format) {
                case NumberFormatMode.KW:
                case NumberFormatMode.KWc:
                    if(v >= 1000) {
                        v /= 1000
                        const largeFormat = format.replace(/kW/, "MW")
                        return `${v.toFixed(4)} ${largeFormat}`
                    } else {
                        return `${v.toFixed(2)} ${format}`
                    }
                default:
                    return `${v} ${format}`
            }
        }
    }
}