import { EnvCondition, MeteredData, Reading, Temperature } from "@/core/interfaces"
import { DateHelper } from "@/core/helpers"
import { httpService } from "@/core/services"
import ApiService from "@/core/services/api.service"
import { jsonRpcService } from "@/core/services/json-rpc.service"
import { MonthHour } from "@/History"
import { DateHour, Period, SiteInfo } from "@/Simulation/interfaces"
import { AnyNode } from "@/store/SiteViewState"
import { Models, Services } from "@ekko/predict-client-api"
import { TimeBasedParameter } from "../timeBased"
import { SiteNodes } from "./SiteNodes"
import { CustomPeriod } from "@/store/simulation-view/types"

/**
 *
 */
let warnedDefaultWeather = false

/**
 *
 */
const DefaultWeather: DateHour<Temperature>[] = [...Array(24)].map(
    (_, i) => ({date: new Date("2001-01-01"), hour: 0, value: {dryBulb: 20, wetBulb: 23}})
)

/**
 *
 */
export class SiteParameter {
    /**
     *
     */
    private allItems: AnyNode[] = []

    /**
     *
     */
    private get period(): Period {
        const startPoint = this.customPeriod.startDate
        const endPoint = this.customPeriod.endDate
        const diffM = endPoint.getFullYear() * 12 + endPoint.getMonth() -
            startPoint.getFullYear() * 12 - startPoint.getMonth()
        const period: Period = {
            start: DateHelper.fullYearMonthOnly(startPoint),
            months: diffM + 1
        }

        return period
    }

    /**
     * @param siteModel
     * @param siteConfigurationModel
     * @param customPeriod
     */
    constructor(
        private siteModel: Models.SiteModel,
        private siteConfigurationModel: Models.SiteConfigurationModel | null,
        private customPeriod: CustomPeriod
    ) {
    }

    /**
     * Pre-fetchs some entities data which cause incomplete payload when those are present in the client indexed db
     */
    static async preFetchData() {

        // Pre-fetching all device mode for NodeSimulationData to work
        await ApiService.getRecords<Services.DeviceModeService, Models.DeviceModeModel>(
            "DeviceModeService",
            "getAllDeviceModes",
        )

        // Pre-fetching all custom device mode for NodeSimulationData to work
        await ApiService.getRecords<Services.CustomDeviceModeService, Models.CustomDeviceModeModel>(
            "CustomDeviceModeService",
            "getAllCustomDeviceModes",
        )

        // Pre-fetching all IT Provisioning Events for NodeSimulationData to work
        await ApiService.getRecords<Services.ITProvisioningEventService, Models.ITProvisioningEventModel>(
            "ITProvisioningEventService",
            "getAllITProvisioningEvents",
        )

        // Pre-fetching all IT Load Profiles for NodeSimulationData to work
        await ApiService.getRecords<Services.ITLoadProfileService, Models.ITLoadProfileModel>(
            "ITLoadProfileService",
            "getAllITLoadProfiles",
        )

        // Pre-fetching all IT Load Profiles for NodeSimulationData to work
        await ApiService.getRecords<Services.ClimateProfileReferenceService, Models.ClimateProfileReferenceModel>(
            "ClimateProfileReferenceService",
            "getAllClimateProfileReferences",
        )


    }

    /**
     * Retieves the site information
     */
    async siteInfoBuilder(): Promise<SiteInfo> {
        let climate: SiteInfo["climate"] | null = null

        const climateProfile = await this.siteModel.getClimateProfileReference()

        if (climateProfile) {
            climate = climateProfile.id
        }

        const currency = this.siteModel.getCurrency()
        if(!currency) {
            throw new Error("No currency supplied")
        }
        if(!climate) {
            if(!warnedDefaultWeather) {
                warnedDefaultWeather = true
                console.warn("Using default weather profile")
            }
            climate = DefaultWeather as SiteInfo["climate"]
        }
        return {
            currency,
            powerCost: await this.getPowerCost(),
            // TODO To be implemented once timezone available
            timezone: 'Europe/London',
            climate,
            co2PerKWh: this.siteModel.getCo2PerKWh()
        }
    }

    /**
     * Gets metered data for simulateHistorical() on the aggregator
     *
     * @returns
     */
    async getMeteredData(): Promise<MeteredData | null> {
        const siteId = this.siteModel.id
        const period = this.period
        const refinementsIn = await jsonRpcService.getSimulationRefinement(siteId, period)

        const refinements: Map<string, Reading<Date>[]> = new Map(
            [...refinementsIn.entries()].map(([nodeReference, readings]) => [nodeReference, readings.map(reading => ({
                ...reading,
                timestamp: new Date(reading.timestamp)
            }))])
        )

        if (!refinements || refinements.size == 0) {
            return null
        }

        const startDate = new Date(period.start)
        startDate.setMonth(startDate.getMonth())
        const endDate = new Date(startDate.toString())
        endDate.setMonth(endDate.getMonth() + period.months)
        const weatherReadings = await httpService.getWeatherMeteredReadings(siteId, startDate.toISOString(), endDate.toISOString())
        let meteredTemperature: Map<string, Partial<EnvCondition>> | null = null

        if (weatherReadings) {
            meteredTemperature = new Map<string, Partial<EnvCondition>>()
            for (const weatherReading of weatherReadings) {
                const envCondition: EnvCondition = {
                    wetBulb: weatherReading.wetBulb,
                    dryBulb: weatherReading.dryBulb,
                    RH: weatherReading.relativeHumidity
                }
                meteredTemperature.set(new Date(weatherReading.timestamp).toISOString(), envCondition)
            }
        }

        const out = new Map<string, Map<string, Reading<Date>[]>>()

        for (const [nodeReference, readings] of refinements) {
            for (const reading of readings) {
                const readingKey = reading.timestamp.toISOString()
                let outTimestamp = out.get(readingKey)
                if (!outTimestamp) {
                    outTimestamp = new Map<string, Reading<Date>[]>()
                    out.set(readingKey, outTimestamp)
                }

                let nodeReadings = outTimestamp.get(nodeReference)
                if (!nodeReadings) {
                    nodeReadings = []
                    outTimestamp.set(nodeReference, nodeReadings)
                }
                nodeReadings.push(reading)
            }
        }

        return { meteredTemperature, readings: out }
    }

    /**
     * Retrieves all power cost related to the site
     */
    async getPowerCost() {
        const monthHours: MonthHour<number>[] = []

        const powerCostProfile = await this.siteModel.getPowerCostProfile()

        if(!powerCostProfile) {
            throw new Error("No power cost profile for site")
        }

        for await (const costMonth of powerCostProfile.getPowerCostMonths()) {
            for await (const powerCost of costMonth.getPowerCosts()) {
                monthHours.push({
                    month: new Date(costMonth.getMonth()!),
                    hour: powerCost.getHour()!,
                    value: powerCost.getCost()!
                })
            }

        }

        monthHours.sort((a, b) => {
            if(a.month > b.month) {
                return 1
            } else if(a.month < b.month) {
                return -1
            } else {
                return a.hour - b.hour
            }
        })
        return monthHours
    }

    /**
     * Returns simulation payload which is node list with their respective supply dependency, resilience and performanceData
     */
    async fetch() {

        this.allItems = await SiteNodes.forSiteConfig(
            this.siteConfigurationModel)

        SiteParameter.preFetchData()

        return TimeBasedParameter.buildNodesData(this.allItems)
    }
}