import ApiService from "@/core/services/api.service"
import { FilterType } from "@ekko/client-api-core"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import {
  actions as tableActions,
  getters as tableGetters,
  mutations as tableMutations,
} from "../climate-profiles/table"
import { ClimateProfile } from "../climate-profiles/types"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import { ClimateProfileArchiveState } from "./types"

class State implements Partial<ClimateProfileArchiveState> {
  climateProfiles: ClimateProfile[] = []
  loaded = false
}

const getters = <GetterTree<ClimateProfileArchiveState, any>>{

}

const mutations = <MutationTree<State>>{
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  unarchiveClimateProfile(state, climateProfile: ClimateProfile): void {
    state.climateProfiles = state.climateProfiles.filter(
      d => (d.profile as any).id !== (climateProfile.profile as any).id
    )
  },
  archiveClimateProfile(state, climateProfile: ClimateProfile): void {
    state.climateProfiles.push(climateProfile)
  },
}

const actions = <ActionTree<State, any>>{
  async fetchClimateProfiles({ commit }) {
    const profileService = await ApiService.getClimateProfileReferenceService()
    const filter: FilterType = { attribute: "defunct", op: "equal", value: true }
    const profilesGenerator = profileService.getAllClimateProfileReferences(filter)
    const climateProfiles: Models.ClimateProfileReferenceModel[] = []
    for await (const profile of profilesGenerator) {
      climateProfiles.push(profile)
    }

    const locationService = await ApiService.getClimateLocationService()
    const locationsGenerator = locationService.getAllClimateLocations()
    const climateLocations: Models.ClimateLocationModel[] = []
    for await (const location of locationsGenerator) {
      climateLocations.push(location)
    }

    const geoLocationService = await ApiService.getClimateGeoLocationService()
    const geoLocationsGenerator = geoLocationService.getAllClimateGeoLocations()
    const climateGeoLocations: Models.ClimateGeoLocationModel[] = []
    for await (const geoLocation of geoLocationsGenerator) {
      climateGeoLocations.push(geoLocation)
    }

    commit("setLoaded", true)
    commit("setClimateProfiles", {climateProfiles, climateLocations, climateGeoLocations})
  },
  unarchiveClimateProfile({ commit }, climateProfile: ClimateProfile) {
    commit("unarchiveClimateProfile", climateProfile)
    commit('climateProfile/createClimateProfile', climateProfile, { root: true })
  },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const ClimateProfileArchiveModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...tableGetters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...tableMutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...tableActions,
    ...actions
  },
}

export default ClimateProfileArchiveModule
