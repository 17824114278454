import { Models, Services } from "@ekko/predict-client-api"
import { AccessControlType, AccessLevel } from "../enums"
import ApiService from "./api.service"

/**
 *
 */
const RestrictedRole = "restricted"

/**
 *
 */
class AccessControlService {
    /**
     * This modifies the access level of the named object to the level supplied
     * (if controlled by the user).
     *
     * @param obj The persisted object
     * @param type
     * @param accessLevel
     */
    async updateAccessLevel(
        obj: { id: string },
        type: string,
        accessLevel: AccessLevel
    ) {
        // Update access control
        const accessControlService =
            await ApiService.getNamedService<Services.AccessControlService>(
                "AccessControlService")
        const accessControls: Models.AccessControlModel[] = []

        const filters = [
            { attribute: "objectId", op: "equal" as const, value: obj.id },
            { attribute: "objectType", op: "equal" as const, value: type },
        ]
        const CLIENT_API_SUPPORTS_MULTIPLE_FILTERS = false
        if (CLIENT_API_SUPPORTS_MULTIPLE_FILTERS) {
            for await (const accessControl of accessControlService.getAllAccessControls(
                ...filters
            )) {
                accessControls.push(accessControl)
            }
        } else {
            // Workaround: filter by the most restrictive (id) then re-filter
            // in client
            for await (const accessControl of accessControlService.getAllAccessControls(
                filters[0]
            )) {
                if (accessControl.getObjectType() == type) {
                    accessControls.push(accessControl)
                } else {
                    console.warn(`Unexpected type ${accessControl.getObjectType()} found, expecting ${type}`)
                }
            }
        }

        let currentAccessLevel: AccessLevel
        if (accessControls.some((ac) =>
            ac.getAccessControlType() == AccessControlType.other &&
            ac.getRead()
        )) {
            currentAccessLevel = AccessLevel.PUBLIC
        } else if (
            accessControls.some(
                ac =>
                    ac.getAccessControlType() == AccessControlType.role &&
                    ac.getReference() == RestrictedRole && ac.getRead()
            )
        ) {
            currentAccessLevel = AccessLevel.RESTRICTED
        } else {
            currentAccessLevel = AccessLevel.PRIVATE
        }

        if (currentAccessLevel != accessLevel) {
            const newAccessControl = new Models.AccessControlModel()
            newAccessControl.setObjectType(type)
            newAccessControl.setObjectId(obj.id)
            newAccessControl.setRead(true)
            switch (accessLevel) {
                case AccessLevel.PRIVATE:
                    // No action - there should be no objects
                    break
                case AccessLevel.PUBLIC:
                    newAccessControl.setAccessControlType(
                        AccessControlType.other)
                    await accessControlService.addAccessControl(
                        newAccessControl)
                    break
                case AccessLevel.RESTRICTED:
                    newAccessControl.setAccessControlType(
                        AccessControlType.role)
                    newAccessControl.setReference(RestrictedRole)
                    await accessControlService.addAccessControl(
                        newAccessControl)
                    break
                default:
                    console.error(
                        `Unknown access level ${accessLevel}, using private`)
            }
            if (accessControls.length) {
                for (const accessControl of accessControls) {
                    await accessControlService.removeAccessControl(
                        accessControl)
                }
            }
        }
    }
}

export const accessControlService = new AccessControlService()