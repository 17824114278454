export type Endpoint = "api" | "aggregator" | "power-simulator" | "sensor-config"

/**
 *
 */
type ConfigContent = {
    AGGREGATOR_URL: string,
    API_URL: string,
    SENSOR_CONFIG_URL: string,
    SIMULATOR_URL: string,
    UNBATCH_FOR_AWS: string,
}
/**
 * Url Configuration helper class
 */
export class UrlConfig {
    /**
     *
     */
    private static cachedConfig?: ConfigContent | null

    /**
     *
     */
    private static cacheLoad?: Promise<ConfigContent | null>

    /**
     *
     */
    private static async getConfigUncached(): Promise<ConfigContent | null> {
        let newConfig: ConfigContent | null = null
        try {
            if (process.env.NODE_ENV != 'production') {
                const urlConfig = await fetch('/url-config.json')
                newConfig = await urlConfig.json()
            }
        } catch (error) {
            newConfig = null
        }
        this.cachedConfig = newConfig
        return newConfig
    }

    /**
     * Gets configuration urls
     */
    static async getConfig(): Promise<ConfigContent | null> {
        if (this.cacheLoad) {
            return this.cacheLoad
        } else if (this.cachedConfig === undefined) {
            this.cacheLoad = this.getConfigUncached()
            return this.cacheLoad
        } else {
            return this.cachedConfig
        }
    }

    /**
     * Gets base url
     * @param endpoint
     */
    static async getBaseUrl(endpoint: Endpoint) {
        const config = await UrlConfig.getConfig()
        let url = ''

        switch (endpoint) {
            case 'api':
                url = config ? config.API_URL : process.env.VUE_APP_DEVICE_HANDLER_API as string
                break
            case 'aggregator':
                url = config ? config.AGGREGATOR_URL : process.env.VUE_APP_AGGREGATOR_SERVICE as string
                break
            case 'power-simulator':
                url = config ? config.SIMULATOR_URL : process.env.VUE_APP_POWER_SIMULATOR_SERVICE as string
                break
            case 'sensor-config':
                url = config ? config.SENSOR_CONFIG_URL : process.env.VUE_APP_SENSOR_STORAGE_SERVICE as string
                break
        }

        return url

    }

    /**
     *
     */
    static async shouldUnbatchForAWS() {
        const config = await UrlConfig.getConfig()
        return config ? !!config.UNBATCH_FOR_AWS : !!process.env.VUE_APP_UNBATCH_FOR_AWS
    }
}
