/**
 *
 */
export class LazyAsync<T> {
    /**
     *
     */
    private cache?: T

    /**
     *
     */
    private promise?: Promise<T>

    /**
     *
     * @returns
     */
    private buildPromise(): Promise<T> {
        return this.lambda().then(v => this.cache = v).finally(
            () => this.promise = undefined)
    }

    /**
     *
     * @param lambda
     */
    constructor(private lambda: () => Promise<T>) {
    }

    /**
     *
     * @returns
     */
    get(): Promise<T> | T {
        if (this.cache !== undefined) {
            return this.cache
        } else if (this.promise) {
            return this.promise
        } else {
            this.promise = this.buildPromise()
            return this.promise
        }
    }

    /**
     *
     * @returns
     */
    getImmediate(): T | undefined {
        if (this.cache !== undefined) {
            return this.cache
        } else if (!this.promise) {
            this.promise = this.buildPromise()
        }
        return undefined
    }
}
