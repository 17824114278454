import { SimulationOverTime } from "./types"

export class PowerDemandHelper {
    /**
     *
     * @param results
     * @returns
     */
     static extractPowerDemandData(results: SimulationOverTime) {
        const powerDemandData: Record<string, number[]> = {}

        for (const { simulationData } of Object.values(results)) {
            if (simulationData) {
                for (const [nodeReference, values] of Object.entries(simulationData)) {
                    const [powerDemand] = values

                    if (!powerDemandData[nodeReference]) {
                        powerDemandData[nodeReference] = []
                    }

                    powerDemandData[nodeReference].push(powerDemand)
                }
            }
        }

        return powerDemandData
    }

    /**
     *
     * @param powerDemandData
     * @returns
     */
    static calculatePowerDemandAverages(powerDemandData: Record<string, number[]>) {
        const powerDemandAverages: Record<string, number> = {}

        for (const [nodeReference, values] of Object.entries(powerDemandData)) {
            powerDemandAverages[nodeReference] = values.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            ) / values.length
        }

        return powerDemandAverages
    }
}

