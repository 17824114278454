const ISOCountries = {
  AF: { name: 'Afghanistan' },
  AFG: { name: 'Afghanistan' },
  AX: { name: 'Åland Islands' },
  ALA: { name: 'Åland Islands' },
  AL: { name: 'Albania' },
  ALB: { name: 'Albania' },
  DZ: { name: 'Algeria' },
  DZA: { name: 'Algeria' },
  AS: { name: 'American Samoa' },
  ASM: { name: 'American Samoa' },
  AD: { name: 'Andorra' },
  AND: { name: 'Andorra' },
  AO: { name: 'Angola' },
  AGO: { name: 'Angola' },
  AI: { name: 'Anguilla' },
  AIA: { name: 'Anguilla' },
  AQ: { name: 'Antarctica' },
  ATA: { name: 'Antarctica' },
  AG: { name: 'Antigua and Barbuda' },
  ATG: { name: 'Antigua and Barbuda' },
  AR: { name: 'Argentina' },
  ARG: { name: 'Argentina' },
  AM: { name: 'Armenia' },
  ARM: { name: 'Armenia' },
  AW: { name: 'Aruba' },
  ABW: { name: 'Aruba' },
  AU: { name: 'Australia' },
  AUS: { name: 'Australia' },
  AT: { name: 'Austria' },
  AUT: { name: 'Austria' },
  AZ: { name: 'Azerbaijan' },
  AZE: { name: 'Azerbaijan' },
  BS: { name: 'Bahamas' },
  BHS: { name: 'Bahamas' },
  BH: { name: 'Bahrain' },
  BHR: { name: 'Bahrain' },
  BD: { name: 'Bangladesh' },
  BGD: { name: 'Bangladesh' },
  BB: { name: 'Barbados' },
  BRB: { name: 'Barbados' },
  BY: { name: 'Belarus' },
  BLR: { name: 'Belarus' },
  BE: { name: 'Belgium' },
  BEL: { name: 'Belgium' },
  BZ: { name: 'Belize' },
  BLZ: { name: 'Belize' },
  BJ: { name: 'Benin' },
  BEN: { name: 'Benin' },
  BM: { name: 'Bermuda' },
  BMU: { name: 'Bermuda' },
  BT: { name: 'Bhutan' },
  BTN: { name: 'Bhutan' },
  BO: { name: 'Bolivia (Plurinational State of)' },
  BOL: { name: 'Bolivia (Plurinational State of)' },
  BQ: { name: 'Bonaire, Sint Eustatius and Saba' },
  BES: { name: 'Bonaire, Sint Eustatius and Saba' },
  BA: { name: 'Bosnia and Herzegovina' },
  BIH: { name: 'Bosnia and Herzegovina' },
  BW: { name: 'Botswana' },
  BWA: { name: 'Botswana' },
  BV: { name: 'Bouvet Island' },
  BVT: { name: 'Bouvet Island' },
  BR: { name: 'Brazil' },
  BRA: { name: 'Brazil' },
  IO: { name: 'British Indian Ocean Territory' },
  IOT: { name: 'British Indian Ocean Territory' },
  BN: { name: 'Brunei Darussalam' },
  BRN: { name: 'Brunei Darussalam' },
  BG: { name: 'Bulgaria' },
  BGR: { name: 'Bulgaria' },
  BF: { name: 'Burkina Faso' },
  BFA: { name: 'Burkina Faso' },
  BI: { name: 'Burundi' },
  BDI: { name: 'Burundi' },
  CV: { name: 'Cabo Verde' },
  CPV: { name: 'Cabo Verde' },
  KH: { name: 'Cambodia' },
  KHM: { name: 'Cambodia' },
  CM: { name: 'Cameroon' },
  CMR: { name: 'Cameroon' },
  CA: { name: 'Canada' },
  CAN: { name: 'Canada' },
  KY: { name: 'Cayman Islands' },
  CYM: { name: 'Cayman Islands' },
  CF: { name: 'Central African Republic' },
  CAF: { name: 'Central African Republic' },
  TD: { name: 'Chad' },
  TCD: { name: 'Chad' },
  CL: { name: 'Chile' },
  CHL: { name: 'Chile' },
  CN: { name: 'China' },
  CHN: { name: 'China' },
  CX: { name: 'Christmas Island' },
  CXR: { name: 'Christmas Island' },
  CC: { name: 'Cocos (Keeling) Islands' },
  CCK: { name: 'Cocos (Keeling) Islands' },
  CO: { name: 'Colombia' },
  COL: { name: 'Colombia' },
  KM: { name: 'Comoros' },
  COM: { name: 'Comoros' },
  CG: { name: 'Congo' },
  COG: { name: 'Congo' },
  CD: { name: 'Congo, Democratic Republic of the' },
  COD: { name: 'Congo, Democratic Republic of the' },
  CK: { name: 'Cook Islands' },
  COK: { name: 'Cook Islands' },
  CR: { name: 'Costa Rica' },
  CRI: { name: 'Costa Rica' },
  CI: { name: "Côte d'Ivoire" },
  CIV: { name: "Côte d'Ivoire" },
  HR: { name: 'Croatia' },
  HRV: { name: 'Croatia' },
  CU: { name: 'Cuba' },
  CUB: { name: 'Cuba' },
  CW: { name: 'Curaçao' },
  CUW: { name: 'Curaçao' },
  CY: { name: 'Cyprus' },
  CYP: { name: 'Cyprus' },
  CZ: { name: 'Czechia' },
  CZE: { name: 'Czechia' },
  DK: { name: 'Denmark' },
  DNK: { name: 'Denmark' },
  DJ: { name: 'Djibouti' },
  DJI: { name: 'Djibouti' },
  DM: { name: 'Dominica' },
  DMA: { name: 'Dominica' },
  DO: { name: 'Dominican Republic' },
  DOM: { name: 'Dominican Republic' },
  EC: { name: 'Ecuador' },
  ECU: { name: 'Ecuador' },
  EG: { name: 'Egypt' },
  EGY: { name: 'Egypt' },
  SV: { name: 'El Salvador' },
  SLV: { name: 'El Salvador' },
  GQ: { name: 'Equatorial Guinea' },
  GNQ: { name: 'Equatorial Guinea' },
  ER: { name: 'Eritrea' },
  ERI: { name: 'Eritrea' },
  EE: { name: 'Estonia' },
  EST: { name: 'Estonia' },
  SZ: { name: 'Eswatini' },
  SWZ: { name: 'Eswatini' },
  ET: { name: 'Ethiopia' },
  ETH: { name: 'Ethiopia' },
  FK: { name: 'Falkland Islands (Malvinas)' },
  FLK: { name: 'Falkland Islands (Malvinas)' },
  FO: { name: 'Faroe Islands' },
  FRO: { name: 'Faroe Islands' },
  FJ: { name: 'Fiji' },
  FJI: { name: 'Fiji' },
  FI: { name: 'Finland' },
  FIN: { name: 'Finland' },
  FR: { name: 'France' },
  FRA: { name: 'France' },
  GF: { name: 'French Guiana' },
  GUF: { name: 'French Guiana' },
  PF: { name: 'French Polynesia' },
  PYF: { name: 'French Polynesia' },
  TF: { name: 'French Southern Territories' },
  ATF: { name: 'French Southern Territories' },
  GA: { name: 'Gabon' },
  GAB: { name: 'Gabon' },
  GM: { name: 'Gambia' },
  GMB: { name: 'Gambia' },
  GE: { name: 'Georgia' },
  GEO: { name: 'Georgia' },
  DE: { name: 'Germany' },
  DEU: { name: 'Germany' },
  GH: { name: 'Ghana' },
  GHA: { name: 'Ghana' },
  GI: { name: 'Gibraltar' },
  GIB: { name: 'Gibraltar' },
  GR: { name: 'Greece' },
  GRC: { name: 'Greece' },
  GL: { name: 'Greenland' },
  GRL: { name: 'Greenland' },
  GD: { name: 'Grenada' },
  GRD: { name: 'Grenada' },
  GP: { name: 'Guadeloupe' },
  GLP: { name: 'Guadeloupe' },
  GU: { name: 'Guam' },
  GUM: { name: 'Guam' },
  GT: { name: 'Guatemala' },
  GTM: { name: 'Guatemala' },
  GG: { name: 'Guernsey' },
  GGY: { name: 'Guernsey' },
  GN: { name: 'Guinea' },
  GIN: { name: 'Guinea' },
  GW: { name: 'Guinea-Bissau' },
  GNB: { name: 'Guinea-Bissau' },
  GY: { name: 'Guyana' },
  GUY: { name: 'Guyana' },
  HT: { name: 'Haiti' },
  HTI: { name: 'Haiti' },
  HM: { name: 'Heard Island and McDonald Islands' },
  HMD: { name: 'Heard Island and McDonald Islands' },
  VA: { name: 'Holy See' },
  VAT: { name: 'Holy See' },
  HN: { name: 'Honduras' },
  HND: { name: 'Honduras' },
  HK: { name: 'Hong Kong' },
  HKG: { name: 'Hong Kong' },
  HU: { name: 'Hungary' },
  HUN: { name: 'Hungary' },
  IS: { name: 'Iceland' },
  ISL: { name: 'Iceland' },
  IN: { name: 'India' },
  IND: { name: 'India' },
  ID: { name: 'Indonesia' },
  IDN: { name: 'Indonesia' },
  IR: { name: 'Iran (Islamic Republic of)' },
  IRN: { name: 'Iran (Islamic Republic of)' },
  IQ: { name: 'Iraq' },
  IRQ: { name: 'Iraq' },
  IE: { name: 'Ireland' },
  IRL: { name: 'Ireland' },
  IM: { name: 'Isle of Man' },
  IMN: { name: 'Isle of Man' },
  IL: { name: 'Israel' },
  ISR: { name: 'Israel' },
  IT: { name: 'Italy' },
  ITA: { name: 'Italy' },
  JM: { name: 'Jamaica' },
  JAM: { name: 'Jamaica' },
  JP: { name: 'Japan' },
  JPN: { name: 'Japan' },
  JE: { name: 'Jersey' },
  JEY: { name: 'Jersey' },
  JO: { name: 'Jordan' },
  JOR: { name: 'Jordan' },
  KZ: { name: 'Kazakhstan' },
  KAZ: { name: 'Kazakhstan' },
  KE: { name: 'Kenya' },
  KEN: { name: 'Kenya' },
  KI: { name: 'Kiribati' },
  KIR: { name: 'Kiribati' },
  KP: { name: "Korea (Democratic People's Republic of)" },
  PRK: { name: "Korea (Democratic People's Republic of)" },
  KR: { name: 'Korea, Republic of' },
  KOR: { name: 'Korea, Republic of' },
  KW: { name: 'Kuwait' },
  KWT: { name: 'Kuwait' },
  KG: { name: 'Kyrgyzstan' },
  KGZ: { name: 'Kyrgyzstan' },
  LA: { name: "Lao People's Democratic Republic" },
  LAO: { name: "Lao People's Democratic Republic" },
  LV: { name: 'Latvia' },
  LVA: { name: 'Latvia' },
  LB: { name: 'Lebanon' },
  LBN: { name: 'Lebanon' },
  LS: { name: 'Lesotho' },
  LSO: { name: 'Lesotho' },
  LR: { name: 'Liberia' },
  LBR: { name: 'Liberia' },
  LY: { name: 'Libya' },
  LBY: { name: 'Libya' },
  LI: { name: 'Liechtenstein' },
  LIE: { name: 'Liechtenstein' },
  LT: { name: 'Lithuania' },
  LTU: { name: 'Lithuania' },
  LU: { name: 'Luxembourg' },
  LUX: { name: 'Luxembourg' },
  MO: { name: 'Macao' },
  MAC: { name: 'Macao' },
  MG: { name: 'Madagascar' },
  MDG: { name: 'Madagascar' },
  MW: { name: 'Malawi' },
  MWI: { name: 'Malawi' },
  MY: { name: 'Malaysia' },
  MYS: { name: 'Malaysia' },
  MV: { name: 'Maldives' },
  MDV: { name: 'Maldives' },
  ML: { name: 'Mali' },
  MLI: { name: 'Mali' },
  MT: { name: 'Malta' },
  MLT: { name: 'Malta' },
  MH: { name: 'Marshall Islands' },
  MHL: { name: 'Marshall Islands' },
  MQ: { name: 'Martinique' },
  MTQ: { name: 'Martinique' },
  MR: { name: 'Mauritania' },
  MRT: { name: 'Mauritania' },
  MU: { name: 'Mauritius' },
  MUS: { name: 'Mauritius' },
  YT: { name: 'Mayotte' },
  MYT: { name: 'Mayotte' },
  MX: { name: 'Mexico' },
  MEX: { name: 'Mexico' },
  FM: { name: 'Micronesia (Federated States of)' },
  FSM: { name: 'Micronesia (Federated States of)' },
  MD: { name: 'Moldova, Republic of' },
  MDA: { name: 'Moldova, Republic of' },
  MC: { name: 'Monaco' },
  MCO: { name: 'Monaco' },
  MN: { name: 'Mongolia' },
  MNG: { name: 'Mongolia' },
  ME: { name: 'Montenegro' },
  MNE: { name: 'Montenegro' },
  MS: { name: 'Montserrat' },
  MSR: { name: 'Montserrat' },
  MA: { name: 'Morocco' },
  MAR: { name: 'Morocco' },
  MZ: { name: 'Mozambique' },
  MOZ: { name: 'Mozambique' },
  MM: { name: 'Myanmar' },
  MMR: { name: 'Myanmar' },
  NA: { name: 'Namibia' },
  NAM: { name: 'Namibia' },
  NR: { name: 'Nauru' },
  NRU: { name: 'Nauru' },
  NP: { name: 'Nepal' },
  NPL: { name: 'Nepal' },
  NL: { name: 'Netherlands' },
  NLD: { name: 'Netherlands' },
  NC: { name: 'New Caledonia' },
  NCL: { name: 'New Caledonia' },
  NZ: { name: 'New Zealand' },
  NZL: { name: 'New Zealand' },
  NI: { name: 'Nicaragua' },
  NIC: { name: 'Nicaragua' },
  NE: { name: 'Niger' },
  NER: { name: 'Niger' },
  NG: { name: 'Nigeria' },
  NGA: { name: 'Nigeria' },
  NU: { name: 'Niue' },
  NIU: { name: 'Niue' },
  NF: { name: 'Norfolk Island' },
  NFK: { name: 'Norfolk Island' },
  MK: { name: 'North Macedonia' },
  MKD: { name: 'North Macedonia' },
  MP: { name: 'Northern Mariana Islands' },
  MNP: { name: 'Northern Mariana Islands' },
  NO: { name: 'Norway' },
  NOR: { name: 'Norway' },
  OM: { name: 'Oman' },
  OMN: { name: 'Oman' },
  PK: { name: 'Pakistan' },
  PAK: { name: 'Pakistan' },
  PW: { name: 'Palau' },
  PLW: { name: 'Palau' },
  PS: { name: 'Palestine, State of' },
  PSE: { name: 'Palestine, State of' },
  PA: { name: 'Panama' },
  PAN: { name: 'Panama' },
  PG: { name: 'Papua New Guinea' },
  PNG: { name: 'Papua New Guinea' },
  PY: { name: 'Paraguay' },
  PRY: { name: 'Paraguay' },
  PE: { name: 'Peru' },
  PER: { name: 'Peru' },
  PH: { name: 'Philippines' },
  PHL: { name: 'Philippines' },
  PN: { name: 'Pitcairn' },
  PCN: { name: 'Pitcairn' },
  PL: { name: 'Poland' },
  POL: { name: 'Poland' },
  PT: { name: 'Portugal' },
  PRT: { name: 'Portugal' },
  PR: { name: 'Puerto Rico' },
  PRI: { name: 'Puerto Rico' },
  QA: { name: 'Qatar' },
  QAT: { name: 'Qatar' },
  RE: { name: 'Réunion' },
  REU: { name: 'Réunion' },
  RO: { name: 'Romania' },
  ROU: { name: 'Romania' },
  RU: { name: 'Russian Federation' },
  RUS: { name: 'Russian Federation' },
  RW: { name: 'Rwanda' },
  RWA: { name: 'Rwanda' },
  BL: { name: 'Saint Barthélemy' },
  BLM: { name: 'Saint Barthélemy' },
  SH: { name: 'Saint Helena, Ascension and Tristan da Cunha' },
  SHN: { name: 'Saint Helena, Ascension and Tristan da Cunha' },
  KN: { name: 'Saint Kitts and Nevis' },
  KNA: { name: 'Saint Kitts and Nevis' },
  LC: { name: 'Saint Lucia' },
  LCA: { name: 'Saint Lucia' },
  MF: { name: 'Saint Martin (French part)' },
  MAF: { name: 'Saint Martin (French part)' },
  PM: { name: 'Saint Pierre and Miquelon' },
  SPM: { name: 'Saint Pierre and Miquelon' },
  VC: { name: 'Saint Vincent and the Grenadines' },
  VCT: { name: 'Saint Vincent and the Grenadines' },
  WS: { name: 'Samoa' },
  WSM: { name: 'Samoa' },
  SM: { name: 'San Marino' },
  SMR: { name: 'San Marino' },
  ST: { name: 'Sao Tome and Principe' },
  STP: { name: 'Sao Tome and Principe' },
  SA: { name: 'Saudi Arabia' },
  SAU: { name: 'Saudi Arabia' },
  SN: { name: 'Senegal' },
  SEN: { name: 'Senegal' },
  RS: { name: 'Serbia' },
  SRB: { name: 'Serbia' },
  SC: { name: 'Seychelles' },
  SYC: { name: 'Seychelles' },
  SL: { name: 'Sierra Leone' },
  SLE: { name: 'Sierra Leone' },
  SG: { name: 'Singapore' },
  SGP: { name: 'Singapore' },
  SX: { name: 'Sint Maarten (Dutch part)' },
  SXM: { name: 'Sint Maarten (Dutch part)' },
  SK: { name: 'Slovakia' },
  SVK: { name: 'Slovakia' },
  SI: { name: 'Slovenia' },
  SVN: { name: 'Slovenia' },
  SB: { name: 'Solomon Islands' },
  SLB: { name: 'Solomon Islands' },
  SO: { name: 'Somalia' },
  SOM: { name: 'Somalia' },
  ZA: { name: 'South Africa' },
  ZAF: { name: 'South Africa' },
  GS: { name: 'South Georgia and the South Sandwich Islands' },
  SGS: { name: 'South Georgia and the South Sandwich Islands' },
  SS: { name: 'South Sudan' },
  SSD: { name: 'South Sudan' },
  ES: { name: 'Spain' },
  ESP: { name: 'Spain' },
  LK: { name: 'Sri Lanka' },
  LKA: { name: 'Sri Lanka' },
  SD: { name: 'Sudan' },
  SDN: { name: 'Sudan' },
  SR: { name: 'Suriname' },
  SUR: { name: 'Suriname' },
  SJ: { name: 'Svalbard and Jan Mayen' },
  SJM: { name: 'Svalbard and Jan Mayen' },
  SE: { name: 'Sweden' },
  SWE: { name: 'Sweden' },
  CH: { name: 'Switzerland' },
  CHE: { name: 'Switzerland' },
  SY: { name: 'Syrian Arab Republic' },
  SYR: { name: 'Syrian Arab Republic' },
  TW: { name: 'Taiwan, Province of China' },
  TWN: { name: 'Taiwan, Province of China' },
  TJ: { name: 'Tajikistan' },
  TJK: { name: 'Tajikistan' },
  TZ: { name: 'Tanzania, United Republic of' },
  TZA: { name: 'Tanzania, United Republic of' },
  TH: { name: 'Thailand' },
  THA: { name: 'Thailand' },
  TL: { name: 'Timor-Leste' },
  TLS: { name: 'Timor-Leste' },
  TG: { name: 'Togo' },
  TGO: { name: 'Togo' },
  TK: { name: 'Tokelau' },
  TKL: { name: 'Tokelau' },
  TO: { name: 'Tonga' },
  TON: { name: 'Tonga' },
  TT: { name: 'Trinidad and Tobago' },
  TTO: { name: 'Trinidad and Tobago' },
  TN: { name: 'Tunisia' },
  TUN: { name: 'Tunisia' },
  TR: { name: 'Turkey' },
  TUR: { name: 'Turkey' },
  TM: { name: 'Turkmenistan' },
  TKM: { name: 'Turkmenistan' },
  TC: { name: 'Turks and Caicos Islands' },
  TCA: { name: 'Turks and Caicos Islands' },
  TV: { name: 'Tuvalu' },
  TUV: { name: 'Tuvalu' },
  UG: { name: 'Uganda' },
  UGA: { name: 'Uganda' },
  UA: { name: 'Ukraine' },
  UKR: { name: 'Ukraine' },
  AE: { name: 'United Arab Emirates' },
  ARE: { name: 'United Arab Emirates' },
  GB: { name: 'United Kingdom' },
  GBR: { name: 'United Kingdom' },
  US: { name: 'United States' },
  USA: { name: 'United States' },
  UM: { name: 'United States Minor Outlying Islands' },
  UMI: { name: 'United States Minor Outlying Islands' },
  UY: { name: 'Uruguay' },
  URY: { name: 'Uruguay' },
  UZ: { name: 'Uzbekistan' },
  UZB: { name: 'Uzbekistan' },
  VU: { name: 'Vanuatu' },
  VUT: { name: 'Vanuatu' },
  VE: { name: 'Venezuela (Bolivarian Republic of)' },
  VEN: { name: 'Venezuela (Bolivarian Republic of)' },
  VN: { name: 'Viet Nam' },
  VNM: { name: 'Viet Nam' },
  VG: { name: 'Virgin Islands (British)' },
  VGB: { name: 'Virgin Islands (British)' },
  VI: { name: 'Virgin Islands (U.S.)' },
  VIR: { name: 'Virgin Islands (U.S.)' },
  WF: { name: 'Wallis and Futuna' },
  WLF: { name: 'Wallis and Futuna' },
  EH: { name: 'Western Sahara' },
  ESH: { name: 'Western Sahara' },
  YE: { name: 'Yemen' },
  YEM: { name: 'Yemen' },
  ZM: { name: 'Zambia' },
  ZMB: { name: 'Zambia' },
  ZW: { name: 'Zimbabwe' },
  ZWE: { name: 'Zimbabwe' }
  }

export default ISOCountries