import { Models } from "@ekko/predict-client-api"
import { ResourceType, UserType } from "@/core/enums"
import { PermissionActor } from "../../modules/permissions/table/sites/permissions/PermissionActor"

/**
 *
 */
export class AccessControlHelper {
    /**
     *
     * @param actor
     * @param accessLevel
     * @param site
     * @returns
     */
    static buildAccessControlForSite(actor: PermissionActor, accessLevel: UserType, site: Models.SiteModel) {
        const accessControl = new Models.AccessControlModel()
        accessControl.setObjectType(ResourceType.SITES)
        accessControl.setObjectId(site.id)
        accessControl.setAccessControlType(actor.accessControlType)
        accessControl.setReference(actor.reference)
        switch (accessLevel) {
            case UserType.ENGINEER: {
                accessControl.setRead(true)
                accessControl.setWrite(true)
                break
            }
            case UserType.USER: {
                accessControl.setRead(true)
                accessControl.setWrite(false)
                break
            }
            default: {
                throw new Error(`Internal error: unsupported user type ${accessLevel}`)
            }
        }
        return accessControl
    }
}

export default AccessControlHelper