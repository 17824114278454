import { ReadingType } from "@/History/enums"
import { EnergyHistoryClient } from "@/History/clients/EnergyHistoryClient"
import { GraphHelper, NodesetHelper, NumberFormat, SimulationBatchResults } from "@/core/helpers"
import type { MeteredData, Reading, UnbatchEvents } from "@/core/interfaces"
import { aggregatorService } from "@/core/services"
import type { Models } from "@ekko/predict-client-api"
import { Granularity, GraphType } from "../enums"
import type { NamedSimulationNode, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import type { AggregatorResponse } from "../interfaces/AggregatorResponse"
import type { NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"
import { AllocatableClient } from "./AllocatableClient"
import { AllocationOuputNode } from "./AllocationOuputNode"

/**
 *
 */
export abstract class EnergyBaseClient extends AllocatableClient {
    /**
     * @param allocate
     * @param nodesets
     * @param siteInfo
     * @param meteredData
     * @param unbatchCallbacks
     */
    private async simulateEnergyHistoricalResult(
        allocate: boolean, nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, meteredData: MeteredData,
        unbatchCallbacks: UnbatchEvents | null) {
        return SimulationBatchResults.iterate(nodesets,
            (nodeset, unbatchCallbacks) => {
                const overlap = this.overlappingPeriod(nodeset)
                if (!overlap) return {}
                return aggregatorService.simulateHistorical(GraphType.ENERGY,
                    allocate, nodeset.nodes, siteInfo, overlap,
                    Granularity.DAY, meteredData.meteredTemperature,
                    meteredData.readings, unbatchCallbacks)
            }, NodesetHelper.dateInRange, this.granularity, unbatchCallbacks
        )
    }

    /**
     *
     * @param result
     * @param nodes All nodes in this list will be in the output, possibly
     * filtered to IT loads
     * @param deviceSelected
     * @param outputNodeSelection
     */
    protected addAllocations(result: AggregatorResponse, nodes: NamedSimulationNode[],
        deviceSelected: string | null = null,
        outputNodeSelection: AllocationOuputNode = AllocationOuputNode.ItLoad
    ) {
        const itNodeReferences = this.getItNodeReferences(nodes)

        const output: Map<string, Map<string, { fixed: number, variable: number, it: number }>> = new Map()

        const outputNodes = outputNodeSelection == AllocationOuputNode.ItLoad ?
            new Set(itNodeReferences) :
            new Set(this.getNodeReferences(nodes, deviceSelected))

        for (const [point, dynamicCost] of Object.entries(result)) {
            const pointOutput: Map<string, { fixed: number, variable: number, it: number }> = new Map()
            for (const nodeReference of outputNodes.values()) {
                pointOutput.set(nodeReference, { fixed: 0, variable: 0, it: 0 })
            }
            const fixedAllocation = dynamicCost.fixed.allocation
            const variableAllocation = dynamicCost.variable.allocation
            for (const itNodeReference of itNodeReferences) {
                for (const [nodeReference, cost] of Object.entries(dynamicCost.fixed.costs)) {
                    const outputValue = pointOutput.get(
                        outputNodeSelection == AllocationOuputNode.ItLoad ?
                            itNodeReference :
                            nodeReference
                    )
                    if (!outputValue) {
                        continue
                    }
                    if (fixedAllocation?.[nodeReference]?.[itNodeReference]) {
                        outputValue.fixed += NumberFormat.to2dp(cost * fixedAllocation[nodeReference][itNodeReference])
                    }
                }
                for (const [nodeReference, cost] of Object.entries(dynamicCost.variable.costs)) {
                    const outputValue = pointOutput.get(
                        outputNodeSelection == AllocationOuputNode.ItLoad ?
                            itNodeReference :
                            nodeReference
                    )
                    if (!outputValue) {
                        continue
                    }
                    if (nodeReference == itNodeReference) {
                        outputValue.it += cost
                    } else if (nodeReference.startsWith("itNode/")) {
                        // No action
                    } else {
                        if (variableAllocation?.[nodeReference]?.[itNodeReference]) {
                            outputValue.variable += NumberFormat.to2dp(cost * variableAllocation[nodeReference][itNodeReference])
                        }
                    }
                }
            }
            output.set(point, pointOutput)
        }
        return output
    }

    /**
     *
     * @param result
     * @param nodes
     * @param deviceSelected
     * @returns A point-to-node-to-result map
     */
    protected buildResultTree(result: AggregatorResponse, nodes: NamedSimulationNode[], deviceSelected: string | null = null) {
        const output: Map<string, Map<string, { fixed: number, variable: number, it: number }>> = new Map()

        const outputNodes = new Set(this.getNodeReferences(nodes, deviceSelected))

        for (const [point, dynamicCost] of Object.entries(result)) {
            const pointOutput: Map<string, { fixed: number, variable: number, it: number }> = new Map()
            for (const nodeReference of outputNodes.values()) {
                pointOutput.set(nodeReference, { fixed: 0, variable: 0, it: 0 })
            }
            for (const [nodeReference, cost] of Object.entries(dynamicCost.fixed.costs)) {
                const outputValue = pointOutput.get(nodeReference)
                if (!outputValue) {
                    continue
                }
                outputValue.fixed += cost
            }
            for (const [nodeReference, cost] of Object.entries(dynamicCost.variable.costs)) {
                const outputValue = pointOutput.get(nodeReference)
                if (!outputValue) {
                    continue
                }
                if (nodeReference.startsWith("itNode/")) {
                    outputValue.it = cost
                    outputValue.variable = 0
                } else {
                    outputValue.it = 0
                    outputValue.variable += cost
                }
            }
            output.set(point, pointOutput)
        }
        return output
    }

    /**
     *
     * @param site
     * @param readingType
     * @returns
     */
    protected getHistoricalEnergyReadings(site: Models.SiteModel, readingType?: ReadingType.POWER_LOAD | ReadingType.POWER_SUPPLY) {
        const historyClient = new EnergyHistoryClient(readingType)
        historyClient.period = this.period
        return historyClient.fetch(site.id)
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     */
    protected async simulateEnergy(
        nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo,
        deviceSelected: string | null,
        unbatchCallbacks: UnbatchEvents | null,
    ): Promise<NodeDynamicCostsByType> {
        const nodes = NodesetHelper.nodes(nodesets)
        if (this.isFilteringITNodes(nodes)) {
            const result = await this.simulateEnergyResult(
                true,
                nodesets,
                siteInfo,
                unbatchCallbacks,
            )
            return this.summariseByPoint(this.addAllocations(result, nodes, deviceSelected, AllocationOuputNode.Item))
        } else {
            const result = await this.simulateEnergyResult(
                false,
                nodesets,
                siteInfo,
                unbatchCallbacks,
            )
            return this.summariseByPoint(this.buildResultTree(result, nodes, deviceSelected))
        }
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     */
    protected async simulateEnergyAllocated(
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null, unbatchCallbacks: UnbatchEvents | null
    ): Promise<NodeDynamicCostsByType> {
        const nodes = NodesetHelper.nodes(nodesets)
        const result = await this.simulateEnergyResult(true, nodesets,
            siteInfo, unbatchCallbacks)

        return this.summariseByNode(this.addAllocations(result, nodes, deviceSelected))
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     */
    protected async simulateEnergyAttributed(
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null, unbatchCallbacks: UnbatchEvents | null
    ): Promise<NodeDynamicCostsByType> {
        const nodes = NodesetHelper.nodes(nodesets)
        if (this.isFilteringITNodes(nodes)) {
            const result = await this.simulateEnergyResult(true, nodesets,
                siteInfo, unbatchCallbacks)
            return this.summariseByNode(this.addAllocations(result, nodes, deviceSelected, AllocationOuputNode.Item))
        } else {
            const result = await this.simulateEnergyResult(false, nodesets,
                siteInfo, unbatchCallbacks)

            return this.summariseByNode(this.buildResultTree(result, nodes, deviceSelected))
        }
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param meteredData
     * @param unbatchCallbacks
     */
    protected async simulateEnergyHistorical(
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null, meteredData: MeteredData,
        unbatchCallbacks: UnbatchEvents | null) {
        const nodes = NodesetHelper.nodes(nodesets)
        if (this.isFilteringITNodes(nodes)) {
            const result = await this.simulateEnergyHistoricalResult(true,
                nodesets, siteInfo, meteredData, unbatchCallbacks)
            return this.summariseByPoint(this.addAllocations(result, nodes, deviceSelected, AllocationOuputNode.Item))
        } else {
            const result = await this.simulateEnergyHistoricalResult(false,
                nodesets, siteInfo, meteredData, unbatchCallbacks)

            return this.summariseByPoint(this.buildResultTree(result, nodes, deviceSelected))
        }
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param meteredData
     * @param unbatchCallbacks
     */
    protected async simulateEnergyHistoricalAllocated(
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null, meteredData: MeteredData,
        unbatchCallbacks: UnbatchEvents | null
    ) {
        const nodes = NodesetHelper.nodes(nodesets)
        const result = await this.simulateEnergyHistoricalResult(true,
            nodesets, siteInfo, meteredData, unbatchCallbacks)

        return this.summariseByNode(this.addAllocations(result, nodes, deviceSelected))
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param meteredData
     * @param unbatchCallbacks
     */
    protected async simulateEnergyHistoricalAttributed(
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null, meteredData: MeteredData,
        unbatchCallbacks: UnbatchEvents | null) {
        const nodes = NodesetHelper.nodes(nodesets)
        if (this.isFilteringITNodes(nodes)) {
            const result = await this.simulateEnergyHistoricalResult(true,
                nodesets, siteInfo, meteredData, unbatchCallbacks)
            return this.summariseByNode(this.addAllocations(result, nodes, deviceSelected, AllocationOuputNode.Item))
        } else {
            const result = await this.simulateEnergyHistoricalResult(false,
                nodesets, siteInfo, meteredData, unbatchCallbacks)
            return this.summariseByNode(this.buildResultTree(result, nodes, deviceSelected))
        }
    }

    /**
     *
     * @param allocate
     * @param nodesets
     * @param siteInfo
     * @param unbatchCallbacks
     * @returns
     */
    protected async simulateEnergyResult(allocate: boolean,
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        unbatchCallbacks: UnbatchEvents | null) {
        return SimulationBatchResults.iterate(nodesets,
            (nodeset, unbatchCallbacks) => {
                const overlap = this.overlappingPeriod(nodeset)
                if (!overlap) return {}
                return aggregatorService.simulate(
                    GraphType.ENERGY,
                    allocate,
                    nodeset.nodes,
                    siteInfo,
                    overlap,
                    Granularity.DAY,
                    unbatchCallbacks
                )
            },
            NodesetHelper.dateInRange, this.granularity, unbatchCallbacks
        )
    }

    /**
     * @param simulationResult
     * @param actualResult
     */
    public getHistoricalGraphLines<T>(
        simulationResult: Map<string, T>,
        actualResult: Map<string, number> | Reading<Date>[]
    ): Map<string, T & { actual: number }> {
        const combinedMap = new Map<string, T & { actual: number }>()
        let resultMap: Map<string, number>

        if (Array.isArray(actualResult)) {
            resultMap = new Map<string, number>()
            for (const reading of actualResult) {
                const readingKey = new Date(reading.timestamp).toISOString()
                resultMap.set(readingKey, reading.value)
            }
        } else {
            resultMap = actualResult
        }

        for (const [date, costByType] of simulationResult.entries()) {
            const historicalCostByType = {
                ...costByType,
                actual: GraphHelper.getActualValue(resultMap, date) ?? 0,
            }
            combinedMap.set(date, historicalCostByType)
        }

        return combinedMap
    }
}