import Notifications from "@/components/common/Notifications.vue"
import { Notification } from "@/core/interfaces"
import { notificationsService } from "@/core/services/notifications.service"
import { App } from "vue"


let hasRun = false

export default {
  /**
   * Registers the notification component and adds globally available methods on component instances
   * @param app
   * @example
   * // notify for error
   * this.$notifyError("Something went wrong")
   */
  install(app: App) {
    if (!hasRun) {
      hasRun = true

      app.component('Notifications', Notifications)

      app.config.globalProperties.$notify = (notification: Notification) => {
        notificationsService.notify(notification)
      }
      app.config.globalProperties.$notifySuccess = (message: string) => {
        notificationsService.notifySuccess(message)
      }
      app.config.globalProperties.$notifyError = (message?: string) => {
        notificationsService.notifyError(message)
      }
    }
  }
}

