import { SidePanelLocation } from "@/core/enums/SIdePanelLocation"
import { ActionTree, GetterTree, MutationTree } from "vuex"

/**
 *
 */
class State {
    /**
     *
     */
    isErrorSidePanelOpen: boolean | null = false

    /**
     *
     */
    priorityNodeHighlight = false

    /**
     *
     */
    sidePanelLocation: SidePanelLocation = SidePanelLocation.RIGHT
}

const getters = <GetterTree<State, any>>{
    /**
     * @param state
     * @returns
     */
    isSidePanelLeft(state): boolean {
        return state.sidePanelLocation === SidePanelLocation.LEFT
    },
}

const mutations = <MutationTree<State>>{
    /**
     * @param state
     * @param newLocation
     */
    setSidePanelLocation(state, newLocation) {
        state.sidePanelLocation = newLocation
    },
    /**
     *
     * @param state
     * @param isErrorSidePanelOpen
     */
    setIsErrorSidePanelOpen(state, isErrorSidePanelOpen: boolean) {
        state.isErrorSidePanelOpen = isErrorSidePanelOpen
    },
    /**
     *
     * @param state
     * @param highlight
     */
    setPriorityNodeHighlight(state, highlight: State["priorityNodeHighlight"]) {
        state.priorityNodeHighlight = highlight
    },
}

const actions = <ActionTree<State, any>>{
    /**
     *
     * @param actionState
     */
    closeErrorSidePanel({ commit }) {
        commit("setIsErrorSidePanelOpen", false)
    },
     /**
     * @param actionState
     */
    toggleSidePanelLocation({ state, commit }) {
        const sidePanelLocation = state.sidePanelLocation === SidePanelLocation.RIGHT ? SidePanelLocation.LEFT : SidePanelLocation.RIGHT
        commit("setSidePanelLocation", sidePanelLocation)
    },
    /**
     *
     * @param actionState
     */
    toggleIsErrorSidePanelOpen({ commit, state }) {
        commit("setIsErrorSidePanelOpen", !state.isErrorSidePanelOpen)
    },
    /**
     *
     * @param actionState
     * @param highlight
     */
    updatePriorityNodeHighlight({ commit }, highlight: State["priorityNodeHighlight"]) {
        commit("setPriorityNodeHighlight", highlight)
    },
}

const DisplayPreferencesModule = {
    namespaced: true,
    state: {
        ...new State(),
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
}

export default DisplayPreferencesModule