import { Interface, NodeType } from "predict-performance-calculation"

/**
 *
 */
export class SimulationResult {
    /**
     *
     */
    private type: string
    /**
     *
     */
    coolingLoad: number
    /**
     *
     */
    powerDemand: number
    /**
     *
     */
    powerLoad: number

    /**
     *
     */
    get asLoad(): Interface.Load {
        if(this.type == NodeType.ITNode) {
            return {cooling: 0, it: this.powerDemand, power: 0}
        } else {
            return {cooling: this.coolingLoad, it: 0, power: this.powerLoad}
        }
    }

    /**
     *
     * @param result
     * @param nodeRef
     */
    constructor(
        [powerDemand, powerLoad, coolingLoad]: [number, number, number],
        nodeRef: string
    ) {
        this.coolingLoad = coolingLoad
        this.powerDemand = powerDemand
        this.powerLoad = powerLoad
        this.type = nodeRef.replace(/\/.*/, "")
    }
}