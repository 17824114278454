import ApiService from "@/core/services/api.service"
import { PowerCostProfilesState } from "@/store/power-cost-profiles/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<PowerCostProfilesState> {
  powerCostProfiles: Models.PowerCostProfileModel[] = [];
  loaded = false
}

const getters: GetterTree<PowerCostProfilesState, any> = {
  getPowerCostProfiles(state): PaginatedResult<Models.PowerCostProfileModel> {
    let results = state.powerCostProfiles
    const totalCount = results.length
    results = results.slice((state.page - 1) * state.pageSize, state.page * state.pageSize)

    return { results, totalCount }
  },
  getPowerCostProfile: (state) => (id: string): Models.PowerCostProfileModel | undefined => {
    return state.powerCostProfiles.find(m => (m as any).id === id)
  },
  getIsLibraryEmpty(state): boolean {
    return state.loaded && state.powerCostProfiles?.length === 0
  }
}

const mutations: MutationTree<State> = {
  setPowerCostProfiles(state, powerCostProfiles): void {
    state.powerCostProfiles = powerCostProfiles
  },
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  mutDeletePowerCostProfile(state, powerCostProfile: Models.PowerCostProfileModel): void {
    state.powerCostProfiles = state.powerCostProfiles.filter(m => (m as any).id !== (powerCostProfile as any).id)
  },
  mutCreatePowerCostProfile(state, powerCostProfile: Models.PowerCostProfileModel): void {
    state.powerCostProfiles.push(powerCostProfile)
  },
  mutUpdatePowerCostProfile(state, powerCostProfile: Models.PowerCostProfileModel): void {
    state.powerCostProfiles = state.powerCostProfiles.map(m => {
      if ((m as any).id === (powerCostProfile as any).id) {
        return powerCostProfile
      }
      return m
    })
  },
}

const actions = <ActionTree<State, any>>{
  async fetchPowerCostProfiles({ commit }, siteModel: Models.SiteModel) {

    const powerCostProfilesFound: Models.PowerCostProfileModel[] = [(await siteModel.getPowerCostProfile())!]

    commit("setLoaded", true)
    commit("setPowerCostProfiles", powerCostProfilesFound)
  },
  async deletePowerCostProfile({ commit }, powerCostProfile: Models.PowerCostProfileModel) {
    const powerCostProfileService = await ApiService.getPowerCostProfileService()
    powerCostProfileService.removePowerCostProfile(powerCostProfile)
    commit("mutDeletePowerCostProfile", powerCostProfile)
  },
  async createPowerCostProfile({ commit }, siteModel: Models.SiteModel) {
    try {
      const powerCostProfileService = await ApiService.getPowerCostProfileService()
      const { PowerCostProfileModel } = await ApiService.getModels()
      const powerCostProfileModel = new PowerCostProfileModel()

      powerCostProfileModel.addSite(siteModel)

      const [newPowerCostProfile]: Models.PowerCostProfileModel[] =
        await powerCostProfileService.addPowerCostProfile(powerCostProfileModel)
      commit("mutCreatePowerCostProfile", newPowerCostProfile)

      return newPowerCostProfile
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updatePowerCostProfile({ commit }, powerCostProfile: Models.PowerCostProfileModel) {
    const powerCostProfileService = await ApiService.getPowerCostProfileService()
    await powerCostProfileService.updatePowerCostProfile(powerCostProfile)
    commit("mutUpdatePowerCostProfile", powerCostProfile)
  },
  reset({ commit }) {
    commit("setPowerCostProfiles", [])
    commit("setLoaded", false)
  }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const PowerCostProfileModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...actions
  },
}

export default PowerCostProfileModule
