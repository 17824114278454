import { Granularity } from "@/Simulation/enums"

/**
 *
 */
export abstract class BatchResults<T> {
    /**
     *
     * @param point A short point, eg "2022", "2022-01" or "2022-01-01"
     * @returns
     */
    protected granularPoint(point: string) {
        switch(this.granularity) {
            case Granularity.MONTH: {
                const md = point.match(/^(\d{4}-\d{2})/)
                if(!md) {
                    throw new Error(`Bad partial date: ${point}`)
                }
                return md[1]
            }
            case Granularity.YEAR: {
                const md = point.match(/^(\d{4})/)
                if(!md) {
                    throw new Error(`Bad partial date: ${point}`)
                }
                return md[1]
            }
            case Granularity.DAY:
                // Fall through
            default:
                return point
        }
    }

    /**
     *
     */
    abstract readonly count: number

    /**
     * @param granularity
     */
    constructor(private granularity: Granularity) {

    }

    /**
     *
     * @param resultsIn
     */
    abstract add(resultsIn: T)

    /**
     *
     * @returns
     */
    abstract finalise()
}