import { SingleSupplySimulationNode } from "@/Simulation/interfaces"
import { NodeType } from "predict-performance-calculation"
import { ItemParameter, ItNodeParameter, OtherNodeParameter, SimpleParameter } from "../nodeParameter"
import type { NodeParameterBase } from "../nodeParameter"

/**
 *
 */
export class NodeParameter {
    /**
     * Retrieves the right node parameter instance
     * @param name
     */
    static factory<N extends SingleSupplySimulationNode>(name: string) {
        const registeredClasses = {
            [NodeType.Item]: ItemParameter,
            [NodeType.ITNode]: ItNodeParameter,
            [NodeType.OtherNode]: OtherNodeParameter,
        }

        if (registeredClasses[name]) {
            return new registeredClasses[name]<N>() as NodeParameterBase<any, N>
        } else {
            return new SimpleParameter<any, N>()
        }
    }
}