import { Models } from "@ekko/predict-client-api"
import { OrganizationStatus } from "../enums"
import { Group, User } from "../interfaces"

/**
 *
 */
export class Organisation {
    /**
     *
     */
    get id() {
        return this.ownerReference.id
    }

    /**
     *
     */
    get isParent() {
        return this.ownerReference.getIsParent()!
    }

    /**
     *
     */
    get name() {
        return this.ownerReference.getName()!
    }

    /**
     *
     */
    get namespace() {
        return this.ownerReference.getNamespace()!
    }

    /**
     *
     */
    get parentOrganization() {
        return this.ownerReference.getParentOrganization() ?? ""
    }

    /**
     *
     */
    get numberOfSites() {
        return this.sites.length
    }

    /**
     *
     */
    get numberOfUsers() {
        return this.users.length
    }

    /**
     *
     */
    get numberOfGroups() {
        return this.groups.length
    }

    /**
     *
     */
    get reference() {
        return this.ownerReference.getReference()!
    }

    /**
     *
     */
    get siteLimit() {
        return this.ownerReference.getSiteLimit()!
    }

    /**
     *
     */
    get status() {
        return this.ownerReference.getStatuses() as OrganizationStatus
    }

    /**
     *
     */
    get type() {
        return this.ownerReference.type
    }

    /**
     *
     */
    get userLimit() {
        return this.ownerReference.getUserLimit()!
    }

    /**
     *
     */
    get logoClass() {
        return this.ownerReference.getLogoClasses()
    }

    /**
     *
     */
    get logoUrl() {
        return this.ownerReference.getLogoUrl()
    }

    /**
     *
     * @param ownerReference
     * @param sites
     * @param users
     * @param groups
     */
    constructor(public ownerReference: Models.OwnerReferenceModel,
        public readonly sites: Models.SiteModel[],
        public readonly users: User[],
        public readonly groups: Group[]
    ) {

    }
}