import { NamedSimulationNode, SimulationSiteConfiguration } from "@/Simulation/interfaces"
import { SiteNodes } from "@/Simulation/siteParameter"
import { TimeBasedParameter } from "@/Simulation/timeBased"
import { SiteConfigurationScenario } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"

/**
 *
 */
export class NodesetHelper {
    /**
     *
     * @param pointDate
     * @param nodeset
     * @returns
     */
    static dateInRange(pointDate: Date, nodeset: SimulationSiteConfiguration) {
        return pointDate >= nodeset.start &&
            (!nodeset.finish || pointDate < nodeset.finish)
    }

    /**
     *
     * @param siteConfigurationsScenario
     * @returns
     */
    static async getNodesetsForAdvanced(siteConfigurationsScenario: SiteConfigurationScenario[]) {
        const nodesets: SimulationSiteConfiguration[] = []
        for (const scScenario of siteConfigurationsScenario) {
            const nodeModels = await SiteNodes.forSiteConfig(scScenario.model)
            const nodes = await TimeBasedParameter.buildNodesData(nodeModels)
            nodesets.push({
                start: new Date(scScenario.dateFrom),
                nodes,
                finish: new Date(scScenario.dateTo),
            })
        }

        return nodesets
    }
    /**
     * @param site
     * @returns
     */
    static async getNodesetsForSite(site: Models.SiteModel) {
        const firstConfiguration = await site.getFirstConfiguration()

        const nodesets: SimulationSiteConfiguration[] = []
        for (let configuration = firstConfiguration; configuration; configuration = await configuration.getFollowedBy()) {
            nodesets.push(await this.getNodesetForSiteConfiguration(configuration))
        }

        return nodesets
    }
    /**
     *
     * @param configuration
     * @returns
     */
    static async getNodesetForSiteConfiguration(configuration: Models.SiteConfigurationModel): Promise<SimulationSiteConfiguration> {
        const nodeModels = await SiteNodes.forSiteConfig(configuration)
        const nodes = await TimeBasedParameter.buildNodesData(nodeModels)
        const followedBy = await configuration.getFollowedBy()
        return {
            start: Object.freeze(new Date(configuration.getEffectiveDate()!)),
            nodes,
            finish: followedBy ? Object.freeze(new Date(followedBy.getEffectiveDate()!)) : null,
        }
    }
    /**
     *
     * @param nodesets
     * @returns
     */
    static nodes(nodesets: SimulationSiteConfiguration[]) {
        const nodes: NamedSimulationNode[] = []
        for (const nodeset of nodesets) {
            nodes.push(...nodeset.nodes)
        }
        return nodes
    }
}