/**
 * # RESPONSIVENESS
 *
 * Vue wraps its data objects with *tracking proxies*. When you fetch a
 * property from an object with a Vue tracking proxy, the property value
 * will _also_ be wrapped in a tracking proxy (excluding immediate values).
 * Special cases like maps do the same for their get methods.
 *
 * When an expression is evaluated, all those tracking proxies are watched to
 * see which properties you fetch. Separately, all those tracking proxies watch
 * assignments to properties (or pushes, etc), and if a write is seen on a
 * tracking proxy to a property that was previously evaluated for an expression,
 * that expression is due to be re-evaluated.
 *
 * This only happens when the write is *on the tracking proxy*, otherwise Vue
 * cannot possibly know to tie it to an expression evaluation.
 */

/**
 *
 */
export interface VueLazyWrapper<T> {
    value: T | null
}

/**
 * This wraps around a lazy-evaluated property to avoid Vue complaining
 * about "undefined"
 *
 * IMPORTANT NOTE: the storage used for this value must come from Vue. Even
 * if you use the value you just assigned to the Vue object, the one you can
 * later check through the Vue object and find actually _is_ still attached,
 * Vue won't see the change. You should think of it as <Vue object>.<property>
 * = <value>. See note on responsiveness above.
 *
 * The pattern you want is:
 *
 * private cacheFoo: VueLazy<Bar> = null
 *
 * private async loadFoo(): Promise<Bar> {
 *   ...
 * }
 *
 * get foo() {
 *   if(!this.cacheFoo) {
 *     this.cacheFoo = {value: null}
 *     const cache = this.cacheFoo // NOTE THIS LINE!
 *     this.loadFoo().then(
 *       result => cache.value = result
 *     )
 *   }
 *   return this.cacheFoo?.value
 * }
*/
export type VueLazy<T> = VueLazyWrapper<T> | null

/**
 * A wrapper for a vue-lazy action to make it a bit quicker to call
 *
 * Use like:
 *
 * lazyCache: {[k: string]: VueLazy<any>} = {
 *  foo: null,
 *  bar: null as VueLazy<number>,
 * }
 * get foo() {
 *   if(!this.lazyCache.foo) {
 *     vueLazySetProperty(this.lazyCache, "foo", this.loadFoo)
 *   }
 *   return this.lazyCache.foo?.value
 * }
 *
 * @param target
 * @param name
 * @param f
 * @returns
 */
export function vueLazySetProperty<T, U>(
    target: U,
    name: keyof U,
    f: () => Promise<T>): VueLazy<T> {

    target[name] = {value: null} as any
    const storage = target[name] as unknown as VueLazyWrapper<T>
    f().then(result => storage.value = result)
    return storage
}

/**
 * A wrapper for a vue-lazy action to make it a bit quicker to call
 *
 * Use like:
 *
 * cacheFoo = new Map<string, VueLazyWrapper<any>>()
 * getFoo(id: string) {
 *   if(!this.cacheFoo.has(id)) {
 *     vueLazySetMap(this.cacheFoo, id, () => this.loadFoo(id))
 *   }
 *   return this.cacheFoo.get(id)?.value
 * }
 *
 * @param target
 * @param name
 * @param f
 * @param defaultValue
 * @returns
 */
export function vueLazySetMap<T, U>(target: Map<U, VueLazyWrapper<T>>, name: U, f: () => Promise<T>,
    defaultValue?: T): VueLazy<T> {

    target.set(name, {value: null})
    const storage: VueLazyWrapper<T> = target.get(name)!
    if(defaultValue) {
        f().then(result => storage.value = result, e => {
            console.warn(e)
            return defaultValue
        })
    } else {
        f().then(result => storage.value = result)
    }
    return storage
}

/**
 * A wrapper for a vue-lazy set map action to make it a bit quicker to call
 *
 * Use like:
 *
 * cacheFoo = new Map<string, VueLazyWrapper<any>>()
 * getFoo(id: string) {
 *   return vueLazySetMapValue(this.cacheFoo, id, () => this.loadFoo(id))
 * }
 *
 * @param target
 * @param name
 * @param f
 * @param defaultValue
 * @returns
 */
export function vueLazySetMapValue<T>(target: Map<string, VueLazyWrapper<T>>, name: string, f: () => Promise<T>,
    defaultValue?: T): T | null | undefined {

    if(!target.has(name)) {
        vueLazySetMap(target, name, f, defaultValue)
    }

    return target.get(name)?.value
}