import { Paginated } from "@/core/helpers/Paginated"
import { CbreItemInformation, CbreSensorInformation, Datatype, UnitType } from "@/core/interfaces"
import { sensorConfigService } from "@/core/services"
import ApiService from "@/core/services/api.service"
import { UserReportableError } from "@/core/UserReportableError"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { FiltersState } from "../common/filters/types"
import { PaginationModule } from "../common/pagination"
import { PaginationState } from "../common/pagination/types"
import { DataImportTimelineBarData } from "../simulation-view/types"

/**
 *
 */
class State {
    /**
     *
     */
    items: CbreItemInformation[] = []
    /**
     *
     */
    selectedReading: CbreItemInformation | null = null
    /**
     *
     */
    dataTypes: Datatype | null = null
    /**
     *
     */
    units: UnitType | null = null
    /**
     *
     */
    loaded = false
    /**
     *
     */
    sensorSiteId: string | null = null
    /**
     *
     */
    timePoint: Date = new Date()
    /**
     *
     */
    timelineBarData: DataImportTimelineBarData | null = null
}

const getters = <GetterTree<State & PaginationState & FiltersState, any>>{
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getSensorItems(state, getters): PaginatedResult<CbreItemInformation> {
        return new Paginated(
            state.items, state.page, state.pageSize, getters.sensorItemSort,
            getters.sensorItemFilter
        )
    },
    /**
     *
     * @param state
     * @param meterPoint
     * @returns
     */
    getPrimarySensor(state, meterPoint: CbreItemInformation) {
        return meterPoint.sensors?.find(s => s.isPrimary) as CbreSensorInformation
    },
    /**
     *
     * @param state
     * @returns
     */
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.items?.length === 0
    },
    /**
     *
     * @param state
     * @returns
     */
    getSelectedItem(state): CbreItemInformation | null {
        return state.selectedReading
    },
    /**
     *
     * @param state
     * @returns
     */
    getDataTypes(state): Datatype | null {
        return state.dataTypes
    },
    /**
     *
     * @param state
     * @returns
     */
    getUnits(state): UnitType | null {
        return state.units
    },
    /**
     *
     * @param state
     * @returns
     */
    getSensorSiteId(state): string | null {
        return state.sensorSiteId
    },
    /**
     *
     * @param state
     * @returns
     */
    sensorItemFilter(state): (a: CbreItemInformation) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return (model) =>
            !!model?.id?.match(regexp) ||
            !!model?.label?.match(regexp) ||
            !!model?.type?.match(regexp) ||
            !!model?.subtype?.match(regexp)
    },
    /**
     * This returns the sort for manufacturer
     *
     * @param state
     * @returns
     */
    sensorItemSort(state): (a: CbreItemInformation, b: CbreItemInformation) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "ID":
                return Paginated.sortUndefinedFirst((sensorItem) => sensorItem.id, reverse)
            case "Label":
                return Paginated.sortUndefinedFirst((sensorItem) => sensorItem.label, reverse)
            case "Primary Sensor":
                return Paginated.sortUndefinedFirst((sensorItem) => this.getPrimarySensor(sensorItem).label, reverse)
            case "Type":
                return Paginated.sortUndefinedFirst((sensorItem) => sensorItem.meterPointType, reverse)
            case "Value":
                return Paginated.sortUndefinedFirst((sensorItem) => this.getPrimarySensor(sensorItem).value, reverse)
            default:
                return (a, b) => 0
        }
    },
}

const mutations = <MutationTree<State>>{
    /**
     *
     * @param state
     * @param cbreItems
     */
    setSensorItems(state, cbreItems: CbreItemInformation[]): void {
        state.items = cbreItems
    },
    /**
     *
     * @param state
     * @param loaded
     */
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    /**
     *
     * @param state
     * @param cbreItemId
     */
    deleteSensorItem(state, cbreItemId: string): void {
        state.items = state.items.filter((i: CbreItemInformation) => i.id !== cbreItemId)
    },
    /**
     *
     * @param state
     * @param sensorId
     */
    deleteSensorByItem(state, sensorId: string): void {
        state.items.forEach(i => {
            if (i.id === state.selectedReading?.id) {
                i.sensors = i.sensors.filter(s => s.id != sensorId)
            }
        })
    },
    /**
     *
     * @param state
     * @param cbreItem
     */
    createSensorItem(state, cbreItem: CbreItemInformation): void {
        state.items.push(cbreItem)
    },
    /**
     *
     * @param state
     * @param sensor
     */
    createSensorByItem(state, sensor: CbreSensorInformation): void {
        const labelExists = state.items.some((item) =>
            item.sensors.some((s) => s.label === sensor.label)
        )
        if (labelExists) throw new UserReportableError(`Sensor with label '${sensor.label}' already exists.`)

        state.items.forEach(i => {
            if (i.id === state.selectedReading?.id) {
                i.sensors.push(sensor)
            }
        })
    },
    /**
     *
     * @param state
     * @param sensor
     */
    updatePrimarySensorByItem(state, sensor: CbreSensorInformation): void {
        state.items.forEach(item => {
            if (item.id === state.selectedReading?.id) {
                const sensorIndex = item.sensors.findIndex((s) => {
                    return s.id === sensor.id
                })
                if (sensorIndex > -1) {
                    item.sensors[sensorIndex] = sensor
                }
            }
        })
    },
    /**
     *
     * @param state
     * @param cbreItem
     */
    updateSensorItem(state, cbreItem: CbreItemInformation): void {
        state.items = state.items.map((i: CbreItemInformation) => {
            return i.id === cbreItem.id
                ? cbreItem
                : i
        })
    },
    /**
     *
     * @param state
     * @param selectedReading
     */
    addSelectedReading(state, selectedReading: CbreItemInformation | null): void {
        state.selectedReading = selectedReading
    },
    /**
     *
     * @param state
     * @param payload
     */
    setDataTypes(state, payload: Datatype): void {
        state.dataTypes = payload
    },
    /**
     *
     * @param state
     * @param payload
     */
    setUnits(state, payload: UnitType): void {
        state.units = payload
    },
    /**
     *
     * @param state
     * @param sensorSiteId
     */
    setSensorSiteId(state, sensorSiteId: string | null): void {
        state.sensorSiteId = sensorSiteId
    },
    /**
     *
     * @param state
     * @param timelineBarData
     */
    setTimelineBarData(state, timelineBarData: State["timelineBarData"]) {
        state.timelineBarData = timelineBarData
    },
    /**
     *
     * @param state
     * @param timePoint
     */
    setTimePoint(state, timePoint: State["timePoint"]) {
        state.timePoint = timePoint
    },
}

const actions = <ActionTree<State, any>>{
    /**
     * @param actionState
     * @param site
     * @returns
     */
    async addDataImportSite({ commit }, site: Models.SiteModel) {
        const scSiteService = await ApiService.getSensorConfigSiteService()
        const siteInitial = new Models.SensorConfigSiteModel()
        siteInitial.setName(site.getName()!)
        const dataImportSite: Models.SensorConfigSiteModel =
            (await scSiteService.addSensorConfigSite(siteInitial))[0]
        return { dataImportSite, rollback: () => scSiteService.removeSensorConfigSite(dataImportSite) }
    },
    /**
     *
     * @param actionState
     */
    async fetchSensorDataTypesAndUnits({ commit }) {
        const response = await sensorConfigService.getSensorDataTypes()

        if (response.status == "success" && response.data) {
            const dataTypes = response.data.datatypes as Datatype
            const units = response.data.units as UnitType

            commit("setDataTypes", dataTypes)
            commit("setUnits", units)
        }
    },
    /**
     *
     * @param actionState
     * @param siteId
     */
    async fetchSensorInformation({ commit, state }, siteId: string) {
        const response = await sensorConfigService.getSensorInformation(siteId)

        if (response) {
            const records = response.items

            if (state.sensorSiteId) {
                const timePoint = state.timePoint.toISOString()

                for (const record of records) {
                    const res = await sensorConfigService.getReadingsAtTime(
                        state.sensorSiteId,
                        record.id,
                        timePoint
                    )
                    record.lastUpdated = res.lastUpdated

                    if (res.meterReadings?.length) {
                        for (const meter of res.meterReadings) {
                            const foundIndex = record.sensors.findIndex((r: any) => r.id == meter.meterId)
                            if (foundIndex > -1) {
                                record.sensors[foundIndex].value = meter.value
                            }
                        }
                    }
                }

                commit("setSensorItems", records)
                commit("setLoaded", true)
            }
        }
    },
    /**
     *
     * @param actionState
     * @param cbreItemId
     */
    async deleteSensorItem({ commit }, cbreItemId: string) {
        commit("deleteSensorItem", cbreItemId)
    },
    /**
     *
     * @param actionState
     * @param sensorId
     */
    async deleteSensorByItem({ commit }, sensorId: string) {
        const rawMeterService = await ApiService.getSensorConfigRawMeterService()
        const meter = await rawMeterService.getSensorConfigRawMeter(sensorId)
        await rawMeterService.removeSensorConfigRawMeter(meter)
        commit("deleteSensorByItem", sensorId)
    },
    /**
     *
     * @param actionState
     * @param meterPoint
     */
    async createSensorItem({ commit }, meterPoint: Models.SensorConfigMeterPointModel) {
        const item = {
            id: meterPoint.id,
            label: meterPoint.getName(),
            meterPointType: meterPoint.getMeterPointType(),
            sensors: [],
            subtype: meterPoint.getSubtype(),
            type: null
        } as CbreItemInformation
        commit("createSensorItem", item)
    },
    /**
     *
     * @param actionState
     * @param rawMeter
     */
    async createSensorByItem({ commit }, rawMeter: Models.SensorConfigRawMeterModel) {
        const rawMeterService = await ApiService.getSensorConfigRawMeterService()
        const [newRawMeter] = await rawMeterService.addSensorConfigRawMeter(rawMeter)

        const sensor = {
            id: newRawMeter.id,
            isPrimary: newRawMeter.getIsPrimary(),
            datatype: newRawMeter.getDataType(),
            label: newRawMeter.getSourceId()
        } as CbreSensorInformation

        commit("createSensorByItem", sensor)
    },
    /**
     *
     * @param actionState
     * @param data
     */
    async updatePrimarySensorByItem({ commit }, data: { sensorId: string, isPrimary: boolean }) {
        const rawMeterService = await ApiService.getSensorConfigRawMeterService()
        const meter = await rawMeterService.getSensorConfigRawMeter(data.sensorId) as Models.SensorConfigRawMeterModel
        meter.setIsPrimary(data.isPrimary)
        await rawMeterService.updateSensorConfigRawMeter(meter)

        const sensor: CbreSensorInformation = {
            id: meter.id,
            label: meter.getSourceId(),
            datatype: meter.getDataType(),
            isPrimary: meter.getIsPrimary() as boolean
        }

        commit("updatePrimarySensorByItem", sensor)
    },
    /**
     *
     * @param actionState
     * @param cbreItem
     */
    updateSensorItem({ commit }, cbreItem: CbreItemInformation) {
        commit("updateSensorItem", cbreItem)
    },
    /**
     *
     * @param actionState
     * @param clonedCbreItem
     */
    cloneSensorItem({ commit }, clonedCbreItem: CbreItemInformation) {
        commit("createSensorItem", clonedCbreItem)
    },
    /**
     *
     * @param actionState
     * @param cbreItem
     */
    setSelectedReading({ commit }, cbreItem: CbreItemInformation | null) {
        commit("addSelectedReading", cbreItem)
    },
    /**
     *
     * @param actionState
     * @param sensorSiteId
     */
    setSensorSiteId({ commit }, sensorSiteId: string | null) {
        commit("setSensorSiteId", sensorSiteId)
    },
    /**
     *
     * @param actionState
     * @param timelineBarData
     */
    updateTimelineBarData({ commit }, timelineBarData: State["timelineBarData"]) {
        commit("setTimelineBarData", timelineBarData)
    },
    /**
     *
     * @param actionState
     * @param timePoint
     */
    updateTimePoint({ commit }, timePoint: State["timePoint"]) {
        commit("setTimePoint", timePoint)
    }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const SensorModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default SensorModule
