import { NamedSimulationNode } from "@/Simulation"
import { SimulationResult } from "@/Simulation/helpers"
import { powerSimulatorService } from "@/core/services"
import { AnyNode } from "@/store/SiteViewState"
import { Interface, NodeType, ResilienceHandler } from "predict-performance-calculation"
import { SimulationData, TimeBasedData } from "../../store/simulation-view/types"
import { NodesDataFetcher } from "./NodesDataFetcher"
import { SimulationCallHelper } from "./SimulationCallHelper"

/**
 *
 */
export class AdviseProvisioning {
    /**
     *
     */
    static readonly provisioningCheckMonths = 6

    /**
     *
     */
    static readonly recommendAllProvisioningChanges = true

    /**
     *
     * @param provisionedSimulationData
     * @param nodesData
     * @param itemProvisioning
     * @param lockedProvisioning
     * @param recommendAllProvisioningChanges
     * @returns
     */
    static adviseForSimulation(provisionedSimulationData: SimulationData,
        nodesData: NamedSimulationNode[],
        itemProvisioning: Record<string, { count: number }>,
        lockedProvisioning: Set<string> = new Set(), recommendAllProvisioningChanges = this.recommendAllProvisioningChanges
    ) {
        const recommendations: Record<string, { from: number; to: number }> = {}

        const itemNodesData = Object.fromEntries(
            nodesData.filter(nd => nd.type == NodeType.Item).map(
                nd => [`${nd.type}/${nd.id}`, nd as Interface.Item]))

        const resilienceHandler = new ResilienceHandler()

        for (const [nodeRef, rawResult] of Object.entries(provisionedSimulationData).sort(([a], [b]) => a.localeCompare(b))) {
            if (lockedProvisioning.has(nodeRef) || !itemNodesData[nodeRef]) {
                continue
            }
            const provisioning = itemProvisioning[nodeRef] ??
                { count: 1 }
            const result = new SimulationResult(rawResult, nodeRef)
            const item = itemNodesData[nodeRef]
            const resilience = item.resilience ?? null
            const performanceData = item.performanceData ??
                item.deviceMode.performanceData
            const devicesRequired = resilienceHandler.getInstalledDevicesFor(
                resilience, performanceData, result.asLoad)
            if (recommendAllProvisioningChanges) {
                if (devicesRequired != provisioning.count) {
                    recommendations[nodeRef] =
                        { from: provisioning.count, to: devicesRequired }
                }
            } else {
                if (devicesRequired > provisioning.count) {
                    recommendations[nodeRef] =
                        { from: provisioning.count, to: devicesRequired }
                }
            }
        }

        return recommendations
    }

    /**
     *
     * @param timeBasedDataIn
     * @param nodes
     * @param nodesDataFetcher
     * @param callHelper
     * @param loadProvisioning
     * @param lockedProvisioning
     * @param recommendAllProvisioningChanges
     * @returns
     */
    static async adviseForSite(timeBasedDataIn: TimeBasedData,
        nodes: AnyNode[], nodesDataFetcher: NodesDataFetcher,
        callHelper: SimulationCallHelper,
        loadProvisioning: Record<string, number>,
        lockedProvisioning: Set<string> = new Set(), recommendAllProvisioningChanges = this.recommendAllProvisioningChanges
    ) {
        const weather = await callHelper.getWeather()

        const nonDesignConditions = {itLoad: loadProvisioning,
            weather}

        const designConditions = {
            provisioning: {...callHelper.getProvisioning()},
        }

        const nodesData =
            callHelper.getNodesData(await nodesDataFetcher.fetchCached(nodes))

        let recommendations: Record<string, { from: number, to: number }> = {}
        let lastRecommendationUid = JSON.stringify(recommendations)

        if(!nonDesignConditions.weather) {
            throw new Error("No weather conditions known")
        }
        for (let i = 0; i < 4; i++) {
            const timeBasedData = {
                ...timeBasedDataIn,
                itLoad: nonDesignConditions.itLoad,
                provisioning: designConditions.provisioning,
                weather: nonDesignConditions.weather,
            }

            const simulationData = await powerSimulatorService.simulate(
                nodesData, timeBasedData.weather, timeBasedData.itLoad,
                timeBasedData.provisioning)

            recommendations = {...recommendations, ...this.adviseForSimulation(
                simulationData, nodesData, designConditions.provisioning,
                lockedProvisioning, recommendAllProvisioningChanges)}

            const recommendationUid = JSON.stringify(recommendations)
            if (recommendationUid == lastRecommendationUid) {
                break
            }
            lastRecommendationUid = recommendationUid
            for (const [nodeRef, rec] of Object.entries(recommendations)) {
                designConditions.provisioning[nodeRef] = {
                    count: rec.to,
                    designCapacity: null,
                }
            }
        }
        return recommendations
    }
}
