/**
 *
 */
export enum NumberFormatMode {
    Currency = "currency",
    GenericUnit = "generic",
    KW = "kW",
    KWc = "kWc",
    KWh = "kWh",
    MonthTimestamp = "month-ts",
    Number = "number",
    Percent = "percent",
    PercentPremultiplied = "percent-premultiplied",
    Temperature = "temperature",
}
