import { ExternalVariableHumidity } from "@/core/enums"
import { LegacyPerfSplitTemperatureType } from "@/core/enums/LegacyPerfSplitTemperatureType"
import { Interface } from "predict-performance-calculation"

/**
 * This class is for performing conversion work for strict compatibility
 */
export class LegacyFixes {
    /**
     *
     */
    private static temperatureTypes: Record<LegacyPerfSplitTemperatureType, Interface.TemperatureType> = {
        [LegacyPerfSplitTemperatureType.DryBulb]: Interface.TemperatureType.DryBulb,
        [LegacyPerfSplitTemperatureType.WetBulb]: Interface.TemperatureType.WetBulb,
    }

    /**
     *
     * @param legacyValue
     * @returns
     */
    public static environmentConditionFromLegacy(
        legacyValue: Interface.TemperatureType | LegacyPerfSplitTemperatureType | ExternalVariableHumidity
    ): Interface.TemperatureType | ExternalVariableHumidity {
        return this.temperatureTypes[legacyValue] || legacyValue
    }

    /**
     *
     * @param legacyValue
     * @returns
     */
    public static temperatureTypeFromLegacy(
        legacyValue: Interface.TemperatureType | LegacyPerfSplitTemperatureType
    ) {
        return this.temperatureTypes[legacyValue] ?? legacyValue
    }
}