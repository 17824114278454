
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { PerformanceParameterBase } from "./PerformanceParameterBase"

export class PolynomialParameter extends PerformanceParameterBase<Models.PolynomialModel> {


    async fetch() {

        const polynomialValues: { power: number, coefficient: number }[] = []

        for await (const values of this.performance.getPolynomialValues()) {
            polynomialValues.push({ power: values.getPower()!, coefficient: values.getCoefficient()! })
        }

        return {
            capacity: this.performance.getCapacity()!,
            type: "polynomial",
            polynomialValues
        } as Interface.PolynomialPerformanceData
    }

}