/**
 * Converts word to case like "protected status" => "Protected Status"
 *
 * @param word
 * @returns
 */
 const capitalizeFirstLetter = (word: string | null) => {
    return word ? word.replace(/\b(\w)/g, (_, $1) => $1.toUpperCase()) : "";
}

export {
    capitalizeFirstLetter
}