import { SimulationNode } from "@/Simulation/interfaces"
import { SimulationData } from "@/store/simulation-view/types"
import { cacheWrap, delayBatch } from "@ekko/predict-utilities"
import { Interface } from "predict-performance-calculation"
import { Endpoint } from "../helpers"
import { ProvisioningState } from "../interfaces/ProvisioningState"
import { Temperature } from "../interfaces/Temperature"
import { AnyJSONRPC } from "./AnyJSONRPC"

interface SimulationPayload {
    nodes: SimulationNode[],
    climate: Interface.TMYData | Temperature,
    itLoads: Record<string, number>,
    provisioningStates: { [ref: string]: ProvisioningState }
}

/**
 * JSONRPC service for calling the power-simulator service
 */
class PowerSimulatorService extends AnyJSONRPC {
    protected endpoint: Endpoint = "power-simulator"

    /**
     *
     * @param nodes
     * @param climate
     * @param itLoads
     * @param provisioningStates
     * @returns
     */
    @cacheWrap(300)
    async simulate(
        nodes: SimulationNode[],
        climate: Interface.TMYData | Temperature,
        itLoads: Record<string, number>,
        provisioningStates: { [ref: string]: ProvisioningState }
    ): Promise<SimulationData> {
        return this.callMethod(
            "simulate",
            {
               nodes,
               climate,
               itLoads,
               provisioningStates
            }
        )
    }

    /**
     *
     * @param nodes
     * @param climate
     * @param itLoads
     * @param provisioningStates
     * @returns
     */
    @cacheWrap(300)
    async simulateBatchPossible(
        nodes: SimulationNode[],
        climate: Interface.TMYData | Temperature,
        itLoads: Record<string, number>,
        provisioningStates: { [ref: string]: ProvisioningState }
    ) {
        const [result] = await this.simulateBatch(
            {
               nodes,
               climate,
               itLoads,
               provisioningStates
            }
        )
        return result
    }

    /**
     *
     * @param payloads
     * @returns
     */
    @delayBatch(50, 24)
    async simulateBatch(...payloads: SimulationPayload[]): Promise<SimulationData[]> {
        let previousNodesSignature: string | undefined
        return this.callMethod(
            "simulate.batch",
            payloads.map(payload => {
                const nodesSignature = JSON.stringify(payload.nodes)
                if(previousNodesSignature === nodesSignature) {
                    return {...payload, nodes: undefined}
                } else {
                    previousNodesSignature = nodesSignature
                    return payload
                }
            }),
            (body) => body.length > 512 * 1024 ? { "Content-Encoding": "deflate" } : {}
        )
    }
}

export const powerSimulatorService = new PowerSimulatorService()
