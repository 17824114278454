import { ActionTree, GetterTree, MutationTree } from "vuex"

export const defaultPageSize = 10

class State {
  page = 1;
  pageSize = defaultPageSize;
}

const getters = <GetterTree<State, any>>{
  getPageSize(state): number {
    return state.pageSize
  },
  getPage(state): number {
    return state.page
  }
}

const mutations = <MutationTree<State>>{
  setPageSize(state, pageSize: number): void {
    state.pageSize = pageSize
  },
  setPage(state, page: number): void {
    state.page = page
  },
}

const actions = <ActionTree<State, any>>{
  changePage({ commit }, page: number) {
    commit("setPage", page)
  },
  changePageSize({ commit }, pageSize: number) {
    commit("setPageSize", pageSize)
  }
}

export const PaginationModule = () => {
  return {
    state: new State(),
    getters: getters,
    mutations: mutations,
    actions: actions,
  }
}