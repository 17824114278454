import * as Yup from "yup"

export class Fields {
    static requiredNumber(message = "Field is required") {
        return Yup
            .number()
            .nullable()
            .transform((v, o) => o === '' ? null : v)
            .typeError(message)
            .required(message)
    }

    static optionalNumber() {
        return Yup
            .number()
            .nullable()
            .transform((v, o) => o === '' ? null : v)
            .optional()
    }

    static requiredBoolean(message = "Field is required") {
        return Yup
            .boolean()
            .nullable()
            .transform((v, o) => !o ? false : true)
            .required(message)
    }

    static requiredString(message = "Field is required") {
        return Yup
            .string()
            .nullable()
            .required(message)
    }

    static requiredNumberWhen(conditionalField: string, conditionalValue: any, message  = "Field is required") {
        return Yup.number()
            .nullable()
            .optional()
            .transform((v, o) => o === '' ? null : v)
            .when(conditionalField, {
                is: conditionalValue,
                then: Fields.requiredNumber(message)
            })
    }

    static requiredStringOrNumber() {
        return Yup
            .mixed()
            .test(
                'requiredStringOrNumber',
                'Field is required',
                (value, testContext) => !!Fields.toNumberOrNull(value)
            )
    }

    private static toNumberOrNull(value: any) {
        if (!isNaN(value) && !isNaN(parseFloat(value))) {
          return Number(value)
        }
        return null
      }
}