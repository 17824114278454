import { InitialiseState } from "./InitialiseState"

/**
 *
 */
export class InitialiseInformation {
    /**
     *
     */
    private debugTimeouts: Array<ReturnType<typeof globalThis.setTimeout>> = []

    /**
     *
     */
    private debugMessagesSent = false

    /**
     *
     */
    private finished = false

    /**
     *
     */
    private started = new Date()

    /**
     *
     */
    private dumpState() {
        this.debugMessagesSent = true
        console.debug(
            `Load state after ${(new Date().valueOf() - this.started.valueOf()) / 1000}s:\n`,
            Object.entries(this.states).map(([n, s]) => `${n}: ${s.loaded}`).join("\n")
        )
    }

    /**
     *
     */
    private finish() {
        this.finished = true
        for (const timeout of this.debugTimeouts) {
            clearTimeout(timeout)
        }
        if (this.debugMessagesSent) {
            console.debug("Finished")
            this.dumpState()
        }
    }

    /**
     *
     */
    get loadedCount() {
        return this.summary?.filter(s => s.loaded).length
    }

    /**
     *
     */
    get loading() {
        return !this.finished
    }

    /**
     *
     */
    get summary() {
        return Object.entries(this.states).map(([name, v]) => ({ name, loaded: v.loaded }))
    }

    /**
     *
     */
    get total() {
        return this.summary?.length
    }

    /**
     *
     * @param states
     */
    constructor(public states: Record<string, InitialiseState>) {
    }

    /**
     *
     */
    init() {
        this.debugTimeouts = [
            setTimeout(() => this.dumpState, 30000),
            setTimeout(() => this.dumpState, 60000),
            setTimeout(() => this.dumpState, 90000),
        ]
    }

    /**
     *
     * @param state
     */
    update(state: InitialiseState) {
        if (this.states[state.name]) {
            this.states[state.name].loaded = state.loaded

            if (Object.values(this.states).every(s => s.loaded)) {
                this.finish()
            }
        }
    }
}
