export enum PerformanceType {
  Grid = 'Grid',
  GridList = 'Grid List',
  Polynomial = "Polynomial",
  PumpCondensed = "PumpCondensed",
  Transformer = "Transformer",
  ReferencePolynomial = "ReferencePolynomial",
  Switchgear = "Switchgear",
  CRAC = "CRAC",
  UPS = "UPS",
  PrimaryPump = "PrimaryPump",
  Cabling = "Cabling",
  CoolingTower = "CoolingTower",
  PDU = "PDU",
  Chiller = "Chiller",
  DryCooler = "DryCooler",
}