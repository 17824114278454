/**
*
*/
export enum PerformanceAnalyticsGraphType {
    OVERVIEW = "Overview",
    POWER = "Power",
    ENERGY = "Energy",
    PUE = "PUE",
    CO2 = "CO2",
    COST = "Cost",
    CAPACITY = "Capacity",
}