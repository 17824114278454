import { EnergyHistoryClient, ReadingType } from "@/History"
import { NodesetHelper, NumberFormat, UnbatchEventRecursive } from "@/core/helpers"
import { NodeReferenceHelper } from "@/core/helpers/NodeReferenceHelper"
import type { Reading, UnbatchEvents } from "@/core/interfaces"
import type { Models } from "@ekko/predict-client-api"
import { NodeType } from "predict-performance-calculation"
import { EnergyBaseClient } from "../../Simulation/clients/EnergyBaseClient"
import { AllocationOuputNode } from "@/Simulation/clients/AllocationOuputNode"
import type { NodeDynamicCostsByType, SimulationSiteConfiguration, SimulationSiteConfigurationUndated, SiteInfo } from "../../Simulation/interfaces"
import { SiteNodes } from "../../Simulation/siteParameter"
import { TimeBasedParameter } from "../../Simulation/timeBased"

export class ActualVsExpectedClient extends EnergyBaseClient {
    /**
     * Corrects in-place any readings marked as POWER to POWER_SUPPLY
     *
     * @param readings
     */
    private correctBadReadings(readings: Reading<Date>[]) {
        let warnedBadMeterMappingType = false
        for (const reading of readings) {
            if (reading.type === ReadingType.POWER) {
                if (!warnedBadMeterMappingType) {
                    console.warn(`Site has misconfigured meter mapping, assuming power supply`)
                    warnedBadMeterMappingType = true
                }
                reading.type = ReadingType.POWER_SUPPLY
            }
        }
    }

    /**
     * Provides baseline configuration simulation configuration
     * @param site
     * @returns
     */
    private async getBaselineSimulationConfig(site: Models.SiteModel) {
        const baselineConfig =
            (await site.getBaselineConfiguration()) ??
            (await site.getFirstConfiguration())

        const nodeModels = await SiteNodes.forSiteConfig(baselineConfig)
        const nodes = await TimeBasedParameter.buildNodesData(nodeModels)
        return { nodes }
    }

    /**
     *
     * @param site
     * @param nodesets
     * @param siteInfo
     * @param unbatchCallbacks
     * @returns
     */
    async PUESimulate(site: Models.SiteModel,
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        unbatchCallbacks: UnbatchEvents) {
        const baselineConfiguration = await this.getBaselineSimulationConfig(site)
        const simulationWithBaseline = await this.simulateWithBaseline(
            siteInfo, nodesets, baselineConfiguration, unbatchCallbacks)

        const historyClient = new EnergyHistoryClient()

        let siteReadings: Reading<Date>[]
        try {
            siteReadings = await historyClient.fetch(site.id)
        } catch(e) {
            console.error(e)
            siteReadings = []
        }

        const sliceHours = 24

        this.correctBadReadings(siteReadings)

        const sumItLoad = await this.getSumItLoadReadings(nodesets)

        const output = new Map<string, { actuals: number, projected: number, baseline?: number, total?: number }>()

        for (const [point, values] of simulationWithBaseline.entries()) {
            const projected = NumberFormat.to2dp((values.fixed + values.variable + values.it) / values.it) ?? 0
            const pointStr = new Date(point).toDateString()
            const totalIncomer = siteReadings.find(r => r.timestamp.toDateString() == pointStr && r.type == ReadingType.POWER_SUPPLY)?.value ?? 0
            const totalEnergy = totalIncomer * sliceHours
            const currentSumItLoad = sumItLoad.get(pointStr)
            const actuals = currentSumItLoad ? NumberFormat.to2dp(totalEnergy / currentSumItLoad) : 0

            if (values.baselineIT) {
                const baseline = NumberFormat.to2dp((values.baseline ?? 0) / values.baselineIT)
                const pointData = {
                    actuals,
                    projected,
                    baseline,
                }

                output.set(point, pointData)
            } else {
                const pointData = {
                    actuals,
                    projected,
                }

                output.set(point, pointData)
            }
        }
        return output
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const nodes = NodesetHelper.nodes(nodesets)
        if (this.isFilteringITNodes(nodes)) {
            const result = await this.simulateEnergyResult(true, nodesets, siteInfo, unbatchCallbacks)
            return this.summariseByPoint(this.addAllocations(result, nodes, deviceSelected, AllocationOuputNode.Item))
        } else {
            const result = await this.simulateEnergyResult(false, nodesets, siteInfo, unbatchCallbacks)
            return this.summariseByPoint(this.buildResultTree(result, nodes, deviceSelected))
        }
    }

    /**
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     */
    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const nodes = NodesetHelper.nodes(nodesets)
        const result = await this.simulateEnergyResult(true, nodesets,
            siteInfo, unbatchCallbacks)

        return this.summariseByNode(this.addAllocations(result, nodes, deviceSelected))
    }

    /**
     *
     * @param siteInfo
     * @param nodesets
     * @param baseline
     * @param unbatchCallbacks
     */
    async simulateWithBaseline(siteInfo: SiteInfo,
        nodesets: SimulationSiteConfiguration[],
        baseline: SimulationSiteConfigurationUndated, unbatchCallbacks?: UnbatchEvents
    ) {
        const [totalSize, [implementationSimulateCallbacks, baselineSimulateCallbacks]] =
            UnbatchEventRecursive.build(unbatchCallbacks, 1, 1)

        const simulationData = await this.simulate(nodesets, siteInfo,
            null, implementationSimulateCallbacks)
        const baselineSimulationData = await this.simulate(
            [{...baseline, start: new Date(this.period.start), finish: null}],
            siteInfo, null, baselineSimulateCallbacks)

        const output = new Map<string, { fixed: number, variable: number, it: number, baseline?: number, baselineIT?: number }>()

        for (const [point, values] of simulationData.entries()) {
            const baselinePointValues = baselineSimulationData.get(point)
            if (baselinePointValues) {
                output.set(point, {
                    ...values,
                    baseline: (baselinePointValues.fixed + baselinePointValues.variable + baselinePointValues.it),
                    baselineIT: baselinePointValues.it,
                })
            } else {
                output.set(point, values)
            }
        }
        unbatchCallbacks?.progress?.(totalSize)
        return output
    }

    /**
     * Gets the IT load in kWh grouped by day
     *
     * @param nodesets
     * @returns
     */
    private async getSumItLoadReadings(nodesets: SimulationSiteConfiguration[]): Promise<Map<string, number>> {
        const sumItReadings = new Map<string, number>()
        const sliceHours = 24
        for (const nodeset of nodesets) {
            const historyClient = new EnergyHistoryClient()
            const overlap = historyClient.overlappingPeriod(nodeset)
            if (!overlap) continue
            historyClient.period = overlap

            const itNodes = nodeset.nodes.filter(n => n.type == NodeType.ITNode)

            for (const itNode of itNodes) {
                const nodeReference = NodeReferenceHelper.getNodeReference(itNode)
                const readings = await historyClient.getNodeReadings(nodeReference)

                for (const reading of readings) {
                    if (reading.timestamp < nodeset.start) continue
                    if (nodeset.finish && reading.timestamp > nodeset.finish) continue

                    const dateStr = reading.timestamp.toDateString()
                    const previousReading = sumItReadings.get(dateStr)
                    if (previousReading) {
                        sumItReadings.set(dateStr, previousReading + reading.value * sliceHours)
                    } else {
                        sumItReadings.set(dateStr, reading.value * sliceHours)
                    }
                }
            }
        }

        return sumItReadings
    }

}