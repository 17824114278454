/**
 *
 */
type DevelopmentFeatureType =
    | "ProvAdvice" // Offer provisioning advice
    | "ProvLock" // Support locking provisioning
    | "InjectAvE" // Inject fake actual-vs-expected filter data

/**
 *
 */
export class DevelopmentFeature {
    /**
     *
     */
    static get enabled() {
        return !!process.env.VUE_APP_ENABLE_DEVELOPMENT_FUNCTION_TOGGLE
    }
    /**
     *
     * @param name
     * @returns
     */
    static has(name: DevelopmentFeatureType) {
        return this.enabled && !!localStorage.getItem(`develFeat${name}`)
    }
}