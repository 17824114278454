import { AuditLog, Identification } from "../interfaces"

/**
 *
 */
export class AuditLogHelper {
    /**
     *
     * @param reference
     * @param log
     * @returns
     */
    static getIdentificationName(reference: string, log: AuditLog) {
        const identificationsRefs = log.identificationByReference
        const identifications = this.getIdentificationsByReference(reference, identificationsRefs)

        if (!identifications?.length) {
            return null
        }

        const [dateString] = log.timeKey.split("#")
        // Sort the identifications by date in ascending orders
        identifications.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

        const closest = this.getClosestIdentification(new Date(dateString), identifications)

        return closest?.name ?? null
    }

    /**
     *
     * @param objectKey
     * @returns
     */
    static getObjectKeyReference(objectKey: string) {
        const [type, idWithDate] = objectKey.split("/")
        const [id] = idWithDate.split("+")

        return `${type}/${id}`
    }

    /**
     *
     * @param reference
     * @param data
     * @returns
     */
    static getIdentificationsByReference(reference: string, data: Record<string, Identification[]>): Identification[] | null {
        return data[reference] ?? null
    }

    /**
     *
     * @param date
     * @param identifications
     * @returns
     */
    static getClosestIdentification(date: Date, identifications: Identification[]) {
        const oneHour = 60 * 60 * 1000

        // Latest (most recent) identification with a timestamp an hour ago or more
        const latestIdentification = identifications.findLast(identification => (date.getTime() - new Date(identification.date).getTime() >= oneHour))

        if (latestIdentification) {
            return latestIdentification
        } else if (identifications?.length > 0) {
            return identifications[0]
        } else {
            return null
        }
    }
}