import ApiService from "@/core/services/api.service"
import { Model } from "@ekko/client-api-core"
import { ActionTree, MutationTree } from "vuex"


class State {
    /**
     *
     */
    cache = new Map<string, Model | null>()
}

const mutations: MutationTree<State> = {
    /**
     * @param state
     */
    replaceCache(state) {
        state.cache = new Map<string, Model | null>()
    },
    /**
     * @param state
     * @param node
     */
    setCache(state, node: Model) {
        state.cache.set(`${node.type}/${node.id}`, node)
    },
}

const actions: ActionTree<State, any> = {
    /**
     *
     * @param actionState
     * @param type
     * @param id
     */
    async cacheObject({commit}, objectSpec: {type: string, id: string}) {
        if(objectSpec instanceof Model) {
            commit("setCache", objectSpec)
        } else {
            const prefix = objectSpec.type.replace(/^(\w)/, (a, $1) => $1.toUpperCase())
            const service = await ApiService.getService(prefix + "Service")
            const domain = (service as any).createDomain('Get' + prefix, objectSpec.id)
            const object = await domain.getData()
            commit("setCache", object)
        }
    },
    /**
     *
     * @param actionState
     */
    clearCache({commit}) {
        commit("replaceCache")
    },
}

const LookupCacheModule = {
    namespaced: true,
    state: {
        ...new State(),
    },
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
}

export default LookupCacheModule