import { FormatterFormatOptions } from "@/core/interfaces"
import { Formatter } from "../Formatter"
import { NumberFormatSuffix } from "../NumberFormatSuffix"

/**
 *
 */
export class PercentPremultiplied extends Formatter {
    protected defaultMaxDigits = 1

    suffix = NumberFormatSuffix.percent

    format(value: number, options?: FormatterFormatOptions): string {
        if(options?.withoutUnits) {
            const formatter = new Intl.NumberFormat(undefined, {
                maximumFractionDigits: options?.digits ?? this.defaultMaxDigits,
                minimumFractionDigits: options?.digits ?? 1,
            })
            return formatter.format(+value)
        } else {
            const formatter = new Intl.NumberFormat(undefined, {
                maximumFractionDigits: options?.digits ?? this.defaultMaxDigits,
                minimumFractionDigits: options?.digits ?? 1,
                style: "unit",
                unit: "percent",
            })
            return formatter.format(+value)
        }
    }
}