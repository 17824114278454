import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { Base } from "./Base"
import { NRef } from "@/store/SiteViewState"

/**
 *
 */
export class OtherNode extends Base<Models.OtherNodeModel> {
    /**
     *
     * @returns
     */
    private getService() {
        return ApiService.getNamedService<Services.OtherNodeService>("OtherNodeService")
    }
    async add(): Promise<NRef<Models.OtherNodeModel>> {
        const service = await this.getService()
        const [nodeOut] = await service.addOtherNode(this.node)
        this.node = nodeOut
        await this.connect()
        return nodeOut
    }
    async connect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.addOtherNodes([this.node])
    }
    async disconnect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.removeOtherNodes([this.node])
    }
    async remove(): Promise<any> {
        const service = await this.getService()
        await service.removeOtherNode(this.node)
        return this.disconnect()
    }
    async update(): Promise<any> {
        const service = await this.getService()
        await service.updateOtherNode(this.node)
    }
}