import {
    ActualExpected,
    CalibrationStatus,
    CombinedCapacity,
    Efficiency,
    MeteringStatus,
    ProvisionalCapacity,
    SimulationOutput,
    SimulationOverTimeData,
    UtilizedCapacity,
} from "@/Simulation/interfaces/filters"
import { ActionTree, GetterTree, MutationTree } from "vuex"

export enum SimulationMode {
    QUICK = "quick",
    LIVE = "live",
    ADV = "adv"
}

export enum SimulationSubNav {
    INFO = "information",
    GRAPHS = "graphs",
    FILTER = "filter",
    SEARCH = "search"
}

export enum SimulationNodeType {
    CoolingSplitterCombiner = "coolingSplitterCombiner",
    ItLoad = "itLoad",
    NodeCooling = "nodeCooling",
    NodePower = "nodePower",
    PowerSplitterCombiner = "powerSplitterCombiner"
}

export interface SimulationPanel {
    mode: SimulationMode | null,
    subNav: SimulationSubNav,
    nodeType: SimulationNodeType | null
}

export class SimulationViewState {
    /**
     *
     */
    activePanel: SimulationPanel = {
        mode: null,
        subNav: SimulationSubNav.INFO,
        nodeType: null
    }
    /**
     *
     */
    filterCalculation: FilterCalculation | null = null
    /**
     *
     */
    selectingForRightPanel = false
    /**
     *
     */
    multiSelection = false
    /**
     *
     */
    deleteMultiSelectedNode: string | null = null
    /**
     *
     */
    unselectNode: string | null = null
}

export type FilterCalculation =
    | ActualExpected
    | CombinedCapacity
    | Efficiency
    | ProvisionalCapacity
    | UtilizedCapacity
    | MeteringStatus
    | SimulationOverTimeData
    | CalibrationStatus
    | SimulationOutput

export const SimulationViewStateGetters: GetterTree<SimulationViewState, any> = {
    /**
     * @param state
     */
    getMode(state): SimulationMode | null {
        return state.activePanel.mode
    },
    /**
     * @param state
     */
     isQuickMode(state): boolean {
        return state.activePanel.mode == SimulationMode.QUICK
    },
    /**
     * @param state
     */
    getSubNav(state): SimulationSubNav {
        return state.activePanel.subNav
    },
    /**
     *
     * @param state
     */
    nodeType(state): SimulationNodeType | null {
        return state.activePanel.nodeType
    }
}

export const SimulationViewStateMutations: MutationTree<SimulationViewState> = {
    /**
     *
     * @param state
     */
    setImpliedNav(state) {
        if (!state.multiSelection && !state.selectingForRightPanel) {
            state.activePanel.subNav = SimulationSubNav.INFO
        }
    },
    /**
     *
     * @param state
     * @param selectingForRightPanel
     */
    setSelectingForRightPanel(state, selectingForRightPanel: boolean) {
        state.selectingForRightPanel = selectingForRightPanel
    },
    /**
     * @param state
     * @param subNav
     */
    setSubNav(state, subNav: SimulationSubNav) {
        state.activePanel.subNav = subNav
        state.activePanel.nodeType = null
    },
    /**
     * @param state
     * @param subNav
     */
    setNodeType(state, nodeType: SimulationNodeType | null) {
        if (state.multiSelection) {
            state.activePanel.nodeType = null
            return
        }

        if (nodeType && state.selectingForRightPanel) {
            state.selectingForRightPanel = false
        }

        state.activePanel.nodeType = nodeType
    },
    /**
     * Sets filter calculation
     * @param state
     * @param calculation
     */
    setFilterCalculation(state, calculation: FilterCalculation | null) {
        state.filterCalculation = calculation
    },
    /**
     *
     * @param state
     * @param multiSelection
     */
    setMultiSelection(state, multiSelection: SimulationViewState["multiSelection"]) {
        state.multiSelection = multiSelection
    },
    /**
     *
     * @param state
     * @param node
     */
    deleteMultiSelectedNode(state, node: SimulationViewState["deleteMultiSelectedNode"]) {
        state.deleteMultiSelectedNode = node
    },
    /**
     *
     * @param actionState
     * @param node
     */
    unselectNode(state, node: SimulationViewState["unselectNode"]) {
        state.unselectNode = node
    },
}

export const SimulationViewStateActions: ActionTree<SimulationViewState, any> = {
    /**
     *
     * @param actionState
     */
    startSelectingForRightPanel({commit}) {
        commit("setSelectingForRightPanel", true)
    },
    /**
     *
     * @param actionState
     * @param multiSelection
     */
    multiSelection({commit}, multiSelection: SimulationViewState["multiSelection"]) {
        commit("setMultiSelection", multiSelection)
    },
    /**
     *
     * @param actionState
     * @param node
     */
    deleteMultiSelectedNode({commit}, node: SimulationViewState["deleteMultiSelectedNode"]) {
        commit("deleteMultiSelectedNode", node)
    },
    /**
     *
     * @param actionState
     * @param node
     */
    unselectNode({commit}, node: SimulationViewState["unselectNode"]) {
        commit("unselectNode", node)
    },
    /**
     *
     * @param actionState
     * @param subNav
     */
    updateSubNav({ commit }, subNav: SimulationSubNav) {
        commit("setSubNav", subNav)
        commit("setSelectedNode", null)
        commit("setNodeType", null)
    },
}