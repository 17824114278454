import ApiService from "@/core/services/api.service"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import {
  actions as tableActions,
  getters as tableGetters,
  mutations as tableMutations,
} from "../it-load-profiles/table"
import { ITLoadProfileArchiveState } from "./types"

class State implements Partial<ITLoadProfileArchiveState> {
  itLoadProfiles: Models.ITLoadProfileModel[] = []
  loaded = false
}

const getters = <GetterTree<ITLoadProfileArchiveState, any>>{

}

const mutations = <MutationTree<State>>{
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  unarchiveITLoadProfile(state, itLoadProfile: Models.ITLoadProfileModel): void {
    state.itLoadProfiles = state.itLoadProfiles.filter(
      d => (d as any).id !== (itLoadProfile as any).id
    )
  },
  archiveITLoadProfile(state, itLoadProfile: Models.ITLoadProfileModel): void {
    state.itLoadProfiles.push(itLoadProfile)
  },
}

const actions = <ActionTree<State, any>>{
  async fetchITLoadProfiles({ commit }) {
    const profileService = await ApiService.getITLoadProfileService()
    const filter = { attribute: "defunct", op: "equal" as const, value: true }
    const profilesGenerator = profileService.getAllITLoadProfiles(filter)
    const itLoadProfiles: Models.ITLoadProfileModel[] = []
    for await (const profile of profilesGenerator) {
      itLoadProfiles.push(profile)
    }

    commit("setLoaded", true)
    commit("setITLoadProfiles", itLoadProfiles)
  },
  unarchiveITLoadProfile({ commit }, itLoadProfile: Models.ITLoadProfileModel) {
    commit("unarchiveITLoadProfile", itLoadProfile)
    commit('itLoadProfile/createITLoadProfile', itLoadProfile, { root: true })
  },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const ITLoadProfileArchiveModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...tableGetters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...tableMutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...tableActions,
    ...actions
  },
}

export default ITLoadProfileArchiveModule
