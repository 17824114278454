import { Reading } from "@/core/interfaces"
import { SimPeriod, Period } from "../interfaces"
import { DateHelper } from "@/core/helpers"

/**
 *
 */
export class PeriodHelper {
    /**
     * Returns the reading closest to that point, assuming they're ordered
     *
     * @param readings
     * @param timePoint
     * @param min
     * @param max
     * @returns
     */
    static closestReading(readings: Reading<Date>[], timePoint: Date, min: Date | null = null, max: Date | null = null) {
        let previousReading: Reading<Date> | undefined
        for(const reading of readings) {
            if((min && reading.timestamp < min) || (max && reading.timestamp >= max)) {
                // Skip
            } else if(reading.timestamp < timePoint) {
                // This ends up as the last point before the time
                previousReading = reading
            } else {
                // "reading" is now the first reading at or after the point
                if(
                    previousReading &&
                    (
                        timePoint.valueOf() - previousReading.timestamp.valueOf() <
                            reading.timestamp.valueOf() - timePoint.valueOf()
                    )
                 ) {
                    return previousReading
                } else {
                    return reading
                }
            }
        }

        // This means that all readings are before the time point, so this is the last one.
        return previousReading
    }

    /**
     * Gets day
     * @param timestamp
     */
    static getDay(timestamp: number) {
        return DateHelper.fullYearMonthOnly(new Date(timestamp))
    }

    /**
     *
     * @param period
     * @param start
     * @param finish
     * @returns
     */
    static overlap(period: Period, start: Readonly<Date>, finish: Readonly<Date> | null): {start: Readonly<Date>, finish: Readonly<Date>} | null {
        const periodStart = new Date(period.start)
        if(finish && periodStart > finish) {
            return null
        }
        const periodFinish = new Date(periodStart)
        periodFinish.setUTCMonth(periodFinish.getUTCMonth() + period.months)
        periodFinish.setUTCHours(-1, 0, 0, 0) // Move to previous day
        if(periodFinish < start) {
            return null
        }
        const overlapStart = periodStart > start ? periodStart :
            new Date(start as Date)
        const overlapFinish = (finish && periodFinish > finish) ? finish : periodFinish
        overlapStart.setUTCDate(1)
        overlapStart.setUTCHours(0, 0, 0, 0)
        return {start: overlapStart, finish: overlapFinish}
    }

    /**
     * Returns a period which encompasses the overlap of period and
     * start-finish with the smallest number of months that can be used.
     *
     * @param period
     * @param start
     * @param finish
     * @returns A period, unless there's no overlap, in which case null.
     */
    static subperiod(period: SimPeriod, start: Readonly<Date>, finish: Readonly<Date> | null): SimPeriod | null {
        const overlap = this.overlap(period, start, finish)
        if(!overlap) {
            return null
        }
        const months = overlap.finish.getUTCFullYear() * 12 + overlap.finish.getUTCMonth() -
            overlap.start.getUTCFullYear() * 12 - overlap.start.getUTCMonth()
            + 1
        return {
            start: overlap.start.toISOString(),
            months,
            oneDayPerMonth: period.oneDayPerMonth,
        }
    }
}