import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/app",
    name: "App",
    meta: { requiresAuth: true },
    component: () =>
      import("../views/App.vue"),
    children: [
      {
        path: "editView/:id",
        name: "AppEditView",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/App/EditView.vue"),
      },
      {
        path: "simulationView/:id",
        name: "AppSimulationView",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/App/SimulationView.vue"),
      },
      {
        path: "dataImportView/:id",
        name: "AppDataImportView",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/App/DataImportView.vue"),
      },
      {
        path: "siteAccess",
        name: "AppSiteAccess",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/App/SiteAccessView.vue"),
      },
    ]
  },
  {
    path: "/authcode",
    name: "AuthCodeView",
    component: () =>
      import("../views/AuthCodeView.vue"),

  },
  {
    path: "/invitation/:id",
    name: "InvitationView",
    meta: { requiresAuth: true },
    component: () =>
      import("../views/InvitationView.vue"),

  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
