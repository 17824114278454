import { AnyPerformanceData } from "@/Simulation/interfaces"

/**
 *
 */
export abstract class PerformanceParameterBase<T> {
    /**
     *
     * @param performance
     */
    constructor(protected performance: T) {
    }

    /**
     * Retrieves performance data parameter details
     */
    abstract fetch(): Promise<AnyPerformanceData>
}