import { AnyNode } from "@/store/SiteViewState"
import { TimeBasedData } from "@/store/simulation-view/types"
import { ProvisioningState } from "predict-performance-calculation/dist/Interface"
import { SimulationCallHelper } from "./SimulationCallHelper"
import { NamedSimulationNode } from "../interfaces"
import { TimeBasedParameter } from "../timeBased"

/**
 *
 */
export class PointSimulationCallHelper extends SimulationCallHelper {
    /**
     *
     * @param nodes
     * @param timeBasedData
     * @param timeBasedParameter
     */
    constructor(nodes: AnyNode[], private timeBasedData: TimeBasedData,
        private timeBasedParameter: TimeBasedParameter | null
    ) {
        super(nodes)
    }

    getItLoad(): Record<string, number> | Promise<Record<string, number>> {
        return this.timeBasedData.itLoad
    }

    getNodesData(nodesData: NamedSimulationNode[]): NamedSimulationNode[] {
        return nodesData
    }

    async getProvisionedItLoad(): Promise<Record<string, number>> {
        return this.timeBasedParameter?.getCurrentFullStaticLoad() ??
            this.timeBasedData.itProvisioning
    }

    getProvisioning(): Record<string, ProvisioningState> {
        return this.timeBasedData.provisioning
    }

    getWeather() {
        return this.timeBasedData.weather
    }
}