import { ReadingType } from "@/History/enums"
import { ResourceType } from "@/core/enums"
import { NodeReferenceHelper } from "@/core/helpers"
import { Reading } from "@/core/interfaces"
import { AnyNode } from "@/store/SiteViewState"
import { NodeReadings, SimulationData, TimeBasedData } from "@/store/simulation-view/types"
import { ConnectionType, DeviceModelType, Factory, Interface, LoadCalculation, NodeType, ResilienceHandler } from "predict-performance-calculation"
import { NamedSimulationNode, QuickModeProps } from "../interfaces"
import { NodeReference } from "../interfaces/NodeReference"
import {
    ActualExpected,
    CombinedCapacity,
    Efficiency,
    MeteringStatus,
    ProvisionalCapacity,
    UtilizedCapacity,
} from "../interfaces/filters"
import { ReadingsHelper } from "./ReadingsHelper"

/**
 * Filters Calculation Helper class
 */
export class FiltersCalculationHelper {
    /**
     * A temperature which is "as high as you could get", for peak draw
     * calculations.
     */
    static readonly limitTemperature = 100

    /**
     *
     */
    private static warnedAbout = new Set<string>()

    /**
     * This is the maximum amount of power this node could require at the given
     * provisioning state, ie the power demand at the highest possible load.
     *
     * This may validly produce a value which cannot practically be reached,
     * but must not produce a value which is below the highest possible loss.
     *
     * @param item
     * @param timeBasedData
     * @param modelCapacities
     * @param quickModeProps
     * @returns
     */
    private static itemPowerDemandLimit(
        item: Interface.Item &
        { deviceModelType?: DeviceModelType | "other" | "it" },
        timeBasedData: TimeBasedData,
        modelCapacities: Map<string, number>,
        quickModeProps: QuickModeProps | null = null
    ) {
        const nodeReference = NodeReferenceHelper.getNodeReference(item)
        const { count: installed } = this.provisioningInfo(timeBasedData,
            nodeReference, modelCapacities, quickModeProps)

        const providesPower = LoadCalculation.loadTypeForDeviceModelType(
            item.deviceModelType) === ConnectionType.POWER
        const performanceData = item.performanceData ??
            item.deviceMode.performanceData

        const weather = timeBasedData.weather ??
            { dryBulb: this.limitTemperature, wetBulb: this.limitTemperature }

        const resilienceHandler = new ResilienceHandler()
        const performanceCalculationClass =
            Factory.PerformanceCalculation.byName(performanceData.type)
        if (!performanceCalculationClass) {
            throw new Error(`Could not find performance data of type ${performanceData.type}`)
        }
        const performanceCalculation = new performanceCalculationClass()
        const provisioningState = { count: installed }

        if (item.resilience) {
            return resilienceHandler.getNominalPowerPeak(item.resilience,
                performanceCalculation, performanceData,
                item.deviceMode.idleDraw, item.deviceMode.peakDraw,
                weather, provisioningState, providesPower)
        } else if (item.deviceMode.peakDraw !== null) {
            return item.deviceMode.peakDraw * installed
        } else {
            const capacity = resilienceHandler.getComputedCapacity(
                null, performanceData, installed,
                { cooling: 0, power: 0, it: 0 }, weather)

            const load = { cooling: 0, power: capacity, it: 0 }

            const scalarDemand = performanceCalculation.simulate(
                performanceData, load, weather, provisioningState)

            if (providesPower) {
                return scalarDemand + capacity
            } else {
                return scalarDemand
            }
        }
    }

    /**
     * Gets the provisioning information for the time.
     *
     * @param timeBasedData
     * @param nodeReference
     * @param modelCapacities
     * @param quickModeProps
     * @returns
     */
    private static provisioningInfo(timeBasedData: TimeBasedData,
        nodeReference: string, modelCapacities: Map<string, number>,
        quickModeProps: QuickModeProps | null
    ) {
        const itemProvisioning =
            (quickModeProps?.provisioning ?? timeBasedData.provisioning)[nodeReference]
        if (itemProvisioning) {
            const count = itemProvisioning.count ?? 1
            const designCapacity = itemProvisioning.designCapacity ?? (count * (modelCapacities.get(nodeReference) ?? 0))
            if (designCapacity == 0) {
                this.warnDefaultCapacity(nodeReference)
            }
            return { count, designCapacity }
        }

        const itProvisioning =
            (quickModeProps?.provisionedItLoad ?? timeBasedData.itProvisioning)[nodeReference]
        if (itProvisioning) {
            return { count: 1, designCapacity: itProvisioning }
        }

        this.warnDefaultProvisioning(nodeReference)
        return { count: 1, designCapacity: 0 }
    }

    /**
     *
     * @param nodeReference
     */
    private static warnDefaultCapacity(nodeReference: string) {
        if (!this.warnedAbout.has(nodeReference)) {
            this.warnedAbout.add(nodeReference)
            console.warn(`Had to allocate 0 capacity for ${nodeReference}`)
        }
    }

    /**
     *
     * @param nodeReference
     */
    private static warnDefaultProvisioning(nodeReference: string) {
        if (!this.warnedAbout.has(nodeReference)) {
            this.warnedAbout.add(nodeReference)
            console.warn(`Had to allocate 0 capacity, 1 count for ${nodeReference}`)
        }
    }

    /**
     * Actuals v Expected Precalculation
     *
     * @param nodes
     * @param simulationData
     * @param timeBasedData
     * @param nodeReadings
     * @param modelCapacities
     * @param timePoint
     */
    static async actualExpected(nodes: NamedSimulationNode[], simulationData: SimulationData,
        timeBasedData: TimeBasedData, nodeReadings: NodeReadings,
        modelCapacities: Map<string, number>, timePoint: Date
    ): Promise<ActualExpected> {
        const precalculatedData: ActualExpected = {}

        /**
         *
         * @param readings
         * @returns
         */
        const closest = (readings: Reading<Date>[]) =>
            ReadingsHelper.getClosestReading(readings, timePoint)?.value ?? null

        /**
         *
         * @param node
         * @returns
         */
        const limit = (node) => this.anyNodePowerDemandLimit(node,
            simulationData, timeBasedData, modelCapacities)

        for (const [nodeReference, values] of Object.entries(simulationData)) {
            const [powerDemand] = values
            const node = this.getNodeByReference(nodes, nodeReference)
            if (!node) continue

            const relevantReadings = nodeReadings[nodeReference]?.filter(
                reading => reading.type == ReadingType.POWER_SUPPLY
            ) ?? []

            const relevantDayReadings =
                ReadingsHelper.dayReadings(relevantReadings, timePoint)

            precalculatedData[nodeReference] = {
                name: node.name,
                actual: closest(relevantDayReadings),
                expected: powerDemand,
                limit: limit(node),
            }
        }

        return precalculatedData
    }

    /**
     * Efficiency Precalculation
     *
     * @param nodes
     * @param simulationData
     * @returns
     */
    static efficiency(nodes: NamedSimulationNode[], simulationData: SimulationData): Efficiency {
        const precalculatedData = {} as Efficiency

        for (const [nodeReference, values] of Object.entries(simulationData)) {
            const [powerDemand, powerLoad, coolingLoad] = values
            const node = this.getNodeByReference(nodes, nodeReference)
            const efficiency = this.isItOrOtherLoadNode(node) ? 100 : ((coolingLoad + powerLoad) / (coolingLoad + powerDemand)) * 100
            const name = node?.name ?? ""


            precalculatedData[nodeReference] = {
                name,
                efficiency,
            }
        }

        return precalculatedData
    }

    /**
     * Provisioned Capacity Precalculation
     *
     * @param nodes
     * @param provisionedSimulationData
     * @param timeBasedData
     * @param modelCapacities
     * @returns
     */
    static provisionedCapacity(
        nodes: NamedSimulationNode[],
        provisionedSimulationData: SimulationData,
        timeBasedData: TimeBasedData,
        modelCapacities: Map<string, number>
    ): ProvisionalCapacity {
        const precalculatedData = {} as ProvisionalCapacity

        for (const [nodeReference, values] of Object.entries(provisionedSimulationData)) {
            const [demand, powerLoad, coolingLoad] = values
            const provisionedCapacity = powerLoad + coolingLoad
            const node = this.getNodeByReference(nodes, nodeReference)

            if (node) {
                let provisioned = 0

                if (node.type === NodeType.Item) {
                    const calculatedCapacity =
                        this.getCalculatedCapacity(node, timeBasedData)
                    provisioned = (provisionedCapacity / calculatedCapacity) * 100
                } else if ([NodeType.ITNode, NodeType.OtherNode].includes(node.type)) {
                    const workloadCapacity = timeBasedData.workLoadCapacity[nodeReference]
                    if (workloadCapacity) {
                        provisioned = 100 * demand / workloadCapacity
                    }
                }

                precalculatedData[nodeReference] = {
                    name: node.name!,
                    provisioned,
                }
            }
        }

        return precalculatedData
    }

    /**
     * Utilized Capacity Precalculation
     *
     * @param nodes
     * @param simulationData
     * @param timeBasedData
     * @param modelCapacities
     * @returns
     */
    static utilizedCapacity(
        nodes: NamedSimulationNode[],
        simulationData: SimulationData,
        timeBasedData: TimeBasedData,
        modelCapacities: Map<string, number>
    ): UtilizedCapacity {
        const precalculatedData = {} as UtilizedCapacity

        for (const [nodeReference, values] of Object.entries(simulationData)) {
            const [demand, powerLoad, coolingLoad] = values
            const appliedCapacity = powerLoad + coolingLoad
            const node = this.getNodeByReference(nodes, nodeReference)

            if (node) {
                let utilised = 0

                if (node.type === NodeType.Item) {
                    const calculatedCapacity =
                        this.getCalculatedCapacity(node, timeBasedData)
                    utilised = (appliedCapacity / calculatedCapacity) * 100
                } else if ([NodeType.ITNode, NodeType.OtherNode].includes(node.type)) {
                    const workloadCapacity = timeBasedData.workLoadCapacity[nodeReference]
                    if (workloadCapacity) {
                        utilised = 100 * demand / workloadCapacity
                    }
                }

                precalculatedData[nodeReference] = {
                    name: node.name!,
                    utilised,
                }
            }
        }

        return precalculatedData
    }

    /**
     * Combined Capacity Precalculation
     *
     * @param nodes
     * @param simulationData
     * @param provisionedSimulationData
     * @param timeBasedData
     * @param modelCapacities
     * @returns
     */
    static combinedCapacity(
        nodes: NamedSimulationNode[],
        simulationData: SimulationData,
        provisionedSimulationData: SimulationData,
        timeBasedData: TimeBasedData,
        modelCapacities: Map<string, number>
    ): CombinedCapacity {
        const precalculatedData = {} as CombinedCapacity
        const efficiencies = this.efficiency(nodes, simulationData)
        const utilizedCapacities =
            this.utilizedCapacity(nodes, simulationData, timeBasedData, modelCapacities)
        const provisionalCapacities =
            this.provisionedCapacity(nodes, provisionedSimulationData, timeBasedData, modelCapacities)

        for (const node of nodes) {
            const nodeReference = NodeReferenceHelper.getNodeReference(node)
            precalculatedData[nodeReference] = {
                name: node.name!,
                efficiency: efficiencies[nodeReference]?.efficiency,
                provisioned: provisionalCapacities[nodeReference]?.provisioned,
                utilised: utilizedCapacities[nodeReference]?.utilised,
            }
        }

        return precalculatedData
    }

    /**
     * Metering Status Precalculation
     *
     * @param nodes
     * @param nodeReadings
     * @param timePoint
     */
    static async meteringStatus(nodes: AnyNode[],
        nodeReadings: NodeReadings, timePoint: Date
    ): Promise<MeteringStatus> {
        const precalculatedData: MeteringStatus = {}

        for (const node of nodes) {
            let type: NodeType
            switch (node.type as NodeType | ResourceType) {
                case ResourceType.COMBINERS:
                    type = NodeType.Combiner
                    break
                case ResourceType.COOLING_METER_POINTS:
                    type = NodeType.CoolingMeterPoint
                    break
                case ResourceType.ITEMS:
                    type = NodeType.Item
                    break
                case ResourceType.IT_NODES:
                    type = NodeType.ITNode
                    break
                case ResourceType.OTHER_NODES:
                    type = NodeType.OtherNode
                    break
                case ResourceType.POWER_METER_POINTS:
                    type = NodeType.PowerMeterPoint
                    break
                case ResourceType.SPLITTERS:
                    type = NodeType.Splitter
                    break
                default:
                    if (Object.values(NodeType).includes(node.type)) {
                        console.warn(`Incorrect resource type ${node.type}`)
                        type = node.type as NodeType
                    } else {
                        throw new Error(`Node type ${node.type} isn't supported`)
                    }
            }
            const nodeReference = `${type}/${node.id}`
            const reporting = new Set(
                ReadingsHelper.dayReadings(
                    nodeReadings[nodeReference] ?? [], timePoint
                ).map(r => r.type)
            ).size

            let meters = 0
            for await (const mapping of node.getMeterMappings()) {
                for await (const sources of mapping.getMappingSources()) {
                    meters++
                }
            }

            precalculatedData[nodeReference] = {
                name: node.getName() ?? "",
                meters,
                reporting
            }
        }

        return precalculatedData
    }

    /**
     * Gets node by reference
     *
     * @param nodes
     * @param nodeReference
     * @returns
     */
    static getNodeByReference(nodes: NamedSimulationNode[], nodeReference: string) {
        const [type, id] = nodeReference.split("/")

        return nodes.find((n) => n.type == type && n.id == id)
    }

    /**
     *
     * @param node
     * @returns
     */
    private static isItOrOtherLoadNode(node: NamedSimulationNode | undefined) {
        return node && [NodeType.ITNode, NodeType.OtherNode].includes(node.type)
    }

    /**
     * Gets node reference
     * @param nodeReference
     */
    static getNodeReference(nodeReference: NodeReference) {
        return `${nodeReference.type}/${nodeReference.id}`
    }

    /**
     * This is the maximum amount of power this node could require, ie the power
     * demand at the highest possible load.
     *
     * @param node
     * @param simulationData
     * @param timeBasedData
     * @param modelCapacities
     * @param quickModeProps
     * @returns
     */
    static anyNodePowerDemandLimit(node: NamedSimulationNode | undefined,
        simulationData: SimulationData, timeBasedData: TimeBasedData,
        modelCapacities: Map<string, number>,
        quickModeProps: QuickModeProps | null = null
    ) {
        if (node) {
            const nodeReference = NodeReferenceHelper.getNodeReference(node)
            switch (node.type) {
                case NodeType.Splitter:
                    return this.splitterPowerDemandLimit(node, simulationData)
                case NodeType.Combiner:
                case NodeType.PowerMeterPoint:
                case NodeType.CoolingMeterPoint:
                    return this.passthroughNodePowerDemandLimit(node,
                        simulationData)
                case NodeType.Item:
                    return this.itemPowerDemandLimit(
                        node,
                        timeBasedData,
                        modelCapacities,
                        quickModeProps
                    )
                case NodeType.ITNode:
                case NodeType.OtherNode: {
                    const provisioningInfo = this.provisioningInfo(timeBasedData, nodeReference, modelCapacities, quickModeProps)
                    return provisioningInfo.designCapacity
                }
            }
        }
        return 0
    }

    /**
     * This is the maximum amount of power this node could require. In
     * principle for splitters this would be the power demand limit of the node
     * it supplies.
     *
     * @param node
     * @param simulationData
     */
    static splitterPowerDemandLimit(node: Interface.Splitter,
        simulationData: SimulationData) {
        let sumOfPowerDemands = 0

        if (node.cooledBy) {
            for (const c of node.cooledBy) {
                const ref = this.getNodeReference(c)
                const cooledByNode = simulationData[ref]
                const powerDemand = cooledByNode[0]

                sumOfPowerDemands += powerDemand
            }
        }

        if (node.poweredBy) {
            for (const p of node.poweredBy) {
                const ref = this.getNodeReference(p)
                const poweredByNode = simulationData[ref]
                const powerDemand = poweredByNode[0]

                sumOfPowerDemands += powerDemand
            }
        }

        return sumOfPowerDemands
    }

    /**
     * This is the maximum amount of power this node could require. For meter
     * points, this should match the same value on the downstream node. For
     * combiners, this would be the sum of such values.
     *
     * @param node
     * @param simulationData
     */
    static passthroughNodePowerDemandLimit(
        node: Interface.Combiner | Interface.PowerMeterPoint | Interface.CoolingMeterPoint,
        simulationData: SimulationData
    ) {
        let sumOfPowerDemands = 0

        if (node.cooledBy) {
            const cooledBy = node.cooledBy
            const ref = this.getNodeReference(cooledBy)
            const cooledByNode = simulationData[ref]
            const powerDemand = cooledByNode[0]
            sumOfPowerDemands += powerDemand
        }

        if (node.poweredBy) {
            const poweredBy = node.poweredBy
            const ref = this.getNodeReference(poweredBy)
            const poweredByNode = simulationData[ref]
            const powerDemand = poweredByNode[0]
            sumOfPowerDemands += powerDemand
        }

        return sumOfPowerDemands
    }

    /**
     * Get item calulated capacity
     *
     * @param node
     * @param timeBasedData
     * @returns
     */
    private static getCalculatedCapacity(node: Interface.Item,
        timeBasedData: TimeBasedData) {
        const capacity = node.deviceMode.performanceData.capacity
        const nodeReference = NodeReferenceHelper.getNodeReference(node)

        const resilienceHandler = new ResilienceHandler()
        return resilienceHandler.getComputedCapacity(
            node.resilience ?? null,
            node.performanceData ?? node.deviceMode.performanceData,
            timeBasedData.provisioning[nodeReference]?.count ?? 1,
            LoadCalculation.getLoadObject(capacity, node),
            timeBasedData.weather ?? { dryBulb: 20, wetBulb: 20 }
        )
    }
}


