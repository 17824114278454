import { NamedSimulationNode, SimulationOutput } from "@/Simulation/interfaces"
import { SimulationData } from "@/store/simulation-view/types"
import { FiltersCalculationHelper } from "../FiltersCalculationHelper"

/**
 *
 */
export class SimulationOutputFilter extends FiltersCalculationHelper {
    /**
     * Produces the simulation output data compiled from simulation data
     *
     * @param nodes
     * @param simulationData
     * @returns
     */
    static produceData(nodes: NamedSimulationNode[], simulationData: SimulationData) {
        const simulationOuputs: SimulationOutput = {}

        for (const [nodeReference, [powerDemand, powerLoad, coolingLoad]] of Object.entries(simulationData)) {

            const node = SimulationOutputFilter.getNodeByReference(nodes, nodeReference)
            const name = node?.name as string
            simulationOuputs[nodeReference] = {
                name,
                powerDemand: Math.round(powerDemand),
                coolingSupply: Math.round(powerDemand + coolingLoad - powerLoad)
            }
        }
        return simulationOuputs

    }

}