import { NamedSimulationNode, NodeReference, SiteNodes, TimeBasedParameter } from "@/Simulation"
import { AnyNode } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"
import { ConnectionType, Interface, NodeType } from "predict-performance-calculation"
import { NodeReferenceHelper } from "../helpers"
import { SiteError } from "./SiteError"
import { LayoutError } from "./LayoutError"
import { NodeError } from "./NodeError"

/**
 *
 */
export class LayoutValidity {
    /**
     *
     */
    private nodes: AnyNode[] | null = null

    /**
     *
     * @param supplyBy
     * @returns
     */
    private supplyArray(
        supplyBy: Interface.Tuple<NodeType> | Interface.Tuple<NodeType>[] | null
    ) {
        if(!supplyBy) {
            return []
        }
        return Array.isArray(supplyBy) ? supplyBy : [supplyBy]
    }

    /**
     *
     */
    public layoutErrors = new Set<LayoutError>()

    /**
     *
     */
    public nodeErrors = new Map<string, Set<NodeError>>()

    /**
     *
     */
    public siteErrors = new Set<SiteError>()

    /**
     *
     */
    get isValid() {
        return this.isValidExcludingNodes && [...this.nodeErrors.values()].every(errors => errors.size == 0)
    }

    /**
     *
     */
    get isValidExcludingNodes() {
        return this.siteErrors.size == 0 && this.layoutErrors.size == 0
    }

    /**
     *
     * @param site
     * @param nodes
     */
    constructor(private site: Models.SiteModel | null, nodes: AnyNode[] | null = null) {
        if (nodes) {
            this.setNodes(nodes)
        }
    }

    /**
     *
     * @returns
     */
    async checkNodes() {
        if (!this.nodes) {
            return
        }

        // At the top to ensure that we only update when ready
        const nodesData = this.nodes.length ?
            await TimeBasedParameter.buildNodesData(this.nodes) :
            null

        // Reset states which get set here. These will get overwritten before
        // the UI updates
        this.nodeErrors = new Map()
        this.layoutErrors.delete(LayoutError.noConnection)
        this.layoutErrors.delete(LayoutError.multiplePowerSources)

        if (nodesData) {
            const suppliers = new Set<string>()
            for (const n of this.nodes) {
                for (const relation of ['cooledBy', 'poweredBy']) {
                    if (n[relation]) {
                        const suppliedBy = Array.isArray(n[relation]) ? n[relation] : [n[relation]]
                        for (const s of suppliedBy) {
                            suppliers.add(NodeReferenceHelper.getNodeReference(s))
                        }
                    }
                }
            }

            let someHaveSupplier = false

            const unpoweredNodes: NamedSimulationNode[] = []
            const powerSupplyNodes = new Set<string>()

            for (const sNode of nodesData) {
                const reference = NodeReferenceHelper.getNodeReference(sNode)
                const nodeValidation = new Set<NodeError>()
                this.nodeErrors.set(reference, nodeValidation)

                if (sNode.poweredBy) {
                    if (Array.isArray(sNode.poweredBy)) {
                        for (const poweredBy of sNode.poweredBy) {
                            powerSupplyNodes.add(NodeReferenceHelper.getNodeReference(poweredBy))
                        }
                    } else {
                        powerSupplyNodes.add(NodeReferenceHelper.getNodeReference(sNode.poweredBy))
                    }
                } else {
                    unpoweredNodes.push(sNode)
                }

                const noSupplier = !sNode.cooledBy && !sNode.poweredBy
                const noDemander = !NodeReferenceHelper.suppliedNodes(sNode, nodesData, ConnectionType.POWER) && !NodeReferenceHelper.suppliedNodes(sNode, nodesData, ConnectionType.COOLING)
                if (noSupplier && noDemander) {
                    if (!suppliers.has(reference)) {
                        nodeValidation.add(NodeError.nodeDisconnected)
                    }
                } else {
                    someHaveSupplier = true
                }

                switch (sNode.type) {
                    case NodeType.Item:
                        if (
                            !sNode.provisioningEvents?.length ||
                            sNode.provisioningEvents?.some(e => e.count < 1 || !e.date)
                        ) {
                            nodeValidation.add(NodeError.noProvisioning)
                        }

                        if (!sNode.resilience) {
                            nodeValidation.add(NodeError.invalidResilience)
                        }
                        break

                    case NodeType.ITNode:
                        if (
                            !sNode.itProvisioning?.itProvisioningEvents.length ||
                            sNode.itProvisioning?.itProvisioningEvents?.some(e => !e.month || e.power === null || e.powerCapacity === null)
                        ) {
                            nodeValidation.add(NodeError.noProvisioning)
                        }
                        break

                    case NodeType.OtherNode:
                        if (
                            !sNode.otherProvisioning?.otherProvisioningEvents.length ||
                            sNode.otherProvisioning?.otherProvisioningEvents?.some(e => !e.month || e.power === null || e.powerCapacity === null)
                        ) {
                            nodeValidation.add(NodeError.noProvisioning)
                        }
                        break
                    case NodeType.Combiner:
                    case NodeType.Splitter:
                        this.checkCombinersAndSplitters(nodeValidation, sNode, nodesData)
                        break
                }
            }

            const powerSources = unpoweredNodes.filter(node => powerSupplyNodes.has(NodeReferenceHelper.getNodeReference(node)))

            if (!someHaveSupplier) {
                this.layoutErrors.add(LayoutError.noConnection)
            }
            if (powerSources.length > 1) {
                this.layoutErrors.add(LayoutError.multiplePowerSources)
            }
        }
    }

    /**
     *
     * @returns
     */
    async checkSite() {
        if (!this.site) {
            throw new Error("No site to check yet")
        }

        // Get all the awaits done first
        const climate = await this.site.getClimateProfileReference()
        const powerCost = await this.site.getPowerCostProfile()

        let seenCostMonths = false
        if (powerCost) {
            for await (const month of powerCost.getPowerCostMonths()) {
                seenCostMonths = true
                break
            }
        }

        // Reset the state, will be updated before UI update
        this.siteErrors = new Set()
        this.layoutErrors.delete(LayoutError.noNodes)

        if (!seenCostMonths) {
            this.siteErrors.add(SiteError.noPowerProfile)
        }
        if (!climate) {
            this.siteErrors.add(SiteError.noClimate)
        }
        if (this.nodes?.length === 0) {
            this.layoutErrors.add(LayoutError.noNodes)
        }
    }

    /**
     *
     * @param siteConfiguration
     */
    async checkSiteConfiguration(configuration: Models.SiteConfigurationModel) {
        const nodes = await SiteNodes.forSiteConfig(configuration)
        this.setNodes(nodes)
        return this.checkNodes()
    }

    /**
     *
     * @param node
     * @returns
     */
    nodeErrorsFor(node: NodeReference) {
        const reference = NodeReferenceHelper.getNodeReference(node)
        return this.nodeErrors.get(reference)
    }

    /**
     *
     * @param nodes
     */
    setNodes(nodes: AnyNode[]) {
        this.nodes = nodes
        if (this.nodes.length == 0) {
            this.layoutErrors.add(LayoutError.noNodes)
        }
        else {
            this.layoutErrors.delete(LayoutError.noNodes)
        }
    }

    /**
     *
     * @param nodeValidation
     * @param node
     * @param nodesData
     */
    checkCombinersAndSplitters(
        nodeValidation: Set<NodeError>,
        node: NamedSimulationNode,
        nodesData: NamedSimulationNode[]
    ) {
        const powerOut = NodeReferenceHelper.suppliedNodes(node, nodesData, ConnectionType.POWER)
        const powerIn = this.supplyArray(node.poweredBy)

        const coolingOut = this.supplyArray(node.cooledBy)
        const coolingIn = NodeReferenceHelper.suppliedNodes(node, nodesData, ConnectionType.COOLING)

        /**
         * 0 - none
         * 1 - one side only
         * 2 - both sides
         */
        const powerLinkDirections = +!!powerIn.length + +!!powerOut.length
        const coolingLinkDirections = +!!coolingIn.length + +!!coolingOut.length

        if (powerLinkDirections > 0 && coolingLinkDirections > 0) {
            nodeValidation.add(NodeError.invalidType)
        }

        if (powerLinkDirections == 1 || coolingLinkDirections == 1) {
            nodeValidation.add(NodeError.invalidInOut)
        }
    }
}