import { SimulationNode } from "@/Simulation/interfaces"
import { ITComparisonHistoryClient } from "./ITComparisonHistoryClient"
import { Models } from "@ekko/predict-client-api"
import ApiService from "@/core/services/api.service"

/**
 * Class for CUE
 */
export class CUEHistoryClient extends ITComparisonHistoryClient {
    protected async fetchComparison(
        siteId: string,
        nodes: SimulationNode[],
        ): Promise<Map<string, number>> {
        const siteService = await ApiService.getSiteService()
        const site: Models.SiteModel = await siteService.getSite(siteId)
        const datacentreEnergy = await this.fetchEnergyHistory(siteId, nodes)
        const itLoad = await this.fetchITLoad(siteId, nodes)

        const cueValues = new Map<string, number>()
        for (const energyReading of datacentreEnergy) {
            const readingKey = new Date(energyReading.timestamp).toISOString()
            const itLoadReading = itLoad.get(readingKey)
            if (itLoadReading) {
                cueValues.set(readingKey, site.getCo2PerKWh()! * energyReading.value / itLoadReading)
            }

        }

        return cueValues
    }
}