/**
 * Use this as an exception when you want the top level to report it as an
 * error notification
 */
export class UserReportableError {
    /**
     *
     * @param message
     */
    constructor(public readonly message: string) {

    }
}