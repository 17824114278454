import { NodeHelper } from "@/core/helpers"
import { AnyNode } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"

/**
 *
 */
export class SiteNodes {
    /**
     * Retrieves item nodes for a site, minus any duplicates (unique)
     *
     * This method is required because Orbit.js returns duplicate items when
     * adding a new item to a layout, even though ultimately only one
     * corresponding record has been persisted to the back-end database.
     *
     * @param siteConfiguration
     * @returns
     */
    private static async getItemsUniqueWorkaround(siteConfiguration: Models.SiteConfigurationModel) {
        const uniqueItemNodes = new Map<string, Models.ItemModel>()
        for await (const nodePage of siteConfiguration.getItemsPaginated()) {
            for (const itemNode of nodePage) {
                uniqueItemNodes.set(itemNode.id, itemNode)
            }
        }
        return Array.from(uniqueItemNodes.values())
    }

    /**
     * Retrieves all type of nodes of this site
     *
     * @param siteConfiguration
     * @returns
     */
    static async forSiteConfig(
        siteConfiguration: Models.SiteConfigurationModel | null
    ) {
        if(!siteConfiguration) {
            return []
        }

        const nodePageLoadersS = [
            siteConfiguration.getSplittersPaginated(),
        ]

        const nodePageLoaders = [
            siteConfiguration.getCombinersPaginated(),
            siteConfiguration.getItNodesPaginated(),
            siteConfiguration.getMeterPointsPaginated(),
            siteConfiguration.getOtherNodesPaginated(),
        ]

        const nodes: AnyNode[] = []
        for(const nodeLoader of nodePageLoaders) {
            for await (const nodePage of nodeLoader) {
                nodes.push(...NodeHelper.nRefs(nodePage))
            }
        }
        // Workaround for duplicate items returned by Orbit.js when adding an item to a layout
        nodes.push(...NodeHelper.nRefs(await SiteNodes.getItemsUniqueWorkaround(siteConfiguration)))

        for(const nodeLoader of nodePageLoadersS) {
            for await (const nodePage of nodeLoader) {
                nodes.push(...NodeHelper.nRefs(nodePage))
            }
        }

        return nodes
    }
}