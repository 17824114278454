import { AnyDataType, AnySensor, AnySensorItem, StoredSensorInfo } from "../../interfaces"

/**
 *
 */
export abstract class BaseSensorService<I extends AnySensorItem,
    D extends AnyDataType>
{
    /**
     * Filter the datatypes based on the the filter method
     *
     * @param datatypes
     * @returns
     */
    private filterDataTypes(datatypes: D): D {
        const filteredDataTypes = Object.fromEntries(
            Object.entries(datatypes).filter(
                d => this.dataTypeFilter(d[1]))) as D
        const droppedKeys = Object.keys(datatypes).filter(
            k => !filteredDataTypes[k])
        if(droppedKeys.length) {
            console.log(
                `Dropped ${droppedKeys.length} excluded data types: ${droppedKeys.map(k => datatypes[k].label).join(", ")}`
            )
        }
        return filteredDataTypes
    }

    /**
     * Filter the items based on the retained datatypes
     *
     * @param items
     * @param filteredDatatypes
     * @returns
     */
    private filterItems(items: I[], filteredDatatypes: D) {
        const droppedSensors: Record<string, number> = {}
        const filteredItems = items.map(i => {
            const sensors: AnySensor[] = []
            for(const sensor of i.sensors) {
                const datatype = sensor.datatype
                if(filteredDatatypes[datatype]) {
                    sensors.push(sensor)
                } else {
                    if(!droppedSensors[datatype]) {
                        droppedSensors[datatype] = 0
                    }
                    droppedSensors[datatype]++
                }
            }
            return {
                ...i,
                sensors,
            }
        }).filter(i => i.sensors.length)

        console.log(
            `Excluded sensors: ${Object.entries(droppedSensors).map(([dt, c]) => `${dt}: ${c}`).join(", ")}`)
        return filteredItems
    }

    /**
     * Filter operator returns true any power or cooling label
     * @param datatype
     * @returns
     */
    protected abstract dataTypeFilter(datatype: AnyDataType[""]): boolean

    /**
     * @returns
     */
    protected abstract getSiteSensorInformationRaw():
        Promise<StoredSensorInfo<I, D>>

    /**
     * Creates an instance of sensor service.
     *
     * @param siteId
     */
    constructor(protected siteId: string) {

    }

    /**
     * Gets site sensor information
     *
     * @returns The items/data types as filtered by policy
     */
    async getSiteSensorInformation() {
        const sensors = await this.getSiteSensorInformationRaw()

        let items: I[]
        if (sensors.items) {
            items = sensors.items
        } else {
            items = []
        }

        let datatypes: D
        if (sensors.datatypes) {
            datatypes = sensors.datatypes
        } else {
            datatypes = {} as D
        }

        const filteredDatatypes = this.filterDataTypes(datatypes)

        return {items: this.filterItems(items, filteredDatatypes),
            datatypes: filteredDatatypes}
    }
}