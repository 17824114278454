import { Models } from "@ekko/predict-client-api"
import {
    GridListParameter,
    GridParameter,
    HeatExchangerParameter,
    PerformanceParameterBase,
    PolynomialParameter,
} from "../PerformanceParameter"

/**
 *
 */
export class PerformanceParameter {
    /**
     *
     */
    private static readonly registeredClasses = {
        grid: GridParameter,
        gridList: GridListParameter,
        heatExchanger: HeatExchangerParameter,
        polynomial: PolynomialParameter,
    }


    /**
     * Retrieves the right device performance instance
     * @param deviceMode
     */
    static async factory(deviceMode: Models.DeviceModeModel): Promise<
        PerformanceParameterBase<
            Models.GridModel |
            Models.GridListModel |
            Models.PolynomialModel |
            Models.HeatExchangerModel
        >
    > {
        const performance = await deviceMode.getPerformanceData()

        if(!performance) {
            throw new Error(`Device mode ${deviceMode.id} is missing performance data`)
        }

        if (this.registeredClasses[performance.modelType]) {
            return new this.registeredClasses[performance.modelType](performance)
        }

        throw new Error(`${performance.modelType} is not supported as performance parameter class`)
    }
}
