import { DateHelper } from "@/core/helpers"
import { PeriodHelper } from "../helpers/PeriodHelper"
import { CustomPeriodClient, SimPeriod, SimulationSiteConfiguration } from "../interfaces"

/**
 *
 */
export abstract class SimulatableClientBase implements CustomPeriodClient {
    /**
     * Period to simulate. By default this is past 12 months.
     */
    period: SimPeriod

    /**
     *
     */
    get simPeriod() {
        return this.period
    }

    /**
     * @returns The overlap between the configured period and the nodeset's
     * start/finish. This will always run for a whole number of months.
     */
    overlappingPeriod(nodeset: SimulationSiteConfiguration) {
        return PeriodHelper.subperiod(this.period, nodeset.start, nodeset.finish)
    }

    /**
     *
     */
    constructor() {
        // Temporary
        const startPoint = new Date()
        const periodLength = 12
        startPoint.setDate(1)
        startPoint.setMonth(startPoint.getMonth() - periodLength)
        this.period = {
            start: DateHelper.fullYearMonthOnly(startPoint),
            months: periodLength,
            oneDayPerMonth: false,
        }
    }

    /**
     * Customise the Period to cover the span described by the provided dates
     *
     * This is inclusive, so 2022-01-01 to 2022-01-15 is 1 month, 2022-01-01 to 2022-02-01 is 2 months.
     *
     * @param startDate
     * @param endDate
     * @param oneDayPerMonth
     */
    customPeriod(startDate: Date, endDate: Date, oneDayPerMonth = this.period.oneDayPerMonth) {
        const periodLength = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            endDate.getMonth() - startDate.getMonth() + 1
        this.period = {
            start: DateHelper.fullYearMonthOnly(startDate),
            months: periodLength,
            oneDayPerMonth
        }
    }
}