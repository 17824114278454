import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { AppendUnits } from "./AppendUnits"

/**
 *
 */
export class Fahrenheit extends AppendUnits {
    protected defaultMaxDigits = 1

    protected suffixSymbol = "\u00b0F"

    protected numberFormatOptions(options?: { digits?: number | undefined } | undefined): Intl.NumberFormatOptions {
        return {
            maximumFractionDigits: options?.digits ?? this.defaultMaxDigits,
            minimumFractionDigits: options?.digits ?? 1,
        }
    }

    suffix = NumberFormatSuffix.fahrenheit

    nativeToPreferred(value: number, options?: {digits?: number}): number {
        return super.nativeToPreferred(+value * 9/5 + 32, options)
    }

    preferredToNative(value: number): number {
        return (value - 32) * 5/9
    }
}