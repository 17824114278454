import { ReadingType } from "@/History"
import { NamedSimulationNode } from "@/Simulation/interfaces"
import { CalibrationStatus } from "@/Simulation/interfaces/filters/CalibrationStatus"
import { NodeReadings, SimulationData } from "@/store/simulation-view/types"
import { FiltersCalculationHelper } from "../FiltersCalculationHelper"
import { ReadingsHelper } from "../ReadingsHelper"

/**
 *
 */
abstract class CalibrationStatusError {
    /**
     *
     */
    public get uncertainty() {
        if(this.compoundSkew === undefined) {
            return undefined
        } else {
            return this.compoundSkew - 1
        }
    }

    /**
     *
     * @param message
     * @param compoundSkew This is advisory here
     * @param hasMeteredLoad
     */
    constructor(public message: string, private compoundSkew: number,
        public readonly hasMeteredLoad
    ) {

    }
}

/**
 *
 */
class CalibrationStatusErrorReadingsFailure extends CalibrationStatusError {
}

/**
 *
 */
class CalibrationStatusErrorNoReadings extends CalibrationStatusError {
}

/**
 *
 */
interface MeteredEquivalentLoad {
    compoundSkew: number
    isMetered: boolean
    load: number
}

/**
 *
 */
interface MeteredEquivalentSupply {
    compoundSkew: number
    supply: number
}

/**
 *
 */
export class CalibrationStatusFilter extends FiltersCalculationHelper {
    /**
     *
     */
    private static debug = false

    /**
     *
     * @param message
     */
    private static debugLog(message: string) {
        if(this.debug) {
            console.log(message)
        }
    }

    /**
     *
     */
    private cacheMeteredEquivalentCoolingSupplies =
        new Map<string, MeteredEquivalentSupply>()

    /**
     *
     */
    private cacheMeteredEquivalentLoads =
        new Map<string, MeteredEquivalentLoad>()

    /**
     *
     */
    private cacheMeteredEquivalentPowerSupplies =
        new Map<string, MeteredEquivalentSupply>()


    /**
     *
     */
    private debug = false

    /**
     *
     * @param message
     */
    private debugLog(message: string) {
        if(this.debug) {
            console.log(message)
        }
    }

    /**
     * @param nodeReference
     * @throws {CalibrationStatusError}
     * @returns The deviation, and the uncertainty.
     */
    private getCalibrationVariance(nodeReference: string):
        {deviation: number, uncertainty: number, hasMeteredLoad: boolean}
    {
        const [simPowerSupply, simPowerLoad, simCoolingLoad] =
            this.simulationData[nodeReference]

        this.debugLog(`Checking ${nodeReference}`)

        const {load, compoundSkew, isMetered: hasMeteredLoad} =
            this.meteredEquivalentLoad(nodeReference)

        if(hasMeteredLoad) {
            this.debugLog(`Got a metered load: ${load}`)
        } else {
            this.debugLog(`Got a metered-equivalent load: ${load}`)
        }

        // Calibration only refers to supply values

        const dayReadings = this.readings[nodeReference]

        if(!dayReadings) {
            this.debugLog(`Can't calibrate - skew would be ${compoundSkew}`)
            throw new CalibrationStatusErrorReadingsFailure(`No readings - cannot calibrate`, compoundSkew, hasMeteredLoad)
        }

        this.debugLog(`Got ${dayReadings.length} readings`)

        const simLoad = simPowerLoad + simCoolingLoad

        this.debugLog(`Simulation load is: ${simLoad}`)

        // If there are multiple metered supplies (somehow), we can just use the power one.

        const powerSupplyDayReadings = dayReadings.filter(r => r.type == ReadingType.POWER_SUPPLY)

        const powerSupplyReading =
            ReadingsHelper.getClosestReading(powerSupplyDayReadings,
                this.timePoint)

        if(powerSupplyReading) {
            this.debugLog(`Got a power supply reading: ${powerSupplyReading.value}`)
            // Do power calibration
            if(powerSupplyReading.value == 0) {
                return simPowerSupply == 0 ?
                    {deviation: 0, uncertainty: 0, hasMeteredLoad} : // 0/0 - no skew
                    {deviation: 1, uncertainty: 0, hasMeteredLoad} // n > 0 / 0 - treat as 100% wrong
            }
            // Build the equivalent simulated supply.

            let closestSupply: number
            if(simLoad != 0 || load != 0) {
                // Close enough for heuristics
                const equivalentSimSupply = simPowerSupply * load / simLoad
                // Hedge our bets a little here: use the closer of
                // the actual sim value and the equivalent one
                closestSupply = [equivalentSimSupply, simPowerSupply].sort(
                    (a, b) => Math.abs(a - powerSupplyReading.value) - Math.abs(b - powerSupplyReading.value)
                )[0]

                this.debugLog(`Estimating equivalent supply as: ${closestSupply}`)
            } else {
                closestSupply = simPowerSupply
                this.debugLog(`Zero load - taking sim power supply of ${simPowerSupply}`)
            }

            return {deviation: closestSupply / powerSupplyReading.value - 1,
                uncertainty: compoundSkew - 1, hasMeteredLoad}
        } else {
            const coolingSupplyDayReadings = dayReadings.filter(r => r.type == ReadingType.COOLING_SUPPLY)
            const coolingSupplyReading =
                ReadingsHelper.getClosestReading(
                    coolingSupplyDayReadings, this.timePoint)
            if(!coolingSupplyReading) {
                this.debugLog(`Can't calibrate - skew would be ${compoundSkew}`)
                throw new CalibrationStatusErrorNoReadings(
                    `No power or cooling supply readings`, compoundSkew,
                    hasMeteredLoad)
            }

            this.debugLog(`Got a cooling supply reading: ${coolingSupplyReading.value}`)
            // Do cooling calibration
            const simCoolingSupply = simPowerSupply + simCoolingLoad
            if(coolingSupplyReading.value == 0) {
                return simCoolingSupply == 0 ?
                    {deviation: 0, uncertainty: 0, hasMeteredLoad} : // 0/0 - no skew
                    {deviation: 1, uncertainty: 0, hasMeteredLoad} // n > 0 / 0 - treat as 100% wrong
            }
            // Build the equivalent simulated supply.

            // Close enough for heuristics
            const equivalentSimSupply = simCoolingSupply * load / simLoad
            // Hedge our bets a little here: use the closer of
            // the actual sim value and the equivalent one
            const closestSupply =
                [equivalentSimSupply, simCoolingSupply].sort(
                    (a, b) => Math.abs(a - coolingSupplyReading.value) -
                    Math.abs(b-coolingSupplyReading.value)
                )[0]

            this.debugLog(`Estimating equivalent supply as: ${closestSupply}`)

            return {deviation: closestSupply / coolingSupplyReading.value - 1,
                uncertainty: compoundSkew - 1, hasMeteredLoad}
        }
    }

    /**
     * Either:
     *
     * 1. The real metered load
     * OR:
     * 2. The sum of metered equivalent supplies for the nodes this provides
     * service to
     *
     * @param nodeReference
     * @returns
     */
    private meteredEquivalentLoad(nodeReference: string):
        MeteredEquivalentLoad
    {
        const cache = this.cacheMeteredEquivalentLoads.get(nodeReference)
        if(cache) {
            return cache
        }
        this.debugLog(`Checking MEL on ${nodeReference}`)
        const dayReadings = this.readings[nodeReference]

        if(!dayReadings) {
            throw new Error(`No readings for ${nodeReference}`)
        }

        // Note that you shouldn't have both!
        const loadDayReadings = dayReadings.filter(
            r => r.type == ReadingType.COOLING_LOAD || r.type == ReadingType.POWER_LOAD)

        const [simPowerSupply, simPowerLoad, simCoolingLoad] =
            this.simulationData[nodeReference]

        const simLoad = simCoolingLoad + simPowerLoad

        const closestLoadReading = ReadingsHelper.getClosestReading(
            loadDayReadings, this.timePoint)
        if(closestLoadReading) {
            const valueSkew = Math.abs(closestLoadReading.value / simLoad - 1) + 1
            this.debugLog(`Metered load on ${nodeReference} (s=${valueSkew})`)
            const result = {load: closestLoadReading.value, compoundSkew: valueSkew, isMetered: true}
            this.cacheMeteredEquivalentLoads.set(nodeReference, result)
            return result
        }

        // If we don't have a load reading, we can add the _supply_ of all
        // nodes this supplies. Particularly relevant to splitters.
        const coolingSupplies: string[] = []
        const powerSupplies: string[] = []

        const [type, id] = nodeReference.split("/")
        for(const node of this.nodes) {
            if(node.type == "splitter") {
                if(node.cooledBy.some(nr => nr.type == type && nr.id == id)) {
                    coolingSupplies.push(node.type + "/" + node.id)
                }
                if(node.poweredBy.some(nr => nr.type == type && nr.id == id)) {
                    powerSupplies.push(node.type + "/" + node.id)
                }
            } else {
                if(node.cooledBy && node.cooledBy.type == type &&
                    node.cooledBy.id == id
                ) {
                    coolingSupplies.push(node.type + "/" + node.id)
                }
                if(node.poweredBy && node.poweredBy.type == type &&
                    node.poweredBy.id == id
                ) {
                    powerSupplies.push(node.type + "/" + node.id)
                }
            }
        }

        let supplyTotal = 0
        let skewSum = 0
        const supplySkews: number[] = []
        for(const suppliedNodeReference of coolingSupplies) {
            const {supply, compoundSkew: skew} =
                this.meteredEquivalentCoolingSupply(suppliedNodeReference)
            supplySkews.push(skew)
            supplyTotal += supply
            skewSum += skew * supplyTotal
        }
        for(const suppliedNodeReference of powerSupplies) {
            const {supply, compoundSkew: skew} =
                this.meteredEquivalentPowerSupply(suppliedNodeReference)
            supplySkews.push(skew)
            supplyTotal += supply
            skewSum += skew * supplyTotal
        }
        const supplySkew = skewSum ? skewSum / supplyTotal : 1
        this.debugLog(`Metered equivalent load on ${nodeReference} (skews=${supplySkews.join(", ")}, conditional=${supplySkew})`)
        const result = {load: supplyTotal, compoundSkew: supplySkew, isMetered: false}
        this.cacheMeteredEquivalentLoads.set(nodeReference, result)
        return result
    }

    /**
     * Either:
     *
     * 1. Real metered cooling supply
     * OR
     * 2. Metered-equivalent power supply plus metered equivalent load
     *
     * @param nodeReference
     * @returns
     */
    private meteredEquivalentCoolingSupply(nodeReference: string):
        MeteredEquivalentSupply
    {
        const cache = this.cacheMeteredEquivalentCoolingSupplies.get(nodeReference)
        if(cache) {
            return cache
        }

        this.debugLog(`Checking MES(c) on ${nodeReference}`)
        const dayReadings = this.readings[nodeReference]
        if(!dayReadings) {
            throw new Error(`No readings for ${nodeReference}`)
        }

        const supplyDayReadings = dayReadings.filter(
            r => r.type == ReadingType.COOLING_SUPPLY)

        const [simPowerSupply, simPowerLoad, simCoolingLoad] =
            this.simulationData[nodeReference]

        const closestSupplyReading = ReadingsHelper.getClosestReading(
            supplyDayReadings, this.timePoint)
        if(closestSupplyReading) {
            const simCoolingSupply = simPowerSupply + simCoolingLoad
            const valueSkew = Math.abs(closestSupplyReading.value / simCoolingSupply - 1) + 1
            this.debugLog(`Metered supply on ${nodeReference} (s=${valueSkew})`)
            return {supply: closestSupplyReading.value, compoundSkew: valueSkew}
        }

        // If we don't have the cooling supply, we can take it as cooling load
        // + power supply.
        const {supply: powerSupply, compoundSkew: supplySkew} =
            this.meteredEquivalentPowerSupply(nodeReference)

        const {load, compoundSkew: loadSkew} =
            this.meteredEquivalentLoad(nodeReference)

        const compoundSkew = (supplySkew * powerSupply + loadSkew * load) / (powerSupply + load)

        this.debugLog(`Metered supply on ${nodeReference} (s=${powerSupply} @${supplySkew}, c=${load} @${loadSkew} -> ${compoundSkew})`)
        const result = {supply: powerSupply + load, compoundSkew}

        this.cacheMeteredEquivalentCoolingSupplies.set(nodeReference, result)
        return result
    }

    /**
     * Either:
     *
     * 1. Real metered power supply
     * Or:
     * 2. If it's close enough, _simulated_ power supply multiplied by the
     * difference in metered equivalent load
     *
     * @param nodeReference
     * @returns
     */
    private meteredEquivalentPowerSupply(nodeReference: string):
        MeteredEquivalentSupply
    {
        const cache =
            this.cacheMeteredEquivalentPowerSupplies.get(nodeReference)
        if(cache) {
            return cache
        }

        this.debugLog(`Checking MES(p) on ${nodeReference}`)
        const dayReadings = this.readings[nodeReference]
        if(!dayReadings) {
            throw new Error(`No readings for ${nodeReference}`)
        }

        const supplyDayReadings = dayReadings.filter(
            r => r.type == ReadingType.POWER_SUPPLY)

        const closestSupplyReading = ReadingsHelper.getClosestReading(
            supplyDayReadings, this.timePoint)

        const [simPowerSupply, simPowerLoad, simCoolingLoad] =
            this.simulationData[nodeReference]
        if(closestSupplyReading) {
            const valueSkew = Math.abs(closestSupplyReading.value / simPowerSupply - 1) + 1
            this.debugLog(`Metered supply on ${nodeReference} (s=${valueSkew})`)
            return {supply: closestSupplyReading.value, compoundSkew: valueSkew}
        }
        // No supply reading, but maybe we can estimate?
        const {load, compoundSkew} = this.meteredEquivalentLoad(nodeReference)

        const simLoad = simPowerLoad + simCoolingLoad

        this.debugLog(`Metered supply on ${nodeReference} (l=${load}, sl=${simLoad}, cSkew=${compoundSkew})`)
        const result = {supply: simPowerSupply * load / simLoad, compoundSkew}

        this.cacheMeteredEquivalentPowerSupplies.set(nodeReference, result)
        return result
    }

    /**
     * Produces the calibration status data compiled from the node readings and simulation data
     *
     * @param nodes
     * @param readings
     * @param simulationData
     * @param calibrationBound
     * @param timePoint
     * @returns
     */
    static produceData(nodes: NamedSimulationNode[], readings: NodeReadings,
        simulationData: SimulationData, calibrationBound: number,
        timePoint: Date
    ) {
        const calibrationOutputs: CalibrationStatus = {}

        const allDayReadings = Object.fromEntries(Object.entries(readings).map(
            ([ref, nodeReadings]) => [ref,
                ReadingsHelper.dayReadings(nodeReadings, timePoint)]))

        const helper = new CalibrationStatusFilter(nodes, allDayReadings,
            simulationData, timePoint)

        for (const nodeReference of Object.keys(simulationData)) {
            const name = FiltersCalculationHelper.getNodeByReference(
                nodes, nodeReference)?.name ?? ""
            this.debugLog(`---- Detecting calibration (${name})`)
            try {
                const {deviation, uncertainty, hasMeteredLoad} =
                    helper.getCalibrationVariance(nodeReference)
                calibrationOutputs[nodeReference] = {
                    deviation,
                    hasReadings: true,
                    name,
                    calibrationBound,
                    uncertainty,
                    hasMeteredLoad,
                }
            } catch(e) {
                if(e instanceof CalibrationStatusError) {
                    let hasReadings = true
                    if(e instanceof CalibrationStatusErrorReadingsFailure) {
                        console.error(e)
                    } else {
                        this.debugLog(e.message)
                        if(e instanceof CalibrationStatusErrorNoReadings) {
                            hasReadings = false
                        }
                    }
                    calibrationOutputs[nodeReference] = {
                        deviation: null,
                        hasReadings,
                        name,
                        calibrationBound,
                        uncertainty: e.uncertainty,
                        hasMeteredLoad: e.hasMeteredLoad,
                    }
                } else {
                    throw e
                }
            }
            this.debugLog(calibrationOutputs[nodeReference] as any)
        }

        return calibrationOutputs
    }

    /**
     *
     * @param nodes
     * @param readings
     * @param simulationData
     * @param timePoint
     */
    constructor(
        private nodes: NamedSimulationNode[],
        private readings: NodeReadings,
        private simulationData: SimulationData,
        private timePoint: Date,
    ) {
        super()
    }
}