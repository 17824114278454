import { DateHelper } from "@/core/helpers"
import { Reading } from "@/core/interfaces"

/**
 *
 */
export class ReadingsHelper {
    /**
     * @param date
     */
    private static dayFor(date: Date) {
        return DateHelper.fullYearMonthOnly(date)
    }

    /**
     *
     * @param timePoint
     * @returns
     */
    static closestReadingTo(timePoint: Date) {
        const timePointValue = timePoint.valueOf()
        return (a: Reading<Date>, b: Reading<Date>) => Math.abs(a.timestamp.valueOf() - timePointValue) -
            Math.abs(b.timestamp.valueOf() - timePointValue)
    }

    /**
     * Returns the readings filtered to the particular day
     *
     * @param readings
     * @param timePoint
     */
    static dayReadings(readings: Reading<Date>[], timePoint: Date): Reading<Date>[]
    static dayReadings(readings: Reading<Date>[] | null, timePoint: Date): Reading<Date>[] | undefined
    static dayReadings(readings: Reading<Date>[] | null, timePoint: Date): Reading<Date>[] | undefined {
        const targetDay = this.dayFor(timePoint)
        return readings?.filter(r => this.dayFor(r.timestamp) === targetDay)
    }

    /**
     *
     * @param readings
     * @param timePoint
     * @returns
     */
    static getClosestReading(readings: Reading<Date>[], timePoint: Date): Reading<Date> | undefined {
        return readings.sort(this.closestReadingTo(timePoint))[0]
    }
}