import { ScreenshotType } from "@/core/enums/ScreenshotType"
import { ActionTree, MutationTree } from "vuex"

class State {
    /**
     * 
     */
    requestedScreenshotType: ScreenshotType | null = null
}

const mutations: MutationTree<State> = {
    /**
     *
     * @param state
     * @param type
     */
    setRequestedScreenshotType(state, type: ScreenshotType) {
        state.requestedScreenshotType = type
    }
}

const actions: ActionTree<State, any> = {
    /**
     *
     * @param actionState
     * @param type
     */
    takeScreenshot({ commit }, type: ScreenshotType) {
        commit("setRequestedScreenshotType", type)
    },
    /**
     * @param actionState
     */
    clearRequestedScreenshot({ commit }) {
        commit("setRequestedScreenshotType", null)
    }
}

const ScreenshotModule = {
    namespaced: true,
    state: {
        ...new State(),
    },
    mutations: {
        ...mutations,
    },
    actions: {
        ...actions,
    },
}

export default ScreenshotModule