import { NodeReferenceHelper, NumberFormat } from "@/core/helpers"
import { NodeType } from "predict-performance-calculation"
import { SimulatableClientBase } from "./SimulatableClientBase"
import { Granularity } from "../enums"
import { DateGroupSimulationResult, GranularCapacityUsage, NamedSimulationNode, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { HistogramFormat } from "../interfaces/HistogramFormat"
import { NodeDynamicCosts, NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"

/**
 * Base class for simulation clients
 */
export abstract class ClientBase extends SimulatableClientBase {
    /**
     * @param nodes
     * @returns the IT node references in the nodeset
     */
    private nodesetITNodeReferences(nodes: NamedSimulationNode[]) {
        return nodes.filter(n => n.type === NodeType.ITNode).map(n => NodeReferenceHelper.getNodeReference(n))
    }

    /**
     * @param nodes
     * @returns the IT node references to consider. This doesn't guarantee
     * that they are in the nodeset.
     */
    protected getItNodeReferences(nodes: NamedSimulationNode[]) {
        return this.filterITNodeReferences || this.nodesetITNodeReferences(nodes)
    }

    /**
     * @param nodes
     * @param deviceSelected
     * @returns The references of all nodes, or as filtered
     */
    protected getNodeReferences(nodes: NamedSimulationNode[], deviceSelected: string | null) {
        return nodes
            .filter(node => !deviceSelected || (node.type == NodeType.Item && node.deviceModelType == deviceSelected))
            .map(node => NodeReferenceHelper.getNodeReference(node))
            .filter(nodeReference => !this.filterNodeReferences || this.filterNodeReferences.includes(nodeReference))
            .filter(nodeReference => !this.filterITNodeReferences || !nodeReference.match(/^itNode\//) || this.filterITNodeReferences.includes(nodeReference))
    }

    /**
     *
     * @param nodes
     * @returns True if a valid filter applies
     */
    protected isFilteringITNodes(nodes: NamedSimulationNode[]) {
        const filter = this.filterITNodeReferences
        return filter && this.nodesetITNodeReferences(nodes).some(r => !filter.includes(r))
    }

    /**
     *
     * @param result
     * @returns
     */
    protected summariseByNode<T extends {[k: string]: number}>(result: Map<string, Map<string, T>>) {
        const output: Map<string, T> = new Map()

        for (const nodeCosts of result.values()) {
            for (const [nodeReference, cost] of nodeCosts.entries()) {
                const outputValue = output.get(nodeReference)
                if (outputValue) {
                    for(const [k, v] of Object.entries(cost)) {
                        (outputValue[k] as number) = NumberFormat.to2dp(outputValue[k] + v)
                    }
                } else {
                    output.set(nodeReference, {...cost})
                }
            }
        }
        return output
    }

    /**
     *
     * @param result
     * @returns
     */
    protected summariseByPoint<T extends {[k: string]: number}>(result: Map<string, Map<string, T>>) {
        const output: Map<string, T> = new Map()

        for (const [point, nodeCosts] of result.entries()) {
            for (const cost of nodeCosts.values()) {
                const outputValue = output.get(point)
                if (outputValue) {
                    for(const [k, v] of Object.entries(cost)) {
                        (outputValue[k] as number) = NumberFormat.to2dp(outputValue[k] + v)
                    }
                } else {
                    output.set(point, {...cost})
                }
            }
        }
        return output
    }

    /**
     *
     */
    filterITNodeReferences: string[] | undefined = undefined

     /**
      *
      */
    filterNodeReferences: string[] | undefined = undefined

    /**
     * Call aggregation service for simulation
     *
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     */
    abstract simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo, deviceSelected: string | null): Promise<NodeDynamicCosts | NodeDynamicCostsByType | GranularCapacityUsage | HistogramFormat[] | DateGroupSimulationResult[]>

    /**
     * Granularity of the simulation
     */
    granularity: Granularity = Granularity.MONTH


    /**
     * Customise the Period to cover the span described by the provided dates
     *
     * This is inclusive, so 2022-01-01 to 2022-01-15 is 1 month, 2022-01-01 to 2022-02-01 is 2 months.
     *
     * @param startDate
     * @param endDate
     * @param oneDayPerMonth
     * @param inferGranularity If true, 2Y+ will be yearly, 3M+ monthly, otherwise daily
     */
    customPeriod(startDate: Date, endDate: Date, oneDayPerMonth = this.period.oneDayPerMonth, inferGranularity = false) {
        super.customPeriod(startDate, endDate, oneDayPerMonth)
        if(inferGranularity) {
            if(this.period.months >= 24) {
                this.granularity = Granularity.YEAR
            } else if(this.period.months >= 3) {
                this.granularity = Granularity.MONTH
            } else {
                this.granularity = Granularity.DAY
            }
        }
    }
}