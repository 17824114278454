
import { Models } from "@ekko/predict-client-api"
import { Interface } from "predict-performance-calculation"
import { PerformanceParameterBase } from "./PerformanceParameterBase"
import { LegacyFixes } from "@/Simulation/helpers/LegacyFixes"
import { LegacyPerfSplitTemperatureType } from "@/core/enums/LegacyPerfSplitTemperatureType"

export class GridListParameter extends PerformanceParameterBase<Models.GridListModel> {


    async fetch() {

        const tempLoadLosses: { temperature: number, loadLosses: { load: number, loss: number }[] }[] = []
        for await (const tempLoadLoss of this.performance.getTempLoadLosses()) {

            const newTempLoadLoss = { loadLosses: [] } as unknown as { temperature: number, loadLosses: { load: number, loss: number }[] }

            newTempLoadLoss.temperature = tempLoadLoss.getTemperature() as number

            for await (const loadLoss of tempLoadLoss.getLoadLosses()) {
                newTempLoadLoss.loadLosses.push({
                    load: Number(loadLoss.getLoad()),
                    loss: Number(loadLoss.getLosses())
                })

                tempLoadLosses.push(newTempLoadLoss)

            }
        }

        const storedTemperatureType =
            this.performance.getTemperatureType() as Interface.TemperatureType | LegacyPerfSplitTemperatureType

        return {
            capacity: this.performance.getCapacity()!,
            type: "gridList",
            temperatureType:
                LegacyFixes.temperatureTypeFromLegacy(storedTemperatureType),
            tempLoadLosses
        } as Interface.GridListPerformanceData
    }
}