const ISOStates = {
    "AL": {
        name: "Alabama"
    },
    "AK": {
        name: "Alaska"
    },
    "AS": {
        name: "American Samoa"
    },
    "AZ": {
        name: "Arizona"
    },
    "AR": {
        name: "Arkansas"
    },
    "CA": {
        name: "California"
    },
    "CO": {
        name: "Colorado"
    },
    "CT": {
        name: "Connecticut"
    },
    "DE": {
        name: "Delaware"
    },
    "DC": {
        name: "District Of Columbia"
    },
    "FM": {
        name: "Federated States Of Micronesia"
    },
    "FL": {
        name: "Florida"
    },
    "GA": {
        name: "Georgia"
    },
    "GU": {
        name: "Guam"
    },
    "HI": {
        name: "Hawaii"
    },
    "ID": {
        name: "Idaho"
    },
    "IL": {
        name: "Illinois"
    },
    "IN": {
        name: "Indiana"
    },
    "IA": {
        name: "Iowa"
    },
    "KS": {
        name: "Kansas"
    },
    "KY": {
        name: "Kentucky"
    },
    "LA": {
        name: "Louisiana"
    },
    "ME": {
        name: "Maine"
    },
    "MH": {
        name: "Marshall Islands"
    },
    "MD": {
        name: "Maryland"
    },
    "MA": {
        name: "Massachusetts"
    },
    "MI": {
        name: "Michigan"
    },
    "MN": {
        name: "Minnesota"
    },
    "MS": {
        name: "Mississippi"
    },
    "MO": {
        name: "Missouri"
    },
    "MT": {
        name: "Montana"
    },
    "NE": {
        name: "Nebraska"
    },
    "NV": {
        name: "Nevada"
    },
    "NH": {
        name: "New Hampshire"
    },
    "NJ": {
        name: "New Jersey"
    },
    "NM": {
        name: "New Mexico"
    },
    "NY": {
        name: "New York"
    },
    "NC": {
        name: "North Carolina"
    },
    "ND": {
        name: "North Dakota"
    },
    "MP": {
        name: "Northern Mariana Islands"
    },
    "OH": {
        name: "Ohio"
    },
    "OK": {
        name: "Oklahoma"
    },
    "OR": {
        name: "Oregon"
    },
    "PW": {
        name: "Palau"
    },
    "PA": {
        name: "Pennsylvania"
    },
    "PR": {
        name: "Puerto Rico"
    },
    "RI": {
        name: "Rhode Island"
    },
    "SC": {
        name: "South Carolina"
    },
    "SD": {
        name: "South Dakota"
    },
    "TN": {
        name: "Tennessee"
    },
    "TX": {
        name: "Texas"
    },
    "UT": {
        name: "Utah"
    },
    "VT": {
        name: "Vermont"
    },
    "VI": {
        name: "Virgin Islands"
    },
    "VA": {
        name: "Virginia"
    },
    "WA": {
        name: "Washington"
    },
    "WV": {
        name: "West Virginia"
    },
    "WI": {
        name: "Wisconsin"
    },
    "WY": {
        name: "Wyoming"
    }
}

export default ISOStates