import { EkkoSoftDataType, EkkoSoftItem } from "@/core/interfaces"
import { httpService } from "../http.service"
import { BaseSensorService } from "./base-sensor.service"

/**
 *
 */
export class EkkoSoftSensorService
extends BaseSensorService<EkkoSoftItem, EkkoSoftDataType>
{
    protected dataTypeFilter(datatype: EkkoSoftDataType[""]) {
        return !!datatype.label.match(/power|cooling/i)
    }

    protected async getSiteSensorInformationRaw() {
        return httpService.ekkoSoftSensorInformation(this.siteId)
    }
}