import { PercentPremultiplied } from "./PercentPremultiplied"

/**
 *
 */
export class Percent extends PercentPremultiplied {
    format(value: number, options?: {digits?: number}): string {
        return super.format(value * 100, options)
    }

    nativeToPreferred(value: number, options?: {digits?: number}): number {
        return super.nativeToPreferred(value * 100, options)
    }

    preferredToNative(value: number): number {
        return value / 100
    }
}