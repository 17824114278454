import { Paginated } from "@/core/helpers/Paginated"
import ApiService from "@/core/services/api.service"
import { MeterPointsState } from "@/store/meter-points/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<MeterPointsState> {

    /**
     *
     */
    meterPoints: Models.SensorConfigMeterPointModel[] = [];

    /**
     *
     */
    loaded = false
}

const getters: GetterTree<MeterPointsState, any> = {
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getMeterPoints(state, getters): PaginatedResult<Models.SensorConfigMeterPointModel> {
        return new Paginated(
            state.meterPoints, state.page, state.pageSize, getters.meterPointSort,
            getters.meterPointFilter
        )
    },
    /**
     *
     * @param state
     * @returns
     */
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.meterPoints?.length === 0
    },
    /**
     *
     * @param state
     * @returns
     */
    meterPointFilter(state): (a: Models.SensorConfigMeterPointModel) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return meterPoint => !!meterPoint?.getName()?.match(regexp) ||
            !!meterPoint?.getMeterPointType()?.match(regexp)
    },
    /**
     *
     * @param state
     * @returns
     */
    meterPointSort(state): (a: Models.SensorConfigMeterPointModel, b: Models.SensorConfigMeterPointModel) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "Label":
                return Paginated.sortUndefinedFirst((meterPoint) => meterPoint.getName(), reverse)
            case "Type":
                return Paginated.sortUndefinedFirst((meterPoint) => meterPoint.getMeterPointType(), reverse)
            default:
                return (a, b) => 0
        }
    },
}

const mutations: MutationTree<State> = {

    /** */
    setMeterPoints(state, meterPoints): void {
        state.meterPoints = meterPoints
    },
    /**
     *
     * @param state
     * @param loaded
     */
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    /**
     *
     * @param state
     * @param meterPoint
     */
    mutDeleteMeterPoint(state, meterPoint: Models.SensorConfigMeterPointModel): void {
        state.meterPoints = state.meterPoints.filter(m => (m as any).id !== (meterPoint as any).id)
    },
    /**
     *
     * @param state
     * @param meterPoint
     */
    mutCreateMeterPoint(state, meterPoint: Models.SensorConfigMeterPointModel): void {
        state.meterPoints.push(meterPoint)
    },
    /**
     *
     * @param state
     * @param meterPoint
     */
    mutUpdateMeterPoint(state, meterPoint: Models.SensorConfigMeterPointModel): void {
        state.meterPoints = state.meterPoints.map(m => {
            if ((m as any).id === (meterPoint as any).id) {
                return meterPoint
            }
            return m
        })
    },
}

const actions = <ActionTree<State, any>>{
    /**
     *
     * @param actionState
     * @param sensorConfigSiteId
     */
    async fetchMeterPoints({ commit }, sensorConfigSiteId: string) {

        const siteService = await ApiService.getSensorConfigSiteService()
        const site = await siteService.getSensorConfigSite(sensorConfigSiteId) as Models.SensorConfigSiteModel
        const recordsFound: Models.SensorConfigMeterPointModel[] = []
        for await (const room of site.getRooms()) {
            for await (const meterPoint of room.getMeterPoints()) {
                recordsFound.push(meterPoint)
            }
        }

        commit("setLoaded", true)
        commit("setMeterPoints", recordsFound)
    },
    /**
     *
     * @param actionState
     * @param meterPoint
     */
    async deleteMeterPoint({ commit }, meterPoint: Models.SensorConfigMeterPointModel) {
        const meterPointService = await ApiService.getSensorConfigMeterPointService()
        await meterPointService.removeSensorConfigMeterPoint(meterPoint)
        commit("mutDeleteMeterPoint", meterPoint)
    },
    /**
     *
     * @param actionState
     * @param meterPoint
     */
    async createMeterPoint({ commit }, meterPoint: Models.SensorConfigMeterPointModel) {
        const meterPointService = await ApiService.getSensorConfigMeterPointService()
        const [newMeterPoint] = await meterPointService.addSensorConfigMeterPoint(meterPoint)
        commit("mutCreateMeterPoint", newMeterPoint)
        return newMeterPoint
    },
    /**
     *
     * @param actionState
     * @param meterPoint
     */
    async updateMeterPoint({ commit }, meterPoint: Models.SensorConfigMeterPointModel) {
        const meterPointService = await ApiService.getSensorConfigMeterPointService()
        await meterPointService.updateSensorConfigMeterPoint(meterPoint)
        commit("mutUpdateMeterPoint", meterPoint)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const MeterPointModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default MeterPointModule
