import { TimeBasedParameter } from "@/Simulation"
import { AnyNode } from "../../store/SiteViewState"
import { StoredNodesDataPromise, StoredNodesDataResult } from "../../store/simulation-view"

/**
 *
 */
export class NodesDataFetcher {
    /**
     *
     */
    private debug = false
    /**
     *
     */
    private last: StoredNodesDataPromise | StoredNodesDataResult | null = null

    /**
     *
     */
    private nextRevision = 0

    /**
     *
     * @param message
     */
    private debugLog(message: any) {
        if(this.debug) {
            console.debug(message)
        }
    }

    /**
     *
     * @param state
     */
    private async fetch(nodes: AnyNode[]) {
        const promise = TimeBasedParameter.buildNodesData(nodes)
        const matches = (nodesIn: AnyNode[]) => {
            return nodes.length == nodesIn.length &&
                (nodesIn.length == 0 || nodes[0].id == nodesIn[0].id)
        }
        const revision = this.nextRevision
        this.debugLog(`Starting nodes data fetch #${revision}`)
        this.last = {
            promise,
            matches,
            revision,
        }
        this.nextRevision++
        const result = await promise
        this.debugLog(`Finished nodes data fetch #${revision}`)
        if (this.last.revision == revision) {
            this.last = {
                result,
                matches,
                revision,
            }
        }
        return result
    }

    /**
     * If it's already cached, returns it; otherwise returns null
     *
     * @param nodes
     * @returns
     */
    cached(nodes: AnyNode[]) {
        if (this.last && this.last.matches(nodes)) {
            if ("promise" in this.last) {
                this.debugLog("Not ready yet")
                return null
            } else {
                this.debugLog("Ready")
                return this.last.result
            }
        } else {
            this.fetch(nodes).catch(
                e => console.error(e)) // Just trigger it to load if needed.
            this.debugLog("Started")
            return null
        }
    }

    /**
     *
     */
    clear() {
        this.last = null
    }

    /**
     *
     * @param state
     */
    fetchCached(nodes: AnyNode[]) {
        if (this.last && this.last.matches(nodes)) {
            if ("promise" in this.last) {
                this.debugLog(
                    `Retaining existing nodes data (promise) #${this.last.revision}`)
                return this.last.promise
            } else {
                this.debugLog(
                    `Retaining existing nodes data #${this.last.revision}`)
                return this.last.result
            }
        }
        return this.fetch(nodes)
    }
}
