/**
 *
 */
export enum NodeError {
    nodeDisconnected,
    noModel,
    noProvisioning,
    invalidResilience,
    invalidInOut,
    invalidType,
    overCapacity,
}
