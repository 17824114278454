import { NodeReferenceHelper, NumberFormat } from "@/core/helpers"
import { Temperature, UnbatchEvents } from "@/core/interfaces"
import { powerSimulatorService } from "@/core/services/power-simulator.service"
import { Models } from "@ekko/predict-client-api"
import { Interface, ItLoadProvisioning, NodeType } from "predict-performance-calculation"
import { ClimateHelper } from "../helpers/ClimateHelper"
import { NamedSimulationNode, Provisioning } from "../interfaces"

/**
 * A site configuration profile
 */
export abstract class ProfileBase {

    static regularPoints = 10

    /**
     *
     * @param site
     * @param nodes
     * @param provisioning
     */
    constructor(private site: Models.SiteModel, private nodes: NamedSimulationNode[], private provisioning: Provisioning) {

    }

    /**
     *
     * @returns
     */
    private async temperaturePoints() {

        const min = -10
        const max = 35
        const offset = NumberFormat.to2dp((max - min) / ProfileBase.regularPoints)

        const points: Temperature[] = []
        for (let pointIterator = 0; pointIterator <= ProfileBase.regularPoints; pointIterator++) {
            const dryBulb = NumberFormat.to2dp(min + (pointIterator * offset))
            //Pick the highest RH from climate profile
            const highestRH = await this.getHighestRH() ?? 50
            const wetBulb = ClimateHelper.wetBulbTemperature(dryBulb, highestRH)
            points.push({ dryBulb, wetBulb })
        }

        return points

    }

    /**
     *
     * @returns
     */
    private async getHighestRH() {
        const climateProfile = await this.site.getClimateProfileReference()

        return ClimateHelper.getHighestRelativeHumidity(climateProfile!.id)
    }

    /**
     *
     * @returns
     */
    private staticLoadPoints() {
        const loadPoints: { [ref: string]: number }[] = []
        const offset = 0.1
        const itNodes = this.nodes.filter(node => node.type === NodeType.ITNode) as Interface.ITNode[]
        const otherNodes = this.nodes.filter(node => node.type === NodeType.OtherNode) as Interface.OtherNode[]

        for (let pointIterator = 0; pointIterator <= ProfileBase.regularPoints; pointIterator++) {
            const loads: { [ref: string]: number } = {}

            for (const itNode of itNodes) {

                const load = ItLoadProvisioning.ITLoadHandler.capacityForDate(itNode.itProvisioning!, new Date())
                loads[NodeReferenceHelper.getNodeReference(itNode)] = load * offset * pointIterator
            }
            for (const otherNode of otherNodes) {
                const load = ItLoadProvisioning.OtherLoadHandler.capacityForDate(otherNode.otherProvisioning!, new Date())
                loads[NodeReferenceHelper.getNodeReference(otherNode)] = load * offset * pointIterator
            }
            loadPoints.push(loads)
        }

        return loadPoints
    }

    /**
     * Calculates all temperature points vs all IT loads for a DCiE/PUE profile
     *
     * @param unbatchCallbacks
     * @returns
     */
    private async batchSimulate(unbatchCallbacks: UnbatchEvents | null = null) {

        const simulationResult = new Map<number, Map<number, { [key: string]: [number, number, number] }>>()

        const temperaturePoints = await this.temperaturePoints()
        const staticLoadPoints = this.staticLoadPoints()
        unbatchCallbacks?.start?.(temperaturePoints.length * staticLoadPoints.length)
        let i = 0
        const promises: Promise<any>[] = []
        for (const temperature of temperaturePoints) {
            const temperatureLoads = new Map<number, { [key: string]: [number, number, number] }>()
            simulationResult.set(temperature.dryBulb, temperatureLoads)
            for (const itLoad of staticLoadPoints) {
                promises.push(
                    powerSimulatorService.simulateBatchPossible(
                        this.nodes,
                        temperature,
                        itLoad,
                        this.provisioning,
                    ).then((simulationData) => {
                        unbatchCallbacks?.progress?.(++i)

                        const sumload = Object.values(itLoad).reduce((a, b) => a + b)
                        temperatureLoads.set(sumload, simulationData)
                    })
                )
            }
        }
        await Promise.all(promises)
        return simulationResult

    }

    /**
     *
     * @param efficiencyCalculator
     * @param unbatchCallbacks
     * @returns
     */
    protected async simulateInner(
        efficiencyCalculator: (powerLoss: number, itLoad: number) => number,
        unbatchCallbacks: UnbatchEvents | null = null)
    {
        const results = await this.batchSimulate(unbatchCallbacks)

        const output: { [temperature: number]: { [itLoad: number]: number } } = {}
        for (const [temperature, simulationForItLoads] of results.entries()) {

            const itLoadOutput: { [itLoad: number]: number } = {}
            const powerSupplyNode = this.nodes?.find(n => !n.cooledBy && !n.poweredBy)

            if (powerSupplyNode) {
                for (const [itLoad, simulationResult] of simulationForItLoads.entries()) {
                    let sumItLoad
                    let powerLoss
                    for (const [nodeReference, [powerDemand, powerLoad]] of Object.entries(simulationResult)) {
                        const loss = powerDemand - powerLoad
                        if (nodeReference.startsWith("itNode/")) {
                            if (!sumItLoad) {
                                sumItLoad = 0
                            }
                            sumItLoad += loss
                        }
                    }
                    if (sumItLoad) {
                        sumItLoad = sumItLoad / 1000
                        const supplyNodeRef = NodeReferenceHelper.getNodeReference(powerSupplyNode)
                        const [powerDemand] = simulationResult[supplyNodeRef]
                        itLoadOutput[itLoad] = efficiencyCalculator(powerDemand/1000, sumItLoad)
                    }
                }
            }
            if (Object.keys(itLoadOutput).length) {
                output[temperature] = itLoadOutput
            }
        }

        return output

    }

    /**
     *
     * @param unbatchCallbacks
     * @returns
     */
    abstract simulate(unbatchCallbacks?: UnbatchEvents | null): Promise<Record<number, Record<number, number>>>
}