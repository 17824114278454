import { NodeReferenceHelper } from "@/core/helpers"
import { NodeReference } from "@/Simulation"
import { NodeParameterBase } from "./NodeParameterBase"

/**
 *
 */
type SingleSupplyNodeModel = NodeReference & {
    getCooledBy(): Promise<NodeReference | null>,
    getPoweredBy(): Promise<NodeReference | null>
}

/**
 *
 */
type SingleSupplySimulatorNode = NodeReference &
    {cooledBy: NodeReference | null, poweredBy: NodeReference | null}

/**
 *
 */
export class SimpleParameter<M extends SingleSupplyNodeModel, I extends SingleSupplySimulatorNode> extends NodeParameterBase<M, I> {
    async fetch(node: M) {
        return {
            ...await this.fetchBasic(node),
            ...await NodeReferenceHelper.getSupplierReference(node),
        } as I
    }
}