import { Paginated } from "@/core/helpers/Paginated"
import ApiService from "@/core/services/api.service"
import { ManufacturersState } from "@/store/manufacturers/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<ManufacturersState> {
    manufacturers: Models.ManufacturerModel[] = [];
    loaded = false
}

const getters: GetterTree<ManufacturersState, any> = {
    /**
     *
     * @param state
     * @returns
     */
    getManufacturers(state, getters): PaginatedResult<Models.ManufacturerModel> {
        const results = getters.usableManufacturers
        if(results) {
            return new Paginated(
                results, state.page, state.pageSize, getters.manufacturerSort,
                getters.manufacturerFilter
            )
        } else {
            return { results: [], totalCount: 0 }
        }
    },
    getManufacturer: (state) => (id: string): Models.ManufacturerModel | undefined => {
        return state.manufacturers.find(m => (m as any).id === id)
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.manufacturers?.length === 0
    },
    /**
     * This returns the filter for manufacturer
     *
     * @param state
     * @returns
     */
    manufacturerFilter(state): (a: Models.ManufacturerModel) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return manufacturer => !!manufacturer?.getName()?.match(regexp) ||
            !!manufacturer?.getDescription()?.match(regexp)
    },
    /**
     * This returns the sort for manufacturer
     *
     * @param state
     * @returns
     */
    manufacturerSort(state): (a: Models.ManufacturerModel, b: Models.ManufacturerModel) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "Manufacturer":
                return Paginated.sortUndefinedFirst((manufacturer) => manufacturer.getName(), reverse)
            default:
                return (a, b) => 0
        }
    },
    /**
     *
     * @param state
     */
    usableManufacturers(state): Models.ManufacturerModel[] {
        return state.manufacturers.filter(
            m => !m.getDefunct()
        )
    }
}

const mutations: MutationTree<State> = {
    setManufacturers(state, manufacturers): void {
        state.manufacturers = manufacturers
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    mutDeleteManufacturer(state, manufacturer: Models.ManufacturerModel): void {
        state.manufacturers = state.manufacturers.filter(m => (m as any).id !== (manufacturer as any).id)
    },
    mutCreateManufacturer(state, manufacturer: Models.ManufacturerModel): void {
        state.manufacturers.push(manufacturer)
    },
    mutUpdateManufacturer(state, manufacturer: Models.ManufacturerModel): void {
        state.manufacturers = state.manufacturers.map(m => {
            if ((m as any).id === (manufacturer as any).id) {
                return manufacturer
            }
            return m
        })
    },
}

const actions = <ActionTree<State, any>>{
    async fetchManufacturers({ commit }) {
        const service = await ApiService.getManufacturerService()
        const records = service.getAllManufacturers()
        const recordsFound: Models.ManufacturerModel[] = []
        for await (const item of records) {
            recordsFound.push(item)
        }
        commit("setLoaded", true)
        commit("setManufacturers", recordsFound)
    },
    deleteManufacturer({ commit }, manufacturer: Models.ManufacturerModel) {
        commit("mutDeleteManufacturer", manufacturer)
    },
    createManufacturer({ commit }, manufacturer: Models.ManufacturerModel) {
        commit("mutCreateManufacturer", manufacturer)
    },
    updateManufacturer({ commit }, manufacturer: Models.ManufacturerModel) {
        commit("mutUpdateManufacturer", manufacturer)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const ManufacturerModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default ManufacturerModule
