import { NumberFormat } from "@/core/helpers"
import { MeteredData, UnbatchEvents } from "@/core/interfaces"
import { SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"
import { EnergyBaseClient } from "./EnergyBaseClient"

export class PUEClient extends EnergyBaseClient {
    /**
     *
     * @param parentOutput
     * @returns
     */
    private rescaleByNode(parentOutput: NodeDynamicCostsByType) {
        const output: Map<string, { fixed: number, variable: number, it: number }> = new Map()

        let itSum = 0
        for (const value of parentOutput.values()) {
            itSum += value.it
        }

        for (const [nodeReference, value] of parentOutput.entries()) {
            output.set(nodeReference, {
                fixed: NumberFormat.to3dp(value.fixed / itSum),
                variable: NumberFormat.to3dp(value.variable / itSum),
                it: NumberFormat.to3dp(value.it / itSum),
            })
        }

        return output
    }

    /**
     *
     * @param parentOutput
     * @returns
     */
    private rescaleByPoint(parentOutput: NodeDynamicCostsByType) {
        const output: Map<string, { fixed: number, variable: number, it: number }> = new Map()

        for (const [nodeReference, value] of parentOutput.entries()) {
            output.set(nodeReference, {
                fixed: NumberFormat.to3dp(value.fixed / value.it),
                variable: NumberFormat.to3dp(value.variable / value.it),
                it: 1,
            })
        }

        return output
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergy(nodesets, siteInfo,
            deviceSelected, unbatchCallbacks)
        return this.rescaleByPoint(parentOutput)
    }

    /**
     *
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param unbatchCallbacks
     * @returns
     */
    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected?: string | null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyAllocated(nodesets,
            siteInfo, null, unbatchCallbacks)
        return this.rescaleByPoint(parentOutput)
    }

    /**
     *
     * @param nodesets
     * @param siteInfo
     * @returns
     */
    async simulateAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected?: string | null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyAttributed(nodesets,
            siteInfo, null, unbatchCallbacks)
        return this.rescaleByNode(parentOutput)
    }

    /**
     *
     * @param nodesets
     * @param siteInfo
     * @param deviceSelected
     * @param meteredData
     * @param unbatchCallbacks
     * @returns
     */
    async simulateHistoricalAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const parentOutput = await this.simulateEnergyHistoricalAttributed(
            nodesets, siteInfo, deviceSelected, meteredData, unbatchCallbacks
        ) as NodeDynamicCostsByType
        return this.rescaleByNode(parentOutput)
    }
}