import { NumberFormat } from "@/core/helpers/NumberFormat"
import { AggregatorSimulateClient, MeteredData, UnbatchEvents } from "@/core/interfaces"
import { Models } from "@ekko/predict-client-api"
import { CostsByType, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { NodeDynamicCostsByType } from "../interfaces/NodeDynamicCosts"
import { EnergyBaseClient } from "./EnergyBaseClient"
import { ReadingType } from "@/History/enums"

/**
 *
 */
export class CO2Client extends EnergyBaseClient implements AggregatorSimulateClient {
    /**
     *
     * @param rate
     * @param energyClientResult
     * @returns
     */
    private calculateValues(rate: number | null, energyClientResult: NodeDynamicCostsByType) {
        if (rate != null) {
            for (const [key, value] of energyClientResult.entries()) {
                const costs: CostsByType = {
                    fixed: NumberFormat.to2dp(value.fixed * rate),
                    variable: NumberFormat.to2dp(value.variable * rate),
                    it: NumberFormat.to2dp(value.it * rate),
                }

                if (value.capEx) {
                    costs.capEx = NumberFormat.to2dp(value.capEx * rate)
                }

                if (value.opEx) {
                    costs.opEx = NumberFormat.to2dp(value.opEx * rate)
                }

                energyClientResult.set(key, costs)
            }
        }
        return energyClientResult
    }

    /**
     *
     * @param site
     * @param readingType
     * @returns
     */
    getHistoricalReadings(site: Models.SiteModel, readingType?: ReadingType.POWER_LOAD | ReadingType.POWER_SUPPLY) {
        return this.getHistoricalEnergyReadings(site, readingType)
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const energyClientResult = await this.simulateEnergy(nodesets, siteInfo,
            deviceSelected, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)
    }

    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const energyClientResult = await this.simulateEnergyAllocated(nodesets,
            siteInfo, deviceSelected, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)
    }

    async simulateAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<NodeDynamicCostsByType> {
        const energyClientResult = await this.simulateEnergyAttributed(
            nodesets, siteInfo, deviceSelected, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)
    }

    async simulateHistorical(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        const energyClientResult = await this.simulateEnergyHistorical(nodesets,
            siteInfo, deviceSelected, meteredData, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)
    }

    async simulateHistoricalAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        const energyClientResult = await this.simulateEnergyHistoricalAllocated(
            nodesets, siteInfo, deviceSelected, meteredData, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)

    }

    async simulateHistoricalAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks: UnbatchEvents | null = null
    ) {
        const energyClientResult =
            await this.simulateEnergyHistoricalAttributed(nodesets, siteInfo,
                deviceSelected, meteredData, unbatchCallbacks)
        const rate = siteInfo.co2PerKWh

        return this.calculateValues(rate, energyClientResult)

    }
}