import { DateHelper, NodeReferenceHelper, NumberFormat } from "@/core/helpers"
import { Interface, NodeType } from "predict-performance-calculation"
import { PeriodHelper } from "../helpers/PeriodHelper"
import { CapacityUsage, CustomPeriodClient, GranularCapacityUsage, NamedSimulationNode, Period, SimulationSiteConfiguration } from "../interfaces"

/**
 *
 */
export class CapacityClient implements CustomPeriodClient {
    /**
     * Period to simulate. By default this is past 12 months.
     */
    period: Period

    /**
     *
     */
    get simPeriod() {
        return {
            ...this.period,
            oneDayPerMonth: false,
        }
    }

    /**
     *
     */
    constructor() {
        // Temporary
        const startPoint = new Date()
        const periodLength = 12
        startPoint.setDate(1)
        startPoint.setMonth(startPoint.getMonth() - periodLength)
        this.period = {
            start: DateHelper.fullYearMonthOnly(startPoint),
            months: periodLength
        }
    }

    customPeriod(startDate: Date, endDate: Date) {
        const periodLength = (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            endDate.getMonth() - startDate.getMonth() + 1
        this.period = {
            start: DateHelper.fullYearMonthOnly(startDate),
            months: periodLength
        }
    }

    /**
     * @param nodes
     * @returns the IT node references in the nodeset
     */
    private nodesetITNodeReferences(nodes: NamedSimulationNode[]) {
        return nodes.filter(n => n.type === NodeType.ITNode).map(n => NodeReferenceHelper.getNodeReference(n))
    }

    /**
     * @param nodes
     * @returns the IT node references to consider. This doesn't guarantee
     * that they are in the nodeset.
     */
    protected getItNodeReferences(nodes: NamedSimulationNode[]) {
        return this.filterITNodeReferences || this.nodesetITNodeReferences(nodes)
    }

    /**
     *
     */
    filterITNodeReferences: string[] | undefined = undefined

    /**
     *
     * @param nodesets
     * @returns
     */
    simulate(nodesets: SimulationSiteConfiguration[]): GranularCapacityUsage {
        const dailyOutput: GranularCapacityUsage = new Map<string, CapacityUsage>()
        for(const nodeset of nodesets) {
            const itNodeReferences = this.getItNodeReferences(nodeset.nodes)
            const itNodes = nodeset.nodes.filter(
                n => n.type === NodeType.ITNode && itNodeReferences.includes(NodeReferenceHelper.getNodeReference(n))
            ) as (Interface.ITNode & {name: string})[]

            const overlap = PeriodHelper.overlap(this.period, nodeset.start, nodeset.finish)
            if(!overlap) {
                continue
            }

            for (let day = new Date(overlap.start as Date); day <= overlap.finish; day.setDate(day.getDate() + 1)) {
                const pointData = { capacity: 0, usage: 0, }

                let totalITLoad = 0
                for (const node of itNodes) {
                    const itProvisioningEvents = node.itProvisioning?.itProvisioningEvents
                    if(!itProvisioningEvents?.length) continue
                    let activeProvisioningEvent = itProvisioningEvents[itProvisioningEvents.length - 1]

                    for (const provisioningEvent of itProvisioningEvents) {
                        if (new Date(provisioningEvent.month) <= day) {
                            activeProvisioningEvent = provisioningEvent
                        }
                    }

                    pointData.capacity += activeProvisioningEvent.power

                    const loadHours = activeProvisioningEvent.itLoadProfile.itLoadHours

                    let nodeITLoad = 0
                    let lastLoadHour: Interface.ITLoadHour = {
                        hour: 0,
                        load: loadHours[loadHours.length - 1].load
                    }
                    for (const loadHour of loadHours) {
                        nodeITLoad += activeProvisioningEvent.power * lastLoadHour.load * (loadHour.hour - lastLoadHour.hour)
                        lastLoadHour = loadHour
                    }

                    nodeITLoad += activeProvisioningEvent.power * lastLoadHour.load * (24 - lastLoadHour.hour)

                    // Average kW
                    pointData[node.name] = NumberFormat.to2dp(nodeITLoad / 24)
                    totalITLoad += nodeITLoad
                }
                pointData.usage = NumberFormat.to2dp(totalITLoad / 24)

                dailyOutput.set(day.toISOString().replace(/T.*/, ""), pointData)
            }

        }
        const collated = new Map<string, CapacityUsage[]>()
        for(const [day, pointData] of dailyOutput.entries()) {
            const month = day.replace(/-[^-]+$/, "")
            const existing = collated.get(month)
            if(existing) {
                existing.push(pointData)
            } else {
                collated.set(month, [pointData])
            }
        }
        const output = new Map<string, CapacityUsage>()
        for(const [month, pointDatasets] of collated.entries()) {
            const pointDataOut = {
                usage: NumberFormat.to2dp(pointDatasets.reduce((c, a) => c + a.usage, 0) / pointDatasets.length),
                capacity: NumberFormat.to2dp(pointDatasets.reduce((c, a) => c + a.capacity, 0) / pointDatasets.length),
            }
            const itNodeUsageSums = new Map<string, {sum: number, count: number}>()
            for(const pointData of pointDatasets) {
                for(const [k, v] of Object.entries(pointData)) {
                    if(k == "usage" || k == "capacity") {
                        continue
                    }
                    const existing = itNodeUsageSums.get(k)
                    if(existing) {
                        existing.sum += v
                        existing.count++
                    } else {
                        itNodeUsageSums.set(k, {sum: v, count: 1})
                    }
                }
            }
            for(const [itNode, usageSum] of itNodeUsageSums.entries()) {
                pointDataOut[itNode] = NumberFormat.to2dp(usageSum.sum / usageSum.count)
            }
            output.set(month, pointDataOut)
        }
        return output
    }
}