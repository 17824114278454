/**
 *
 */
export enum AccessControlType {
    /**
     * Access for a specific user
     */
    group = "group",
    /**
     * Access for all orgs with the same issuer (namespace)
     */
    iss = "iss",
    /**
     * Access for all orgs
     */
    other = "other",
    /**
     * Access for a specific org
     */
    ownerReference = "ownerReference",
    /**
     * @deprecated
     * Access for users with a specific role
     */
    role = "role",
    /**
     * Access for a specific user
     */
    user = "user",
}