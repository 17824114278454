import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { Base } from "./Base"
import { NRef } from "@/store/SiteViewState"

/**
 *
 */
export class Combiner extends Base<Models.CombinerModel> {
    /**
     *
     * @returns
     */
    private getService() {
        return ApiService.getNamedService<Services.CombinerService>("CombinerService")
    }
    async add(): Promise<NRef<Models.CombinerModel>> {
        const service = await this.getService()
        const [nodeOut] = await service.addCombiner(this.node)
        this.node = nodeOut
        await this.connect()
        return nodeOut
    }
    async connect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.addCombiners([this.node])
    }
    async disconnect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.removeCombiners([this.node])
    }
    async remove(): Promise<any> {
        const service = await this.getService()
        await service.removeCombiner(this.node)
        return this.disconnect()
    }
    async update(): Promise<any> {
        const service = await this.getService()
        await service.updateCombiner(this.node)
    }
}