import { Granularity, GraphType, Period, SimPeriod, SimulationNode, SiteInfo } from "@/Simulation"
import { AggregatorResponse } from "@/Simulation/interfaces/AggregatorResponse"
import { StaticCostResponse } from "@/Simulation/interfaces/StaticCostResponse"
import { cacheWrap } from "@ekko/predict-utilities"
import { Endpoint, SimulationBatchResults, StaticCostBatchResults } from "../helpers"
import { Unbatch } from "../helpers/Unbatch"
import { EnvCondition, Reading, UnbatchEvents } from "../interfaces"
import { AnyJSONRPC } from "./AnyJSONRPC"

/**
 * JSONRPC service for calling the aggregator service
 */
class AggregatorService extends AnyJSONRPC {
    /**
     * @param readings
     */
    private readingsToJson(readings: Map<string, Map<string, Reading<Date>[]>>): Record<string, Record<string, Reading<string>[]>> {
        return Object.fromEntries(
            [...readings.entries()].map(
                ([timespec, nodeReadings]) => [
                    timespec,
                    Object.fromEntries(
                        [...nodeReadings.entries()].map(
                            ([nodeReference, readings]) => [
                                nodeReference,
                                readings.map(reading => ({...reading, timestamp: reading.timestamp.toISOString()}))
                            ]
                        )
                    )
                ]
            )
        )
    }

    /**
     *
     */
    protected endpoint: Endpoint = "aggregator"

    /**
     *
     * @param type
     * @param allocate
     * @param nodes
     * @param siteInfo
     * @param period
     * @param granularity
     * @param unbatchCallbacks
     * @returns
     */
    @Unbatch({period: 4, unbatchCallbacks: 6, granularity: 5},
        granularity => new SimulationBatchResults(granularity))
    @cacheWrap(300)
    async simulate(
        type: GraphType,
        allocate: boolean,
        nodes: SimulationNode[],
        siteInfo: SiteInfo,
        period: SimPeriod,
        granularity: Granularity,
        unbatchCallbacks?: UnbatchEvents
    ): Promise<AggregatorResponse> {
        unbatchCallbacks?.start?.(period.months)
        const result1 = await this.callSimulateTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [1,7]}, granularity)
        const result2 = await this.callSimulateTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [8,14]}, granularity)
        const result3 = await this.callSimulateTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [15,21]}, granularity)
        const result4 = await this.callSimulateTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [22,31]}, granularity)
        unbatchCallbacks?.progress?.(period.months)
        //need to merge results here
        return {...result1, ...result2, ...result3, ...result4}
    }
    /**
     * Simulates on this specific time slice
     * @param type
     * @param allocate
     * @param nodes
     * @param siteInfo
     * @param period
     * @param granularity
     * @returns
     */
    private async callSimulateTimeSliced (
        type: GraphType,
        allocate: boolean,
        nodes: SimulationNode[],
        siteInfo: SiteInfo,
        period: SimPeriod,
        granularity: Granularity,
        ) {
        const result = await this.callMethodBackgroundable(
            "simulate",
            {
                type,
                allocate,
                nodes,
                siteInfo,
                period,
                granularity,
            },
            cursor => ({url: `/backgroundJob/${cursor}`}),
            { "Content-Encoding": "deflate" }
        )
        return result as AggregatorResponse
    }

    /**
     * Simulates historical
     * @param type
     * @param allocate
     * @param nodes
     * @param siteInfo
     * @param period
     * @param granularity
     * @param meteredTemperature
     * @param readings
     * @param unbatchCallbacks
     */
    @Unbatch({period: 4, unbatchCallbacks: 8, granularity: 5},
        granularity => new SimulationBatchResults(granularity))
    @cacheWrap(300)
    async simulateHistorical(
        type: GraphType,
        allocate: boolean,
        nodes: SimulationNode[],
        siteInfo: SiteInfo,
        period: SimPeriod,
        granularity: Granularity,
        meteredTemperature: Map<string, Partial<EnvCondition>> | null,
        readings: Map<string, Map<string, Reading<Date>[]>>,
        unbatchCallbacks?: UnbatchEvents
    ): Promise<AggregatorResponse> {
        unbatchCallbacks?.start?.(1)

        const result1 = await this.callSimulateHistoricalTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [1,7]}, granularity,meteredTemperature,readings)
        const result2 = await this.callSimulateHistoricalTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [8,14]}, granularity,meteredTemperature,readings)
        const result3 = await this.callSimulateHistoricalTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [15,21]}, granularity,meteredTemperature,readings)
        const result4 = await this.callSimulateHistoricalTimeSliced(type,allocate,nodes,siteInfo,
            {...period, dayRange: [22,31]}, granularity,meteredTemperature,readings)
        unbatchCallbacks?.progress?.(period.months)
        //need to merge results here
        return {...result1, ...result2, ...result3, ...result4}
    }
    /**
     * Simulates historical on this specific time slice
     * @param type
     * @param allocate
     * @param nodes
     * @param siteInfo
     * @param period
     * @param granularity
     * @param meteredTemperature
     * @param readings
     * @returns
     */
    private async callSimulateHistoricalTimeSliced(
        type: GraphType,
        allocate: boolean,
        nodes: SimulationNode[],
        siteInfo: SiteInfo,
        period: SimPeriod,
        granularity: Granularity,
        meteredTemperature: Map<string, Partial<EnvCondition>> | null,
        readings: Map<string, Map<string, Reading<Date>[]>>,
        ) {
        const result =  await this.callMethodBackgroundable(
            "simulateHistorical",
            {
                type,
                allocate,
                nodes,
                siteInfo,
                period,
                granularity,
                meteredTemperature: meteredTemperature ?
                    Object.fromEntries(meteredTemperature.entries()) :
                    null,
                readings: this.readingsToJson(readings),
            },
            cursor => ({url: `/backgroundJob/${cursor}`}),
            { "Content-Encoding": "deflate" }
        )
        return result
    }

    /**
     *
     * @param allocate
     * @param amortise
     * @param nodes
     * @param siteInfo
     * @param period
     * @param granularity
     * @param unbatchCallbacks
     * @returns
     */
    @Unbatch({period: 4, granularity: 5, unbatchCallbacks: 6},
        granularity => new StaticCostBatchResults(granularity))
    async getStaticCosts(
        allocate: boolean,
        amortise: boolean,
        nodes: SimulationNode[],
        siteInfo: SiteInfo,
        period: Period,
        granularity: Granularity,
        unbatchCallbacks?: UnbatchEvents,
    ): Promise<StaticCostResponse> {
        unbatchCallbacks?.start?.(1)
        const result = await this.callMethod(
            "getStaticCosts",
            {
                allocate,
                amortise,
                nodes,
                siteInfo,
                period,
                granularity
            },
            { "Content-Encoding": "deflate" }
        )
        unbatchCallbacks?.progress?.(1)
        return result
    }
}

export const aggregatorService = new AggregatorService()
