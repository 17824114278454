import { Endpoint } from "../helpers"
import { CustomResponse, ImportSensor, ReadingsAtTimeResponse, SensorInformation } from "../interfaces"
import { AnyJSONRPC } from "./AnyJSONRPC"

export type DataTypeReading = {
    timestamp: string,
    value: number
}

/**
 * JSONRPC service for calling the aggregator service
*/
class SensorConfigService extends AnyJSONRPC {
    protected endpoint: Endpoint = "sensor-config"

    /**
     *
     * @param siteId
     * @param meterPointId
     * @param meterId
     * @param from
     * @param to
     * @returns
     */
    async getReadings(
        siteId: string,
        meterPointId: string,
        meterId: string,
        from: string,
        to: string
    ): Promise<DataTypeReading[]> {

        const params = {
            siteId,
            meterPointId,
            meterId,
            from,
            to
        }

        return this.callMethod("getReadings", params)
    }

    /**
     *
     * @param siteId
     * @param meterPointId
     * @param time
     * @returns
     */
    async getReadingsAtTime(
        siteId: string,
        meterPointId: string,
        time: string
    ): Promise<ReadingsAtTimeResponse> {

        const params = {
            siteId,
            meterPointId,
            time,
        }

        return this.callMethod("getReadingsAtTime", params)
    }

    /**
     *
     * @param siteId
     * @returns
     */
    async getSensorInformation(siteId: string) {
        const response = await this.authenticatedGetJson<{data: SensorInformation}>(
            `/sensorInformation/${siteId}`, {"Accept": "application/json"})
        return response.data
    }

    /**
     *
     * @returns
     */
    async getSensorDataTypes() {
        return this.authenticatedGetJson<any>(`/sensorDataTypes`)
    }

    /**
     *
     * @param siteId
     * @param readings
     * @returns
     */
    async importReadings(siteId: string, readings: Record<string, ImportSensor[]>): Promise<CustomResponse> {
        const json = JSON.stringify({ siteId, readings })
        const compress = json.length > 512 * 1024
        const response = await this.authenticatedPOST("/readings/import", json,
            {
                "Accept": "application/json",
                "Content-Type": "application/json",
                ...(compress ? { "Content-Encoding": "deflate" } : {}),
            })

        return response.json()
    }
}

export const sensorConfigService = new SensorConfigService()