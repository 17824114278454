import { Paginated } from "@/core/helpers/Paginated"
import ApiService from "@/core/services/api.service"
import { WeatherStoredReferencesState } from "@/store/weather-stored-references/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<WeatherStoredReferencesState> {
    weatherStoredReferences: Models.WeatherStoredReferenceModel[] = [];
    loaded = false
}

const getters: GetterTree<WeatherStoredReferencesState, any> = {
    /**
     * @param state
     * @param getters
     */
    getWeatherStoredReferences(state, getters): PaginatedResult<Models.WeatherStoredReferenceModel> {
        return new Paginated(
            state.weatherStoredReferences, state.page, state.pageSize, getters.weatherStoredReferenceSort,
            getters.weatherStoredReferenceFilter
        )
    },
    getWeatherStoredReference: (state) => (id: string): Models.WeatherStoredReferenceModel | undefined => {
        return state.weatherStoredReferences.find(m => (m as any).id === id)
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.weatherStoredReferences?.length === 0
    },
    /**
     *
     * @param state
     * @returns
     */
    weatherStoredReferenceFilter(state): (a: Models.WeatherStoredReferenceModel) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return weatherStoredReference => !!weatherStoredReference?.getReference()?.match(regexp)
    },
    /**
     *
     * @param state
     * @returns
     */
    weatherStoredReferenceSort(state): (a: Models.WeatherStoredReferenceModel, b: Models.WeatherStoredReferenceModel) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "reference":
                return Paginated.sortUndefinedFirst((weatherStoredReference) => weatherStoredReference?.getReference(), reverse)
            default:
                return (a, b) => 0
        }
    },
}

const mutations: MutationTree<State> = {
    setWeatherStoredReferences(state, weatherStoredReferences): void {
        state.weatherStoredReferences = weatherStoredReferences
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    mutDeleteWeatherStoredReference(state, weatherStoredReference: Models.WeatherStoredReferenceModel): void {
        state.weatherStoredReferences = state.weatherStoredReferences.filter(m => (m as any).id !== (weatherStoredReference as any).id)
    },
    mutCreateWeatherStoredReference(state, weatherStoredReference: Models.WeatherStoredReferenceModel): void {
        state.weatherStoredReferences.push(weatherStoredReference)
    },
    mutUpdateWeatherStoredReference(state, weatherStoredReference: Models.WeatherStoredReferenceModel): void {
        state.weatherStoredReferences = state.weatherStoredReferences.map(m => {
            if ((m as any).id === (weatherStoredReference as any).id) {
                return weatherStoredReference
            }
            return m
        })
    },
}

const actions = <ActionTree<State, any>>{
    async fetchWeatherStoredReferences({ commit }) {
        const service = await ApiService.getWeatherStoredReferenceService()
        const records = service.getAllWeatherStoredReferences()
        const recordsFound: Models.WeatherStoredReferenceModel[] = []
        for await (const item of records) {
            recordsFound.push(item)
        }
        commit("setLoaded", true)
        commit("setWeatherStoredReferences", recordsFound)
    },
    deleteWeatherStoredReference({ commit }, weatherStoredReference: Models.WeatherStoredReferenceModel) {
        commit("mutDeleteWeatherStoredReference", weatherStoredReference)
    },
    createWeatherStoredReference({ commit }, weatherStoredReference: Models.WeatherStoredReferenceModel) {
        commit("mutCreateWeatherStoredReference", weatherStoredReference)
    },
    updateWeatherStoredReference({ commit }, weatherStoredReference: Models.WeatherStoredReferenceModel) {
        commit("mutUpdateWeatherStoredReference", weatherStoredReference)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const WeatherStoredReferenceModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default WeatherStoredReferenceModule
