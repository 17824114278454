import { HistoricalCost, MonthHour } from "@/History"
import { CopiedResource, GeneralNodeReference, Period } from "@/Simulation"
import { Granularity } from "@/Simulation/enums"
import { cacheWrap } from "@ekko/predict-utilities"
import { UserStatus, UserType } from "../enums"
import { Endpoint } from "../helpers"
import { ReadingBatchResults } from "../helpers/ReadingsBatchResults"
import { Unbatch } from "../helpers/Unbatch"
import { AuditLog, Reading, User, UserFormData } from "../interfaces"
import { AnyJSONRPC } from "./AnyJSONRPC"

/**
 * JSONRPC service for calling the aggregator service
*/
class JsonRpcService extends AnyJSONRPC {
    protected endpoint: Endpoint = "api"
    private performanceDataController: AbortController | null = null

    async getInferredPerformanceData(
        typeCode: string,
        version: number,
        content: any
    ): Promise<any> {

        const data =
        {
            "jsonrpc": "2.0",
            "method": "getInferredPerformanceData",
            "params": {
                typeCode,
                version,
                content,
            }
        }

        try {
            if (this.performanceDataController?.signal) {
                this.performanceDataController.abort()
            }
            this.performanceDataController = new AbortController()
            const signal = this.performanceDataController.signal

            const response = await this.authenticatedPOST("", JSON.stringify(data), {
                "Accept": "application/json",
                "Content-Type": "application/json",
            }, { signal })

            const responseBody = await response.json()

            return responseBody.result
        }
        catch (err) {
            if ((err as any).name !== 'AbortError') {
                console.error(err)
            }
        }
    }

    /**
     *
     * @param existingConfigurationId
     * @returns
     */
    cloneConfiguration(
        existingConfigurationId: string
    ): Promise<any> {
        return this.callMethod("cloneConfiguration", { existingConfigurationId })
    }

    /**
     *
     * @param site
     * @param period
     * @param granularity
     * @param powerCosts
     * @returns
     */
    getSiteHistoricalCosts(
        site: string,
        period: Period,
        granularity: Granularity,
        powerCosts: MonthHour<number>[]
    ): Promise<HistoricalCost[]> {
        return this.callMethod("getSiteHistoricalCosts", {
            site,
            period: { start: period.start, months: period.months },
            granularity,
            powerCosts
        })
    }

    /**
     *
     * @param site
     * @param period
     * @param granularity
     * @returns
     */
    getSiteTotalReadings(
        site: string,
        period: Period,
        granularity?: Granularity
    ): Promise<Reading<string>[]> {
        return this.callMethod("getSiteTotalReadings", {
            granularity,
            site,
            period: { start: period.start, months: period.months }
        })
    }

    /**
     *
     * @param nodeReferences
     * @param period
     * @param granularity
     * @returns
     */
    @Unbatch({ period: 1, unbatchCallbacks: 3, granularity: 2 },
        granularity => new ReadingBatchResults(granularity))
    @cacheWrap(300)
    getBatchNodeReadings(
        nodeReferences: string[],
        period: Period,
        granularity: Granularity,
    ): Promise<Record<string, Reading<string>[]>> {
        return this.callMethod("getBatchNodeReadings", {
            nodeReferences,
            period: { start: period.start, months: period.months },
            granularity,
        })
    }

    /**
     *
     * @param nodeReference
     * @param period
     * @param granularity
     * @returns
     */
    @cacheWrap(300)
    getNodeReadings(
        nodeReference: string,
        period: Period,
        granularity: Granularity,
    ): Promise<Reading<string>[]> {
        return this.callMethod("getNodeReadings", {
            nodeReference,
            period: { start: period.start, months: period.months },
            granularity,
        })
    }

    /**
     * Gets simulation refinement
     * @param site
     * @param period
     */
    async getSimulationRefinement(
        site: string,
        period: Period
    ): Promise<Map<string, Reading<string>[]>> {
        const result = await this.callMethod("getSimulationRefinement", {
            site,
            period: { start: period.start, months: period.months },
        })

        return new Map(Object.entries(result))
    }

    /**
     *
     * @param siteConfigurationId
     * @param nodeset
     * @param x
     * @param y
     * @returns
     */
    cloneNodes(
        siteConfigurationId: string,
        nodeset: { type: string, id: string }[],
        x: number,
        y: number
    ): Promise<{ type: string, id: string }[]> {
        return this.callMethod("cloneNodes", {
            siteConfigurationId,
            nodeset,
            x,
            y
        })
    }

    /**
     * @param siteId
     * @param encodedData
     */
    saveScreenshotForSite(siteId: string, encodedData: string): Promise<boolean> {
        return this.callMethod("saveScreenshotForSite", {
            siteId,
            encodedData
        })
    }

    /**
     * Get mapped current user from Cognito
     *
     * @returns
     */
    getCurrentUser(): Promise<User | null> {
        return this.callMethod("getCurrentUser")
    }

    /**
     * Get mapped users from Cognito
     */
    getUsers(): Promise<User[]> {
        return this.callMethod("getUsers")
    }


    /**
     * Link the invitation to the current user's org
     *
     * @param code
     */
    linkInvitation(code: string): Promise<{ id: string, type: string, orgName: string }> {
        return this.callMethod("linkInvitation", { code })
    }

    /**
     * Resend invitation to cognito user who has registered but reset password has expired
     *
     * @param email
     * @returns
     */
    resendInvitation(email: string): Promise<boolean> {
        return this.callMethod("resendInvitation", { email })
    }

    /**
     * Create user in Cognito
     * @param name
     * @param email
     * @param organisation
     * @param type
     * @param status
     */
    createUser(
        name: string,
        email: string,
        organisation: UserFormData["organisation"],
        type: UserType,
        status: UserStatus
    ): Promise<User> {
        return this.callMethod("createUser", {
            name,
            email,
            organisation,
            type,
            status
        })
    }

    /**
     * Update user in Cognito
     * @param id
     * @param name
     * @param email
     * @param organisation
     * @param type
     * @param status
     */
    updateUser(
        id: string,
        name: string,
        email: string,
        organisation: UserFormData["organisation"],
        type: UserType,
        status: UserStatus
    ): Promise<User> {
        return this.callMethod("updateUser", {
            id,
            name,
            email,
            organisation,
            type,
            status
        })
    }

    /**
     * Delete user from Cognito
     * @param id
     */
    deleteUser(id: string): Promise<any> {
        return this.callMethod("deleteUser", { id })
    }

    /**
     *
     * @param id
     * @returns
     */
    disableUser(id: string): Promise<any> {
        return this.callMethod("disableUser", { id })
    }

    /**
     *
     * @param id
     * @returns
     */
    enableUser(id: string): Promise<any> {
        return this.callMethod("enableUser", { id })
    }

    /**
     * Copy a climate profile, IT load profile, or device model resource
     *
     * @param resource
     * @param newOwner
     * @returns Copy of the resource and, for a device model resource, the related device mode copies too
     */
    copyResource(
        resource: GeneralNodeReference,
        newOwner: GeneralNodeReference
    ): Promise<CopiedResource> {
        return this.callMethod("copyResource", {
            resource,
            newOwner,
        })
    }

    /**
     * Reassign site owner
     *
     * @param resource site
     * @param newOwner
     * @param mapping old and new resources
     * @returns True on success
     */
    reassignOwner(
        resource: GeneralNodeReference,
        newOwner: GeneralNodeReference,
        mapping: {
            old: GeneralNodeReference,
            new: GeneralNodeReference,
        }[]
    ): Promise<boolean> {
        return this.callMethod("reassignOwner", {
            resource,
            newOwner,
            mapping,
        })
    }

    /**
     * Retrieve organisation log records in selected range.
     *
     * @param params
     * @returns
     */
    getAuditLogs(params: { orgId: string, from: string, to: string }): Promise<AuditLog[] | null> {
        return this.callMethod("getAuditLogs", params)
    }
}

export const jsonRpcService = new JsonRpcService()