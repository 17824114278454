import { vueLazySetMapValue, VueLazyWrapper } from "@/core/helpers"
import { Paginated } from "@/core/helpers/Paginated"
import ApiService from "@/core/services/api.service"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { FiltersState } from "../common/filters/types"
import { PaginationModule } from "../common/pagination"
import { PaginationState } from "../common/pagination/types"
import { SiteInfoWithOrg } from "./SiteInfoWithOrg"
import { debugOptions } from "./debugOptions"

class State {
    /**
     *
     */
    isAdding = false
    /**
     *
     */
    loaded = false
    /**
     *
     */
    modelOrganizations: Map<string, VueLazyWrapper<Models.OwnerReferenceModel>> = new Map()

    /**
     *
     */
    selectedSite: Models.SiteModel | null = null

    /**
     *
     */
    sites: Models.SiteModel[] = []
}

const getters: GetterTree<State & FiltersState & PaginationState, any> = {
    /**
     *
     * @param state
     * @returns
     */
    sitesWithOrganizations(state): SiteInfoWithOrg[] {
        const out: SiteInfoWithOrg[] = []

        for (const site of state.sites) {
            if (!state.modelOrganizations.has(site.id)) {
                vueLazySetMapValue(
                    state.modelOrganizations,
                    site.id,
                    () => site.getOwnerReference()
                )
            }

            const organization = state.modelOrganizations.get(site.id)?.value

            out.push({
                model: site,
                organization,
                editedName: site.getName() ?? "",
            })
        }

        return out
    },
    /**
     * @param state
     * @returns
     */
    sitesWithOrganizationsFilter(state): (a: SiteInfoWithOrg) => boolean {
        const regexp = new RegExp(state.searchText, "i")
        return ({ model: site, organization }) => (
            // text
            !!site?.getName()?.match(regexp) ||
            !!organization?.getName()?.match(regexp)
        )
    },
    /**
     * @param state
     * @returns
     */
    sitesWithOrganizationsSort(state): (a: SiteInfoWithOrg, b: SiteInfoWithOrg) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "Date Created":
                return Paginated.sortUndefinedFirst((si) => si.model?.getCreatedAt(), reverse)
            case "Name":
                return Paginated.sortUndefinedFirst((si) => si.model?.getName(), reverse)
            case "Organization":
                return Paginated.sortUndefinedFirst((si) => si.organization?.getOrganisation(), reverse)
            default:
                return (a, b) => 0
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getSitesWithOrganizations(state, getters): PaginatedResult<SiteInfoWithOrg> {
        return new Paginated(
            getters.sitesWithOrganizations,
            state.page,
            state.pageSize,
            getters.sitesWithOrganizationsSort,
            getters.sitesWithOrganizationsFilter
        )
    },
    /**
     * @param state
     * @returns
     */
    siteWithId: (state) => async (id: string): Promise<Models.SiteModel | undefined> => {
        let siteFound = state.sites.find(m => (m as any).id === id)
        if (!siteFound) {
            const service = await ApiService.getSiteService()
            siteFound = await service.getSite(id)
        }
        return siteFound
    },
    /**
     *
     * @param state
     * @returns
     */
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.sites?.length === 0
    }
}

const mutations: MutationTree<State> = {
    /**
     *
     * @param state
     * @param site
     */
    appendSite(state, site: Models.SiteModel): void {
        state.sites.push(site)
    },
    /**
     *
     * @param state
     * @param isAdding
     */
    setIsAdding(state, isAdding: boolean) {
        state.isAdding = isAdding
    },
    /**
     *
     * @param state
     * @param sites
     */
    setSites(state, sites): void {
        state.sites = sites
        state.selectedSite = null

        if(debugOptions.fillSitesTable && state.sites.length < debugOptions.fillSitesTable) {
            const owner = new Models.OwnerReferenceModel()
            owner.setName("Test")
            owner.setOrganisation("Test")
            for(let i = 0; i < debugOptions.fillSitesTable; i++) {
                const site = new Models.SiteModel()
                site.addOwnerReference(owner)
                site.setName("test")
                site.setCreatedAt(new Date().toISOString())
                state.sites.push(site)
            }
        }

    },
    /**
     *
     * @param state
     * @param loaded
     */
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    /**
     *
     * @param state
     * @param site
     */
    mutDeleteSite(state, site: Models.SiteModel): void {
        state.sites = state.sites.filter(m => (m as any).id !== (site as any).id)
        if(state.selectedSite?.id == site.id) {
            state.selectedSite = null
        }
    },
    /**
     *
     * @param state
     * @param site
     */
    mutUpdateSite(state, site: Models.SiteModel): void {
        state.sites = state.sites.map(m => {
            if ((m as any).id === (site as any).id) {
                return site
            }
            return m
        })
    },
    /**
     *
     * @param state
     * @param site
     */
    setSelectedSite(state, site: Models.SiteModel | null): void {
        state.selectedSite = site
    },
}

const actions = <ActionTree<State, any>>{
    /**
     *
     * @param actionState
     * @param site
     */
    async addSite({ commit }, site: Models.SiteModel) {
        commit("setIsAdding", true)
        try {
            const siteService = await ApiService.getSiteService()
            const siteConfigurationService =
            await ApiService.getSiteConfigurationService()

            const [newSite]: Models.SiteModel[] = await siteService.addSite(site)

            const siteConfiguration: Models.SiteConfigurationModel =
            new Models.SiteConfigurationModel()
            siteConfiguration.setName("First Configuration")
            siteConfiguration.setNotes("initial site configuration")
            siteConfiguration.setEffectiveDate(new Date().toISOString())
            siteConfiguration.addStartsSite(newSite)
            siteConfiguration.addSite(newSite)
            const [newSiteConfiguration]: Models.SiteConfigurationModel[] =
                await siteConfigurationService.addSiteConfiguration(
                    siteConfiguration)
            newSite.addSiteConfigurations([newSiteConfiguration])
            newSite.addFirstConfiguration(newSiteConfiguration)

            commit("appendSite", newSite)

            return newSite
        } finally {
            // Includes a bit of a delay to ease moving into the next state
            setTimeout(() => commit("setIsAdding", false), 500)
        }
    },
    /**
     *
     * @param actionState
     */
    async cacheDeviceModes() {
        const modelService = await ApiService.getDeviceModelService()

        for await (const entityPage of modelService.getAllDeviceModelsPaginated()) {
            // Noop
        }
        const modeService = await ApiService.getDeviceModeService()

        for await (const entityPage of modeService.getAllDeviceModesPaginated()) {
            // Noop
        }
    },
    /**
     *
     * @param actionState
     * @param site
     */
    async clearSelectedSite({ commit }) {
        commit("setSelectedSite", null)
    },
    /**
     *
     * @param actionState
     */
    async fetchSites({ commit }) {
        const service = await ApiService.getSiteService()
        const records = service.getAllSites()
        const recordsFound: Models.SiteModel[] = []
        for await (const item of records) {
            recordsFound.push(item)
        }
        commit("setLoaded", true)
        commit("setSites", recordsFound)
    },
    /**
     *
     * @param actionState
     * @param site
     */
    deleteSite({ commit }, site: Models.SiteModel) {
        commit("mutDeleteSite", site)
    },
    /**
     *
     * @param actionState
     * @param site
     */
    async selectSite({ commit }, site: Models.SiteModel) {
        commit("setSelectedSite", site)
    },
    /**
     *
     * @param actionState
     * @param site
     */
    async updateSite({ commit }, site: Models.SiteModel) {
        const siteService = await ApiService.getSiteService()
        await siteService.updateSite(site)
        commit("mutUpdateSite", site)
    },
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const SiteModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default SiteModule
