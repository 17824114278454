export enum ResourceType {
    COMBINERS = "combiners",
    MANUFACTURERS = 'manufacturers',
    DEVICE_MODELS = 'deviceModels',
    DEVICE_MODES = 'deviceModes',
    GRIDS = 'grids',
    HEAT_EXCHANGERS = 'heatExchangers',
    LOAD_LOSSES = 'loadLosses',
    OWNER_REFERENCES = 'ownerReferences',
    ACCESS_CONTROLS = 'accessControls',
    POLYNOMIALS = 'polynomials',
    POLYNOMIAL_VALUES = 'polynomialValues',
    GRID_LIST = "gridLists",
    TEMP_LOAD_LOSS = "tempLoadLosses",
    SITES = "sites",
    ITEMS = "items",
    IT_NODES = "itNodes",
    OTHER_NODES = "otherNodes",
    IT_PROVISIONINGS = "itProvisionings",
    IT_PROVISIONING_EVENTS = "itProvisioningEvents",
    SPLITTERS = "splitters",
    CHEAP_ONE_COST_STRATEGY = "cheapOneCostStrategies",
    ENVIRONMENT_COST_STRATEGY = "environmentCostStrategies",
    PRIORITY_COST_STRATEGY = "priorityCostStrategies",
    SHARE_COST_STRATEGY = "shareCostStrategies",
    CLIMATE_PROFILE_REFERENCE = "climateProfileReferences",
    CLIMATE_GEO_LOCATION = "climateGeoLocations",
    CLIMATE_LOCATION = "climateLocations",
    IT_LOAD_PROFILES = "itLoadProfiles",
    IT_LOAD_HOURS = "itLoadHours",
    WEATHER_STORED_REFERENCE = "weatherStoredReferences",
    WEATHER_METER_CONFIGURATION = "weatherMeterConfigurations",
    RESILIENCES = "resiliences",
    RESILIENCE_GROUPINGS = "resilienceGroupings",
    RESILIENCE_GROUPING_STANDBYS = "resilienceGroupingStandbys",
    RESILIENCE_SPARE_CAPACITIES = "resilienceSpareCapacities",
    RESILIENCE_LOAD_STAGINGS = "resilienceLoadStagings",
    PROVISIONING_EVENTS = "provisioningEvents",
    COST_PROFILES = "costProfiles",
    COOLING_METER_POINTS = "coolingMeterPoints",
    POWER_METER_POINTS = "powerMeterPoints",
    IT_COST_PROFILES = "itCostProfiles",
    DEVICE_CAPITAL_COSTS = "deviceCapitalCosts",
    DEVICE_MAINTENANCE_COSTS = "deviceMaintenanceCosts",
    NODE_CAPITAL_COSTS = "nodeCapitalCosts",
    NODE_MAINTENANCE_COSTS = "nodeMaintenanceCosts",
    PRESENTATION_PREFERENCES = "presentationPreferences",
    MANUFACTURER_PERFORMANCE_DATA = "manufacturerPerformanceData",
    IDENTIFICATIONS = "identifications",
}