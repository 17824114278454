import { Models } from "@ekko/predict-client-api"
import { CType, LDist, LoadingStrategy, PowerState, ResilienceType } from "../enums"
import { ResilienceDisabledFieldStatus, ResilienceFormData, ResilienceIdleData, ResilienceLoadingStrategyData, ResilienceTypeData } from "../interfaces"

/**
 *
 */
export class ResilienceForm implements ResilienceFormData {
    /**
     *
     */
    private get loadStrategyFieldDisabledStatus() {
        if(!this.type || this.type.resilienceType == ResilienceType.NO_RESILIENCE) {
            return {
                activeState: true,
                idleState: true,
                passiveState: true,
                stageGroupSize: true,
                triggerLoad: true,
            }
        } else switch(this.loadingStrategy.loadingStrategy) {
            case LoadingStrategy.SHARED:
                return {
                    activeState: true,
                    idleState: this.type.resilienceType != ResilienceType.STANDBY,
                    passiveState: true,
                    stageGroupSize: true,
                    triggerLoad: true,
                }
            case LoadingStrategy.STAGED:
                // Fall through
            case LoadingStrategy.STAGED_EVEN:
                return {
                    activeState: this.type.resilienceType != ResilienceType.STANDBY,
                    idleState: false,
                    passiveState: this.type.resilienceType != ResilienceType.STANDBY,
                    stageGroupSize: false,
                    triggerLoad: false,
                }
        }
    }

    /**
     * @param resilience
     * @param loadStaging
     * @param grouping
     * @param groupingStandby
     * @param spareCapacity
     */
    private findTypeAndStrategy(
        resilience: Models.ResilienceModel,
        loadStaging: Models.ResilienceLoadStagingModel | null,
        grouping: Models.ResilienceGroupingModel | null,
        groupingStandby: Models.ResilienceGroupingStandbyModel | null | undefined,
        spareCapacity: Models.ResilienceSpareCapacityModel | null
    ): [ResilienceType, LoadingStrategy] {
        const spareCapacityValue = spareCapacity?.getSpareCapacity() ?? null
        // Set up Resilience Type
        let type: ResilienceType

        if (grouping?.getGroups() ?? 0 > 1) {
            // 2(N+R)
            type = groupingStandby
                ? ResilienceType.STANDBY
                : ResilienceType.ACTIVE
        } else if (spareCapacityValue !== null && spareCapacityValue >= 0) {
            // N+R
            type = ResilienceType.NR
        } else {
            // This includes N+0
            type = ResilienceType.NO_RESILIENCE
        }
        // Set up loading strategy
        let strategy = LoadingStrategy.SHARED

        if (loadStaging) {
            switch (resilience.getLoadDistribution() as LDist) {
                case LDist.EVEN:
                    strategy = LoadingStrategy.STAGED_EVEN
                    break
                case LDist.FILL:
                    strategy = LoadingStrategy.STAGED
                    break
            }
        }
        return [type, strategy]
    }

    idle: ResilienceIdleData = {
        idleState: PowerState.OFF,
        activeState: null,
        groupingIdleState: null,
    }

    loadingStrategy: ResilienceLoadingStrategyData = {
        loadingStrategy: LoadingStrategy.SHARED,
        triggerLoad: null,
        stageGroupSize: null,
    }

    type: ResilienceTypeData | null = null

    /**
     *
     */
    get fieldDisabledStatus(): ResilienceDisabledFieldStatus {
        if(!this.type || this.type.resilienceType == ResilienceType.NO_RESILIENCE) {
            return {
                shared: true,
                spareCapacity: true,
                staged: true,
                stagedEven: true,

                ...this.loadStrategyFieldDisabledStatus,
            }
        } else {
            return {
                shared: false,
                spareCapacity: false,
                staged: false,
                stagedEven: false,

                ...this.loadStrategyFieldDisabledStatus,
            }
        }
    }

    /**
     *
     */
    get resilienceGroupingModel() {
        if (
            this.type?.resilienceType == ResilienceType.ACTIVE ||
            this.type?.resilienceType == ResilienceType.STANDBY
        ) {
            const resilienceGroupingModel = new Models.ResilienceGroupingModel()
            resilienceGroupingModel.setGroups(2) // ResilienceGrouping exists with groups=2
            return resilienceGroupingModel
        } else {
            return null
        }
    }

    /**
     *
     */
    get resilienceGroupingStandbyModel() {
        if (this.type?.resilienceType == ResilienceType.STANDBY) {
            const resilienceGroupingStandbyModel = new Models.ResilienceGroupingStandbyModel()
            resilienceGroupingStandbyModel.setIdleState(
                this.idle?.groupingIdleState as string
            )
            resilienceGroupingStandbyModel.setActiveState(
                this.idle?.activeState as string
            )
            return resilienceGroupingStandbyModel
        } else {
            return null
        }
    }

    /**
     *
     */
    get resilienceLoadStagingModel() {
        if (
            this.loadingStrategy.loadingStrategy == LoadingStrategy.STAGED_EVEN ||
            this.loadingStrategy.loadingStrategy == LoadingStrategy.STAGED
        ) {
            const resilienceLoadStagingModel = new Models.ResilienceLoadStagingModel()
            resilienceLoadStagingModel.setStageGroupSize(
                this.loadingStrategy.stageGroupSize as number
            )
            resilienceLoadStagingModel.setTriggerLoad(
                (this.loadingStrategy.triggerLoad as number) / 100
            )
            return resilienceLoadStagingModel
        } else {
            return null
        }
    }

    /**
     *
     */
    get resilienceModel() {
        if (this.type?.resilienceType == ResilienceType.NO_RESILIENCE) {
            const resilienceModel = new Models.ResilienceModel()
            resilienceModel.setOffAtZeroLoad(
                this.type.offAtZeroLoad ?? false
            )
            resilienceModel.setOperatingLimit(
                (this.type.operatingLimit ?? 100) / 100
            )
            resilienceModel.setIdleState(
                this.idle.idleState ?? PowerState.OFF
            )

            return resilienceModel
        } else {
            const resilienceModel = new Models.ResilienceModel()

            resilienceModel.setOffAtZeroLoad(
                this.type?.offAtZeroLoad ?? false
            )
            resilienceModel.setOperatingLimit(
                (this.type?.operatingLimit ?? 100) / 100
            )
            resilienceModel.setIdleState(
                this.idle.idleState ?? PowerState.OFF
            )
            if (this.loadingStrategy.loadingStrategy == LoadingStrategy.STAGED) {
                resilienceModel.setLoadDistribution(LDist.FILL as string)
            } else {
                resilienceModel.setLoadDistribution(LDist.EVEN as string)
            }
            return resilienceModel
        }
    }

    /**
     *
     */
    get resilienceSpareCapacityModel() {
        const resilienceSpareCapacityModel = new Models.ResilienceSpareCapacityModel()
        resilienceSpareCapacityModel.setSpareCapacityType(CType.COUNT) // Note: spareCapacityType is always “count”
        resilienceSpareCapacityModel.setSpareCapacity(
            this.type?.spareCapacity as number
        )
        return resilienceSpareCapacityModel
    }

    /**
     *
     * @param item
     */
    constructor(private item: Models.ItemModel | null) {

    }

    /**
     * @param item
     */
    async init(): Promise<void> {
        const resilienceModel: Models.ResilienceModel | null | undefined =
            await this.item?.getResilience()
        if (!resilienceModel) {
            this.type = {
                resilienceType: ResilienceType.NO_RESILIENCE,
                offAtZeroLoad: false,
                operatingLimit: null,
                spareCapacity: null,
            }
            this.loadingStrategy = {
                loadingStrategy: LoadingStrategy.SHARED,
                triggerLoad: null,
                stageGroupSize: null,
            }
            this.idle = {
                idleState: PowerState.OFF,
                activeState: null,
                groupingIdleState: null,
            }
            return
        }
        const loadStaging = await resilienceModel?.getResilienceLoadStaging()
        const spareCapacity = await resilienceModel?.getResilienceSpareCapacity()
        const grouping = await resilienceModel?.getResilienceGrouping()
        const groupingStandby = await grouping?.getResilienceGroupingStandby()
        const [type, strategy] = this.findTypeAndStrategy(
            resilienceModel,
            loadStaging,
            grouping,
            groupingStandby,
            spareCapacity
        )

        this.type = {
            resilienceType: type,
            offAtZeroLoad: resilienceModel.getOffAtZeroLoad(),
            operatingLimit: (resilienceModel.getOperatingLimit() as number) * 100,
            spareCapacity: spareCapacity?.getSpareCapacity() ?? null,
        }
        this.loadingStrategy = {
            loadingStrategy: strategy,
            triggerLoad: loadStaging
                ? (loadStaging.getTriggerLoad() as number) * 100
                : null,
            stageGroupSize: loadStaging?.getStageGroupSize() ?? null,
        }
        this.idle = {
            idleState: resilienceModel.getIdleState() as PowerState,
            activeState: (groupingStandby?.getActiveState() ??
                null) as PowerState | null,
            groupingIdleState: (groupingStandby?.getIdleState() ??
                null) as PowerState | null,
        }
    }

    /**
     *
     * @returns
     */
    setResilienceTypeDefaults() {
        if (!this.type) return
        switch (this.type.resilienceType) {
            case ResilienceType.NO_RESILIENCE:
                break
            case ResilienceType.NR:
            case ResilienceType.ACTIVE:
                // ResilienceSpareCapacity exists
                this.type.spareCapacity = this.type.spareCapacity ?? 0
                // ResilienceGrouping does not exist
                this.idle.activeState = null
                this.idle.groupingIdleState = null
                break
            case ResilienceType.STANDBY:
                // ResilienceSpareCapacity exists
                this.type.spareCapacity = this.type.spareCapacity ?? 0
                // ResilienceGroupingStandby exists
                this.idle.activeState = this.idle.activeState ?? PowerState.ON
                this.idle.groupingIdleState =
                    this.idle.groupingIdleState ?? PowerState.ON
                break
            default:
                break
        }
    }
}