import { AnyNodeModel, NRef } from "@/store/SiteViewState"

/**
 *
 */
export abstract class Base<T extends AnyNodeModel> {
    /**
     *
     * @param node
     */
    constructor(protected node: T) {

    }
    /**
     *
     * @returns
     */
    abstract add(): Promise<NRef<T>>
    /**
     * Connects to the site configuration only.
     *
     * @returns
     */
    abstract connect(): Promise<any>
    /**
     * Disconnects from the site configuration only.
     *
     * @returns
     */
    abstract disconnect(): Promise<any>
    /**
     *
     */
    abstract remove(): Promise<any>
    /**
     *
     */
    abstract update(): Promise<any>
}