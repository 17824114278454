import { FormatterFormatOptions } from "@/core/interfaces"
import { Formatter } from "../Formatter"

/**
 *
 */
export abstract class AppendUnits extends Formatter {
    protected abstract readonly suffixSymbol: string

    /**
     *
     * @param options
     */
    protected abstract numberFormatOptions(options?: {digits?: number}):
        Intl.NumberFormatOptions

    format(value: number, options?: FormatterFormatOptions): string {
        const formatter = new Intl.NumberFormat(undefined,
            this.numberFormatOptions(options))
        const formattedValue = formatter.format(this.nativeToPreferred(+value))
        if(options?.withoutUnits) {
            return formattedValue
        } else {
            return Formatter.appendUnits(formattedValue, this.suffixSymbol)
        }
    }
}