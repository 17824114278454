import ApiService from "@/core/services/api.service"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import { actions as tableActions, getters as tableGetters, mutations as tableMutations } from "./table"
import { ITLoadProfileState } from "./types"

class State implements Partial<ITLoadProfileState> {
  itLoadProfiles: Models.ITLoadProfileModel[] = []
  loaded = false
}

const getters = <GetterTree<ITLoadProfileState, any>>{

}

const mutations = <MutationTree<State>>{
  deleteITLoadProfile(state, itLoadProfile: Models.ITLoadProfileModel): void {
    state.itLoadProfiles = state.itLoadProfiles.filter(
      d => (d as any).id !== (itLoadProfile as any).id
    )
  },
  createITLoadProfile(state, itLoadProfile: Models.ITLoadProfileModel): void {
    state.itLoadProfiles.push(itLoadProfile)
  },
  updateITLoadProfile(state, itLoadProfile: Models.ITLoadProfileModel): void {
    state.itLoadProfiles = state.itLoadProfiles.map(d => {
      if ((d as any).id === (itLoadProfile as any).id) {
        return itLoadProfile
      }
      return d
    })
  }
}

const actions = <ActionTree<State, any>>{
  async fetchITLoadProfiles({ commit }) {
    const profileService = await ApiService.getITLoadProfileService()
    const filter = { attribute: "defunct", op: "equal" as const, value: false }
    const profilesGenerator = profileService.getAllITLoadProfiles(filter)
    const itLoadProfiles: Models.ITLoadProfileModel[] = []
    for await (const profile of profilesGenerator) {
      itLoadProfiles.push(profile)
    }

    commit("setLoaded", true)
    commit("setITLoadProfiles", itLoadProfiles)
  },
  archiveITLoadProfile({ commit }, itLoadProfile: any) {
    commit("deleteITLoadProfile", itLoadProfile)
    commit('itLoadProfileArchive/archiveITLoadProfile', itLoadProfile, { root: true })
  },
  createITLoadProfile({ commit }, itLoadProfile: any) {
    commit("createITLoadProfile", itLoadProfile)
  },
  updateITLoadProfile({ commit }, itLoadProfile: any) {
    commit("updateITLoadProfile", itLoadProfile)
  }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const ITLoadProfileModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...tableGetters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...tableMutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...tableActions,
    ...actions
  },
}

export default ITLoadProfileModule
