import { UnbatchEvents } from "../interfaces"

/**
 *
 */
export class UnbatchEventsSimple implements UnbatchEvents {
    /**
     *
     */
    private static maxUid = 0

    /**
     *
     */
    private readonly debug = false

    /**
     *
     */
    private size?: number

    /**
     *
     */
    private readonly uid: number

    /**
     *
     * @param progressInner
     * @param startInner
     */
    constructor(private progressInner?: (completed: number) => any,
        private startInner?: (size: number) => any
    ) {
        this.uid = ++UnbatchEventsSimple.maxUid
    }

    /**
     *
     * @param completed
     */
    progress(completed: number) {
        if(this.debug) {
            console.debug(`UES #${this.uid}: p=${completed}`)
        }
        this.progressInner?.(completed)
    }

    /**
     *
     * @param size
     */
    start(size: number) {
        if(this.size !== undefined) {
            console.warn(new Error(`Progress size reassigned (was ${this.size})`))
        }
        if(this.debug) {
            console.debug(new Error(`UES #${this.uid}: s=${size}`))
        }
        this.size = size
        this.startInner?.(size)
    }
}