import { NodeType } from "predict-performance-calculation"
import { NamedSimulationNode, QuickModeProps } from "../interfaces"
import { SimulationCallHelper } from "./SimulationCallHelper"
import { AnyNode } from "@/store/SiteViewState"
import { ProvisioningState } from "predict-performance-calculation/dist/Interface"

/**
 *
 */
export class QuickModeHelper extends SimulationCallHelper {
    /**
     *
     * @param nodes
     * @param quickModeProps
     */
    constructor(nodes: AnyNode[], private quickModeProps: QuickModeProps,
        private underlyingCallHelper: SimulationCallHelper
    ) {
        super(nodes)
    }

    getItLoad(): Record<string, number> | Promise<Record<string, number>> {
        return this.quickModeProps.itLoad ??
            this.underlyingCallHelper.getItLoad()
    }

    getNodesData(nodesData: NamedSimulationNode[]) {
        return nodesData.map((nd) => {
            if (nd.type == NodeType.Splitter && this.quickModeProps.splitterConfig?.[nd.type + "/" + nd.id]) {
                return {
                    ...nd,
                    splitterDynamicCostStrategy: {
                        ...nd.splitterDynamicCostStrategy,
                        ...this.quickModeProps.splitterConfig[nd.type + "/" + nd.id]
                    }
                }
            } else {
                return nd
            }
        })
    }

    getProvisionedItLoad(): Record<string, number> | Promise<Record<string, number>> {
        return this.quickModeProps.provisionedItLoad ??
            this.underlyingCallHelper.getProvisionedItLoad()
    }2

    getProvisioning(): Record<string, ProvisioningState> {
        return this.quickModeProps.provisioning ??
            this.underlyingCallHelper.getProvisioning()
    }

    getWeather() {
        return this.quickModeProps.weather ??
            this.underlyingCallHelper.getWeather()
    }
}