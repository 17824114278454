import { ActionTree, GetterTree, MutationTree } from "vuex"

class State {
    searchText = ""
    columnFilter = {} as {name: string, text:string}
    typeFilter = ""
    accessFilter = ""
    sort: { field: string, direction: string } = { field: "type", direction: "asc" }
}

const getters = <GetterTree<State, any>>{

}

const mutations = <MutationTree<State>>{
    setSearchText(state, searchText: string): void {
        state.searchText = searchText
    },
    setColumnFilter(state, column: {name: string, text: string}): void {
        state.columnFilter = column

    },
    setTypeFilter(state, typeFilter: string): void {
        state.typeFilter = typeFilter
    },
    setAccessFilter(state, accessFilter: string): void {
        state.accessFilter = accessFilter
    },
    setSort(state, sortOptions: { field: string, direction: string }): void {
        state.sort = sortOptions
    },
}

const actions = <ActionTree<State, any>>{
    filterByText({ commit }, searchText: string) {
        commit("setPage", 1)
        commit("setSearchText", searchText)
    },
    filterByColumn({ commit }, columnFilter: {name: string, text: string}) {
        commit("setPage", 1)
        commit("setColumnFilter", columnFilter)
    },
    filterByType({ commit }, type: string) {
        commit("setPage", 1)
        commit("setTypeFilter", type)
    },
    filterByAccess({ commit }, access: string) {
        commit("setPage", 1)
        commit("setAccessFilter", access)
    },
    changeSort({ commit }, sortOptions: { field: string, direction: string }) {
        commit("setSort", sortOptions)
    },
}

export const FiltersModule = () => {
    return {
        state: new State(),
        getters: getters,
        mutations: mutations,
        actions: actions,
    }
}