import { PowerCostsState } from "@/store/power-costs/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<PowerCostsState> {
  powerCosts: Models.PowerCostModel[] = [];
  loaded = false
}

const getters: GetterTree<PowerCostsState, any> = {
  getPowerCosts(state): PaginatedResult<Models.PowerCostModel> {
    let results = state.powerCosts
    const totalCount = results.length
    results = results.slice((state.page - 1) * state.pageSize, state.page * state.pageSize)

    return { results, totalCount }
  },
  getPowerCost: (state) => (id: string): Models.PowerCostModel | undefined => {
    return state.powerCosts.find(m => (m as any).id === id)
  },
  getIsLibraryEmpty(state): boolean {
    return state.loaded && state.powerCosts?.length === 0
  }
}

const mutations: MutationTree<State> = {
  setPowerCosts(state, powerCosts): void {
    state.powerCosts = powerCosts
  },
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  mutDeletePowerCost(state, powerCost: Models.PowerCostModel): void {
    state.powerCosts = state.powerCosts.filter(m => (m as any).id !== (powerCost as any).id)
  },
  mutCreatePowerCost(state, powerCost: Models.PowerCostModel): void {
    state.powerCosts.push(powerCost)
  },
  mutUpdatePowerCost(state, powerCost: Models.PowerCostModel): void {
    state.powerCosts = state.powerCosts.map(m => {
      if ((m as any).id === (powerCost as any).id) {
        return powerCost
      }
      return m
    })
  },
}

const actions = <ActionTree<State, any>>{
  async fetchPowerCosts({ commit }, powerCostMonthModel: Models.PowerCostMonthModel) {
    const records = powerCostMonthModel.getPowerCosts()
    const recordsFound: Models.PowerCostModel[] = []
    for await (const powerCost of records) {
      recordsFound.push(powerCost)
    }
    recordsFound.sort((a, b) => (Number(a.getHour()) > Number(b.getHour()))? 1: -1)

    commit("setLoaded", true)
    commit("setPowerCosts", recordsFound)
  },
  deletePowerCost({ commit }, powerCost: Models.PowerCostModel) {
    commit("mutDeletePowerCost", powerCost)
  },
  createPowerCost({ commit }, powerCost: Models.PowerCostModel) {
    commit("mutCreatePowerCost", powerCost)
  },
  updatePowerCost({ commit }, powerCost: Models.PowerCostModel) {
    commit("mutUpdatePowerCost", powerCost)
  },
  reset({ commit }) {
    commit("setPowerCosts", [])
    commit("setLoaded", false)
  }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const PowerCostModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...actions
  },
}

export default PowerCostModule
