import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { Celsius } from "./Celsius"

const zeroCelsius = 273.15

/**
 *
 */
export class Kelvin extends Celsius {
    protected suffixSymbol = "K"

    suffix = NumberFormatSuffix.kelvin

    nativeToPreferred(value: number, options?: {digits?: number}): number {
        return super.nativeToPreferred(value + zeroCelsius, options)
    }

    preferredToNative(value: number): number {
        return value - zeroCelsius
    }
}