import { AnyNode } from "@/store/SiteViewState"
import { ItNodeParameter, OtherNodeParameter } from "../siteParameter/nodeParameter"
import { WeatherReading } from "@/core/interfaces"
import { ProvisioningState } from "predict-performance-calculation/dist/Interface"
import { NamedSimulationNode } from "../interfaces"

/**
 *
 */
export abstract class SimulationCallHelper {
    /**
     *
     */
    protected itParam = new ItNodeParameter()
    /**
     *
     */
    protected otherParam = new OtherNodeParameter()

    /**
     *
     * @param nodes
     */
    constructor(protected nodes: AnyNode[]) {

    }
    /**
     *
     * @returns
     */
    abstract getItLoad(): Promise<Record<string, number>> | Record<string, number>

    /**
     *
     * @param nodesData
     * @returns
     */
    abstract getNodesData(nodesData: NamedSimulationNode[]): NamedSimulationNode[]

    /**
     *
     * @returns
     */
    abstract getProvisionedItLoad(): Promise<Record<string, number>> | Record<string, number>

    /**
     *
     * @returns
     */
    abstract getProvisioning(): Record<string, ProvisioningState>

    /**
     *
     * @returns
     */
    abstract getWeather(): Promise<Pick<WeatherReading, "dryBulb" | "wetBulb"> | null> | Pick<WeatherReading, "dryBulb" | "wetBulb"> | null
}