import { NodeComputationHelper } from "@/Simulation/helpers"
import { Models } from "@ekko/predict-client-api"
import { ConnectionType, NodeType } from "predict-performance-calculation"
import { SimulationNodeType } from "./SimulationViewState"
import { AnyNode, Connection } from "./SiteViewState"

/**
 *
 */
export class SimulationNodeTypeHelper {
    /**
     *
     * @param node
     * @param connections
     * @returns
     */
    static async simulationNodeTypeFor(node: AnyNode,
        connections: Connection[] | null):
        Promise<SimulationNodeType | null>
    {
        switch (node.type) {
            case NodeType.Combiner:
            case NodeType.CoolingMeterPoint:
            case NodeType.PowerMeterPoint:
            case NodeType.Splitter:
                return this.simulationNodeTypeForPassthroughNode(
                    node as Models.CombinerModel | Models.CoolingMeterPointModel |
                    Models.PowerMeterPointModel | Models.SplitterModel, connections)
            case NodeType.Item:
                return this.simulationNodeTypeForItem(node as Models.ItemModel)
            case NodeType.ITNode:
            case NodeType.OtherNode:
                return SimulationNodeType.ItLoad
            default:
                console.warn(`Unknown node type ${node.type}`)
                return null
        }
    }
    /**
     *
     * @param item
     */
    static async simulationNodeTypeForItem(item: Models.ItemModel) {
        const loadType = await NodeComputationHelper.loadTypeForItem(item)
        const deviceMode = await item.getDeviceMode()
        const deviceModel = await deviceMode.assertDeviceModel()
        switch (loadType) {
            case ConnectionType.POWER:
                return SimulationNodeType.NodePower
            case ConnectionType.COOLING:
                return SimulationNodeType.NodeCooling
            default:
                console.warn(`Unable to identify node type for model type ${deviceModel.getDeviceModelType()}`)
                return null
        }
    }
    /**
     *
     * @param node
     * @param connections
     * @returns
     */
    static simulationNodeTypeForPassthroughNode(
        node: Models.CombinerModel | Models.CoolingMeterPointModel |
            Models.PowerMeterPointModel | Models.SplitterModel,
        connections: Connection[] | null
    ) {
        const nodeReference = `${node.type}/${node.id}`

        const connection = connections?.find(
            (c) => c.from == nodeReference || c.to == nodeReference
        )

        if (connection) {
            switch (connection.type) {
                case ConnectionType.POWER:
                    return SimulationNodeType.PowerSplitterCombiner
                case ConnectionType.COOLING:
                    return SimulationNodeType.CoolingSplitterCombiner
                default:
                    console.warn(`Unsupported connection type: ${connection.type}`)
                    return SimulationNodeType.CoolingSplitterCombiner
            }
        } else {
            return SimulationNodeType.CoolingSplitterCombiner
        }
    }
}