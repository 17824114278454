import { ApplicationService } from "@ekko/client-api-core"
import { Application, Models, Services } from "@ekko/predict-client-api"
import { Endpoint } from "../helpers"
import { BaseHttpService } from "./BaseHttpService"
import { SensorConfigStorageService } from "./sensor-config-storage.service"
import { StorageApiService } from "./storage-api.service"

export type ApiModels = {
    AccessControlModel: new (data?: any) => Models.AccessControlModel,
    DeviceModeModel: new (data?: any) => Models.DeviceModeModel,
    DeviceModelModel: new (data?: any) => Models.DeviceModelModel,
    GridModel: new (data?: any) => Models.GridModel,
    HeatExchangerModel: new (data?: any) => Models.HeatExchangerModel,
    LoadLossModel: new (data?: any) => Models.LoadLossModel,
    ManufacturerModel: new (data?: any) => Models.ManufacturerModel,
    OwnerReferenceModel: new (data?: any) => Models.OwnerReferenceModel,
    SiteModel: new (data?: any) => Models.SiteModel,
    ItemModel: new (data?: any) => Models.ItemModel,
    PolynomialModel: new (data?: any) => Models.PolynomialModel,
    PolynomialValueModel: new (data?: any) => Models.PolynomialValueModel,
    GridListModel: new (data?: any) => Models.GridListModel,
    TempLoadLossModel: new (data?: any) => Models.TempLoadLossModel,
    ClimateProfileReferenceModel: new (data?: any) => Models.ClimateProfileReferenceModel,
    ClimateLocationModel: new (data?: any) => Models.ClimateLocationModel,
    ClimateGeoLocationModel: new (data?: any) => Models.ClimateGeoLocationModel,
    SplitterModel: new (data?: any) => Models.SplitterModel,
    CheapOneCostStrategyModel: new (data?: any) => Models.CheapOneCostStrategyModel,
    ShareCostStrategyModel: new (data?: any) => Models.ShareCostStrategyModel,
    EnvironmentCostStrategyModel: new (data?: any) => Models.EnvironmentCostStrategyModel,
    PriorityCostStrategyModel: new (data?: any) => Models.PriorityCostStrategyModel,
    WeatherStoredReferenceModel: new (data?: any) => Models.WeatherStoredReferenceModel,
    WeatherMeterConfigurationModel: new (data?: any) => Models.WeatherMeterConfigurationModel,
    ITNodeModel: new (data?: any) => Models.ITNodeModel,
    OtherNodeModel: new (data?: any) => Models.OtherNodeModel,
    ITProvisioningModel: new (data?: any) => Models.ITProvisioningModel,
    ITProvisioningEventModel: new (data?: any) => Models.ITProvisioningEventModel,
    ITLoadProfileModel: new (data?: any) => Models.ITLoadProfileModel
    ITLoadHourModel: new (data?: any) => Models.ITLoadHourModel,
    ProvisioningEventModel: new (data?: any) => Models.ProvisioningEventModel,
    ResilienceModel: new (data?: any) => Models.ResilienceModel,
    ResilienceGroupingModel: new (data?: any) => Models.ResilienceGroupingModel,
    ResilienceGroupingStandbyModel: new (data?: any) => Models.ResilienceGroupingStandbyModel,
    ResilienceLoadStagingModel: new (data?: any) => Models.ResilienceLoadStagingModel,
    ResilienceSpareCapacityModel: new (data?: any) => Models.ResilienceSpareCapacityModel,
    ITCostProfileModel: new (data?: any) => Models.ITCostProfileModel,
    CostProfileModel: new (data?: any) => Models.CostProfileModel,
    NodeCapitalCostModel: new (data?: any) => Models.NodeCapitalCostModel,
    DeviceCapitalCostModel: new (data?: any) => Models.DeviceCapitalCostModel,
    NodeMaintenanceCostModel: new (data?: any) => Models.NodeMaintenanceCostModel,
    DeviceMaintenanceCostModel: new (data?: any) => Models.DeviceMaintenanceCostModel,
    PresentationPreferenceModel: new (data?: any) => Models.PresentationPreferenceModel,
    ManufacturerPerformanceDataModel: new () => Models.ManufacturerPerformanceDataModel,
    CommentModel: new () => Models.CommentModel,
    EffectivePeriodModel: new () => Models.EffectivePeriodModel,
    SiteConfigurationModel: new () => Models.SiteConfigurationModel,
    UserActionModel: new () => Models.UserActionModel,
    CoolingMeterPointModel: new () => Models.CoolingMeterPointModel,
    PowerMeterPointModel: new () => Models.PowerMeterPointModel,
    EkkoSoftMeterMappingModel: new () => Models.EkkoSoftMeterMappingModel,
    GroupEkkoSoftMeterMappingModel: new () => Models.GroupEkkoSoftMeterMappingModel,
    SiteEkkoSoftMeterMappingModel: new () => Models.SiteEkkoSoftMeterMappingModel,
    EkkoSoftMeterMappingSourceModel: new () => Models.EkkoSoftMeterMappingSourceModel,
    GroupEkkoSoftMeterMappingSourceModel: new () => Models.GroupEkkoSoftMeterMappingSourceModel,
    PowerCostProfileModel: new () => Models.PowerCostProfileModel,
    PowerCostMonthModel: new () => Models.PowerCostMonthModel,
    PowerCostModel: new () => Models.PowerCostModel,
    CustomDeviceModeModel: new () => Models.CustomDeviceModeModel,
    SiteLayoutScreenshotModel: new () => Models.SiteLayoutScreenshotModel,
    SiteCbreMeterMappingModel: new () => Models.SiteCbreMeterMappingModel,
    CbreMeterMappingModel: new () => Models.CbreMeterMappingModel,
    GroupCbreMeterMappingModel: new () => Models.GroupCbreMeterMappingModel,
    CbreMeterMappingSourceModel: new () => Models.CbreMeterMappingSourceModel,
    GroupCbreMeterMappingSourceModel: new () => Models.GroupCbreMeterMappingSourceModel,
    CombinerModel: new () => Models.CombinerModel,
    SensorConfigSiteModel: new () => Models.SensorConfigSiteModel,
    SensorConfigRoomModel: new () => Models.SensorConfigRoomModel,
    SensorConfigMeterPointModel: new () => Models.SensorConfigMeterPointModel,
    SensorConfigRawMeterModel: new () => Models.SensorConfigRawMeterModel,
    SensorConfigVirtualMeterModel: new () => Models.SensorConfigVirtualMeterModel,
    SensorConfigVirtualMeterEntryModel: new () => Models.SensorConfigVirtualMeterEntryModel,
    IdentificationModel: new () => Models.IdentificationModel
}

export type ApiServices =
    | Services.AccessControlService
    | Services.DeviceModeService
    | Services.DeviceModelService
    | Services.GridService
    | Services.HeatExchangerService
    | Services.LoadLossService
    | Services.ManufacturerService
    | Services.OwnerReferenceService
    | Services.SiteService
    | Services.ItemService
    | Services.ClimateProfileReferenceService
    | Services.ClimateLocationService
    | Services.ClimateGeoLocationService
    | Services.WeatherStoredReferenceService
    | Services.WeatherMeterConfigurationService
    | Services.ITNodeService
    | Services.OtherNodeService
    | Services.ITProvisioningService
    | Services.ITProvisioningEventService
    | Services.ITLoadProfileService
    | Services.ProvisioningEventService
    | Services.PresentationPreferenceService
    | Services.ManufacturerPerformanceDataService
    | Services.CommentService
    | Services.EffectivePeriodService
    | Services.SiteConfigurationService
    | Services.CoolingMeterPointService
    | Services.PowerMeterPointService
    | Services.EkkoSoftMeterMappingService
    | Services.GroupEkkoSoftMeterMappingService
    | Services.SiteEkkoSoftMeterMappingService
    | Services.EkkoSoftMeterMappingSourceService
    | Services.GroupEkkoSoftMeterMappingSourceService
    | Services.PowerCostProfileService
    | Services.PowerCostMonthService
    | Services.PowerCostService
    | Services.SiteLayoutScreenshotService
    | Services.CheapOneCostStrategyService
    | Services.EnvironmentCostStrategyService
    | Services.PriorityCostStrategyService
    | Services.ShareCostStrategyService
    | Services.SiteCbreMeterMappingService
    | Services.CbreMeterMappingService
    | Services.GroupCbreMeterMappingService
    | Services.CbreMeterMappingSourceService
    | Services.GroupCbreMeterMappingSourceService
    | Services.CombinerService
    | Services.SensorConfigSiteService
    | Services.SensorConfigRoomService
    | Services.SensorConfigMeterPointService
    | Services.SensorConfigRawMeterService
    | Services.SensorConfigVirtualMeterService
    | Services.SensorConfigVirtualMeterEntryService
    | Services.IdentificationService


export default class ApiService extends BaseHttpService {
    /**
     *
     */
    private static api?: Promise<Application>

    /**
     *
     */
    private static sensorConfigAPI?: Promise<Application>

    protected endpoint: Endpoint = "api"

    /**
     *
     * @returns
     */
     public static getApiUncached(): Promise<Application> {
        return new StorageApiService().getUncached()
    }

    /**
     *
     * @returns
     */
    private static async getSensorConfigApiUncached(): Promise<Application> {
        return new SensorConfigStorageService().getUncached()
    }

    /**
     *
     * @returns
     */
    public static getApi(): Promise<Application> {
        if (!ApiService.api) {
            ApiService.api = this.getApiUncached()
        }

        return ApiService.api
    }

    /**
     *
     * @returns
     */
    public static getSensorConfigApi(): Promise<Application> {
        if (!ApiService.sensorConfigAPI) {
            ApiService.sensorConfigAPI = this.getSensorConfigApiUncached()
        }

        return ApiService.sensorConfigAPI
    }

    /**
     * @param name
     */
    public static async getNamedService<T extends ApplicationService>(name: string): Promise<T> {
        return this.getService(name) as Promise<T>
    }

    /**
     *
     * @param serviceName
     * @param endPoint
     * @returns
     */
    public static async getService(serviceName: string, endPoint?: Endpoint) {
        const api = await ApiService.getApi()
        return api.getService(serviceName) as ApplicationService
    }

    /**
     *
     * @param serviceName
     * @returns
     */
    public static async getSCService(serviceName: string) {
        const api = await ApiService.getSensorConfigApi()
        return api.getService(serviceName) as ApplicationService
    }

    public static async getDeviceModelService(): Promise<Services.DeviceModelService> {
        return this.getNamedService<Services.DeviceModelService>("DeviceModelService")
    }

    public static async getManufacturerService(): Promise<Services.ManufacturerService> {
        return this.getNamedService<Services.ManufacturerService>("ManufacturerService")
    }

    public static async getSiteService(): Promise<Services.SiteService> {
        return this.getNamedService<Services.SiteService>("SiteService")
    }

    public static async getSplitterService(): Promise<Services.SplitterService> {
        return this.getNamedService<Services.SplitterService>("SplitterService")
    }

    public static async getCheapOneCostStrategyService(): Promise<Services.CheapOneCostStrategyService> {
        return this.getNamedService<Services.CheapOneCostStrategyService>("CheapOneCostStrategyService")
    }

    public static async getPriorityCostStrategyService(): Promise<Services.PriorityCostStrategyService> {
        return this.getNamedService<Services.PriorityCostStrategyService>("PriorityCostStrategyService")
    }

    public static async getItemService(): Promise<Services.ItemService> {
        return this.getNamedService<Services.ItemService>("ItemService")
    }

    public static async getCombinerService(): Promise<Services.CombinerService> {
        return this.getNamedService<Services.CombinerService>("CombinerService")
    }

    public static async getEkkoSoftMeterMappingService(): Promise<Services.EkkoSoftMeterMappingService> {
        return this.getNamedService<Services.EkkoSoftMeterMappingService>("EkkoSoftMeterMappingService")
    }

    public static async getEkkoSoftMeterMappingSourceService(): Promise<Services.EkkoSoftMeterMappingSourceService> {
        return this.getNamedService<Services.EkkoSoftMeterMappingSourceService>("EkkoSoftMeterMappingSourceService")
    }

    public static async getDeviceModeService(): Promise<Services.DeviceModeService> {
        return this.getNamedService<Services.DeviceModeService>("DeviceModeService")
    }

    public static async getCustomDeviceModeService(): Promise<Services.CustomDeviceModeService> {
        return this.getNamedService<Services.CustomDeviceModeService>("CustomDeviceModeService")
    }

    public static async getGridService(): Promise<Services.GridService> {
        return this.getNamedService<Services.GridService>("GridService")
    }

    public static async getGridListService(): Promise<Services.GridListService> {
        return this.getNamedService<Services.GridListService>("GridListService")
    }

    public static async getLoadLossService(): Promise<Services.LoadLossService> {
        return this.getNamedService<Services.LoadLossService>("LoadLossService")
    }

    public static async getHeatExchangerService(): Promise<Services.HeatExchangerService> {
        return this.getNamedService<Services.HeatExchangerService>("HeatExchangerService")
    }

    public static async getPolynomialService(): Promise<Services.PolynomialService> {
        return this.getNamedService<Services.PolynomialService>("PolynomialService")
    }

    public static async getPolynomialValueService(): Promise<Services.PolynomialValueService> {
        return this.getNamedService<Services.PolynomialValueService>("PolynomialValueService")
    }

    public static async getClimateProfileReferenceService(): Promise<Services.ClimateProfileReferenceService> {
        return this.getNamedService<Services.ClimateProfileReferenceService>("ClimateProfileReferenceService")
    }

    public static async getClimateLocationService(): Promise<Services.ClimateLocationService> {
        return this.getNamedService<Services.ClimateLocationService>("ClimateLocationService")
    }

    public static async getClimateGeoLocationService(): Promise<Services.ClimateGeoLocationService> {
        return this.getNamedService<Services.ClimateGeoLocationService>("ClimateGeoLocationService")
    }

    public static async getWeatherStoredReferenceService(): Promise<Services.WeatherStoredReferenceService> {
        return this.getNamedService<Services.WeatherStoredReferenceService>("WeatherStoredReferenceService")
    }

    public static async getWeatherMeterConfigurationService(): Promise<Services.WeatherMeterConfigurationService> {
        return this.getNamedService<Services.WeatherMeterConfigurationService>("WeatherMeterConfigurationService")
    }

    public static async getITNodeService(): Promise<Services.ITNodeService> {
        return this.getNamedService<Services.ITNodeService>("ITNodeService")
    }

    public static async getOtherNodeService(): Promise<Services.OtherNodeService> {
        return this.getNamedService<Services.OtherNodeService>("OtherNodeService")
    }

    public static async getCoolingMeterPointService(): Promise<Services.CoolingMeterPointService> {
        return this.getNamedService<Services.CoolingMeterPointService>("CoolingMeterPointService")
    }

    public static async getPowerMeterPointService(): Promise<Services.PowerMeterPointService> {
        return this.getNamedService<Services.PowerMeterPointService>("PowerMeterPointService")
    }

    public static async getITProvisioningService(): Promise<Services.ITProvisioningService> {
        return this.getNamedService<Services.ITProvisioningService>("ITProvisioningService")
    }

    public static async getOtherProvisioningService(): Promise<Services.OtherProvisioningService> {
        return this.getNamedService<Services.OtherProvisioningService>("OtherProvisioningService")
    }

    public static async getITCostProfileService(): Promise<Services.ITCostProfileService> {
        return this.getNamedService<Services.ITCostProfileService>("ITCostProfileService")
    }

    public static async getOtherCostProfileService(): Promise<Services.OtherCostProfileService> {
        return this.getNamedService<Services.OtherCostProfileService>("OtherCostProfileService")
    }

    public static async getCostProfileService(): Promise<Services.CostProfileService> {
        return this.getNamedService<Services.CostProfileService>("CostProfileService")
    }

    public static async getDeviceCapitalCostService(): Promise<Services.DeviceCapitalCostService> {
        return this.getNamedService<Services.DeviceCapitalCostService>("DeviceCapitalCostService")
    }

    public static async getNodeCapitalCostService(): Promise<Services.NodeCapitalCostService> {
        return this.getNamedService<Services.NodeCapitalCostService>("NodeCapitalCostService")
    }

    public static async getNodeMaintenanceCostService(): Promise<Services.NodeMaintenanceCostService> {
        return this.getNamedService<Services.NodeMaintenanceCostService>("NodeMaintenanceCostService")
    }

    public static async getDeviceMaintenanceCostService(): Promise<Services.DeviceMaintenanceCostService> {
        return this.getNamedService<Services.DeviceMaintenanceCostService>("DeviceMaintenanceCostService")
    }

    public static async getITProvisioningEventService(): Promise<Services.ITProvisioningEventService> {
        return this.getNamedService<Services.ITProvisioningEventService>("ITProvisioningEventService")
    }

    public static async getOtherProvisioningEventService(): Promise<Services.OtherProvisioningEventService> {
        return this.getNamedService<Services.OtherProvisioningEventService>("OtherProvisioningEventService")
    }

    public static async getITLoadProfileService(): Promise<Services.ITLoadProfileService> {
        return this.getNamedService<Services.ITLoadProfileService>("ITLoadProfileService")
    }

    public static async getITLoadHourService(): Promise<Services.ITLoadHourService> {
        return this.getNamedService<Services.ITLoadHourService>("ITLoadHourService")
    }

    public static async getProvisioningEventService(): Promise<Services.ProvisioningEventService> {
        return this.getNamedService<Services.ProvisioningEventService>("ProvisioningEventService")
    }

    public static async getResilienceService(): Promise<Services.ResilienceService> {
        return this.getNamedService<Services.ResilienceService>("ResilienceService")
    }

    public static async getResilienceLoadStagingService(): Promise<Services.ResilienceLoadStagingService> {
        return this.getNamedService<Services.ResilienceLoadStagingService>("ResilienceLoadStagingService")
    }

    public static async getResilienceSpareCapacityService(): Promise<Services.ResilienceSpareCapacityService> {
        return this.getNamedService<Services.ResilienceSpareCapacityService>("ResilienceSpareCapacityService")
    }

    public static async getResilienceGroupingService(): Promise<Services.ResilienceGroupingService> {
        return this.getNamedService<Services.ResilienceGroupingService>("ResilienceGroupingService")
    }

    public static async getResilienceGroupingStandbyService(): Promise<Services.ResilienceGroupingStandbyService> {
        return this.getNamedService<Services.ResilienceGroupingStandbyService>("ResilienceGroupingStandbyService")
    }

    public static async getPresentationPreferenceService(): Promise<Services.PresentationPreferenceService> {
        return this.getNamedService<Services.PresentationPreferenceService>("PresentationPreferenceService")
    }

    public static async getCommentService(): Promise<Services.CommentService> {
        return this.getNamedService<Services.CommentService>("CommentService")
    }

    public static async getEffectivePeriodService(): Promise<Services.EffectivePeriodService> {
        return this.getNamedService<Services.EffectivePeriodService>("EffectivePeriodService")
    }

    public static async getNodeGroupService(): Promise<Services.NodeGroupService> {
        return this.getNamedService<Services.NodeGroupService>("NodeGroupService")
    }

    public static async getSiteConfigurationService(): Promise<Services.SiteConfigurationService> {
        return this.getNamedService<Services.SiteConfigurationService>("SiteConfigurationService")
    }

    public static async getPowerCostProfileService(): Promise<Services.PowerCostProfileService> {
        return this.getNamedService<Services.PowerCostProfileService>("PowerCostProfileService")
    }

    public static async getPowerCostMonthService(): Promise<Services.PowerCostMonthService> {
        return this.getNamedService<Services.PowerCostMonthService>("PowerCostMonthService")
    }

    public static async getPowerCostService(): Promise<Services.PowerCostService> {
        return this.getNamedService<Services.PowerCostService>("PowerCostService")
    }

    public static async getSiteCbreMeterMappingService(): Promise<Services.SiteCbreMeterMappingService> {
        return this.getNamedService<Services.SiteCbreMeterMappingService>("SiteCbreMeterMappingService")
    }

    public static async getCbreMeterMappingService(): Promise<Services.CbreMeterMappingService> {
        return this.getNamedService<Services.CbreMeterMappingService>("CbreMeterMappingService")
    }

    public static async getCbreMeterMappingSourceService(): Promise<Services.CbreMeterMappingSourceService> {
        return this.getNamedService<Services.CbreMeterMappingSourceService>("CbreMeterMappingSourceService")
    }

    public static async getOwnerReferenceService(): Promise<Services.OwnerReferenceService> {
        return this.getNamedService<Services.OwnerReferenceService>("OwnerReferenceService")
    }

    public static async getSensorConfigSiteService(): Promise<Services.SensorConfigSiteService> {
        return ApiService.getSCService("SensorConfigSiteService") as Promise<Services.SensorConfigSiteService>
    }

    public static async getSensorConfigRoomService(): Promise<Services.SensorConfigRoomService> {
        return ApiService.getSCService("SensorConfigRoomService") as Promise<Services.SensorConfigRoomService>
    }

    public static async getSensorConfigMeterPointService(): Promise<Services.SensorConfigMeterPointService> {
        return ApiService.getSCService("SensorConfigMeterPointService") as Promise<Services.SensorConfigMeterPointService>
    }

    public static async getSensorConfigRawMeterService(): Promise<Services.SensorConfigRawMeterService> {
        return ApiService.getSCService("SensorConfigRawMeterService") as Promise<Services.SensorConfigRawMeterService>
    }

    public static async getSensorConfigVirtualMeterService(): Promise<Services.SensorConfigVirtualMeterService> {
        return ApiService.getSCService("SensorConfigVirtualMeterService") as Promise<Services.SensorConfigVirtualMeterService>
    }

    public static async getSensorConfigVirtualMeterEntryService(): Promise<Services.SensorConfigVirtualMeterEntryService> {
        return ApiService.getSCService("SensorConfigVirtualMeterEntryService") as Promise<Services.SensorConfigVirtualMeterEntryService>
    }

    public static async getIdentificationService(): Promise<Services.IdentificationService> {
        return this.getNamedService<Services.IdentificationService>("IdentificationService")
    }

    public static async getModels(): Promise<ApiModels> {
        return Models
    }


    /**
     * Retrieves entity records from the server based on its service and model
     * @param serviceName
     * @param method
     * @param relationFilter
     * @param attributeFilter
     */
    public static async getRecords<Service, Model>(
        serviceName: string,
        method: keyof Service,
        relationFilter: { relation: string, record: { type: string, id: string } } | null = null,
        attributeFilter: { attribute: string, op?: "equal" | "gt" | "lt" | "gte" | "lte", value: any } | null = null
    ): Promise<Model[]> {
        const service = await ApiService.getService(serviceName) as Service
        const generator = (service[method] as any)(attributeFilter || relationFilter)
        const recordsFound: Model[] = []
        for await (const record of generator) {
            recordsFound.push(record)
        }
        return recordsFound as Model[]
    }
}