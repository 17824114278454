import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toast-container toast-pos-top-mid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notification)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "toast-2",
          class: _normalizeClass(_ctx.toastClassesByType[_ctx.notification.type])
        }, [
          _createElementVNode("a", {
            href: "#",
            class: "close-toast",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, "✖"),
          _createElementVNode("b", null, _toDisplayString(_ctx.notification.title) + " /", 1),
          _createTextVNode(" " + _toDisplayString(_ctx.notification.message), 1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}