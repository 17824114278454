import { NodeReferenceHelper, NodesetHelper, NumberFormat } from "@/core/helpers"
import { UnbatchEvents } from "@/core/interfaces"
import { DateGroupSimulationResult, NamedSimulationNode, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { AggregatorResponse } from "../interfaces/AggregatorResponse"
import { EnergyBaseClient } from "./EnergyBaseClient"

export class CoolingLoadClient extends EnergyBaseClient {
    /**
     * @param result
     * @param nodes
     */
    private parseSimulateResult(result: AggregatorResponse, nodes: NamedSimulationNode[]) {
        const allNodeReferences = nodes.map(n => NodeReferenceHelper.getNodeReference(n))

        const output: DateGroupSimulationResult[] = []

        for (const [dateGroup, dynamicCost] of Object.entries(result)) {

            const nodeCosts = new Map<string, { fixed: number, variable: number, it: number }>()


            for (const reference of allNodeReferences) {

                let itLoad = 0
                let fixedPower = 0
                let variablePower = 0

                let coolerSuppliers = nodes.find(n => NodeReferenceHelper.getNodeReference(n) === reference)?.cooledBy

                if (coolerSuppliers) {

                    if (!Array.isArray(coolerSuppliers)) {
                        coolerSuppliers = [coolerSuppliers]
                    }

                    for (const [nodeReference, cost] of Object.entries(dynamicCost.fixed.costs)) {
                        if (coolerSuppliers.find(c => nodeReference === `${c.type}/${c.id}`)) {
                            fixedPower += cost
                        }
                    }
                    for (const [nodeReference, cost] of Object.entries(dynamicCost.variable.costs)) {
                        if (nodeReference.startsWith("itNode/")) {
                            itLoad += cost
                        } else {
                            if (coolerSuppliers.find(c => nodeReference === `${c.type}/${c.id}`)) {
                                variablePower += cost
                            }
                        }
                    }
                }

                fixedPower = NumberFormat.to2dp(fixedPower / 1000)
                variablePower =  NumberFormat.to2dp(variablePower / 1000)
                itLoad =  NumberFormat.to2dp(itLoad / 1000)
                nodeCosts.set(reference, { fixed: fixedPower, variable: variablePower, it: itLoad })

            }

            output.push({ dateGroup, nodeCosts })
        }
        return output
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected?: string | null,
        unbatchCallbacks: UnbatchEvents | null = null
    ): Promise<DateGroupSimulationResult[]> {
        const nodes = NodesetHelper.nodes(nodesets)
        const result = await this.simulateEnergyResult(false, nodesets,
            siteInfo, unbatchCallbacks)

        return this.parseSimulateResult(result, nodes)
    }
}