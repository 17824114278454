import { Endpoint } from "../helpers"
import { HttpError } from "../remoteErrors/HttpError"
import { JsonRpcError } from "../remoteErrors/JsonRpcError"
import { BaseHttpService } from "./BaseHttpService"

/**
 *
 */
export abstract class AnyJSONRPC extends BaseHttpService {
    /**
     *
     */
    protected abstract endpoint: Endpoint

    /**
     * Calls the named JSON-RPC method
     *
     * @param method
     * @param params
     * @param headersIn
     * @returns
     */
    protected async callMethod(method: string,
        params: { [key: string]: any } = {},
        headersIn: Record<string, string | undefined> | ((body: string) => Record<string, string | undefined>) = {}
    ) {
        const body = JSON.stringify({
            jsonrpc: "2.0",
            method,
            params,
        })

        const headers =
            (typeof headersIn == "function") ? headersIn(body) : headersIn

        const response = await this.authenticatedPOST("", body, {
            ...headers,
            "Accept": "application/json",
            "Content-Type": "application/json",
        })

        if (!response.ok) {
            console.warn(`Method call "${method}" failed`)
            let responseBody: any | undefined
            try {
                responseBody = await response.json()
            } catch (e) {
                console.warn(e)
                // Fall through
            }
            if (responseBody?.jsonrpc == "2.0") {
                if (responseBody["error"]) {
                    throw new JsonRpcError(responseBody, method)
                } else {
                    throw new HttpError(response.status, `Internal error: JSON-RPC success response with error code on "${method}": ${await response.text()}`)
                }
            }
            throw new HttpError(response.status, `Method call "${method}" failed`)
        }

        const responseBody = await response.json()

        return responseBody.result
    }

    /**
     * Calls the named JSON-RPC method, but if it returns a cursor will scan
     * for the result instead.
     *
     * @param name
     * @param params
     * @param parseCursor Return either a GET URL or a JSON-RPC call spec
     * @param headersIn
     * @returns
     */
    protected async callMethodBackgroundable(
        name: string,
        params: { [key: string]: any },
        parseCursor: (cursor: string) => { url: string } |
        { name: string, params: { [key: string]: any } },
        headersIn: Record<string, string | undefined> | ((body: string) => Record<string, string | undefined>) = {}
    ): Promise<any> {

        const result = await this.callMethod(name, params, headersIn)
        if ("cursor" in result) {
            const cursorRequestSpec = parseCursor(result.cursor)
            let cursorFetch: () => Promise<any>
            if ("url" in cursorRequestSpec) {
                cursorFetch = async () => {
                    const headers = (typeof headersIn == "function") ? headersIn("") : headersIn
                    const response = await this.authenticatedGET(cursorRequestSpec.url, {
                        ...headers,
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    })

                    if (response.ok) {
                        const responseBody = await response.json()
                        if (responseBody.error) {
                            throw new JsonRpcError(responseBody, name)
                        } else {
                            return responseBody.result
                        }
                    } else {
                        console.warn(`Method call "${name}" failed`)
                        throw new HttpError(response.status, `Method call "${name}" failed`)
                    }
                }
            } else {
                cursorFetch = () => this.callMethod(cursorRequestSpec.name, cursorRequestSpec.params, headersIn)
            }

            return new Promise((resolve, reject) => {
                let fetching = false
                let finished = false
                const activeInterval = setInterval(async () => {
                    if (!fetching) {
                        fetching = true
                        try {
                            const result = await cursorFetch()
                            clearInterval(activeInterval)
                            finished = true
                            resolve(result)
                        } catch (e) {
                            if (e instanceof HttpError && e.code == 404) {
                                // Fine
                            } else {
                                clearInterval(activeInterval)
                                finished = true
                                reject(e)
                            }
                        } finally {
                            fetching = false
                        }
                    }
                }, 10_000)
                setTimeout(() => {
                    if (!finished) {
                        clearInterval(activeInterval)
                        finished = true
                        reject(new Error("Timed out"))
                    }
                }, 90_000)
            })
        } else {
            return result
        }
    }
}