import { Reading } from "@/core/interfaces"
import { SimulatableClientBase } from "@/Simulation/clients/SimulatableClientBase"
import { Granularity } from "@/Simulation/enums"
import { SimulationNode } from "@/Simulation/interfaces"

/**
 * Base Class for history clients
 */
export abstract class HistoryClientBase extends SimulatableClientBase {
    /**
     * Fetch information suitable for graphing
     * @param siteId
     * @param nodes
     */
    abstract fetch(
        siteId: string, nodes?: SimulationNode[]
    ): Promise<Reading<Date>[] | Map<string, number> | Map<string, { actual: number }>>

    /**
     * Reading granularity to fetch. The default is DAY.
     */
    granularity = Granularity.DAY
}