import ApiService from "@/core/services/api.service"
import { FilterType } from "@ekko/client-api-core"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"
import { actions as tableActions, getters as tableGetters, mutations as tableMutations } from "./table"
import { ClimateProfile, ClimateProfileState } from "./types"

class State implements Partial<ClimateProfileState> {
  climateProfiles: ClimateProfile[] = []
  climateProfile: ClimateProfile | null = null;
  loaded = false
  countryText = ""
  locationText = ""
  stateText = ""
}

const getters = <GetterTree<ClimateProfileState, any>>{

}

const mutations = <MutationTree<State>>{

  setClimateProfile(state, climateProfile: ClimateProfile) {
    state.climateProfile = climateProfile
  },
  deleteClimateProfile(state, climateProfile: ClimateProfile): void {
    state.climateProfiles = state.climateProfiles.filter(
      d => (d.profile as any).id !== (climateProfile.profile as any).id
    )
  },
  createClimateProfile(state, climateProfile: ClimateProfile): void {
    state.climateProfiles.push(climateProfile)
  },
  updateClimateProfile(state, climateProfile: ClimateProfile): void {
    state.climateProfiles = state.climateProfiles.map(d => {
      if ((d.profile as any).id === (climateProfile.profile as any).id) {
        return climateProfile
      }
      return d
    })
  }
}

const actions = <ActionTree<State, any>>{

  selectClimateProfileWithId: ({ commit, state }, id: string) => {
    const climateProfile = state.climateProfiles.find(m => (m.profile as any).id === id)
    commit('setClimateProfile', climateProfile)
  },
  async fetchClimateProfiles({ commit }) {
    const profileService = await ApiService.getClimateProfileReferenceService()
    const filter: FilterType = { attribute: "defunct", op: "equal", value: false }
    const profilesGenerator = profileService.getAllClimateProfileReferences(filter)
    const climateProfiles: Models.ClimateProfileReferenceModel[] = []

    for await (const profile of profilesGenerator) {
      climateProfiles.push(profile)
    }

    const locationService = await ApiService.getClimateLocationService()
    const locationsGenerator = locationService.getAllClimateLocations()
    const climateLocations: Models.ClimateLocationModel[] = []
    for await (const location of locationsGenerator) {
      climateLocations.push(location)
    }

    const geoLocationService = await ApiService.getClimateGeoLocationService()
    const geoLocationsGenerator = geoLocationService.getAllClimateGeoLocations()
    const climateGeoLocations: Models.ClimateGeoLocationModel[] = []
    for await (const geoLocation of geoLocationsGenerator) {
      climateGeoLocations.push(geoLocation)
    }

    commit("setLoaded", true)
    commit("setClimateProfiles", { climateProfiles, climateLocations, climateGeoLocations })
  },
  archiveClimateProfile({ commit }, climateProfile: any) {
    commit("deleteClimateProfile", climateProfile)
    commit('climateProfileArchive/archiveClimateProfile', climateProfile, { root: true })
  },
  createClimateProfile({ commit }, climateProfile: any) {
    commit("createClimateProfile", climateProfile)
  },
  updateClimateProfile({ commit }, climateProfile: any) {
    commit("updateClimateProfile", climateProfile)
  }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const ClimateProfileModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...tableGetters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...tableMutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...tableActions,
    ...actions
  },
}

export default ClimateProfileModule
