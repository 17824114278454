import { Models } from "@ekko/predict-client-api"
import { DeviceModelType, Interface, LoadCalculation, ResilienceHandler } from "predict-performance-calculation"

/**
 *
 */
export class NodeComputationHelper {
    /**
     * This returns the provisioned (100% for the time) capacity for the node
     *
     * @param count
     * @param resilience
     * @param capacity
     * @returns
     */
    static getComputedProvisionedCapacity(count: number,
        resilience: Interface.Resilience | null, capacity: number)
    {
        const resilienceHandler = new ResilienceHandler()
        return resilienceHandler.getComputedProvisionedCapacity(resilience,
            {type: "", capacity}, count)
    }

    /**
     *
     * @param item
     */
    static async loadTypeForItem(item: Models.ItemModel) {
        const deviceMode = await item.getDeviceMode()
        const deviceModel = await deviceMode.assertDeviceModel()

        return LoadCalculation.loadTypeForDeviceModelType(deviceModel.getDeviceModelType() as DeviceModelType)
    }
}