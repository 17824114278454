import { Interface, NodeType } from "predict-performance-calculation"
import { NodeParameterBase } from "./NodeParameterBase"
import { WorkloadMaxType } from "../../enums/WorkloadMaxType"

/**
 *
 */
export abstract class WorkloadNodeParameter<T extends { id: string, type: string }, N extends { id: string, type: NodeType }> extends NodeParameterBase<T, N> {
    /**
     * @param node
     * @returns
     */
    protected abstract getProvisioningEvents(node: T): Promise<Interface.LoadProvisioningEvent<Date>[]>

    /**
     *
     * @param node
     * @param from
     * @param to
     * @param type
     * @returns
     */
    async getHighestProvisioning(node: T, from: Date, to: Date,
        type: WorkloadMaxType
    ) {
        let highestProvisioning: number | undefined
        for (const p of await this.getProvisioningEvents(node)) {
            const getMaxLoadRating = type == WorkloadMaxType.peak ?
                () => p.itLoadProfile.itLoadHours.reduce((c, a) => Math.max(c, a.load), 0) :
                () => 1
            if (p.month < from) {
                highestProvisioning = p.power * getMaxLoadRating()
            }
            if (p.month > to) {
                break
            }
            const totalPower = p.power * getMaxLoadRating()
            if (highestProvisioning) {
                if (totalPower > highestProvisioning) {
                    highestProvisioning = totalPower
                }
            } else {
                highestProvisioning = totalPower
            }
        }
        return highestProvisioning ?? 0
    }
}