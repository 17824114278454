/**
 *
 */
export enum GraphViewMode {
    ALLOCATED = "allocated",
    ATTRIBUTED = "attributed",
    DAILY = "daily",
    MONTHLY = "monthly",
    YEARLY = "yearly",
    UTILIZATION = "utilization",
}