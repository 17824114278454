/**
 *
 */
export const debugOptions = {
    /**
     *
     */
    get fillSitesTable(): number | null {
        return null
    }
}
