/**
 *
 */
export class IterableHelper {
    /**
     * @param records
     * @param getKey
     * @param add
     * @param summarise
     * @param createSum
     */
    static aggregate<T>(records: Iterable<T>, getKey: (record: T) => string,
        add: (to: T, from: T) => void,
        summarise: (aggregatedRecord: T, count: number, key: string) => T,
        createSum: (record: T) => T = (record) => ({...record})
    ): T[] {
        const aggregatedValues = new Map<string, {count: number, sum: T}>()
        for(const record of records) {
            const key = getKey(record)
            const aggregatedValue = aggregatedValues.get(key)
            if(aggregatedValue) {
                aggregatedValue.count++
                add(aggregatedValue.sum, record)
            } else {
                aggregatedValues.set(key, {
                    count: 1,
                    sum: createSum(record)
                })
            }
        }

        return Array.from(aggregatedValues.entries()).map(
            ([key, aggregation]) => summarise(aggregation.sum, aggregation.count, key)
        )
    }
}