import { HistoryClientBase, CostHistoryClient, DCiEHistoryClient, EnergyHistoryClient, PUEHistoryClient, CUEHistoryClient } from "../clients"

/**
 * Class ClientCollection
 */
export class ClientCollection {
    /**
     * Return an instance of ClientBase
     * @param name
     */
    static factory(name: string): HistoryClientBase {
        const registeredClasses: { [key: string]: new () => HistoryClientBase } = {
            CostHistoryClient,
            EnergyHistoryClient,
            PUEHistoryClient,
            DCiEHistoryClient,
            CUEHistoryClient,
        }

        if (registeredClasses[name]) {
            return new registeredClasses[name]()
        }

        throw new Error(`${name} is not supported client class`)
    }
}