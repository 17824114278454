import ApiService from "@/core/services/api.service"
import { Models, Services } from "@ekko/predict-client-api"
import { Base } from "./Base"
import { NRef } from "@/store/SiteViewState"
import { PowerState } from "@/core/enums"

/**
 *
 */
export class Item extends Base<Models.ItemModel> {
    /**
     *
     * @returns
     */
    private getService() {
        return ApiService.getNamedService<Services.ItemService>("ItemService")
    }
    async add(): Promise<NRef<Models.ItemModel>> {
        const service = await this.getService()
        const [nodeOut] = await service.addItem(this.node)
        this.node = nodeOut
        await this.connect()

        const resilienceService = await ApiService.getResilienceService()

        // Create default resilience of type NO RESILIENCE
        const resilienceModel = new Models.ResilienceModel()
        resilienceModel.setOffAtZeroLoad(false)
        resilienceModel.setOperatingLimit(1)
        resilienceModel.setIdleState(PowerState.OFF)
        resilienceModel.addItem(nodeOut)

        const [resilience] = await resilienceService.addResilience(resilienceModel)

        nodeOut.addResilience(resilience)

        return nodeOut
    }
    async connect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.addItems([this.node])
    }
    async disconnect(): Promise<any> {
        const siteConfiguration = await this.node.getSiteConfiguration()
        siteConfiguration?.removeItems([this.node])
    }
    async remove(): Promise<any> {
        const service = await this.getService()
        await service.removeItem(this.node)
        return this.disconnect()
    }
    async update(): Promise<any> {
        const service = await this.getService()
        await service.updateItem(this.node)
    }
}