import { Paginated } from "@/core/helpers/Paginated"
import { AuditLog } from "@/core/interfaces"
import { jsonRpcService } from "@/core/services"
import { ActionTree, GetterTree, MutationTree } from "vuex"
import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { FiltersState } from "../common/filters/types"
import { PaginationModule } from "../common/pagination"
import { PaginationState } from "../common/pagination/types"
import { AuditLogHelper } from "@/core/helpers"

/**
 *
 */
class State {
    /**
     *
     */
    loaded = false

    /**
     *
     */
    logs: AuditLog[] = []
}

const getters: GetterTree<State & FiltersState & PaginationState, any> = {
    /**
     *
     * @param state
     * @returns
     */
    getLogsFilter(state): (a: AuditLog) => boolean {
        const regexp = new RegExp(state.searchText, "i")

        return (l) => !!regexp.exec(l.ownerOrg) ||
            !!regexp.exec(l.userOrg) ||
            !!regexp.exec(l.user) ||
            !!regexp.exec(l.objectKey) ||
            !!regexp.exec(l.subObject) ||
            !!regexp.exec(l.change.method) ||
            !!regexp.exec(l.change.id ? l.change.id.toString() : "") ||
            !!regexp.exec(l.change.paramLength ? l.change.paramLength.toString() : "") ||
            !!regexp.exec(l.change.attributes?.name ?? "") ||
            !!regexp.exec(l.outcome.httpCode.toString()) ||
            !!regexp.exec(l.outcome.errorCode ? l.outcome.errorCode.toString() : "") ||
            !!regexp.exec(l.timeKey) ||
            !!regexp.exec(AuditLogHelper.getIdentificationName(l.user, l) ?? "") ||
            !!regexp.exec(AuditLogHelper.getIdentificationName(AuditLogHelper.getObjectKeyReference(l.objectKey), l) ?? "")
    },
    /**
     *
     * @param state
     * @returns
     */
    getLogsSort(state): (a: AuditLog, b: AuditLog) => number {
        const reverse = (state.sort.direction === 'desc')

        switch (state.sort.field) {
            case "Owner Org":
                return Paginated.sortUndefinedFirst((l) => l.ownerOrgName, reverse)
            case "User Org":
                return Paginated.sortUndefinedFirst((l) => l.userOrgName, reverse)
            case "User Id":
                return Paginated.sortUndefinedFirst((l) => l.user, reverse)
            case "Object Id":
                return Paginated.sortUndefinedFirst((l) => l.objectKey, reverse)
            case "Action/Change":
                return Paginated.sortUndefinedFirst((l) => (l.change.method).toLowerCase(), reverse)
            case "Entity Id":
                return Paginated.sortUndefinedFirst((l) => l.change.id, reverse)
            case "Request Size":
                return Paginated.sortUndefinedFirst((l) => l.change.paramLength, reverse)
            case "Name Set":
                return Paginated.sortUndefinedFirst((l) => l.change.attributes?.name, reverse)
            case "Outcome":
                return Paginated.sortUndefinedFirst((l) => l.outcome.httpCode, reverse)
            case "Error Code":
                return Paginated.sortUndefinedFirst((l) => l.outcome.errorCode, reverse)
            case "Time":
                return Paginated.sortUndefinedFirst((l) => l.timeKey, reverse)
            case "User Name":
                return Paginated.sortUndefinedFirst((l) => AuditLogHelper.getIdentificationName(l.user, l), reverse)
            case "Object Name":
                return Paginated.sortUndefinedFirst((l) => AuditLogHelper.getIdentificationName(AuditLogHelper.getObjectKeyReference(l.objectKey), l), reverse)
            default:
                return (a, b) => 0
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getLogs(state, getters): PaginatedResult<AuditLog> {
        const results = state.logs

        return new Paginated(
            results,
            state.page,
            state.pageSize,
            getters.getLogsSort,
            getters.getLogsFilter
        )
    },
    /**
     *
     * @param state
     */
    getLogsIsEmpty(state): boolean {
        return !!state.logs && state.logs?.length === 0
    },
}

const mutations: MutationTree<State> = {
    /**
     * @param state
     * @param logs
     */
    setLogs(state, logs: State["logs"]): void {
        state.logs = logs
    },
    /**
     * @param state
     * @param loaded
     */
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    }
}

const actions = <ActionTree<State, any>>{
    /**
     *
     * @param actionState
     * @param params
     */
    async fetchLogs({ commit }, params: { orgId: string, from: string, to: string}) {
        const logs = await jsonRpcService.getAuditLogs(params) ?? []

        commit("setLoaded", true)
        commit("setLogs", logs)
    }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const LogsModule = {
    namespaced: true,
    state: {
        ...paginationStore.state,
        ...filtersModule.state,
        ...new State(),
    },
    getters: {
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...mutations
    },
    actions: {
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...actions
    },
}

export default LogsModule
