import { PCVR } from "@ekko/pre-compression-value-reduction"
import { LazyAsync } from "./LazyAsync"
import { Endpoint, UrlConfig } from "../helpers/UrlConfig"

/**
 * Not even on the class, so it does not get leaked
 */
let lastVerifedToken: string | null


/**
 *
 */
const debugSkipEncoding = false

/**
 *
 */
export abstract class BaseHttpService {
    /**
     *
     */
    public static get testRunningOffline() {
        return !!process.env.VUE_APP_OFFLINE
    }

    /**
     *
     */
    public static readonly fetch =
        (input: RequestInfo | URL, init?: RequestInit | undefined) => fetch(input, init)

    /**
     *
     */
    protected static readonly refreshToken = "EkkoSimRefreshToken"

    /**
     *
     */
    protected static readonly sessionName = "EkkoSimToken"

    /**
     *
     */
    private authenticationMode = new LazyAsync<"development" | "production" | "production-client">(async () => {
        if(BaseHttpService.testRunningOffline) {
            return "production-client"
        }
        try {
            const authenticationModeResponse =
                await BaseHttpService.fetch("/authentication/mode")
            return await authenticationModeResponse.json()
        } catch (e) {
            console.warn("Could not fetch authentication mode, falling back to static")
            const authenticationModeResponse =
                await BaseHttpService.fetch("/authenticationMode.txt")
            return authenticationModeResponse.text()
        }
    })

    /**
     *
     * @param token
     * @returns
     */
    private async verifyJwToken(token: string): Promise<boolean> {
        const [tokenInfo, _] = token.split(",")
        try {
            // Verify the JWT token without authentication
            const verifyResponse = await BaseHttpService.fetch(
                "/authentication/verifyToken", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: tokenInfo })
            })

            if (verifyResponse.status === 200) return true

            const refreshToken = sessionStorage.getItem(BaseHttpService.refreshToken)
            if(!refreshToken) {
                console.warn("Refresh token not provided.")
                return false
            }

            // If token verification fails, attempt to refresh the access token
            const refreshResponse = await BaseHttpService.fetch(
                "/authentication/refreshAccessToken", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token, refreshToken })
            })

            if (refreshResponse.status === 200) {
                const refreshedAccessToken = await refreshResponse.text()
                sessionStorage.setItem(BaseHttpService.sessionName, refreshedAccessToken)
                return true
            }

            return false
        } catch (error) {
            console.warn(error)
            return false
        }
    }

    /**
     *
     */
    protected abstract endpoint: Endpoint

    /**
     *
     * @param url
     * @param headers
     * @returns
     */
    protected async authenticatedGET(url: string, headers: Record<string, string> = {}) {
        const baseUrl = await UrlConfig.getBaseUrl(this.endpoint)

        const response = await BaseHttpService.fetch(`${baseUrl}${url}`, {
            method: "GET",
            ...await this.getHttpCredentials(headers),
        })

        return response
    }

    /**
     *
     * @param url
     * @param headers
     * @returns
     */
    protected async authenticatedGetJson<T>(url: string, headers: Record<string, string> = {}): Promise<T> {
        const response = await this.authenticatedGET(url, {"Accept": "application/json", ...headers})
        return response.json()
    }

    /**
     *
     * @param url
     * @param body
     * @param headers
     * @param fetchInfo
     * @returns
     */
    protected async authenticatedPOST(url: string, body: any, headers: Record<string, string> = {}, fetchInfo: Partial<Request> = {}) {
        const baseUrl = await UrlConfig.getBaseUrl(this.endpoint)

        let bodyEncoded: string | Blob
        if(debugSkipEncoding && headers["Content-Encoding"] === "pcvr") {
            delete headers["Content-Encoding"]
        }
        if (headers["Content-Encoding"] === "pcvr" && headers["Content-Type"] === "application/json") {
            bodyEncoded = PCVR.encode(body)
        } else if (headers["Content-Encoding"] === "deflate") {
            const inStream =
                new Blob([body], {type: "application/json"}).stream()
            bodyEncoded = await new Response(inStream.pipeThrough(
                new CompressionStream("deflate"))).blob()
        } else {
            bodyEncoded = body
        }

        const response = await BaseHttpService.fetch(`${baseUrl}${url}`, {
            method: "POST",
            body: bodyEncoded,
            ...await this.getHttpCredentials(headers),
            ...fetchInfo,
        })

        return response
    }

    /**
     *
     * @param headers
     * @returns
     */
    protected async getHttpCredentials(headers: {[k: string]: string}): Promise<Pick<RequestInit, "headers" | "credentials">> {
        if(BaseHttpService.testRunningOffline) {
            return {headers, credentials: "include"}
        }
        const authenticationMode = await this.authenticationMode.get()
        if(authenticationMode == "production-client") {
            const token = sessionStorage.getItem(BaseHttpService.sessionName)
            if(!token) {
                // Deep link 2
                window.location.href = process.env.VUE_APP_AUTHENTICATION_URL! + "&state=" + encodeURIComponent(location.pathname + location.search + location.hash)
                throw new Error("Not authenticated - no token")
            }
            const [tokenInfo, accessToken] = token.split(",")
            if(token !== lastVerifedToken) {
                const isVerified = await this.verifyJwToken(token)
                if(isVerified) {
                    lastVerifedToken = token
                } else {
                    window.location.href = process.env.VUE_APP_AUTHENTICATION_URL!
                    throw new Error("Not authenticated - unverified")
                }
            }
            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        } else if(authenticationMode == "development") {
            return {
                headers: {
                    ...headers,
                    Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MTY2ODMwOTQsImV4cCI6MTY0ODIxOTA5NCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXSwiaHR0cHM6Ly9la2tvc2Vuc2Uvc2VydmljZVJvbGUiOiJhZ2dyZWdhdGUifQ.TbvV422qXABw0bjnrWVw2KtWIbhnvWZCk0y7zOt_lSY",
                }
            }
        } else {
            return {headers, credentials: "include"}
        }
    }
}