import { NodesetHelper, NumberFormat, SimulationBatchResults, StaticCostBatchResults, UnbatchEventRecursive } from "@/core/helpers"
import { AggregatorSimulateClient, MeteredData, UnbatchEvents } from "@/core/interfaces"
import { aggregatorService } from "@/core/services/aggregator.service"
import { CostHistoryClient } from "@/History/clients"
import { Models } from "@ekko/predict-client-api"
import { Granularity, GraphType } from "../enums"
import { NamedSimulationNode, SimulationSiteConfiguration, SiteInfo } from "../interfaces"
import { AggregatorResponse } from "../interfaces/AggregatorResponse"
import { StaticCostResponse } from "../interfaces/StaticCostResponse"
import { EnergyBaseClient } from "./EnergyBaseClient"
import { AllocationOuputNode } from "./AllocationOuputNode"

/**
 *
 */
export class CostClient extends EnergyBaseClient implements AggregatorSimulateClient {
    /**
     *
     * @param result
     * @param nodes
     * @param deviceSelected
     * @param staticCostResult
     */
    private addCostAllocations(result: AggregatorResponse, nodes: NamedSimulationNode[],
        deviceSelected: string | null, staticCostResult: StaticCostResponse,
        outputNodeSelection: AllocationOuputNode = AllocationOuputNode.ItLoad
    ) {
        const loadOut = this.addAllocations(result, nodes, deviceSelected)

        const output = new Map(
            [...loadOut.entries()].map(([k, v]) => [k, new Map(
                [...v.entries()].map(([kx, vx]) => [kx, {...vx, capEx: 0, opEx: 0}])
            )])
        )

        const itNodeReferences = this.getItNodeReferences(nodes)

        for (const [point, staticCost] of Object.entries(staticCostResult)) {
            const pointOutput = output.get(point)
            if (pointOutput && staticCost.costs) {
                for (const itNodeReference of itNodeReferences) {
                    for (const [nodeReference, cost] of Object.entries(staticCost.costs)) {
                        const outputValue = pointOutput.get(
                            outputNodeSelection == AllocationOuputNode.ItLoad ?
                                nodeReference :
                                itNodeReference
                        )
                        if(!outputValue) {
                            continue
                        }
                        const allocation = staticCost.allocation
                        if (allocation?.[nodeReference]?.[itNodeReference]) {
                            outputValue.capEx += NumberFormat.to2dp(cost.capex * allocation[nodeReference][itNodeReference])
                            outputValue.opEx += NumberFormat.to2dp(cost.opex * allocation[nodeReference][itNodeReference])
                        }
                    }
                }
            }
        }

        return output
    }

    /**
     *
     * @param result
     * @param nodes
     * @param deviceSelected
     * @param staticCostResult
     * @returns A point-to-node-to-result map
     */
    private buildCostResultTree(result: AggregatorResponse, nodes: NamedSimulationNode[],
        deviceSelected: string | null, staticCostResult: StaticCostResponse
    ) {
        const loadOut = this.buildResultTree(result, nodes, deviceSelected)

        const output = new Map(
            [...loadOut.entries()].map(([k, v]) => [k, new Map(
                [...v.entries()].map(([kx, vx]) => [kx, {...vx, capEx: 0, opEx: 0}])
            )])
        )

        for (const [point, staticCost] of Object.entries(staticCostResult)) {
            const pointOutput = output.get(point)
            if (pointOutput && staticCost.costs) {
                for (const [nodeReference, cost] of Object.entries(staticCost.costs)) {
                    const outputValue = pointOutput.get(nodeReference)
                    if(!outputValue) {
                        continue
                    }

                    outputValue.capEx += cost.capex
                    outputValue.opEx += cost.opex
                }
            }
        }

        return output
    }

    /**
     * @param allocate
     * @param nodesets
     * @param siteInfo
     * @param unbatchCallbacks
     * @returns
     */
    private async getStaticCosts(allocate: boolean,
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        unbatchCallbacks?: UnbatchEvents)
    {
        return StaticCostBatchResults.iterate(
            nodesets,
            nodeset => {
                const overlap = this.overlappingPeriod(nodeset)
                if(!overlap) return {}
                return aggregatorService.getStaticCosts(
                    allocate,
                    this.amortise,
                    nodeset.nodes,
                    siteInfo,
                    overlap,
                    Granularity.DAY,
                    unbatchCallbacks,
                )
            },
            NodesetHelper.dateInRange, this.granularity
        )
    }

    /**
     * @param allocate
     * @param nodesets
     * @param siteInfo
     * @param meteredData
     * @param unbatchCallbacks
     */
    private async simulateHistoricalInitial(allocate: boolean,
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        meteredData: MeteredData, unbatchCallbacks?: UnbatchEvents
    ) {
        const heuristicSimulateCost = 40
        const heuristicStaticAllocateCost = 1
        const [totalSize, [simulateCallbacks, staticCostCallbacks]] =
            UnbatchEventRecursive.build(unbatchCallbacks, heuristicSimulateCost,
                heuristicStaticAllocateCost)
        const simulateResult = await SimulationBatchResults.iterate(
            nodesets,
            (nodeset, unbatchCallbacks) => {
                const overlap = this.overlappingPeriod(nodeset)
                if(!overlap) return {}
                return aggregatorService.simulateHistorical(
                    GraphType.COST,
                    allocate,
                    nodeset.nodes,
                    siteInfo,
                    overlap,
                    Granularity.DAY,
                    meteredData.meteredTemperature,
                    meteredData.readings,
                    unbatchCallbacks,
                )
            },
            NodesetHelper.dateInRange, this.granularity,
            simulateCallbacks
        )

        const staticCostResult =
            await this.getStaticCosts(allocate, nodesets, siteInfo,
                staticCostCallbacks)
        unbatchCallbacks?.progress?.(totalSize)
        return {simulateResult, staticCostResult}
    }

    /**
     * @param allocate
     * @param nodesets
     * @param siteInfo
     * @param unbatchCallbacks
     */
    private async simulateInitial(allocate: boolean,
        nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        unbatchCallbacks?: UnbatchEvents
    ) {
        const heuristicSimulateCost = 40
        const heuristicStaticAllocateCost = 1
        const [totalSize, [simulateCallbacks, staticCostCallbacks]] =
            UnbatchEventRecursive.build(unbatchCallbacks,
                heuristicSimulateCost, heuristicStaticAllocateCost)

        const simulateResult = await SimulationBatchResults.iterate(
            nodesets,
            (nodeset, unbatchCallbacks) => {
                const overlap = this.overlappingPeriod(nodeset)
                if(!overlap) return {}
                return aggregatorService.simulate(
                    GraphType.COST,
                    allocate,
                    nodeset.nodes,
                    siteInfo,
                    overlap,
                    Granularity.DAY,
                    unbatchCallbacks,
                )
            },
            NodesetHelper.dateInRange, this.granularity,
            simulateCallbacks
        )

        const staticCostResult =
            await this.getStaticCosts(allocate, nodesets, siteInfo,
                staticCostCallbacks)

        unbatchCallbacks?.progress?.(totalSize)
        return {simulateResult, staticCostResult}
    }

    /**
     *
     * @param site
     * @returns
     */
    getHistoricalReadings(site: Models.SiteModel) {
        const historyClient = new CostHistoryClient()
        return historyClient.fetch(site.id)
    }

    async simulate(nodesets: SimulationSiteConfiguration[], siteInfo: SiteInfo,
        deviceSelected: string | null = null, unbatchCallbacks?: UnbatchEvents
    ) {
        const nodes = NodesetHelper.nodes(nodesets)
        if(this.isFilteringITNodes(nodes)) {
            const {simulateResult, staticCostResult} =
                await this.simulateInitial(true, nodesets, siteInfo,
                    unbatchCallbacks)
            return this.summariseByPoint(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult, AllocationOuputNode.Item))
        } else {
            const {simulateResult, staticCostResult} =
                await this.simulateInitial(false, nodesets, siteInfo,
                    unbatchCallbacks)
            return this.summariseByPoint(this.buildCostResultTree(simulateResult, nodes, deviceSelected, staticCostResult))
        }
    }

    async simulateAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks?: UnbatchEvents
    ) {
        const nodes = NodesetHelper.nodes(nodesets)
        const {simulateResult, staticCostResult} =
            await this.simulateInitial(true, nodesets, siteInfo,
                unbatchCallbacks)
        return this.summariseByNode(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult))
    }

    async simulateAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null = null,
        unbatchCallbacks?: UnbatchEvents
    ) {
        const nodes = NodesetHelper.nodes(nodesets)
        if(this.isFilteringITNodes(nodes)) {
            const {simulateResult, staticCostResult} =
                await this.simulateInitial(true, nodesets, siteInfo,
                    unbatchCallbacks)
            return this.summariseByNode(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult, AllocationOuputNode.Item))
        } else {
            const {simulateResult, staticCostResult} =
                await this.simulateInitial(false, nodesets, siteInfo,
                    unbatchCallbacks)
            return this.summariseByNode(this.buildCostResultTree(simulateResult, nodes, deviceSelected, staticCostResult))
        }
    }

    async simulateHistorical(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks?: UnbatchEvents) {
        const nodes = NodesetHelper.nodes(nodesets)
        if(this.isFilteringITNodes(nodes)) {
            const {simulateResult, staticCostResult} =
                await this.simulateHistoricalInitial(true, nodesets, siteInfo,
                    meteredData, unbatchCallbacks)
            return this.summariseByPoint(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult, AllocationOuputNode.Item))
        } else {
            const {simulateResult, staticCostResult} =
                await this.simulateHistoricalInitial(false, nodesets, siteInfo,
                    meteredData, unbatchCallbacks)
            return this.summariseByPoint(this.buildCostResultTree(simulateResult, nodes, deviceSelected, staticCostResult))
        }
    }

    async simulateHistoricalAllocated(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks?: UnbatchEvents) {
        const nodes = NodesetHelper.nodes(nodesets)
        const {simulateResult, staticCostResult} =
            await this.simulateHistoricalInitial(true, nodesets, siteInfo,
                meteredData, unbatchCallbacks)

        return this.summariseByNode(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult))
    }

    async simulateHistoricalAttributed(nodesets: SimulationSiteConfiguration[],
        siteInfo: SiteInfo, deviceSelected: string | null,
        meteredData: MeteredData, unbatchCallbacks?: UnbatchEvents) {
        const nodes = NodesetHelper.nodes(nodesets)
        if(this.isFilteringITNodes(nodes)) {
            const {simulateResult, staticCostResult} =
                await this.simulateHistoricalInitial(true, nodesets, siteInfo,
                    meteredData, unbatchCallbacks)
            return this.summariseByNode(this.addCostAllocations(simulateResult, nodes, deviceSelected, staticCostResult, AllocationOuputNode.Item))
        } else {
            const {simulateResult, staticCostResult} =
                await this.simulateHistoricalInitial(false, nodesets, siteInfo,
                    meteredData, unbatchCallbacks)
            return this.summariseByNode(this.buildCostResultTree(simulateResult, nodes, deviceSelected, staticCostResult))
        }
    }
}