import { SimulationNode } from "@/Simulation/interfaces"
import { Reading, UnbatchEvents } from "@/core/interfaces"
import { ReadingType } from "../enums"
import { EnergyHistoryClientBase } from "./EnergyHistoryClientBase"

/**
 * Class for Energy
 */
export class EnergyHistoryClient extends EnergyHistoryClientBase {
    /**
     *
     * @param readingType
     */
    constructor(public readingType: ReadingType.POWER_LOAD | ReadingType.POWER_SUPPLY = ReadingType.POWER_SUPPLY) {
        super()
    }

    /**
     *
     * @param siteId
     * @param nodes
     * @param unbatchCallbacks
     * @returns
     */
    fetch(siteId: string, nodes?: undefined,
        unbatchCallbacks?: UnbatchEvents):
        Promise<Reading<Date>[]>
    fetch(siteId: string, nodes: SimulationNode[],
        unbatchCallbacks?: UnbatchEvents):
        Promise<Reading<Date>[]>
    async fetch(siteId: string, nodes?: SimulationNode[],
        unbatchCallbacks?: UnbatchEvents):
        Promise<Reading<Date>[]>
    {
        return this.fetchEnergyHistory(siteId, nodes, unbatchCallbacks)
    }
}