import { NodeComputationHelper } from "@/Simulation/helpers/NodeComputationHelper"
import { ItemParameter } from "@/Simulation/siteParameter/nodeParameter"
import { Models } from "@ekko/predict-client-api"
import { NodeType } from "predict-performance-calculation"
import { ReportFields, ResilienceType } from "../enums"
import { ReportData } from "../interfaces"
import { CSVHelper } from "./CSVHelper"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

/**
 *
 */
export type ReportNode = Models.ItemModel | Models.ITNodeModel | Models.OtherNodeModel
/**
 *
 */
export class ReportsHelper {
    /**
     * Report download in csv format
     *
     * @param nodes
     * @param siteName
     */
    static async reportDownload(nodes: ReportNode[], siteName: string) {
        const rows: Record<ReportFields, string | number>[] = []

        for (const node of nodes) {
            switch (node.modelType) {
                case NodeType.Item:
                    rows.push(await this.getNodeData(node as Models.ItemModel))
                    break
                case NodeType.ITNode:
                    rows.push(await this.getItNodeData(node as Models.ITNodeModel))
                    break
                case NodeType.OtherNode:
                    rows.push(await this.getOtherNodeData(node as Models.OtherNodeModel))
                    break
            }
        }

        CSVHelper.downloadData(rows, this.getFileName(siteName))
    }
    /**
     * Get CSV file name
     *
     * @param siteName
     * @returns
     */
    private static getFileName(siteName: string) {
        return `${siteName}_Node_Config`
    }
    /**
     * Get node data for download csv
     *
     * @param node
     * @returns
     */
    private static async getNodeData(node: Models.ItemModel) {
        const deviceModeModel = await node.getDeviceMode()
        const deviceModelModel = await deviceModeModel.assertDeviceModel()
        const latestEvent = await this.getLastProvisioningEvent(node)
        const manufacturer = await deviceModelModel.getManufacturer()
        const itemParameter = new ItemParameter()
        const resilience = await itemParameter.getResilience(node)
        const resilienceType = await this.getResilienceType(node)
        const calibrationStatus = await this.getCalibrationStatus(node)
        const deviceMode = await node.getDeviceMode()
        const installedCapacity = latestEvent
            ? latestEvent.getCount()! * deviceMode.getCapacity()!
            : 0
        const calculatedProvisionedCapacity =
            NodeComputationHelper.getComputedProvisionedCapacity(
                latestEvent?.getCount() ?? 1, resilience,
                deviceMode.getCapacity() ?? 1)
        const reportData: ReportData = {
            [ReportFields.LABEL]: node.getName() ?? "",
            [ReportFields.TYPE]: capitalizeFirstLetter(deviceModelModel.getDeviceModelType()),
            [ReportFields.MODEL]: deviceModelModel.getName() ?? "",
            [ReportFields.MANUFACTURER]: manufacturer?.getName() ?? "",
            [ReportFields.MODE]: deviceModelModel.getSpec() ?? "",
            [ReportFields.CAPACITY]: installedCapacity,
            [ReportFields.CALIBRATED]: calibrationStatus,
            [ReportFields.RESILIENCE]: resilienceType ?? "",
            [ReportFields.QUANTITY]: latestEvent ? latestEvent.getCount() ?? 1 : 0,
            [ReportFields.CALCULATED_CAPACITY]: calculatedProvisionedCapacity,
        }

        return reportData
    }
    /**
     * Get it node data for download csv
     *
     * @param node
     * @returns
     */
    private static async getItNodeData(node: Models.ITNodeModel) {
        const lastProvisioning = await this.getLastItProvisioningEvent(node)
        const capacity = lastProvisioning?.getPowerCapacity() ?? ""
        const reportData: ReportData = {
            [ReportFields.LABEL]: node.getName() ?? "",
            [ReportFields.TYPE]: this.formatNodeType(node.modelType),
            [ReportFields.MODEL]: "",
            [ReportFields.MANUFACTURER]: "",
            [ReportFields.MODE]: "",
            [ReportFields.CAPACITY]: capacity,
            [ReportFields.CALIBRATED]: "",
            [ReportFields.RESILIENCE]: "",
            [ReportFields.QUANTITY]: "",
            [ReportFields.CALCULATED_CAPACITY]: capacity
        }

        return reportData
    }
    /**
     * Get other node data for download csv
     *
     * @param node
     * @returns
     */
    private static async getOtherNodeData(node: Models.OtherNodeModel) {
        const lastProvisioning = await this.getLastOtherProvisioningEvent(node)
        const capacity = lastProvisioning?.getPowerCapacity() ?? ""
        const reportData: ReportData = {
            [ReportFields.LABEL]: node.getName() ?? "",
            [ReportFields.TYPE]: this.formatNodeType(node.modelType),
            [ReportFields.MODEL]: "",
            [ReportFields.MANUFACTURER]: "",
            [ReportFields.MODE]: "",
            [ReportFields.CAPACITY]: capacity,
            [ReportFields.CALIBRATED]: "",
            [ReportFields.RESILIENCE]: "",
            [ReportFields.QUANTITY]: "",
            [ReportFields.CALCULATED_CAPACITY]: capacity
        }

        return reportData
    }
    /**
     * Get last provisioning event
     *
     * @param node
     * @returns
     */
    private static async getLastProvisioningEvent(node: Models.ItemModel) {
        const now = new Date()
        let latest: { date: Date, event: Models.ProvisioningEventModel } | undefined

        for await (const event of node.getProvisioningEvents()) {
            const eventDate = new Date(event.getDate()!)
            if (eventDate < now && (!latest || latest.date <= eventDate)) {
                latest = { date: eventDate, event }
            }
        }

        return latest?.event ?? null
    }
    /**
     * Get resilience type
     *
     * @param node
     * @returns
     */
    private static async getResilienceType(node: Models.ItemModel) {
        const resilienceModel = await node.getResilience()
        const resilienceGroupingModel = resilienceModel
            ? await resilienceModel.getResilienceGrouping()
            : null
        const resilienceGroupingStandbyModel =
            resilienceModel && resilienceGroupingModel
                ? await resilienceGroupingModel.getResilienceGroupingStandby()
                : null

        return this.findType(
            resilienceModel,
            resilienceGroupingModel,
            resilienceGroupingStandbyModel
        )
    }
    /**
     * Find resilience type
     *
     * @param resilience
     * @param resilienceGrouping
     * @param resilienceGroupingStandby
     * @returns
     */
    private static findType(
        resilience: Models.ResilienceModel | null,
        resilienceGrouping: Models.ResilienceGroupingModel | null,
        resilienceGroupingStandby: Models.ResilienceGroupingStandbyModel | null
    ) {
        let type = ResilienceType.NO_RESILIENCE

        if (!resilience) {
            return type
        }

        if (!resilienceGrouping) {
            type = ResilienceType.NR
        } else if (resilienceGrouping && !resilienceGroupingStandby) {
            type = ResilienceType.ACTIVE
        } else if (resilienceGrouping && resilienceGroupingStandby) {
            type = ResilienceType.STANDBY
        }

        return type
    }
    /**
     * Get Calibration Status
     *
     * @param node
     * @returns
     */
    private static async getCalibrationStatus(node: Models.ItemModel) {
        const customDeviceMode = await node.getCustomDeviceMode()

        return customDeviceMode?.getActive() ? "Yes" : "No"
    }
    /**
     * Format ITNode and OtherNode model type
     *
     * @param modelType
     * @returns
     */
    private static formatNodeType(modelType: string) {
        const nodeString = "Node"
        const words = modelType.split(nodeString)

        return `${capitalizeFirstLetter(words[0])} ${nodeString}`
    }
    /**
     * Get last it provisioning event
     *
     * @param node
     * @returns
     */
    private static async getLastItProvisioningEvent(node: Models.ITNodeModel) {
        const provisoning = await node.assertItProvisioning()
        const provisioningEventsModels = provisoning.getItProvisioningEvents()
        const provisioningEventsUnsorted: Models.ITProvisioningEventModel[] = []
        const now = new Date()

        for await (const itProvisioningEvent of provisioningEventsModels) {
            provisioningEventsUnsorted.push(itProvisioningEvent)
        }

        const provisioningEvents = provisioningEventsUnsorted.sort(
            (a, b) => new Date(a.getMonth()!).getTime() -
                new Date(b.getMonth()!).getTime()
        )

        if (provisioningEvents.length) {
            let lastEvent = provisioningEvents[provisioningEvents.length - 1]

            for (const event of provisioningEvents) {
                if (new Date(event.getMonth()!) < now) {
                    lastEvent = event
                }
            }

            return lastEvent
        }

        return null
    }
    /**
     * Get last other provisioning event
     *
     * @param node
     * @returns
     */
    private static async getLastOtherProvisioningEvent(node: Models.OtherNodeModel) {
        const provisoning = await node.assertOtherProvisioning()
        const provisioningEventsModels = provisoning.getOtherProvisioningEvents()
        const provisioningEventsUnsorted: Models.OtherProvisioningEventModel[] = []
        const now = new Date()

        for await (const provisioningEvent of provisioningEventsModels) {
            provisioningEventsUnsorted.push(provisioningEvent)
        }

        const provisioningEvents = provisioningEventsUnsorted.sort(
            (a, b) => new Date(a.getMonth()!).getTime() - new Date(b.getMonth()!).getTime()
        )

        if (provisioningEvents.length) {
            let lastEvent = provisioningEvents[provisioningEvents.length - 1]

            for (const event of provisioningEvents) {
                if (new Date(event.getMonth()!) < now) {
                    lastEvent = event
                }
            }

            return lastEvent
        }

        return null
    }
}