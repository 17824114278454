import { AccessLevel, DeviceModelQuality, PerformanceType } from "@/core/enums"
import {
    AddDevicePerformanceHelper,
    AddManufacturerPerformanceHelper,
    vueLazySetMapValue,
    VueLazyWrapper,
} from "@/core/helpers"
import { Paginated } from "@/core/helpers/Paginated"
import * as Interfaces from "@/core/interfaces"
import { accessControlService } from "@/core/services/access-control.service"
import ApiService from "@/core/services/api.service"
import { ItemParameter } from "@/Simulation/siteParameter/nodeParameter"
import { DeviceModeInfo, DeviceModesState } from "@/store/device-modes/types"
import { Models, Services } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { DeviceModelType } from "predict-performance-calculation"
import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { FiltersState } from "../common/filters/types"
import { PaginationModule } from "../common/pagination"
import { PaginationState } from "../common/pagination/types"
import DeviceModelModule from "../devices"
import ManufacturerModule from "../manufacturers"

class State implements Partial<DeviceModesState> {
    manufacturerFilter = ""
    loaded = false
    /**
     *
     */
    deviceModes?: Models.DeviceModeModel[]

    /**
     *
     */
    modeModels: Map<string, VueLazyWrapper<Models.DeviceModelModel>> = new Map()

    currentCustomDeviceMode: Models.CustomDeviceModeModel | null = null
}

const getters: GetterTree<State & FiltersState & PaginationState & typeof DeviceModelModule["state"] & typeof ManufacturerModule["state"], any> = {
    /**
     * @param state
     */
    deviceModeInfo(state): DeviceModeInfo[] | null {
        const deviceModes = state.deviceModes
        const deviceModels = state.devices
        const manufacturers = state.manufacturers
        if(deviceModels && deviceModes && manufacturers) {
            return deviceModes
                .filter(mode => !!(mode as any).data.relationships?.deviceModel)
                .map(mode => {
                    const model = vueLazySetMapValue(
                        state.modeModels,
                        mode.id,
                        () => mode.getDeviceModel()
                    )

                    const manufacturer = model && vueLazySetMapValue(
                        state.modelManufacturers,
                        model.id,
                        () => model.getManufacturer()
                    )

                    return {
                        activeMode: mode,
                        id: mode.id,
                        mode,
                        model,
                        manufacturer,
                    }
            })
        } else {
            return null
        }
    },
    /**
     * This returns the filter for device mode info
     * @param state
     * @returns
     */
    deviceModeInfoFilter(state): (a: DeviceModeInfo) => boolean {
        const regexp = new RegExp(state.searchText, "i")

        return deviceMode => {
            const textMatches = deviceMode?.model?.getName()?.match(regexp) ||
                deviceMode?.manufacturer?.getName()?.match(regexp) ||
                deviceMode?.model?.getDescription()?.match(regexp) ||
                deviceMode?.model?.getAccessLevel()?.match(regexp) ||
                deviceMode?.mode?.getCapacity()?.toString()?.match(regexp)

            const accessFilterMatches = state.accessFilter && state.accessFilter !== 'all'
                ? deviceMode?.model?.getAccessLevel() === state.accessFilter
                : true

            const manufacturerFilterFilterMatches = state.manufacturerFilter && state.manufacturerFilter !== 'all'
                ? deviceMode?.manufacturer?.getName() === state.manufacturerFilter
                : true

            return textMatches && accessFilterMatches && manufacturerFilterFilterMatches || false
        }
    },
    /**
     * This returns the sort for device mode info
     *
     * @param state
     * @returns
     */
    deviceModeInfoSort(state): (a: DeviceModeInfo, b: DeviceModeInfo) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            default:
                // Fall through
            case "Manufacturer":
                return Paginated.sortUndefinedFirst((o) => o.manufacturer?.getName(), reverse,
                    (o) => o.model?.getName()
                )
            case "Model":
                return Paginated.sortUndefinedFirst((o) => o.model?.getName(), reverse)
            case "Capacity":
                return Paginated.sortUndefinedFirst((o) => o.mode.getCapacity(), reverse,
                    (o) => o.model?.getName()
                )
            case "Access":
                return Paginated.sortUndefinedFirst((o) => o.model?.getAccessLevel(), reverse,
                    (o) => o.model?.getName()
                )
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getDeviceModes(state, getters): (filter?: (o: DeviceModeInfo) => boolean) => PaginatedResult<DeviceModeInfo> {
        return (filter?: (o: DeviceModeInfo) => boolean) => {
            const results = getters.deviceModeInfo
            if(results) {
                return new Paginated(
                    results, state.page, state.pageSize, getters.deviceModeInfoSort,
                    filter ? (o) => filter(o) && getters.deviceModeInfoFilter(o) : getters.deviceModeInfoFilter
                )
            } else {
                return { results: [], totalCount: 0 }
            }
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getDeviceMode: (state, getters) => (id: string): DeviceModeInfo | undefined => {
        return getters.deviceModeInfo?.find(m => (m.mode as any).id === id)
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getIsLibraryEmpty(state, getters): (defunct: boolean) => boolean {
        return (defunct: boolean) => state.loaded && getters.deviceModeInfo?.filter((dmi: DeviceModeInfo) => dmi.model?.getDefunct() === defunct).length === 0
    }
}

const mutations: MutationTree<State> = {
    /**
     * @param state
     * @param deviceModes
     */
    setDeviceModes(state, deviceModes: Models.DeviceModeModel[]): void {
        state.deviceModes = deviceModes
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    },
    setManufacturerFilter(state, manufacturerFilter: string): void {
        state.manufacturerFilter = manufacturerFilter
    },
    setCurrentCustomDeviceMode(state, customDeviceMode: Models.CustomDeviceModeModel){
        state.currentCustomDeviceMode = customDeviceMode
    }
}

/**
 * Helpers for the device modes store. These handle actions
 * which don't actually do anything with the store itself.
 */
class ActionHelper {
    /**
     *
     */
    static cacheInst?: ActionHelper

    /**
     *
     */
    static get inst() {
        if(!this.cacheInst) {
            this.cacheInst = new ActionHelper()
        }
        return this.cacheInst
    }

    /**
     * This creates and persists a new DeviceMode
     *
     * @param deviceModel
     * @param formData
     * @param isCalibration
     * @param customDeviceMode
     * @returns The new entity
     */
    async addDeviceMode(
        deviceModel: Models.DeviceModelModel,
        formData: Interfaces.DeviceModelForm,
        isCalibration = false,
        customDeviceMode: Models.CustomDeviceModeModel | null = null,
    ): Promise<Models.DeviceModeModel> {
        const service = await ApiService.getDeviceModeService()

        const entity = new Models.DeviceModeModel()
        entity.setName(formData.details.spec)
        entity.setPeakDraw(formData.electrical.peakDraw)
        entity.setNameplateDraw(formData.electrical.nameplateDraw)
        entity.setIdleDraw(formData.electrical.idleDraw)

        if (isCalibration && customDeviceMode) {
            entity.addCustomDeviceMode(customDeviceMode)
        } else {
            if(!deviceModel) {
                throw new Error("INTERNAL ERROR: device model unset")
            }
            entity.addDeviceModel(deviceModel)
            deviceModel.addDeviceModes([entity])
        }

        // MITIGATION
        ItemParameter._mitigation_knownStoredDeviceModes = null
        //

        const [initialStoredEntity]: Models.DeviceModeModel[] = await service.addDeviceMode(entity)

        let storedEntity: Models.DeviceModeModel
        if (formData.details.deviceModelType === DeviceModelType.HEAT_EXCHANGER) {
            await AddDevicePerformanceHelper.addHeatExchanger(formData, initialStoredEntity)
            // Capacity changed - reload
            const service = await ApiService.getDeviceModeService()

            storedEntity = await service.getDeviceMode(entity.id)
        } else {
            storedEntity = await this.addPerformanceByType(
                initialStoredEntity,
                formData
            )
        }

        // Workaround: "capacity" is a fake field in DeviceMode, and OrbitJS has no way of knowing
        // that its entry is invalid. So instead, we set it here.
        storedEntity.setCapacity(formData.details.capacity as number)
        // Workaround over.

        return storedEntity
    }

    /**
     * Add a new ManufacturerPerformanceData
     *
     * @param deviceMode
     * @param performance
     * @param type
     * @param version
     */
    async addManufacturerPerformance(
        deviceMode: Models.DeviceModeModel,
        performance: any,
        type: string,
        version = 0,
    ) {
        const service = await ApiService.getNamedService<Services.ManufacturerPerformanceDataService>(
            "ManufacturerPerformanceDataService"
        )

        const entity = new Models.ManufacturerPerformanceDataModel()
        entity.addDeviceMode(deviceMode)
        entity.setVersion(version)
        entity.setTypeCode(type)
        entity.setContent(JSON.parse(JSON.stringify(performance)))

        await service.addManufacturerPerformanceData(entity)
    }

    /**
     * Add either a new PerformanceData of some kind, or a
     * new ManufacturerPerformanceData
     *
     * @param entity
     * @param formData
     */
    async addPerformanceByType(
        entity: Models.DeviceModeModel,
        formData: Interfaces.DeviceModelForm,
    ) {
        switch(formData.performanceType) {
            case PerformanceType.Grid:
                // Fall through
            case PerformanceType.GridList:
                // Fall through
            case PerformanceType.Polynomial:
                await AddDevicePerformanceHelper.add(formData, entity)
                break
            default:
                await this.addManufacturerPerformance(
                    entity,
                    AddManufacturerPerformanceHelper.ToPerformanceData(formData),
                    formData.performanceType as string,
                )
        }
        // Capacity changed - reload
        const service = await ApiService.getDeviceModeService()

        return service.getDeviceMode(entity.id)
    }
}

const actions: ActionTree<State & typeof DeviceModelModule["state"] & typeof ManufacturerModule["state"], any> = {
    /**
     * @param param0
     * @param param1
     */
    async addDeviceModel(
        {commit, dispatch, state},
        {deviceModeInfo, editing, formData} : {
            deviceModeInfo: DeviceModeInfo,
            editing: boolean,
            formData: Interfaces.DeviceModelForm,
        }
    ) {
        const deviceModelService = await ApiService.getDeviceModelService()
        let archivedDeviceModel: Models.DeviceModelModel | null = null
        let storedDeviceModel: Models.DeviceModelModel | null = null
        let storedDeviceMode: Models.DeviceModeModel | null = null
        try {
            if (editing && deviceModeInfo.model && !deviceModeInfo.model.getDefunct()) {
                archivedDeviceModel = deviceModeInfo.model
                archivedDeviceModel.setDefunct(true)
                await deviceModelService.updateDeviceModel(archivedDeviceModel)
            }

            const newDeviceModel = new Models.DeviceModelModel()

            storedDeviceModel = await dispatch(
                "persistDeviceModel",
                {
                    deviceModel: newDeviceModel,
                    formData,
                }
            ) as Models.DeviceModelModel

            storedDeviceMode = await ActionHelper.inst.addDeviceMode(
                storedDeviceModel,
                formData
            )
            await dispatch("fetchDeviceModes")

            if (formData?.details?.accessLevel) {
                storedDeviceModel.setAccessLevel(
                    formData?.details?.accessLevel as AccessLevel
                )
                await accessControlService.updateAccessLevel(
                    storedDeviceModel,
                    "deviceModels",
                    formData?.details?.accessLevel as AccessLevel
                )
            }

            if (editing && deviceModeInfo.model) {
                const deviceModelArchived = deviceModeInfo.model
                await deviceModelArchived.addReplacedBy(storedDeviceModel)
                await deviceModelService.updateDeviceModel(deviceModelArchived)

                commit("replacedDeviceModel", deviceModelArchived)
            }
        } catch (error) {
            if (archivedDeviceModel && storedDeviceModel) {
                await archivedDeviceModel.removeReplacedBy(storedDeviceModel)
                await deviceModelService.updateDeviceModel(archivedDeviceModel)
            }
            if(storedDeviceModel) {
                await deviceModelService.removeDeviceModel(storedDeviceModel)
            }
            if (archivedDeviceModel) {
                archivedDeviceModel.setDefunct(false)
                await deviceModelService.updateDeviceModel(archivedDeviceModel)
            }
            throw error
        }
        if(state.deviceModes) {
            commit("setDeviceModes", [...state.deviceModes, storedDeviceMode])
        }
        commit("includeDeviceModel", storedDeviceModel)
    },
    /**
     * @param store
     * @param deviceModel
     */
    async archive({commit}, deviceModel: Models.DeviceModelModel) {
        const deviceService = await ApiService.getDeviceModelService()
        try {
            deviceModel.setDefunct(true)
            await deviceService.updateDeviceModel(deviceModel)
        } catch (error) {
            deviceModel.setDefunct(false)
            throw error
        }

        commit("replacedDeviceModel", deviceModel)
    },
    /**
     *
     * @param param0
     */
    async fetchDeviceModeInfo({ commit, dispatch }) {
        await dispatch("fetchDeviceModes")
        await dispatch("fetchDeviceModels")
        await dispatch("fetchManufacturers")
        commit("setLoaded", true)
    },

    /**
     *
     * @param param0
     */
    async fetchDeviceModes({ commit }) {
        const service = await ApiService.getDeviceModeService()

        const entities: Models.DeviceModeModel[] = []
        for await (const entityPage of service.getAllDeviceModesPaginated()) {
            entities.push(...entityPage)
        }
        commit("setDeviceModes", entities)
    },

    filterByManufacturer({ commit }, manufacturer: string) {
        commit("setManufacturerFilter", manufacturer)
    },

    /**
     *
     * @param param0
     * @param param1
     * @returns
     */
    async persistDeviceModel(
        {state},
        {deviceModel, formData}: {
            deviceModel: Models.DeviceModelModel,
            formData: Interfaces.DeviceModelForm
        }
    ) {
        if(!state.manufacturers.length) {
            throw new Error("Internal error: no manufacturers")
        }

        const deviceService: Services.DeviceModelService =
            await ApiService.getDeviceModelService()
        deviceModel.setModel(formData.details.model)

        deviceModel.setSpec(formData.details.spec)
        deviceModel.setDeviceModelType(formData.details.deviceModelType)
        deviceModel.setDescription(formData.details.description)
        deviceModel.setLifespan(formData.details.lifespan)

        // set properties missing from UI, but required for storage
        deviceModel.setName(formData.details.model)
        deviceModel.setNotes("")
        deviceModel.setQuality(DeviceModelQuality.STANDARD)

        const manufacturer = state.manufacturers.find(m => (m as any).id === formData.details.manufacturer)

        deviceModel.addManufacturer(manufacturer as Models.ManufacturerModel)

        const [storedDeviceModel]: Models.DeviceModelModel[] =
            await deviceService.addDeviceModel(deviceModel)

        return storedDeviceModel
    },

    /**
     * @param param0
     * @param param1
     */
    async setCalibrationState(
        { commit, dispatch },
        { deviceModeInfo, enable, formData }: {
            deviceModeInfo: DeviceModeInfo,
            enable: boolean,
            formData: Interfaces.DeviceModelForm,
        }
    ) {
        const customDeviceModeService = await ApiService.getCustomDeviceModeService()
        let storedCustomDeviceMode = deviceModeInfo.calibration?.customDeviceMode
        if (enable) {
            const storedDeviceMode = deviceModeInfo.calibration?.localDeviceMode
            if (storedDeviceMode) {
                // delete previous calibration
                const deviceModeService: Services.DeviceModeService = await ApiService.getDeviceModeService()
                const deviceModel = await storedDeviceMode.getDeviceModel()
                if(deviceModel) {
                    throw new Error(`INTERNAL ERROR: attempted to remove non-custom device mode ${storedDeviceMode.id}`)
                }
                await deviceModeService.removeDeviceMode(storedDeviceMode)
            }
            if (!storedCustomDeviceMode) {
                const newCustomDeviceMode = new Models.CustomDeviceModeModel()
                newCustomDeviceMode.setActive(true)
                newCustomDeviceMode.addItem(
                    deviceModeInfo.calibration?.item as Models.ItemModel
                );
                [storedCustomDeviceMode] = await customDeviceModeService.addCustomDeviceMode(newCustomDeviceMode) as [Models.CustomDeviceModeModel]
            }

            const createdDeviceMode = await ActionHelper.inst.addDeviceMode(
                deviceModeInfo.model!,
                formData,
                enable,
                storedCustomDeviceMode
            )
            await storedCustomDeviceMode.addDeviceMode(createdDeviceMode)
        } else if (storedCustomDeviceMode) {
            storedCustomDeviceMode.setActive(false)
        }
        if (storedCustomDeviceMode) {
            await customDeviceModeService.updateCustomDeviceMode(storedCustomDeviceMode)
        }
        await dispatch("fetchDeviceModeInfo")
        commit('setCurrentCustomDeviceMode', storedCustomDeviceMode)
    },
    /**
     * @param store
     * @param deviceModel
     */
    async unarchive({commit}, deviceModel: Models.DeviceModelModel) {
        const deviceService = await ApiService.getDeviceModelService()
        deviceModel.setDefunct(false)
        await deviceService.updateDeviceModel(deviceModel)
        commit("replacedDeviceModel", deviceModel)
    },
}

const devicesStore = DeviceModelModule
const paginationStore = PaginationModule()
const filtersModule = FiltersModule()
const manufacturerStore = ManufacturerModule

const DeviceModeModule = {
    namespaced: true,
    state: {
        ...devicesStore.state,
        ...paginationStore.state,
        ...filtersModule.state,
        ...manufacturerStore.state,
        ...new State(),
    },
    getters: {
        ...devicesStore.getters,
        ...paginationStore.getters,
        ...filtersModule.getters,
        ...getters
    },
    mutations: {
        ...devicesStore.mutations,
        ...paginationStore.mutations,
        ...filtersModule.mutations,
        ...manufacturerStore.mutations,
        ...mutations
    },
    actions: {
        ...devicesStore.actions,
        ...paginationStore.actions,
        ...filtersModule.actions,
        ...manufacturerStore.actions,
        ...actions,
    },
}

export default DeviceModeModule
