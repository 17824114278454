import { BaseSensorService } from "./base-sensor.service"
import { httpService } from "../http.service"
import { CbreDataType, CbreItem } from "@/core/interfaces"

/**
 *
 */
export class CbreSoftSensorService
extends BaseSensorService<CbreItem, CbreDataType>
{
    protected dataTypeFilter(datatype: CbreDataType[""]) {
        return !!datatype.label.match(/power|cooling/i)
    }

    protected async getSiteSensorInformationRaw() {
        return httpService.cbreSensorInformation(this.siteId)
    }
}