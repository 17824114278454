import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { AppendUnits } from "./AppendUnits"

/**
 *
 */
export class Celsius extends AppendUnits {
    protected defaultMaxDigits = 1

    protected suffixSymbol = "\u00b0C"

    protected numberFormatOptions(options?: { digits?: number | undefined } | undefined): Intl.NumberFormatOptions {
        return {
            maximumFractionDigits: options?.digits ?? this.defaultMaxDigits,
            minimumFractionDigits: options?.digits ?? 1,
        }
    }

    suffix = NumberFormatSuffix.celsius
}