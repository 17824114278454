import { NumberFormatSuffix } from "../NumberFormatSuffix"
import { AppendUnits } from "./AppendUnits"

/**
 *
 */
export class Kilowatts extends AppendUnits {
    protected defaultMaxDigits = 1
    protected suffixSymbol = "kW"

    protected numberFormatOptions(options?: { digits?: number | undefined } | undefined): Intl.NumberFormatOptions {
        return {
            maximumFractionDigits: options?.digits ?? this.defaultMaxDigits,
        }
    }

    suffix = NumberFormatSuffix.kilowatts
}