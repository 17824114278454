import { UnbatchEvents } from "../interfaces"

/**
 * If you had two callbackable items to do, you could do:
 *
 * outer.start?.(2)
 * const inner1 = new UnbatchEventRecursive(outer, 0)
 * const inner2 = new UnbatchEventRecursive(outer, 1)
 *
 * Or, if you expect inner1:inner2 time to be 2:7, you could do:
 *
 * outer.start?.(9)
 * const inner1 = new UnbatchEventRecursive(outer, 0, 2)
 * const inner2 = new UnbatchEventRecursive(outer, 2, 7)
 *
 * Or:
 *
 * const [size, [inner1, inner2]] = UnbatchEventRecursive.build(outer, 2, 7)
 */
export class UnbatchEventRecursive implements UnbatchEvents {
    /**
     *
     */
    private static maxUid = 0

    /**
     *
     * @param unbatchCallbacks
     * @param sizes
     * @returns
     */
    static build(unbatchCallbacks?: UnbatchEvents, ...sizes: number[]): [number, (UnbatchEventRecursive | undefined)[]] {
        if(!unbatchCallbacks) {
            return [0, sizes.map(() => undefined)]
        }
        let offset = 0
        const recursiveHandlers: UnbatchEventRecursive[] = []
        for(const size of sizes) {
            recursiveHandlers.push(new UnbatchEventRecursive(unbatchCallbacks, offset, size))
            offset += size
        }
        unbatchCallbacks.start?.(offset)
        return [offset, recursiveHandlers]
    }

    /**
     *
     */
    private readonly debug = false

    /**
     *
     */
    private scale = 1

    /**
     *
     */
    private readonly uid: number

    /**
     *
     * @param unbatchCallbacks
     * @param offset
     * @param outerScale
     */
    constructor(private unbatchCallbacks: UnbatchEvents, private offset: number, private outerScale = 1) {
        this.uid = ++UnbatchEventRecursive.maxUid
    }

    /**
     *
     * @param completed
     */
    progress(completed: number) {
        if(this.debug) {
            console.debug(`UER #${this.uid}: p=${completed}`)
        }
        const delta = Math.min(completed * this.outerScale / this.scale, this.outerScale)
        this.unbatchCallbacks.progress?.(this.offset + delta)
        if(this.debug) {
            console.debug(`UER #${this.uid} progress set (${this.offset} + ${delta})`)
        }
    }

    /**
     *
     * @param size
     */
    start(size: number) {
        if(this.scale !== undefined) {
            console.warn(new Error(`Progress size reassigned (was ${this.scale})`))
        }
        if(this.debug) {
            console.debug(`UER #${this.uid}: s=${size}`)
        }
        this.scale = size
    }
}