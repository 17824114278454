import { SimulationNode } from "@/Simulation/interfaces"
import { ITComparisonHistoryClient } from "./ITComparisonHistoryClient"

/**
 * Class for PUE
 */
export class PUEHistoryClient extends ITComparisonHistoryClient {
    async fetchComparison(
        siteId: string,
        nodes: SimulationNode[],
    ): Promise<Map<string, number>> {
        const itLoad = await this.fetchITLoad(siteId, nodes)
        const datacentreEnergy = await this.fetchEnergyHistory(siteId, nodes)
        const pueValues = new Map<string, number>()

        for (const energyReading of datacentreEnergy) {
            const readingKey = new Date(energyReading.timestamp).toISOString()
            const itLoadReading = itLoad.get(readingKey)

            if (itLoadReading) {
                pueValues.set(readingKey, energyReading.value / itLoadReading)
            }

        }

        return pueValues
    }
}