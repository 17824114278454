import { Application, config } from "@ekko/predict-client-api"
import { Endpoint, UrlConfig } from "../helpers"
import { BaseHttpService } from "./BaseHttpService"
import { notificationsService } from "./notifications.service"

/**
 *
 */
export class SensorConfigStorageService extends BaseHttpService {
    protected endpoint: Endpoint = "sensor-config"

    /**
     *
     * @returns
     */
    public async getUncached(): Promise<Application> {
        const Api = new Application(config)
        const baseUrl = await UrlConfig.getBaseUrl("sensor-config")
        Api.setHost(baseUrl)
        Api.setDefaultFetchSettings(
            await this.getHttpCredentials({})
        )
        Api.setRemoteEnabled()
        await Api.init()
        Api.addEventListener("update-fail", () => {
            notificationsService.notifyError("Could not save change, please try again later")
        })

        return Api
    }
}