import { Models } from "@ekko/predict-client-api"
import { SimulationCallHelper } from "./SimulationCallHelper"
import { AnyNode } from "@/store/SiteViewState"
import { ClimateHelper } from "./ClimateHelper"
import { httpService } from "@/core/services"
import { TimeBasedData } from "@/store/simulation-view/types"
import { ProvisioningState } from "predict-performance-calculation/dist/Interface"
import { NamedSimulationNode } from "../interfaces"
import { WorkloadMaxType } from "../enums/WorkloadMaxType"

/**
 *
 */
export class PeriodSimulationCallHelper extends SimulationCallHelper {
    /**
     *
     */
    readonly provisioningCheckMonths = 6

    /**
     *
     */
    private to: Date

    /**
     *
     * @param nodes
     * @param from
     * @param site
     * @param timeBasedData
     */
    constructor(nodes: AnyNode[], private from: Date,
        private site: Models.SiteModel, private timeBasedData: TimeBasedData
    ) {
        super(nodes)
        const endDate = new Date(from)
        endDate.setMonth(endDate.getMonth() + this.provisioningCheckMonths)
        this.to = endDate
    }

    async getItLoad(): Promise<Record<string, number>> {
        const loadProvisioning: Record<string, number> = {}
        for (const node of this.nodes) {
            const nodeRef = `${node.type}/${node.id}`
            if (node instanceof Models.ITNodeModel) {
                loadProvisioning[nodeRef] =
                    await this.itParam.getHighestProvisioning(node, this.from,
                        this.to, WorkloadMaxType.peak)
            } else if (node instanceof Models.OtherNodeModel) {
                loadProvisioning[nodeRef] =
                    await this.otherParam.getHighestProvisioning(node,
                        this.from, this.to, WorkloadMaxType.peak)
            }
        }
        return loadProvisioning
    }

    getNodesData(nodesData: NamedSimulationNode[]): NamedSimulationNode[] {
        return nodesData
    }

    async getProvisionedItLoad(): Promise<Record<string, number>> {
        const loadProvisioning: Record<string, number> = {}
        for (const node of this.nodes) {
            const nodeRef = `${node.type}/${node.id}`
            if (node instanceof Models.ITNodeModel) {
                loadProvisioning[nodeRef] =
                    await this.itParam.getHighestProvisioning(node, this.from,
                        this.to, WorkloadMaxType.provisioned)
            } else if (node instanceof Models.OtherNodeModel) {
                loadProvisioning[nodeRef] =
                    await this.otherParam.getHighestProvisioning(node,
                        this.from, this.to, WorkloadMaxType.provisioned)
            }
        }
        return loadProvisioning
    }

    getProvisioning(): Record<string, ProvisioningState> {
        return this.timeBasedData.provisioning
    }

    async getWeather() {
        const climateProfileRef =
            (await this.site.assertClimateProfileReference()).id
        const climateProfileData =
            (await httpService.getComputedClimate(climateProfileRef)).data

        let worstClimate!: { T2m: number, RH: number } | undefined
        for (const hourDatum of ClimateHelper.iterateClimateInRange(climateProfileData, this.from, this.to)) {
            if (!worstClimate) {
                worstClimate = hourDatum
            }
            if (worstClimate.T2m < hourDatum.T2m ||
                (worstClimate.T2m == hourDatum.T2m &&
                    worstClimate.RH < hourDatum.RH)
            ) {
                worstClimate = hourDatum
            }
        }
        if (!worstClimate) {
            throw new Error(
                "Internal error: could not detect applicable climate")
        }
        return ClimateHelper.fillWeatherReadingTemperature(null, worstClimate)
    }
}