import { DeviceModelType } from "predict-performance-calculation"
import { DeviceModelLabel, ITNodeLabel, MeterNodeLabel, MeterNodeType, OtherNodeLabel, OtherNodeType } from "../enums"
import { ITNodeType } from "../enums/ITNodeType"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

/**
 *
 */
export class NodeModelLabelHelper {

    /**
     * @param type
     */
    static get(type: string) {
        for (const [id, dmType] of Object.entries(DeviceModelType)) {
            if (dmType == type) {
                return DeviceModelLabel[id]
            }
        }
        for (const [id, dmType] of Object.entries(ITNodeType)) {
            if (dmType == type) {
                return ITNodeLabel[id]
            }
        }
        for (const [id, dmType] of Object.entries(OtherNodeType)) {
            if (dmType == type) {
                return OtherNodeLabel[id]
            }
        }
        for (const [id, dmType] of Object.entries(MeterNodeType)) {
            if (dmType == type) {
                return MeterNodeLabel[id]
            }
        }
        return capitalizeFirstLetter(type)
    }
}