import { AnyNodeModel } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"
import { NodeType } from "predict-performance-calculation"
import { Base } from "./Base"
import { Combiner } from "./Combiner"
import { CoolingMeterPoint } from "./CoolingMeterPoint"
import { Item } from "./Item"
import { ITNode } from "./ITNode"
import { OtherNode } from "./OtherNode"
import { PowerMeterPoint } from "./PowerMeterPoint"
import { Splitter } from "./Splitter"

/**
 *
 */
export class Factory {
    /**
     *
     * @param node
     * @returns
     */
    private static isCombiner(node: AnyNodeModel): node is Models.CombinerModel {
        return node.type == NodeType.Combiner
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isCoolingMeterPoint(node: AnyNodeModel): node is Models.CoolingMeterPointModel {
        return node.type == NodeType.CoolingMeterPoint
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isItem(node: AnyNodeModel): node is Models.ItemModel {
        return node.type == NodeType.Item
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isITNode(node: AnyNodeModel): node is Models.ITNodeModel {
        return node.type == NodeType.ITNode
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isOtherNode(node: AnyNodeModel): node is Models.OtherNodeModel {
        return node.type == NodeType.OtherNode
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isPowerMeterPoint(node: AnyNodeModel): node is Models.PowerMeterPointModel {
        return node.type == NodeType.PowerMeterPoint
    }
    /**
     *
     * @param node
     * @returns
     */
    private static isSplitter(node: AnyNodeModel): node is Models.SplitterModel {
        return node.type == NodeType.Splitter
    }
    /**
     *
     * @param node
     * @returns
     */
    static forNode<T extends AnyNodeModel>(node: T): Base<AnyNodeModel> | null {
        if(this.isCombiner(node)) {
            return new Combiner(node)
        } else if(this.isCoolingMeterPoint(node)) {
            return new CoolingMeterPoint(node)
        } else if(this.isItem(node)) {
            return new Item(node)
        } else if(this.isITNode(node)) {
            return new ITNode(node)
        } else if(this.isOtherNode(node)) {
            return new OtherNode(node)
        } else if(this.isPowerMeterPoint(node)) {
            return new PowerMeterPoint(node)
        } else if(this.isSplitter(node)) {
            return new Splitter(node)
        }
        return null
    }
}