import { NamedSimulationNode, SimulationSiteConfiguration } from "@/Simulation"
import { AnyNode, AnyNodeModel, NRef } from "@/store/SiteViewState"
import { Models } from "@ekko/predict-client-api"
import { DeviceModelType, NodeType } from "predict-performance-calculation"
import { ITNodeType, MeterNodeType, OtherNodeType, SplitterCombinerType } from "../enums"
import { PanelError } from "../interfaces"
import { LocalisedError } from "../interfaces/LocalisedError"
import { NodeReferenceHelper } from "./NodeReferenceHelper"

/**
 *
 */
type IconCode = OtherNodeType | ITNodeType | SplitterCombinerType | DeviceModelType | MeterNodeType.METER | "split-meter-cooling" | SplitterCombinerType.COMBINER
/**
 *
 */
export class NodeHelper {
    /**
     *
     */
    static readonly IconFileMap = {
        [DeviceModelType.COOLING_TOWER]: "cooling-tower",
        [DeviceModelType.CRAC]: "crac-unit",
        [DeviceModelType.DRY_COOLER]: "dry-cooler",
        [DeviceModelType.HEAT_EXCHANGER]: "heat-exchanger",
        [DeviceModelType.PUMP_CONDENSED]: "pump-simple",
        [DeviceModelType.SWITCH]: "switchgear",
        [ITNodeType.IT]: "it-load",
        [OtherNodeType.GENERAL_LOAD]: "general-load",
        "split-meter-cooling": "split-meter-cooling",
    }

    /**
     * Gets the reference of the supplied node, as well as its counterparts in
     * all other nodesets.
     *
     * @see NodeReferenceHelper#getNodeReference()
     *
     * @param node
     * @param nodeData
     * @param nodesets
     * @returns
     */
    static getNodeReferences(node: { type: string, id: string },
        nodeData: Pick<NamedSimulationNode, "persistentReference"> | undefined,
        nodesets: SimulationSiteConfiguration[] | null
    ) {
        if (nodeData && nodesets) {
            const persistentReference = nodeData.persistentReference
            const refs = new Set<string>()
            refs.add(`${node.type}/${node.id}`)
            for (const nodeset of nodesets) {
                let matchingNode: NamedSimulationNode | undefined
                for (const n of nodeset.nodes) {
                    if (n.type == node.type && n.id == node.id) {
                        // This is the "home nodeset" - ensure that it's not
                        // added a second time
                        matchingNode = undefined
                        break
                    } else if (n.persistentReference == persistentReference) {
                        matchingNode = n
                    }
                }

                // This can only add one
                if (matchingNode) {
                    refs.add(NodeReferenceHelper.getNodeReference(matchingNode))
                }
            }
            return [...refs]
        } else {
            return [`${node.type}/${node.id}`]
        }
    }

    /**
     *
     * @param node
     * @returns
     */
    static getPersistentReference(node: AnyNode) {
        return node.getPersistentReference() ?? `${node.type}:${node.getName()}`
    }

    /**
     *
     * @param node
     * @returns
     */
    static async getTypeOfNode(node: AnyNode) {
        switch (node.modelType) {
            case NodeType.Combiner:
                return SplitterCombinerType.COMBINER
            case NodeType.CoolingMeterPoint:
                return NodeType.CoolingMeterPoint
            case NodeType.PowerMeterPoint:
                return NodeType.PowerMeterPoint
            case NodeType.Item:
                {
                    if (!(node instanceof Models.ItemModel)) {
                        throw new Error("component.getIcon(): node not an ItemModel")
                    }
                    const mode = await node.getDeviceMode()
                    if(!mode) {
                        console.warn(`Missing device mode for ${node.type}/${node.id}`)
                        return DeviceModelType.UPS
                    }
                    const model = await mode.assertDeviceModel()
                    return model.getDeviceModelType() as DeviceModelType
                }
            case NodeType.Splitter:
                {
                    if (!(node instanceof Models.SplitterModel)) {
                        throw new Error("component.getIcon(): node not a SplitterModel")
                    }
                    const strategy = await node.getSplitterDynamicCostStrategy()
                    if (strategy) {
                        switch (strategy.type) {
                            case 'cheapOneCostStrategy': return SplitterCombinerType.ECONOMIZER
                            case 'shareCostStrategy': return SplitterCombinerType.SHARED
                            case 'priorityCostStrategy': return SplitterCombinerType.STAGED
                            case 'environmentCostStrategy': return SplitterCombinerType.TEMPERATURE
                            default:
                                console.warn("Unknown strategy " + strategy.type)
                                return SplitterCombinerType.TEMPERATURE
                        }
                    } else {
                        console.warn("No strategy for splitter:" + node.id)
                        return SplitterCombinerType.TEMPERATURE
                    }
                }

            case NodeType.OtherNode:
                {
                    if (!(node instanceof Models.OtherNodeModel)) {
                        throw new Error("component.getIcon(): node not a OtherNodeModel")
                    }
                    const type = node.getSupplyType()
                    switch (type) {
                        case 'lighting': return OtherNodeType.LIGHTING
                        case 'other': return OtherNodeType.GENERAL_LOAD
                        default:
                            console.warn('Unknown supply type ' + type)
                            return OtherNodeType.GENERAL_LOAD
                    }
                }
            default:
                return ITNodeType.IT
        }
    }

    /**
     *
     * @param node
     * @returns
     */
    static async getNodeIcon(node: AnyNode) {
        const type = await NodeHelper.getIconCode(node)
        const icon = NodeHelper.IconFileMap[type] || type
        return 'images/icons/icon-' + icon  + '-red.svg'
    }

    /**
     *
     * @param node
     * @returns
     */
    static async getNodeLabel(node: AnyNode) {
        if (node.type == NodeType.Item) {
            const deviceMode = await (node as Models.ItemModel).getDeviceMode()
            const deviceModel = await deviceMode.assertDeviceModel()
            const deviceModelName = deviceModel.getName()
            return `${node.getName()}: ${deviceModelName}`
        }

        return node.getName() ?? ''
    }

    /**
     *
     * @param node
     * @param issue
     * @returns
     */
    static async getPanelError(node: AnyNode, issue: LocalisedError): Promise<PanelError> {
        return {
            nodeRef: NodeReferenceHelper.getNodeReference(node),
            name: await this.getNodeLabel(node),
            icon: await this.getNodeIcon(node),
            issue
        }
    }

    /**
     *
     * @param node
     * @returns
     */
    static async getIconCode(node: AnyNode): Promise<IconCode> {
        const typeOfNode = await NodeHelper.getTypeOfNode(node)
        switch(typeOfNode) {
            case NodeType.CoolingMeterPoint:
                return "split-meter-cooling"
            case NodeType.PowerMeterPoint:
                return MeterNodeType.METER
            default:
                return typeOfNode
        }
    }

    /**
     * This is a convenience method to mark an object as a valid node reference
     * to the compiler.
     *
     * @param node
     * @returns
     */
    static nRef<T extends AnyNodeModel>(node: T) {
        return node as NRef<T>
    }

    /**
     * This is a convenience method to mark objects as valid node references
     * to the compiler.
     *
     * @param nodes
     * @returns
     */
    static nRefs<T extends AnyNodeModel>(nodes: T[]) {
        return nodes as NRef<T>[]
    }
}