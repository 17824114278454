import { DynamicStrategyType } from "../enums/DynamicStrategyType"
import { StrategySplitterLabel } from "../enums/StrategySplitterLabel"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

export class StrategySplitterLabelHelper {

    /**
     * @param type
     */
    static get(type: string) {
        for (const [id, dmType] of Object.entries(DynamicStrategyType)) {
            if (dmType == type) {
                return StrategySplitterLabel[id]
            }
        }
        return capitalizeFirstLetter(type)
    }
}