import { CommentsState } from "@/store/comments/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

type EntityModel =
  | Models.ItemModel
  | Models.ITNodeModel
  | Models.OtherNodeModel
  | Models.SplitterModel
  | Models.CombinerModel
  | Models.SiteModel
  | Models.CoolingMeterPointModel
  | Models.PowerMeterPointModel

class State implements Partial<CommentsState> {
  comments: Models.CommentModel[] = [];
  loaded = false
}

const getters: GetterTree<CommentsState, any> = {
  getComments(state): Models.CommentModel[] {
    const results = state.comments
      .sort((a: Models.CommentModel, b: Models.CommentModel) => {
        return new Date(a.getDate() as string) > new Date(b.getDate() as string) ? -1 : 1
      })

    return results
  },
  getComment: (state) => (id: string): Models.CommentModel | undefined => {
    return state.comments.find(m => (m as any).id === id)
  },
}

const mutations: MutationTree<State> = {
  setComments(state, comments): void {
    state.comments = [...comments]
  },
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  mutDeleteComment(state, comment: Models.CommentModel): void {
    state.comments = state.comments.filter(m => (m as any).id !== (comment as any).id)
  },
  mutCreateComment(state, comment: Models.CommentModel): void {
    state.comments.push(comment)
  },
  mutUpdateComment(state, comment: Models.CommentModel): void {
    state.comments = state.comments.map(m => {
      if ((m as any).id === (comment as any).id) {
        return comment
      }
      return m
    })
  },
}


const actions = <ActionTree<State, any>>{
  async fetchComments({ commit }, node: EntityModel) {
    const records = node.getComments()
    const recordsFound: Models.CommentModel[] = []
    for await (const item of records) {
      recordsFound.push(item)
    }
    // commit("setLoaded", true)
    commit("setComments", recordsFound)
  },
  deleteComment({ commit }, comment: Models.CommentModel) {
    commit("mutDeleteComment", comment)
  },
  createComment({ commit }, comment: Models.CommentModel) {
    commit("mutCreateComment", comment)
  },
  updateComment({ commit }, comment: Models.CommentModel) {
    commit("mutUpdateComment", comment)
  },
}

const CommentModule = {
  namespaced: true,
  state: {
    ...new State(),
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
}

export default CommentModule
