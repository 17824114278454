import { PowerCostMonthsState } from "@/store/power-cost-months/types"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { FiltersModule } from "../common/filters"
import { PaginationModule } from "../common/pagination"

class State implements Partial<PowerCostMonthsState> {
  powerCostMonths: Models.PowerCostMonthModel[] = [];
  loaded = false
}

const getters: GetterTree<PowerCostMonthsState, any> = {
  getPowerCostMonths(state): PaginatedResult<Models.PowerCostMonthModel> {
    let results = state.powerCostMonths
    const totalCount = results.length
    results = results.slice((state.page - 1) * state.pageSize, state.page * state.pageSize)

    return { results, totalCount }
  },
  getPowerCostMonth: (state) => (id: string): Models.PowerCostMonthModel | undefined => {
    return state.powerCostMonths.find(m => (m as any).id === id)
  },
  getIsLibraryEmpty(state): boolean {
    return state.loaded && state.powerCostMonths?.length === 0
  }
}

const mutations: MutationTree<State> = {
  setPowerCostMonths(state, powerCostMonths): void {
    state.powerCostMonths = [...powerCostMonths]
  },
  setLoaded(state, loaded: boolean): void {
    state.loaded = loaded
  },
  mutDeletePowerCostMonth(state, powerCostMonth: Models.PowerCostMonthModel): void {
    state.powerCostMonths = state.powerCostMonths.filter(m => (m as any).id !== (powerCostMonth as any).id)
  },
  mutCreatePowerCostMonth(state, powerCostMonth: Models.PowerCostMonthModel): void {
    state.powerCostMonths.push(powerCostMonth)
  },
  mutUpdatePowerCostMonth(state, powerCostMonth: Models.PowerCostMonthModel): void {
    state.powerCostMonths = state.powerCostMonths.map(m => {
      if ((m as any).id === (powerCostMonth as any).id) {
        return powerCostMonth
      }
      return m
    })
  },
}

const actions = <ActionTree<State, any>>{
  async fetchPowerCostMonths({ commit }, profileModel: Models.PowerCostProfileModel) {
    const records = profileModel.getPowerCostMonths()
    const recordsFound: Models.PowerCostMonthModel[] = []
    for await (const powerCostMonth of records) {
      recordsFound.push(powerCostMonth)
    }

    recordsFound.sort((a, b) => new Date(a.getMonth() as string) > new Date(b.getMonth() as string)? 1: -1)

    commit("setLoaded", true)
    commit("setPowerCostMonths", recordsFound)
  },
  deletePowerCostMonth({ commit }, powerCostMonth: Models.PowerCostMonthModel) {
    commit("mutDeletePowerCostMonth", powerCostMonth)
  },
  createPowerCostMonth({ commit }, powerCostMonth: Models.PowerCostMonthModel) {
    commit("mutCreatePowerCostMonth", powerCostMonth)
  },
  updatePowerCostMonth({ commit }, powerCostMonth: Models.PowerCostMonthModel) {
    commit("mutUpdatePowerCostMonth", powerCostMonth)
  },
  reset({ commit }) {
    commit("setPowerCostMonths", [])
    commit("setLoaded", false)
  }
}

const paginationStore = PaginationModule()
const filtersModule = FiltersModule()

const PowerCostMonthModule = {
  namespaced: true,
  state: {
    ...paginationStore.state,
    ...filtersModule.state,
    ...new State(),
  },
  getters: {
    ...paginationStore.getters,
    ...filtersModule.getters,
    ...getters
  },
  mutations: {
    ...paginationStore.mutations,
    ...filtersModule.mutations,
    ...mutations
  },
  actions: {
    ...paginationStore.actions,
    ...filtersModule.actions,
    ...actions
  },
}

export default PowerCostMonthModule
