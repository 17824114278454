import { DeviceModelType, NodeType } from "predict-performance-calculation"

/**
 *
 */
export abstract class NodeParameterBase<T extends {id: string, type: string}, N extends {id: string, type: NodeType}> {
    /**
     *
     * @param node
     * @returns
     */
    protected async fetchBasic(node: T) {
        return {
            id: node.id,
            type: node.type as N["type"],
        }
    }

    /**
     * Retrieves node parameter details
     * @param node
     */
    abstract fetch(node: T): Promise<N & { deviceModelType?: DeviceModelType | "it" | "other", id: string }>
}