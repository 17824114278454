import { Paginated } from "@/core/helpers/Paginated"
import { Models } from "@ekko/predict-client-api"
import { ActionTree, GetterTree, MutationTree } from "vuex"

import { PaginatedResult } from "../../core/interfaces/PaginatedResult"
import { ClimateProfile, ClimateProfileState } from "./types"

const getters = <GetterTree<ClimateProfileState, any>> {
    /**
     *
     * @param state
     * @returns
     */
    climateProfilesFilter(state): (climateProfile: ClimateProfile) => boolean {
        const columnFilter = new RegExp(state.columnFilter.text, "i")
        const searchFilter = new RegExp(state.searchText, "i")
        return (climateProfile: ClimateProfile) => {
            let textMatches
            if (state.columnFilter.name && state.columnFilter.text) {
                switch (state.columnFilter.name) {
                    case 'state':
                        textMatches = climateProfile?.location.getState()?.match(columnFilter)
                        break
                    case 'country':
                        textMatches = climateProfile?.location.getCountry()?.match(columnFilter)
                        break
                }
            } else {
                textMatches = climateProfile?.profile.getName()?.match(searchFilter)
            }


            const accessFilterMatches = state.accessFilter && state.accessFilter !== 'all'
                ? (climateProfile?.profile as any)?.getAccessLevel() === state.accessFilter
                : true

            return textMatches && accessFilterMatches
        }
    },
    /**
     *
     * @param state
     * @returns
     */
    climateProfilesSort(state): (a: ClimateProfile, b: ClimateProfile) => number {
        const reverse = (state.sort.direction === 'desc')
        switch (state.sort.field) {
            case "Access":
                return Paginated.sortUndefinedFirst(climateProfile => climateProfile.profile?.getAccessLevel(), reverse)
            case "Country":
                return Paginated.sortUndefinedFirst(climateProfile => climateProfile.location?.getCountry(), reverse)
            case "Location":
                return Paginated.sortUndefinedFirst(climateProfile => climateProfile.profile?.getName(), reverse)
            case "Type":
                return Paginated.sortUndefinedFirst(climateProfile => climateProfile.profile?.getClimateProfileType(), reverse)
            default:
                return (a, b) => 0
        }
    },
    /**
     *
     * @param state
     * @param getters
     * @returns
     */
    getClimateProfiles(state, getters): PaginatedResult<ClimateProfile> {
        return new Paginated(
            state.climateProfiles,
            state.page,
            state.pageSize,
            getters.climateProfilesSort,
            getters.climateProfilesFilter
        )
    },
    getIsLibraryEmpty(state): boolean {
        return state.loaded && state.climateProfiles?.length === 0
    }
}

const mutations = <MutationTree<ClimateProfileState>>{
    setClimateProfiles(
        state,
        payload: {
            climateProfiles: Models.ClimateProfileReferenceModel[],
            climateLocations: Models.ClimateLocationModel[],
            climateGeoLocations: Models.ClimateGeoLocationModel[]
        }
    ): void {
        const { climateProfiles, climateLocations, climateGeoLocations } = payload

        state.climateProfiles = climateProfiles.map(profile => {
            return {
                profile,
                location: climateLocations.find(
                    l => (l as any).data.relationships?.climateProfileReference?.data?.id === profile.id
                ) as Models.ClimateLocationModel,
                geoLocation: climateGeoLocations.find(
                    l => (l as any).data.relationships?.climateProfileReference?.data?.id === profile.id
                ) as Models.ClimateGeoLocationModel,
            }
        })
    },
    setLoaded(state, loaded: boolean): void {
        state.loaded = loaded
    }
}

const actions = <ActionTree<ClimateProfileState, any>>{

}

export { getters, mutations, actions }
