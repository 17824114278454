import { NamedSimulationNode, ProvisionedStatistic } from "@/Simulation/interfaces"
import { SimulationOverTimeData } from "@/Simulation/interfaces/filters/SimulationOverTimeData"
import { SimulationData, SimulationOverTime, TimeBasedData } from "@/store/simulation-view/types"
import { Interface } from "predict-performance-calculation"
import { FiltersCalculationHelper } from "../FiltersCalculationHelper"

/**
 *
 */
export class SimulationOverTimeFilter extends FiltersCalculationHelper {
    /**
     * Produces provisioned and utilised data compiled from the simulation over time data
     *
     * @param nodes
     * @param simulationDataOverTime
     * @param modelCapacities
     * @returns
     */
    static produceData(nodes: NamedSimulationNode[], simulationDataOverTime: SimulationOverTime,
        modelCapacities: Map<string, number>) {
        const contemporaryProvisioningStates = new Map<string, Interface.ProvisioningState>()
        const utilisedOutputs = new Map<string, ProvisionedStatistic>()
        const provisionedOutputs = new Map<string, ProvisionedStatistic>()

        for (const [, simulationDataSlice] of Object.entries(simulationDataOverTime).sort(([a], [b]) => a.localeCompare(b))) {
            const timeBasedData = simulationDataSlice?.timeBasedData
            if (timeBasedData) {
                for (const [ref, provisioningState] of Object.entries(timeBasedData.provisioning)) {
                    contemporaryProvisioningStates.set(ref, provisioningState)
                }
                if (simulationDataSlice.simulationData) {
                    const simulationData = simulationDataSlice.simulationData as SimulationData
                    this.getUtilisedStatistics(nodes, simulationData, contemporaryProvisioningStates, utilisedOutputs,
                        timeBasedData, modelCapacities)
                }
                if (simulationDataSlice.provisionedSimulationData) {
                    const provisionedSimulationData = simulationDataSlice.provisionedSimulationData as SimulationData
                    this.getProvisionedStatistics(provisionedSimulationData, contemporaryProvisioningStates,
                        provisionedOutputs)
                }
            }
        }

        if(utilisedOutputs.size == 0) {
            return null
        }

        const precalculatedData: SimulationOverTimeData = {}

        for (const [ref] of utilisedOutputs.entries()) {
            const node = this.getNodeByReference(nodes, ref)
            const name = node?.name as string
            const provisioned = this.getCalculatedProvisionedValues(ref, provisionedOutputs)
            const utilised = this.getCalculatedUtilisedValues(ref, utilisedOutputs)

            precalculatedData[ref] = {
                name,
                provisioned,
                utilised,
            }
        }

        return precalculatedData
    }

    /**
     * Calculates provisioned average and max from statistics
     * @param nodeRef
     * @param outputs
     * @returns
     */
    private static getCalculatedProvisionedValues(nodeRef: string,
        outputs: Map<string, ProvisionedStatistic>) {
        const values = {
            average: 0,
            max: 0
        }
        const output = outputs.get(nodeRef)
        if (output) {
            if (output.count > 0) {
                values.average = output.sum / output.count
                values.max = output.max
            }
        }
        return values
    }

    /**
     * Calculates utilised average and max from statistics
     * @param nodeRef
     * @param outputs
     * @returns
     */
    private static getCalculatedUtilisedValues(nodeRef: string,
        outputs: Map<string, ProvisionedStatistic>) {
        const values = {
            average: 0,
            max: 0,
        }
        const output = outputs.get(nodeRef)
        if (output) {
            if (output.count > 0) {
                values.average = output.sum / output.count
                values.max = output.max
            }
        }
        return values
    }


    /**
     *
     * @param nodes
     * @param data
     * @param contemporaryProvisioningStates
     * @param outputs
     * @param timeBasedData
     * @param modelCapacities
     */
    private static getUtilisedStatistics(nodes: NamedSimulationNode[], data: SimulationData,
        contemporaryProvisioningStates: Map<string, Interface.ProvisioningState>, outputs: Map<string, ProvisionedStatistic>,
        timeBasedData: TimeBasedData, modelCapacities: Map<string, number>
    ) {
        for (const [ref, [, powerLoad, coolingLoad]] of Object.entries(data)) {

            const provisioningState = contemporaryProvisioningStates.get(ref)
            const value = this.getAnyDesignValue(provisioningState, powerLoad, coolingLoad)

            if (!outputs.has(ref)) {
                outputs.set(ref, { max: 0, sum: 0, count: 0 })
            }
            const stats = outputs.get(ref)
            if (stats) {
                stats.max = Math.max(stats.max, value)
                stats.sum += value
                stats.count++
            }
        }
    }

    /**
    * Retrieves provisioned stats from its simulation data
    * @param data
    * @param contemporaryProvisioningStates
    * @param outputs
    */
    private static getProvisionedStatistics(data: SimulationData,
        contemporaryProvisioningStates: Map<string, Interface.ProvisioningState>,
        outputs: Map<string, ProvisionedStatistic>) {

        for (const [ref, [, powerLoad, coolingLoad]] of Object.entries(data)) {

            const provisioningState = contemporaryProvisioningStates.get(ref)
            const value = this.getAnyDesignValue(provisioningState, powerLoad, coolingLoad)

            if (!outputs.has(ref)) {
                outputs.set(ref, { max: 0, sum: 0, count: 0 })
            }
            const stats = outputs.get(ref)
            if (stats) {
                stats.max = Math.max(stats.max, value)
                stats.sum += value
                stats.count++
            }
        }
    }


    /**
     * Gets type design value from total load and design capacity
     * @param provisioningState
     * @param powerLoad
     * @param coolingLoad
     * @returns
     */
    private static getAnyDesignValue(provisioningState: Interface.ProvisioningState | undefined, powerLoad: number, coolingLoad: number) {
        let value = 0
        if (provisioningState?.designCapacity) {
            value = (powerLoad + coolingLoad) / provisioningState.designCapacity
        }
        return value
    }
}